/**
 * type: 1、value 代表数字，直接赋值；2、enum 代表枚举，需要对应赋值；3、range 代表区间，在某个范围内有代表意义；4、static 固定值
 */

export const assistantMap = [
    {
        code: '5768',
        dataIndex: 'hemoglobinValue',
        type: 'value',
    }, {
        code: '5793',
        dataIndex: 'whiteBloodCellCount',
        type: 'value',
    }, {
        code: '5785',
        dataIndex: 'plateletCount',
        type: 'value',
    }, {
        code: '5614',
        dataIndex: 'ferritin',
        type: 'value',
    }, {
        code: '5511',
        dataIndex: 'glycatedHemoglobin',
        type: 'value',
    }, {
        code: '5043',
        dataIndex: 'bloodSugarType',
        type: 'value',
    }, {
        code: '5046',
        // 不确定与 ogtt2h 是否是一致的
        dataIndex: '',
        type: 'value',
    }, {
        code: '5040',
        dataIndex: 'ogtt1h',
        type: 'value',
    }, {
        code: '5041',
        dataIndex: 'ogtt2h',
        type: 'value',
    }, {
        code: '5042',
        dataIndex: 'ogtt3h',
        type: 'value',
    }, {
        code: '5008',
        dataIndex: 'albumin',
        type: 'value',
    }, {
        code: '5083',
        dataIndex: 'totalBilirubin',
        type: 'value',
    }, {
        // 这里页面上和文档对不上一个是综合胆红素一个是结合胆红素
        code: '5029',
        dataIndex: 'conjugatedBilirubin',
        type: 'value',
    }, {
        code: '5026',
        dataIndex: 'serumCreatinine',
        type: 'value',
    }, {
        code: '5089',
        dataIndex: 'bloodUreaNitrogen',
        type: 'value',
    }, {
        code: '5619',
        dataIndex: 'ft3',
        type: 'value',
    }, {
        code: '5618',
        dataIndex: 'ft4',
        type: 'value',
    }, {
        // 暂无对应关系
        code: '5010',
        dataIndex: 'alt',
        type: 'value',
    }, {
        code: '5017',
        dataIndex: 'ast',
        type: 'value',
    }, {
        code: '5664',
        dataIndex: 'tsh',
        type: 'value',
    }, {
        code: '5659',
        dataIndex: 'tt3',
        type: 'value',
        format: (v) => v * 1000
    }, {
        code: '5666',
        dataIndex: 'tt4',
        type: 'value',
        format: (v) => v * 1000
    }, {
        code: '5594',
        dataIndex: 'atg',
        type: 'value',
    }, {
        code: '5595',
        dataIndex: 'atpo',
        type: 'value',
    }, {
        code: '5794',
        dataIndex: 'aboBloodType',
        type: 'enum',
        value: {
            '“O”型': "O型", '“A”型': "A型", '“B”型': "B型", '“AB”型': "AB型"
        },
        default: "不祥"
    }, {
        code: '5755',
        dataIndex: 'rhBloodType',
        type: 'enum',
        value: {
            "阴性": "阴性",
            "阳性": "阳性"
        }
    }, {
        code: '5683',
        dataIndex: 'urineSugar',
        type: 'enum',
        value: {
            '-': '阴性', '+-': '±', '1+': '+', '2+': '++', '3+': '+++', '4+': '++++'
        },
        default: "未查"
    }, {
        code: '5699',
        dataIndex: 'urineProtein',
        type: 'enum',
        value: {
            '-': '阴性', '+-': '±', '1+': '+', '2+': '++', '3+': '+++', '4+': '++++'
        },
        default: "未查"
    }, {
        code: '5689',
        dataIndex: 'urineKetone',
        type: 'enum',
        value: {
            '-': '阴性', '+-': '±', '1+': '+', '2+': '++', '3+': '+++', '4+': '++++'
        },
        default: "未查"
    }, {
        code: '5690',
        dataIndex: 'urineWhiteBloodCell',
        type: 'enum',
        value: {
            '-': '阴性', '+-': '±', '1+': '+', '2+': '++', '3+': '+++', '4+': '++++'
        },
        default: "未查"
    }, {
        code: '5672',
        dataIndex: 'urineOccultBlood',
        type: 'enum',
        value: {
            '-': '阴性', '+-': '±', '1+': '+', '2+': '++', '3+': '+++', '4+': '++++'
        },
        default: "未查"
    }, {
        code: '5438',
        dataIndex: 'virginalCleanliness',
        type: 'enum',
        value: {
            'Ⅰ°': "Ⅰ度", 'Ⅱ°': "Ⅱ度", 'Ⅲ°': "Ⅲ度", 'Ⅳ°': "Ⅳ度"
        },
        default: "未查"
    }, {
        code: '5485',
        dataIndex: 'hivAntibody',
        type: 'enum',
        value: {
            "阴性": "阴性",
            "阳性": "阳性"
        }
    }, {
        code: '5469',
        dataIndex: 'hbsag',
        type: 'value',
    }, {
        code: '6122',
        // <0.050 或者是没有符号，直接一个数值通过labTestItemReferRange字段，在之间就阴性，超过就阳性
        dataIndex: 'hbsag',
        type: 'range',
        range: 'labTestItemReferRange',
        value: ['阴性', '阳性']
    }, {
        // 阴道分泌物
        code: 'ydfmw',
        dataIndex: 'virginalDischarge',
        type: 'enum',
        value: {
            // 未见异常，滴虫，假丝酵母菌，其他，细菌性阴道病
        }
    }, {
        code: '5467',
        dataIndex: 'hbeag',
        type: 'value',
    }, {
        code: '6124',
        // <0.050 或者是没有符号，直接一个数值通过labTestItemReferRange字段，在之间就阴性，超过就阳性
        dataIndex: 'hbeag',
        type: 'range',
        range: 'labTestItemReferRange',
        value: ['阴性', '阳性']
    }, {
        code: '5466',
        // 阴性
        dataIndex: 'hbeab',
        type: 'value',
    }, {
        code: '5623',
        // <0.050 或者是没有符号，直接一个数值通过labTestItemReferRange字段，在之间就阴性，超过就阳性
        dataIndex: 'hbeab',
        type: 'range',
        range: 'labTestItemReferRange',
        value: ['阴性', '阳性']
    }, {
        code: '5464',
        // 阴性
        dataIndex: 'hbcab',
        type: 'value',
    }, {
        code: '6126',
        // <0.050 或者是没有符号，直接一个数值通过labTestItemReferRange字段，在之间就阴性，超过就阳性
        dataIndex: 'hbcab',
        type: 'range',
        range: 'labTestItemReferRange',
        value: ['阴性', '阳性']
    }, {
        code: '5468',
        // 阴性
        dataIndex: 'hbsab',
        type: 'value',
    }, {
        code: '6123',
        // <0.050 或者是没有符号，直接一个数值通过labTestItemReferRange字段，在之间就阴性，超过就阳性
        dataIndex: 'hbsab',
        type: 'range',
        range: 'labTestItemReferRange',
        value: ['阴性', '阳性']
    },
    // {
    //     // 沙眼衣原体
    //     code: '没有',
    //     dataIndex: 'trachomatis',
    //     type: 'custom',
    // }, {
    //     // 淋球菌检测
    //     code: '没有',
    //     dataIndex: 'gonorrhoeaeTest',
    //     type: 'custom',
    // }, 
    // 检测方法
    {
        code: '5492',
        dataIndex: 'tpAntibodyTestMethod',
        type: 'static',
        value: 'TRUST'
    },
    // 检测方法
    {
        code: '5489',
        dataIndex: 'tpAntibodyTestMethod',
        type: 'static',
        value: 'RPR'
    },
    // 检测方法
    {
        code: '6531',
        dataIndex: 'tpAntibodyTestMethod',
        type: 'static',
        value: '其他'
    },
    // 检测结果
    {
        code: '5492',
        dataIndex: 'tpAntibodyTestResult',
        type: 'value',
    },
    // 检测结果
    {
        code: '5489',
        dataIndex: 'tpAntibodyTestResult',
        type: 'value',
    },
    // 检测结果
    {
        code: '6531',
        dataIndex: 'tpAntibodyTestResult',
        type: 'range',
        range: [0, 1],
        value: ['阴性', '阳性']
    }
]

export const amyItems = [
    '7268',
    '6712',
    '4160',
    '5851',
    '6137',
    '4156',
    '4157',
    '4484',
    '6713',
    '3060',
    '4816',
    '2719',
    '4817'
];

export const assistantItems = [
    '2167',
    '5241',
    '7144',
    '7670',
    '4187',
    '4343',
    '6091',
    '6415',
    '4136',
    '4137',
    '4748',
    '4141',
    '7112',
    '7267',
    '2709',
    '2710',
    '4160',
    '5851',
    '5852',
    '4484',
    '8012',
    '4988',
    '4989',
    '4990',
    '4991',
    '4992',
    '6089',
    '6090',
    '2539',
    '2556',
    '2560',
    '2652',
    '2655',
    '4163',
    '4740',
    '0000093275',
    '2553',
    '6688',
    '2382',
    '4186',
    '2819',
    '7296',
    '2832',
    '6161',
    '5274',
    '3056',
    '0000093177',
    '7159',
    '7769',
    '7828',
    '7832',
    '6681',
    '2457',
    '6600',
    '7324'
];

export const ultrasoundItems = [
    '0000006888',
    '0000006898',
    '0000006899',
    '0000006900',
    '0000006901',
    '0000006902',
    '0000006939',
    '0000006940',
    '0000007001',
    '0000007002',
    '0000007003',
    '0000007015',
    '0000007010',
    '9000007010',
    '9400007010',
    '9500007010',
    '9600007010',
    '9700007010',
    '9800007010',
    '9900007010',
    '00000027093',
    '0000006903',
    '0000006904',
    '0000006905',
    '0000006916',
    '0000006947',
    '0000006950',
    '0000006951',
    '0000006956',
    '0000006957',
    '0000007011',
    '0000007017',
    '0000007018',
    '0000007019',
    '0000007020',
    '00000027042',
    '00000027142',
    '7000007011',
    '7400007011',
    '7500007011',
    '7600007011',
    '7700007011',
    '7800007011',
    '7900007011',
    '8000007011',
    '9000007011',
    '9400007011',
    '9400007018',
    '9400007020',
    '9500007011',
    '9500007018',
    '9500007020',
    '9600007011',
    '9600007018',
    '9600007020',
    '9700007011',
    '9700007018',
    '9700007020',
    '9800007011',
    '9800007018',
    '9800007020',
    '9900007011',
    '9900007018',
    '9900007020',
    '0000006834',
]

export const ecgItems = [
    '0000006845'
]
import React, { useState, useRef, useEffect } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  VSForm,
  VSFormDependency,
  VSFormFooter,
  VSFormFooterSubmit,
  VSFormFooterReset,
  VSFormItem,
  VSEditableTable,
  VSTableToolbar,
  VSEditableTableAddition,
  VSTableColumn,
  Modal,
  message,
} from '@vs/vsf-kit';
import { Cascader } from 'antd';
import {
  IdNumRegExp,
  phoneRegExp,
  disabledDate,
  formatDate,
  showDateFormat,
  disabledFutureDate,
  disableLmpDate,
} from '@/utils/rule';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import ImportModal from '@/components/import-modal';
import antenatalServices from '@/services/antenatal';
import gravidaServices from '@/services/gravida';
import staticCommonService from '@/module/common/services';
import CollapseBlock from '@/components/collapse-block';
import GestationalWeek from '@/components/gestational-week';
import HighriskTag from '@/components/highrisk/tag';
import Testmethod from '@/components/test-method';
import BloodPressure from '@/components/blood-pressure';
import Dayrange from '@/components/day-range';
import { useCallBackRef, getGestationalWeek } from '@/utils';
import VsfFormLayout, {
  VsfFormLayoutDefine,
  FormBlock,
  FormBlock2,
} from '@/components/form-layout';
import {
  labelColWidth,
  radioDataSource,
  radioDataSource1,
  radioDataSource2,
  radioDataSource3,
  radioDataSource4,
  radioDataSourCeoverallAssessment,
} from '@/config';
import { unwrapObject, wrapObject } from '@/components/utils';
import { formatImportData } from '../utils';

const Index = props => {
  const { common } = vsf?.stores;
  const { enums, addressData } = common;
  const { visible, onCancel, onOk, importData, data } = props;
  const mockId = useRef(1000000);
  const [currentImportData, setCurrentImportData] = useState();
  const [visibleImport, setVisibleImport] = useState(false);
  const [pregnancyHistory, setPregnancyHistory] = useState([]);
  const [mentalRetardation, setmentalRetardationValue] = useState({
    mentalRetardation: false,
    clan: false,
  });

  const [hisDoctorInfo, setHisDoctorInfo] = useState(common?.hisDoctorInfo);

  const [isRef, formRef] = useCallBackRef();

  const handleSubmit = async () => {
    const values = await formRef?.current?.validateFields();
    if (values?.bto_param?.husbandMentalRetardation) {
      values.bto_param.husbandMentalRetardationLevel =
        values?.bto_param?.husbandMentalRetardation === '无'
          ? undefined
          : values?.bto_param?.husbandMentalRetardationLevel;
    }
    if (values?.bto_param?.mentalRetardation) {
      values.bto_param.mentalRetardationLevel =
        values?.bto_param?.mentalRetardation === '无'
          ? undefined
          : values?.bto_param?.mentalRetardationLevel;
    }
    console.log('校验表单并返回格式化后的所有数据：', values);

    const btoParam = values?.bto_param;
    console.log(values?.bto_param, 'values?.bto_param;');
    const formatData = {
      ...unwrapObject(btoParam),
      source: 'DOCTOR_ENTER',
      status: 'NORMAL',
      gravidaRecordId: common?.gravidaRecordId,
      inspectionTime: formatDate(btoParam?.inspectionTime),
      lmp: formatDate(btoParam?.lmp),
      edc: formatDate(moment(btoParam.lmp).add(40, 'w')),
      hbFiveItemReportTime: formatDate(btoParam?.hbFiveItemReportTime),
      tpAntibodyReportTime: formatDate(btoParam?.tpAntibodyReportTime),
      nonTpAntibodyReportTime: formatDate(btoParam?.nonTpAntibodyReportTime),
      hivReportTime: formatDate(btoParam?.hivReportTime),
      followupTime: formatDate(btoParam?.followupTime),
      enterTime: formatDate(btoParam?.enterTime),
      earlyPregnancyAbnormalDetail: (
        btoParam?.earlyPregnancyAbnormalDetail || []
      ).join(),
      healthGuidance: (btoParam?.healthGuidance || []).join(),
    };

    antenatalServices
      .createFirstInspectionsRecord({
        btoParam: formatData,
      })
      .then(res => {
        if (res?.code === 200) {
          message.success(res?.message);
          handleCancel();
          vsf?.setStoreValue('gravida.createRecordVisible', false);
        } else {
          message.error(res?.message || '提交失败');
        }
      });
  };

  // const pregnancyHistoryEditableProp = {
  //   editType: 'multiple',
  //   onCanEdit: v => true,
  //   onChange: v => {
  //     setPregnancyHistory(v);
  //   },
  //   deleteAction: {
  //     onAction: data => {
  //       return new Promise(resolve => {
  //         const newData = [...(pregnancyHistory || [])];
  //         const index = newData.findIndex(item => data.id === item.id);
  //         newData.splice(index, 1);
  //         setPregnancyHistory(newData);
  //         resolve(true);
  //       });
  //     },
  //   },
  // };

  const handleOk = () => {
    handleSubmit();
  };
  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleSetValues = data => {
    if (data) {
      formRef?.current?.setFieldsValue({
        bto_param: {
          ...wrapObject(data, ['range', 'address']),
          earlyPregnancyAbnormalDetail:
            data?.earlyPregnancyAbnormalDetail?.split(',') || [],
          healthGuidance: data?.healthGuidance?.split(',') || [],
          ...(data?.diastolicPressure && data?.systolicPressure
            ? {
                bloodPressure: {
                  diastolicPressure: data?.diastolicPressure,
                  systolicPressure: data?.systolicPressure,
                },
              }
            : {}),
          enterTime: formatDate(new Date()),
        },
      });
    }
  };

  const handleImportData = data => {
    const value = formatImportData(data);
    setmentalRetardationValue({
      mentalRetardation: value?.husbandMentalRetardation === '无',
      clan: value?.mentalRetardation === '无',
    });
    formRef?.current?.setFieldsValue({
      bto_param: value,
    });
    message.success('导入成功');
  };

  const getHisDoctorList = async () => {
    const result = await common.getHisDoctorList();
    console.log(result, 'result');
    setHisDoctorInfo(result);
  };

  const handleChange = (record, allValues) => {
    if (record?.bto_param?.enterPerson) {
      const current = hisDoctorInfo?.doctor?.find(
        item => item.value === record?.bto_param?.enterPerson,
      );
      formRef?.current?.setFieldsValue({
        bto_param: {
          enterOrg: current?.origin?.orgId,
        },
      });
    }
    if (record?.bto_param?.lmp) {
      formRef?.current?.setFieldsValue({
        bto_param: {
          gestationalWeek: getGestationalWeek({
            lmp: record?.bto_param?.lmp,
          }),
          edc: moment(record?.bto_param?.lmp)
            .add(40, 'w')
            .format('YYYY-MM-DD'),
        },
      });
    }
  };

  useEffect(() => {
    if (isRef) {
      getHisDoctorList();
      handleSetValues(data);
    }
  }, [data, isRef]);

  if (!data) return null;

  console.log(formRef?.current?.getFieldsValue(), 'formRef');
  return (
    <Modal
      width={1400}
      className="e-medical-record-city-screening-modal"
      title="首诊产检记录"
      keyboard={true}
      destroyOnClose={true}
      forceRender={true}
      okText="保存"
      cancelText="取消"
      visible={visible}
      id="city-screening-modal"
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
    >
      <div className="e-medical-record-detail-container">
        <div className="detail-content">
          <ImportModal
            title="数据导入"
            visible={visibleImport}
            importData={currentImportData}
            onCancel={() => {
              setVisibleImport(false);
            }}
            onOk={v => {
              handleImportData(v);
              setVisibleImport(false);
            }}
          />
          <VSForm
            layout="horizontal"
            formRef={formRef}
            labelCol={labelColWidth[5]}
            onValuesChange={handleChange}
          >
            <CollapseBlock
              title="孕妇基本信息"
              description=""
              error=""
              id="anchor-base"
            >
              <VsfFormLayout columnCount={3}>
                <VSFormItem
                  label="孕妇姓名"
                  name={['bto_param', 'name']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="年龄"
                  name={['bto_param', 'age']}
                  valueType="digit"
                  fieldProps={{
                    min: 1,
                    controls: false,
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="电话"
                  name={['bto_param', 'cellphone']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                  rules={[
                    {
                      pattern: phoneRegExp,
                      message: '电话格式错误',
                    },
                  ]}
                ></VSFormItem>
                <VSFormItem
                  label="农业户籍"
                  name={['bto_param', 'accountType']}
                  dataSource={enums['account_type']}
                  valueType="select"
                  fieldProps={{
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="人员状态"
                  name={['bto_param', 'personnelStatus']}
                  valueType="select"
                  dataSource={enums['personnel_status']}
                  fieldProps={{
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="证件号码"
                  name={['bto_param', 'idNum']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                  rules={[
                    {
                      pattern: IdNumRegExp,
                      message: '证件号码格式错误',
                    },
                  ]}
                ></VSFormItem>
              </VsfFormLayout>
              <VsfFormLayout columnCount={1}>
                <VSFormItem
                  label="工作单位"
                  name={['bto_param', 'workplace']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
              </VsfFormLayout>
              <VsfFormLayout columnCount={3}>
                <VSFormItem
                  label="丈夫姓名"
                  name={['bto_param', 'husbandName']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="丈夫年龄"
                  name={['bto_param', 'husbandAge']}
                  valueType="digit"
                  fieldProps={{
                    min: 1,
                    precision: 0,
                    addonAfter: '岁',
                    controls: false,
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="丈夫电话"
                  name={['bto_param', 'husbandCellphone']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                  rules={[
                    {
                      pattern: phoneRegExp,
                      message: '电话格式错误',
                    },
                  ]}
                ></VSFormItem>
              </VsfFormLayout>
              <VsfFormLayout columnCount={1}>
                <VSFormItem
                  label="工作单位"
                  name={['bto_param', 'husbandWorkplace']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
              </VsfFormLayout>
              <VsfFormLayout columnCount={1}>
                <VsfFormLayoutDefine columnSpan={[1, 2]}>
                  <VSFormItem
                    label="户口所在地址"
                    name={['bto_param', 'registered']}
                    valueType="custom"
                  >
                    <Cascader placeholder="请选择" options={addressData} />
                  </VSFormItem>
                  <VSFormItem
                    label=""
                    name={['bto_param', 'registeredDetailAddress']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine columnSpan={[1, 2]}>
                  <VSFormItem
                    label="现住地址"
                    name={['bto_param', 'current']}
                    valueType="custom"
                  >
                    <Cascader placeholder="请选择" options={addressData} />
                  </VSFormItem>
                  <VSFormItem
                    label=""
                    name={['bto_param', 'currentDetailAddress']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine columnSpan={[1, 2]}>
                  <VSFormItem
                    label="产后修养地址"
                    name={['bto_param', 'post']}
                    valueType="custom"
                  >
                    <Cascader placeholder="请选择" options={addressData} />
                  </VSFormItem>
                  <VSFormItem
                    label=""
                    name={['bto_param', 'postDetailAddress']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
              </VsfFormLayout>
            </CollapseBlock>
            <CollapseBlock
              title="基本信息"
              description=""
              error=""
              id="anchor-base"
            >
              <VsfFormLayout columnCount={3}>
                <VSFormItem
                  label="产检日期"
                  name={['bto_param', 'inspectionTime']}
                  valueType="date"
                  fieldProps={{
                    disabledDate: disabledFutureDate,
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="末次月经"
                  name={['bto_param', 'lmp']}
                  valueType="date"
                  fieldProps={{
                    disabledDate: disableLmpDate,
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="产检孕周"
                  name={['bto_param', 'gestationalWeek']}
                  valueType="custom"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                >
                  <GestationalWeek disabled />
                </VSFormItem>

                <VSFormItem
                  label="预产期"
                  name={['bto_param', 'edc']}
                  valueType="date"
                  fieldProps={{
                    disabledDate,
                    format: showDateFormat,
                    disabled: true,
                    placeholder: '请输入',
                  }}
                ></VSFormItem>

                <VsfFormLayout columnCount={2} labelCol={labelColWidth[5]}>
                  <VSFormItem
                    label="孕次"
                    name={['bto_param', 'gravidity']}
                    valueType="digit"
                    fieldProps={{
                      precision: 0,
                      addonAfter: '次',
                      placeholder: '请输入',
                      controls: false,
                      min: 1,
                      max: 10,
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="产次"
                    name={['bto_param', 'parity']}
                    valueType="digit"
                    fieldProps={{
                      precision: 0,
                      addonAfter: '次',
                      placeholder: '请输入',
                      controls: false,
                      min: 1,
                      max: 10,
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
                <VsfFormLayout columnCount={2} labelCol={labelColWidth[5]}>
                  <VSFormItem
                    label="阴道分娩"
                    name={['bto_param', 'virginalBirth']}
                    valueType="digit"
                    fieldProps={{
                      precision: 0,
                      addonAfter: '天',
                      placeholder: '请输入',
                      controls: false,
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="剖宫产"
                    name={['bto_param', 'cesareanBirth']}
                    valueType="digit"
                    fieldProps={{
                      precision: 0,
                      addonAfter: '天',
                      placeholder: '请输入',
                      controls: false,
                    }}
                  ></VSFormItem>
                </VsfFormLayout>

                <VSFormItem
                  label="民族"
                  name={['bto_param', 'nation']}
                  valueType="select"
                  dataSource={enums['nation']}
                  fieldProps={{
                    showSearch: true,
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="文化程度"
                  name={['bto_param', 'education']}
                  valueType="select"
                  dataSource={enums['educational_level']}
                  fieldProps={{
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="职业"
                  name={['bto_param', 'occupation']}
                  valueType="select"
                  dataSource={enums['occupation']}
                  fieldProps={{
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
              </VsfFormLayout>
              <VsfFormLayoutDefine
                labelCol={labelColWidth[5]}
                columnSpan={[1, 2]}
              >
                <VSFormItem
                  label="现病史"
                  name={['bto_param', 'hpi']}
                  valueType="radio"
                  dataSource={radioDataSource3}
                  fieldProps={{
                    className: 'ant-radio-checkbox-border',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="现病史详述"
                  name={['bto_param', 'hpiDetail']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
              </VsfFormLayoutDefine>
              <VsfFormLayoutDefine
                labelCol={labelColWidth[5]}
                columnSpan={[1, 2]}
              >
                <VSFormItem
                  label="既往史"
                  name={['bto_param', 'pastHistory']}
                  valueType="radio"
                  dataSource={radioDataSource3}
                  fieldProps={{
                    className: 'ant-radio-checkbox-border',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="既往史详述"
                  name={['bto_param', 'pastHistoryDetail']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
              </VsfFormLayoutDefine>
              <VsfFormLayoutDefine
                labelCol={labelColWidth[5]}
                columnSpan={[1, 2]}
              >
                <VSFormItem
                  label="主诉"
                  name={['bto_param', 'mainComplaint']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '',
                    disabled: true,
                  }}
                ></VSFormItem>
              </VsfFormLayoutDefine>
            </CollapseBlock>
            <CollapseBlock
              title="病史信息"
              description=""
              error=""
              id="anchor-medical"
            >
              <FormBlock2 title="孕妇家族史">
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="遗传病史"
                    name={['bto_param', 'geneticHistory']}
                    valueType="radio"
                    dataSource={radioDataSource}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="遗传病史详述"
                    name={['bto_param', 'geneticHistoryDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="精神病史"
                    name={['bto_param', 'mentalDiseaseHistory']}
                    valueType="radio"
                    dataSource={radioDataSource}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="精神病史详述"
                    name={['bto_param', 'mentalDiseaseHistoryDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="畸形"
                    name={['bto_param', 'malformation']}
                    valueType="radio"
                    dataSource={radioDataSource}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="畸形详述"
                    name={['bto_param', 'malformationDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 1, 1]}
                >
                  <VSFormItem
                    label="智障"
                    name={['bto_param', 'mentalRetardation']}
                    valueType="radio"
                    dataSource={radioDataSource}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                      onChange: e => {
                        setmentalRetardationValue({
                          ...mentalRetardation,
                          clan: e === '无',
                        });
                      },
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="智障程度"
                    name={['bto_param', 'mentalRetardationLevel']}
                    valueType="radio"
                    dataSource={radioDataSource2}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                      disabled: mentalRetardation.clan,
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="智障病史详述"
                    name={['bto_param', 'mentalRetardationDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="其他家族史"
                    name={['bto_param', 'otherFamilyHistory']}
                    valueType="radio"
                    dataSource={radioDataSource}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="其他家族史详述"
                    name={['bto_param', 'otherFamilyHistoryDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
              </FormBlock2>
              <FormBlock2 title="丈夫家族史">
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="遗传病史"
                    name={['bto_param', 'husbandGeneticHistory']}
                    valueType="radio"
                    dataSource={radioDataSource}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="遗传病史详述"
                    name={['bto_param', 'husbandGeneticHistoryDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="精神病史"
                    name={['bto_param', 'husbandMentalDiseaseHistory']}
                    valueType="radio"
                    dataSource={radioDataSource}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="精神病史详述"
                    name={['bto_param', 'husbandMentalDiseaseHistoryDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="畸形"
                    name={['bto_param', 'husbandMalformation']}
                    valueType="radio"
                    dataSource={radioDataSource}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="畸形详述"
                    name={['bto_param', 'husbandMalformationDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 1, 1]}
                >
                  <VSFormItem
                    label="智障"
                    name={['bto_param', 'husbandMentalRetardation']}
                    valueType="radio"
                    dataSource={radioDataSource}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                      onChange: e => {
                        setmentalRetardationValue({
                          ...mentalRetardation,
                          mentalRetardation: e === '无',
                        });
                      },
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="智障程度"
                    name={['bto_param', 'husbandMentalRetardationLevel']}
                    valueType="radio"
                    dataSource={radioDataSource2}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                      disabled: mentalRetardation.mentalRetardation,
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="智障病史详述"
                    name={['bto_param', 'husbandMentalRetardationDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="其他丈夫家族史"
                    name={['bto_param', 'husbandOtherFamilyHistory']}
                    valueType="radio"
                    dataSource={radioDataSource}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="其他丈夫家族史详述"
                    name={['bto_param', 'husbandOtherFamilyHistoryDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
              </FormBlock2>
              <FormBlock2 title="个人史">
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="吸烟"
                    name={['bto_param', 'smokingHistory']}
                    valueType="radio"
                    dataSource={radioDataSource1}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="吸烟详述"
                    name={['bto_param', 'smokingHistoryDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="喝酒"
                    name={['bto_param', 'drinkingHistory']}
                    valueType="radio"
                    dataSource={radioDataSource1}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="喝酒详述"
                    name={['bto_param', 'drinkingHistoryDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="服用药物"
                    name={['bto_param', 'takeMedicine']}
                    valueType="radio"
                    dataSource={radioDataSource1}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="服用药物详述"
                    name={['bto_param', 'takeMedicineDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="药物过敏史"
                    name={['bto_param', 'drugAllergyHistory']}
                    valueType="radio"
                    dataSource={radioDataSource1}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="药物过敏史详述"
                    name={['bto_param', 'drugAllergyHistoryDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="有毒有害物质"
                    name={['bto_param', 'harmfulSubstanceContactHistory']}
                    valueType="radio"
                    dataSource={radioDataSource1}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="毒害物质描述"
                    name={['bto_param', 'harmfulSubstanceContactHistoryDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="接触放射线"
                    name={['bto_param', 'radiationContactHistory']}
                    valueType="radio"
                    dataSource={radioDataSource1}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="放射线描述"
                    name={['bto_param', 'radiationContactHistoryDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="外伤史"
                    name={['bto_param', 'traumaticHistory']}
                    valueType="radio"
                    dataSource={radioDataSource1}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="外伤史详述"
                    name={['bto_param', 'traumaticHistoryDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="其他个人史"
                    name={['bto_param', 'otherPersonalHistory']}
                    valueType="radio"
                    dataSource={radioDataSource1}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="其他个人史详述"
                    name={['bto_param', 'otherPersonalHistoryDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
              </FormBlock2>
              <FormBlock2 title="手术史">
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="妇科手术史"
                    name={['bto_param', 'gynaecologyOperationHistory']}
                    valueType="radio"
                    dataSource={radioDataSource1}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="妇科手术史详述"
                    name={['bto_param', 'gynaecologyOperationHistoryDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[7]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="其他手术史"
                    name={['bto_param', 'otherOperationHistory']}
                    valueType="radio"
                    dataSource={radioDataSource1}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="其他手术史详述"
                    name={['bto_param', 'otherOperationHistoryDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
              </FormBlock2>
            </CollapseBlock>

            <CollapseBlock
              title="月经及怀孕史信息"
              description=""
              error=""
              id="anchor-pregnancy"
            >
              <FormBlock2 title="月经史">
                <VsfFormLayout columnCount={3}>
                  <VSFormItem
                    label="初潮年龄"
                    name={['bto_param', 'menarche']}
                    valueType="digit"
                    fieldProps={{
                      min: 1,
                      precision: 0,
                      addonAfter: '岁',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="月经周期"
                    name={['bto_param', 'menstrualCycle']}
                    valueType="custom"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  >
                    <Dayrange
                      dataKey={['menstrualCycleMin', 'menstrualCycleMax']}
                    />
                  </VSFormItem>
                  <VSFormItem
                    label="经期"
                    name={['bto_param', 'menstrualPeriod']}
                    valueType="custom"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  >
                    <Dayrange
                      dataKey={['menstrualPeriodMin', 'menstrualPeriodMax']}
                    />
                  </VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="避孕史">
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[5]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="避孕史"
                    name={['bto_param', 'contraceptionHistory']}
                    valueType="radio"
                    dataSource={radioDataSource}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="避孕史详述"
                    name={['bto_param', 'contraceptionHistoryDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
              </FormBlock2>
              {/* <FormBlock2 title="孕产史">
                <VSEditableTable
                  dataType="static"
                  value={pregnancyHistory}
                  {...pregnancyHistoryEditableProp}
                >
                  <VSTableColumn
                    title="孕次"
                    valueType="digit"
                    dataIndex="gravidity"
                    fieldProps={{
                      min: 1,
                      max: 10,
                      precision: 0,
                      controls: false,
                    }}
                  ></VSTableColumn>
                  <VSTableColumn
                    title="生产日期"
                    valueType="date"
                    dataIndex="birthday"
                    fieldProps={{
                      format: showDateFormat,
                      renderPreview: v =>
                        v?.value ? moment(v.value).format('YYYY-MM-DD') : '',
                    }}
                  ></VSTableColumn>
                  <VSTableColumn
                    title="足月产"
                    valueType="select"
                    dataIndex="mature"
                    dataSource={enums['mature']}
                  ></VSTableColumn>
                  <VSTableColumn
                    title="早产"
                    valueType="select"
                    dataIndex="premature"
                    dataSource={enums['premature']}
                  ></VSTableColumn>
                  <VSTableColumn
                    title="流产"
                    valueType="select"
                    dataIndex="abortion"
                    dataSource={enums['abortion']}
                  ></VSTableColumn>
                  <VSTableColumn
                    title="其他异常妊娠"
                    valueType="text"
                    dataIndex="otherAbnormalPregnancy"
                  ></VSTableColumn>
                  <VSTableColumn
                    title="存活"
                    valueType="select"
                    dataIndex="survival"
                    dataSource={enums['survival']}
                  ></VSTableColumn>
                  <VSTableColumn
                    title="分娩方式"
                    valueType="select"
                    dataIndex="birthMethod"
                    dataSource={enums['birth_method']}
                  ></VSTableColumn>
                  <VSTableColumn
                    title="胎婴儿情况"
                    valueType="select"
                    dataIndex="childStatus"
                    dataSource={enums['child_status']}
                  ></VSTableColumn>
                  <VSTableColumn
                    title="其他"
                    valueType="text"
                    dataIndex="comment"
                  ></VSTableColumn>
                  <VSEditableTableAddition
                    onAdd={() => {
                      return new Promise((resolve, reject) => {
                        const newData = [
                          ...pregnancyHistory,
                          {
                            id: mockId.current,
                          },
                        ];
                        setPregnancyHistory(newData);
                        mockId.current += 1;
                        resolve(true);
                      });
                    }}
                  >
                    新增
                  </VSEditableTableAddition>
                </VSEditableTable>
              </FormBlock2> */}
            </CollapseBlock>

            <CollapseBlock
              title="常规检查"
              description=""
              error=""
              id="anchor-regular"
            >
              <FormBlock2 title="本次妊娠早孕期异常情况">
                <VsfFormLayout columnCount={1} labelCol={labelColWidth[7]}>
                  {/* <VSFormItem
                    label="孕早期异常情况"
                    name={['bto_param', 'earlyPregnancyAbnormal']}
                    valueType="checkbox"
                  ></VSFormItem> */}
                  <VSFormItem
                    label="妊娠早孕期异常情况"
                    name={['bto_param', 'earlyPregnancyAbnormalDetail']}
                    valueType="checkbox"
                    dataSource={enums['routine_check_abnormal_detail']}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="本次怀孕方式">
                <VsfFormLayout columnCount={1} labelCol={labelColWidth[7]}>
                  <VSFormItem
                    label="怀孕方式"
                    name={['bto_param', 'fereilizingMethod']}
                    valueType="radio"
                    dataSource={enums['pregnancy_method']}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="体格检查">
                <VsfFormLayout columnCount={3}>
                  <VsfFormLayoutDefine
                    labelCol={labelColWidth[5]}
                    columnSpan={[1, 1]}
                  >
                    <VSFormItem
                      label="体重"
                      name={['bto_param', 'weight']}
                      valueType="digit"
                      fieldProps={{
                        addonAfter: 'kg',
                        controls: false,
                        placeholder: '请输入',
                        min: 30,
                        max: 300,
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="体重指数"
                      name={['bto_param', 'bmi']}
                      valueType="digit"
                      fieldProps={{
                        addonAfter: 'kg/m2',
                        controls: false,
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                  </VsfFormLayoutDefine>
                  <VSFormItem
                    label="身高"
                    name={['bto_param', 'height']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'cm',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="心率"
                    name={['bto_param', 'heartRate']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: '次/分',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VsfFormLayoutDefine
                    labelCol={labelColWidth[5]}
                    columnSpan={[1]}
                  >
                    <VSFormItem
                      label="血压"
                      name={['bto_param', 'bloodPressure']}
                      valueType="custom"
                    >
                      <BloodPressure
                        dataKey={['systolicPressure', 'diastolicPressure']}
                      />
                    </VSFormItem>
                  </VsfFormLayoutDefine>
                  <VSFormItem
                    label="心律"
                    name={['bto_param', 'heartRhythm']}
                    valueType="select"
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                    dataSource={[
                      {
                        label: '齐',
                        value: true,
                      },
                      {
                        label: '不齐',
                        value: false,
                      },
                    ]}
                  ></VSFormItem>
                </VsfFormLayout>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[5]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="心脏杂音"
                    name={['bto_param', 'heartMurmur']}
                    valueType="radio"
                    dataSource={radioDataSource}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="心脏杂音详述"
                    name={['bto_param', 'heartMurmurDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[5]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="心脏"
                    name={['bto_param', 'heart']}
                    valueType="radio"
                    dataSource={radioDataSource}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="心脏详述"
                    name={['bto_param', 'heartDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[5]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="肺部"
                    name={['bto_param', 'lung']}
                    valueType="radio"
                    dataSource={radioDataSource}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="肺部详述"
                    name={['bto_param', 'lungDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[5]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="其他异常"
                    name={['bto_param', 'otherAbnormal']}
                    valueType="radio"
                    dataSource={radioDataSource}
                    fieldProps={{
                      className: 'ant-radio-checkbox-border',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="其他异常详述"
                    name={['bto_param', 'otherAbnormalDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
              </FormBlock2>
              <FormBlock2 title="妇科检查">
                <VsfFormLayout columnCount={1}>
                  <VsfFormLayoutDefine
                    labelCol={labelColWidth[5]}
                    columnSpan={[1, 2]}
                  >
                    <VSFormItem
                      label="外阴"
                      name={['bto_param', 'vulva']}
                      valueType="select"
                      dataSource={enums['vulva']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="外阴详述"
                      name={['bto_param', 'vulvaDetail']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                  </VsfFormLayoutDefine>
                  <VsfFormLayoutDefine
                    labelCol={labelColWidth[5]}
                    columnSpan={[1, 2]}
                  >
                    <VSFormItem
                      label="阴道"
                      name={['bto_param', 'vagina']}
                      valueType="select"
                      dataSource={enums['vulva']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="阴道详述"
                      name={['bto_param', 'vaginaDetail']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                  </VsfFormLayoutDefine>
                  <VsfFormLayoutDefine
                    labelCol={labelColWidth[5]}
                    columnSpan={[1, 2]}
                  >
                    <VSFormItem
                      label="宫颈"
                      name={['bto_param', 'cervix']}
                      valueType="select"
                      dataSource={enums['vulva']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="宫颈详述"
                      name={['bto_param', 'cervixDetail']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                  </VsfFormLayoutDefine>
                  <VsfFormLayoutDefine
                    labelCol={labelColWidth[5]}
                    columnSpan={[1, 2]}
                  >
                    <VSFormItem
                      label="子宫"
                      name={['bto_param', 'uterus']}
                      valueType="select"
                      dataSource={enums['vulva']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="子宫详述"
                      name={['bto_param', 'uterusDetail']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                  </VsfFormLayoutDefine>
                  <VsfFormLayoutDefine
                    labelCol={labelColWidth[5]}
                    columnSpan={[1, 2]}
                  >
                    <VSFormItem
                      label="附件"
                      name={['bto_param', 'adnexa']}
                      valueType="select"
                      dataSource={enums['vulva']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="附件详述"
                      name={['bto_param', 'adnexaDetail']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                  </VsfFormLayoutDefine>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="骨盆外测量">
                <VsfFormLayout columnCount={3}>
                  <VSFormItem
                    label="髂棘间径"
                    name={['bto_param', 'interspinalDiameter']}
                    valueType="digit"
                    fieldProps={{
                      min: 0,
                      addonAfter: 'cm',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="髂嵴间径"
                    name={['bto_param', 'intercristalDiameter']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'cm',
                      min: 0,
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="骶耻间径"
                    name={['bto_param', 'externalConjugate']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'cm',
                      min: 0,
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="出口横径"
                    name={['bto_param', 'transverseOutlet']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'cm',
                      min: 0,
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
            </CollapseBlock>

            <CollapseBlock
              title="辅助检查"
              description=""
              error=""
              id="anchor-assistant"
              // rightContent={
              //   <div>
              //     <Button
              //       icon={<DownloadOutlined />}
              //       onClick={e => {
              //         e.stopPropagation();
              //         setCurrentImportData(importData.assistant);
              //         setVisibleImport(true);
              //       }}
              //     >
              //       数据导入
              //     </Button>
              //   </div>
              // }
            >
              <FormBlock2 title="血常规">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="血红蛋白质值"
                    name={['bto_param', 'hemoglobinValue']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'g/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="白细胞计数值"
                    name={['bto_param', 'whiteBloodCellCount']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'x10^9/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="血小板计数值"
                    name={['bto_param', 'plateletCount']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'x10^9/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="血常规其他"
                    name={['bto_param', 'otherBloodRoutine']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="贫血三项">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="铁蛋白"
                    name={['bto_param', 'ferritin']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'ug/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="糖化血红蛋白">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="糖化血红蛋白"
                    name={['bto_param', 'glycatedHemoglobin']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mmol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="血糖相关">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="血糖"
                    name={['bto_param', 'bloodSugarType']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mmol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="餐后两小时"
                    name={['bto_param', 'bloodSugarType2h']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mmol/L',
                      placeholder: '请输入',
                      controls: false,
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="OGTT">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="OGTT血糖1："
                    name={['bto_param', 'ogtt1h']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mmol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="OGTT血糖2："
                    name={['bto_param', 'ogtt2h']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mmol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="OGTT血糖3："
                    name={['bto_param', 'ogtt3h']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mmol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="血型">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="ABO血型"
                    name={['bto_param', 'aboBloodType']}
                    valueType="select"
                    dataSource={enums['abo_blood_type']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="RH+"
                    name={['bto_param', 'rhBloodType']}
                    valueType="select"
                    dataSource={enums['rh_blood_type']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="尿常规">
                <VsfFormLayout columnCount={2} labelCol={labelColWidth[6]}>
                  <VsfFormLayout columnCount={2}>
                    <VSFormItem
                      label="尿蛋白"
                      name={['bto_param', 'urineProtein']}
                      valueType="select"
                      dataSource={enums['urine_protein']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label=""
                      name={['bto_param', 'urineProteinValue']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VsfFormLayout columnCount={2}>
                    <VSFormItem
                      label="尿糖"
                      name={['bto_param', 'urineSugar']}
                      valueType="select"
                      dataSource={enums['urine_protein']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label=""
                      name={['bto_param', 'urineSugarValue']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VsfFormLayout columnCount={2}>
                    <VSFormItem
                      label="尿酮体"
                      name={['bto_param', 'urineKetone']}
                      valueType="select"
                      dataSource={enums['urine_protein']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label=""
                      name={['bto_param', 'urineKetoneValue']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VsfFormLayout columnCount={2}>
                    <VSFormItem
                      label="尿潜血"
                      name={['bto_param', 'urineOccultBlood']}
                      valueType="select"
                      dataSource={enums['urine_protein']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label=""
                      name={['bto_param', 'urineOccultBloodValue']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VsfFormLayout columnCount={2}>
                    <VSFormItem
                      label="尿白血球"
                      name={['bto_param', 'urineWhiteBloodCell']}
                      valueType="select"
                      dataSource={enums['urine_protein']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label=""
                      name={['bto_param', 'urineWhiteBloodCellValue']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>

                  <VSFormItem
                    label="尿常规其他"
                    name={['bto_param', 'otherUrineRoutine']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                    // dataSource={enums['urine_protein']}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="肝功能">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="血清谷丙转氨酶"
                    name={['bto_param', 'alt']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'U/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="血清谷草转氨酶"
                    name={['bto_param', 'ast']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'U/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="白蛋白"
                    name={['bto_param', 'albumin']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'g/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="总胆红素"
                    name={['bto_param', 'totalBilirubin']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'umol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="结合胆红素"
                    name={['bto_param', 'conjugatedBilirubin']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'umol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="总胆汁酸"
                    name={['bto_param', 'bileAcid']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'umol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="肾功能">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="血清肌酐"
                    name={['bto_param', 'serumCreatinine']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'umol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="血尿素氮"
                    name={['bto_param', 'bloodUreaNitrogen']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mmol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="阴道感染">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="阴道分泌物"
                    name={['bto_param', 'virginalDischarge']}
                    valueType="select"
                    dataSource={enums['virginal_discharge']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="阴道清洁度"
                    name={['bto_param', 'virginalCleanliness']}
                    valueType="select"
                    dataSource={enums['virginal_cleanliness']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="沙眼衣原体"
                    name={['bto_param', 'trachomatis']}
                    valueType="select"
                    dataSource={enums['trachomatis']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="淋球菌检测"
                    name={['bto_param', 'gonorrhoeaeTest']}
                    valueType="select"
                    dataSource={enums['gonorrhoeae_test']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="甲状腺功能">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="甲状腺功能"
                    name={['bto_param', 'thyroidFunction']}
                    valueType="select"
                    dataSource={enums['urine_protein']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="TSH"
                    name={['bto_param', 'tsh']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mlU/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="FT3"
                    name={['bto_param', 'ft3']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'pmol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="TT3"
                    name={['bto_param', 'tt3']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mlU/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="FT4"
                    name={['bto_param', 'ft4']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mlU/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="TT4"
                    name={['bto_param', 'tt4']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'pmol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="ATG"
                    name={['bto_param', 'atg']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'IU/ML',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="ATPO"
                    name={['bto_param', 'atpo']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'IU/ML',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="TCT检查">
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[6]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="宫颈脱落细胞检查"
                    name={['bto_param', 'cervicalExfoliationTest']}
                    valueType="select"
                    dataSource={enums['cervical_exfoliation_test']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="其他检查详述"
                    name={['bto_param', 'otherInspectionDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="B超"
                    name={['bto_param', 'ultrasound']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
                <VsfFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="心电图"
                    name={['bto_param', 'ecg']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
            </CollapseBlock>

            <CollapseBlock
              title="艾梅乙检查"
              description=""
              error=""
              id="anchor-infect"
              // rightContent={
              //   <div>
              //     <Button
              //       icon={<DownloadOutlined />}
              //       onClick={e => {
              //         e.stopPropagation();
              //         setCurrentImportData(importData.amy);
              //         setVisibleImport(true);
              //       }}
              //     >
              //       数据导入
              //     </Button>
              //   </div>
              // }
            >
              <FormBlock2 title="乙型肝炎五项">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[8]}>
                  <VSFormItem
                    label="乙肝表面抗原(HBsAg)"
                    name={['bto_param', 'hbsag']}
                    valueType="select"
                    dataSource={enums['hbsag']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="乙肝肝炎e抗体"
                    name={['bto_param', 'hbeab']}
                    valueType="select"
                    dataSource={enums['hbeab']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="乙型肝炎核心抗体"
                    name={['bto_param', 'hbcab']}
                    valueType="select"
                    dataSource={enums['hbcab']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="乙型肝炎表面抗体"
                    name={['bto_param', 'hbsab']}
                    valueType="select"
                    dataSource={enums['hbsab']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="乙肝肝炎e抗原"
                    name={['bto_param', 'hbeag']}
                    valueType="select"
                    dataSource={enums['hbeag']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="报告日期"
                    name={['bto_param', 'hbFiveItemReportTime']}
                    valueType="date"
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="检测孕周"
                    name={['bto_param', 'hbFiveItemGestationalWeek']}
                    valueType="custom"
                  >
                    <GestationalWeek />
                  </VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="梅毒螺旋体抗体检验方法">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[8]}>
                  <VSFormItem
                    label="检测方法"
                    name={['bto_param', 'tpAntibodyTestMethod']}
                    valueType="select"
                    dataSource={enums['tp_antibody_test_method']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  >
                    {/* <Testmethod dataSource={enums['tp_antibody_test_method']} /> */}
                  </VSFormItem>
                  <VsfFormLayout columnCount={1}>
                    <VSFormItem
                      label="检测结果"
                      name={['bto_param', 'tpAntibodyTestResult']}
                      valueType="select"
                      dataSource={enums['tp_antibody_test_result']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VSFormItem
                    label="报告日期"
                    name={['bto_param', 'tpAntibodyReportTime']}
                    valueType="date"
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  >
                    <GestationalWeek />
                  </VSFormItem>
                  <VSFormItem
                    label="检测孕周"
                    name={['bto_param', 'tpAntibodyTestGestationalWeek']}
                    valueType="custom"
                  >
                    <GestationalWeek />
                  </VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="非梅毒螺旋体抗体检验方法">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[8]}>
                  <VSFormItem
                    label="检测方法"
                    name={['bto_param', 'nonTpAntibodyTestMethod']}
                    valueType="select"
                    dataSource={enums['tp_antibody_test_method']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  >
                    {/* <Testmethod dataSource={enums['tp_antibody_test_method']} /> */}
                  </VSFormItem>
                  <VsfFormLayout
                    style={{
                      marginLeft: 57,
                    }}
                    columnCount={2}
                  >
                    <VSFormItem
                      label="检测结果"
                      name={['bto_param', 'nonTpAntibodyTestResult']}
                      valueType="select"
                      dataSource={enums['tp_antibody_test_result']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                      labelCol={{
                        style: {
                          width: 75,
                        },
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="滴度结果"
                      name={['bto_param', 'titerResult']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '请输入',
                      }}
                      labelCol={{
                        style: {
                          width: 75,
                        },
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VSFormItem
                    label="报告日期"
                    name={['bto_param', 'nonTpAntibodyReportTime']}
                    valueType="date"
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  >
                    <GestationalWeek />
                  </VSFormItem>
                  <VSFormItem
                    label="检测孕周"
                    name={['bto_param', 'nonTpAntibodyTestGestationalWeek']}
                    valueType="custom"
                  >
                    <GestationalWeek />
                  </VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="HIV抗体检测">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[8]}>
                  <VSFormItem
                    label="HIV抗体"
                    name={['bto_param', 'hivAntibody']}
                    valueType="select"
                    dataSource={enums['hiv_antibody']}
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="报告日期"
                    name={['bto_param', 'hivReportTime']}
                    valueType="date"
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="检测孕周"
                    name={['bto_param', 'hivTestGestationalWeek']}
                    valueType="custom"
                  >
                    <GestationalWeek />
                  </VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
            </CollapseBlock>

            {/* <CollapseBlock
              title="免费超声检查"
              description=""
              error=""
              id="anchor-highrisk"
            >
              <VsfFormLayout columnCount={3}>
                <VSFormItem
                  label="免费超声检查"
                  name={['bto_param', 'freeUltrasound']}
                  valueType="select"
                  dataSource={(enums['free_ultrasound'] || []).map(item => ({
                    ...item,
                    label: item.value == 1 ? '有' : '无',
                  }))}
                  fieldProps={{
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
              </VsfFormLayout>
            </CollapseBlock> */}

            <CollapseBlock
              title="高危检查"
              description=""
              error=""
              id="anchor-highrisk"
            >
              <VsfFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                <VsfFormLayout columnCount={3}>
                  <VSFormItem
                    label="是否高危"
                    name={['bto_param', 'highRisk']}
                    valueType="select"
                    dataSource={enums['high_risk']}
                    fieldProps={{
                      disabled: true,
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </VsfFormLayout>
              <VsfFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                <VSFormItem
                  label="高危因素"
                  name={['bto_param', 'highRiskFactors']}
                  valueType="text"
                  fieldProps={{
                    disabled: true,
                  }}
                ></VSFormItem>
              </VsfFormLayout>
              <VsfFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                <VSFormItem
                  label="其他高危因素"
                  name={['bto_param', 'otherHighRiskFactors']}
                  valueType="text"
                  fieldProps={{
                    disabled: true,
                  }}
                ></VSFormItem>
              </VsfFormLayout>
              <VsfFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                <VSFormItem
                  label="高危评估"
                  name={['bto_param', 'highRiskAssessment']}
                  valueType="custom"
                  fieldProps={{
                    disabled: true,
                  }}
                >
                  <HighriskTag />
                </VSFormItem>
                <VSFormItem
                  label="传染病"
                  name={['bto_param', 'infectious']}
                  valueType="custom"
                  fieldProps={{
                    disabled: true,
                  }}
                >
                  <HighriskTag />
                </VSFormItem>
              </VsfFormLayout>
            </CollapseBlock>

            <CollapseBlock
              title="总体评估和保健指导"
              description=""
              error=""
              id="anchor-comment"
            >
              <VsfFormLayoutDefine
                labelCol={labelColWidth[6]}
                columnSpan={[1, 2]}
              >
                <VSFormItem
                  label="总体评估"
                  name={['bto_param', 'overallAssessment']}
                  valueType="radio"
                  dataSource={radioDataSourCeoverallAssessment}
                  fieldProps={{
                    className: 'ant-radio-checkbox-border',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="总体评估详述"
                  name={['bto_param', 'overallAssessmentDetail']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
              </VsfFormLayoutDefine>
              <VsfFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                <VSFormItem
                  label="保健指导"
                  name={['bto_param', 'healthGuidance']}
                  valueType="checkbox"
                  dataSource={enums['healthcare_guidance']}
                ></VSFormItem>
              </VsfFormLayout>
              <VsfFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                <VSFormItem
                  label="保健指导详述"
                  name={['bto_param', 'healthGuidanceDetail']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
              </VsfFormLayout>
              <VsfFormLayoutDefine
                labelCol={labelColWidth[6]}
                columnSpan={[1, 2]}
              >
                <VSFormItem
                  label="孕妇学校"
                  name={['bto_param', 'gravidaSchool']}
                  valueType="radio"
                  dataSource={radioDataSource4}
                ></VSFormItem>
              </VsfFormLayoutDefine>
            </CollapseBlock>

            <CollapseBlock
              title="转诊信息"
              description=""
              error=""
              id="anchor-transfer"
            >
              <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                <VSFormItem
                  label="转诊"
                  name={['bto_param', 'referral']}
                  valueType="radio"
                  dataSource={radioDataSource1}
                  fieldProps={{
                    className: 'ant-radio-checkbox-border',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="转诊原因"
                  name={['bto_param', 'referralReason']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="转诊机构"
                  name={['bto_param', 'referralOrg']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="转诊科室"
                  name={['bto_param', 'referralDepartment']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
              </VsfFormLayout>
            </CollapseBlock>

            <CollapseBlock
              title="随访信息"
              description=""
              error=""
              id="anchor-visit"
            >
              <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                <VSFormItem
                  label="下次随访日期"
                  name={['bto_param', 'nextFollowupTime']}
                  valueType="date"
                  fieldProps={{
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="随访医生"
                  name={['bto_param', 'followupDoctor']}
                  valueType="select"
                  fieldProps={{
                    showSearch: true,
                    placeholder: '请选择',
                  }}
                  dataSource={hisDoctorInfo?.doctor}
                ></VSFormItem>
                <VSFormItem
                  label="随访单位"
                  name={['bto_param', 'followupUnit']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="录入日期"
                  name={['bto_param', 'enterTime']}
                  valueType="date"
                  fieldProps={{
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="录入人"
                  name={['bto_param', 'enterPerson']}
                  valueType="select"
                  fieldProps={{
                    showSearch: true,
                    placeholder: '请选择',
                  }}
                  dataSource={hisDoctorInfo?.doctor}
                ></VSFormItem>
                <VSFormItem
                  label="录入机构"
                  name={['bto_param', 'enterOrg']}
                  valueType="select"
                  fieldProps={{
                    placeholder: '请选择',
                    disabled: true,
                  }}
                  dataSource={hisDoctorInfo?.organization}
                ></VSFormItem>
              </VsfFormLayout>
            </CollapseBlock>
          </VSForm>
        </div>
      </div>
    </Modal>
  );
};

export default definePage(Index);

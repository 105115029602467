// 慢性病历史
export const chronicDiseaseArray = [
    {
        name: 'ccvdHistory',
        cname: '心脑血管疾病史'
    },
    {
        name: 'endocrineDiseaseHistory',
        cname: '内分泌疾病史'
    },
    {
        name: 'hepatopathyHistory',
        cname: '肝病历史'
    },
    {
        name: 'lungDiseaseHistory',
        cname: '肺病历史'
    },
    {
        name: 'nephropathy',
        cname: '肾脏病史'
    },
    {
        name: 'majorDiseaseHistory',
        cname: '重大疾病史'
    },
    {
        name: 'infectiousDiseaseHistory',
        cname: '传染性疾病史'
    },
    {
        name: 'operationhistory',
        cname: '手术史'
    },
    {
        name: 'transfusionHistory',
        cname: '输血史'
    },
    {
        name: 'traumaticHistory',
        cname: '外伤史'
    },
    {
        name: 'drugHistoryDuringEarlyPregnancy',
        cname: '孕早期服药史'
    }
]
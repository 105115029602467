import React, { useEffect, useRef, useState } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Layout,
  Page,
  Section,
  Modal,
  Search,
  Box,
  Placeholder,
  Card,
  Button,
} from '@vs/vsf-kit';
import { Select } from 'antd';
import { cloneDeep } from 'lodash';
import staticCommonService from '@/module/common/services';
import commonServices from '@/services/common';
import moment from 'moment';
import { useDebounceFn } from 'ahooks';
// import { PlusOutlined } from '@vs/vsf-icons';

const historyTabList = [
  {
    key: 0,
    label: '全部',
  },
  {
    key: 1,
    label: '本科室',
  },
  {
    key: 2,
    label: '其他科室',
  },
];

const handleFormatModalData = (list, dataKey) => {
  if (!Array.isArray(list)) return [];
  return list.map(item => ({
    label:
      dataKey === 'complaint' ? item?.complaintContent : item?.diagnosisContent,
    id: item?.id,
  }));
};

/**
 * 主诉、诊断弹框组件
 **/

const size = 50;

const Index = (props: {
  hisId: string;
  title: string | undefined;
  visible: boolean;
  defaultData: string[];
  onOk: (data: any) => void;
  onCancel: () => void;
  max?: number;
  selectDataSource?: any[];
  dataSource?: any[];
}) => {
  const {
    hisId,
    title,
    visible,
    onOk,
    onCancel,
    defaultData,
    max,
    selectDataSource,
    dataSource,
  } = props;
  const [current, setCurrent] = useState<any>({});
  const [tagList, setTagList] = useState<string[]>(defaultData || []);
  const [diagnosisHistory, setDiagnosisHistory] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState();
  const [currentScope, setCurrentScope] = useState<number>(0);

  const [data, setData] = useState<any>([]);

  const scrollId = useRef<string>();
  const count = useRef<number>(0);

  const getDataSource = async () => {
    const diagnosisModalData = await commonServices.getDiagnosis({
      status: true,
    });

    const target = handleFormatModalData(diagnosisModalData.data, 'diagnosis');
    setData(target);

    vsf.setStoreValue('common.diagnosisModalData', target);
  };

  useEffect(() => {
    if (visible) {
      if (!dataSource) {
        getDataSource();
      } else {
        setData(dataSource);
      }
    }
  }, [visible, dataSource]);

  const handleToggleScope = v => {
    setCurrentScope(v);
  };

  const handleOk = () => {
    if (onOk) {
      onOk(tagList);
    }
  };
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleClick = v => {
    setCurrent(v);
    if (tagList.includes(v.label)) {
      handleDeselect({
        value: v.label,
      });
    } else {
      handleAddItem(v.label);
    }
  };

  const handleSelect = v => {
    handleAddItem(v?.label);
  };

  const filterOption = (inputValue, option) => {
    return (option?.keywords || '')?.indexOf(inputValue) >= 0;
  };

  const handleDeselect = data => {
    const arr = cloneDeep(tagList);
    const index = arr.findIndex(item => item === data.value);
    arr.splice(index, 1);
    setTagList(arr);
  };

  const handleAddItem = label => {
    if (tagList.includes(label)) return;
    const arr = cloneDeep(tagList);
    if (!max || arr.length < max) {
      arr.push(label);
    } else {
      arr.shift();
      arr.push(label);
    }
    setTagList(arr);
  };

  // （防抖）
  const { run } = useDebounceFn(
    value => {
      getDiagnosisList(value?.value, value.type);
    },
    {
      wait: 300,
    },
  );

  const renderItem = data => {
    return (
      <div
        className="item"
        onClick={() => {
          handleAddItem(data?.diagnosis);
        }}
      >
        <div className="label">{data?.diagnosis}</div>
        <div className="info">
          <div>
            <span>{data?.departmentName}</span>
            <span>{data?.hisName}</span>
          </div>
          <div>{moment(data?.diagnoseTime).format('YYYY-MM-DD')}</div>
        </div>
      </div>
    );
  };

  const getDiagnosisHistory = async scope => {
    const res = await staticCommonService.getDiagnosisHistory({
      hisId,
      scope,
      departmentId: '22',
    });
    // setDiagnosisHistory(res?.data || []);
  };

  const handleSearch = newValue => {
    if (newValue) {
      scrollId.current = '';
      setInputValue(newValue);
      run({
        value: newValue,
        type: false,
      });
    } else {
      setDiagnosisHistory([]);
    }
  };

  const getDiagnosisList = async (value, isScroll = false) => {
    const qto: any = {};
    if (isScroll) {
      qto.scrollId = scrollId.current;
    }
    const res = await staticCommonService.getDiagnoseList({
      qto: {
        ...qto,
        nameLike: value,
        size,
        from: 1,
      },
      ext: {},
    });
    if (res.code === 200) {
      scrollId.current = res.data?.scrollId;
      count.current = res.data?.count;
      const data = res.data.result.map((item: any) => ({
        keywords: item?.pinyinInitial,
        label: item?.name,
        value: item?.name,
      }));
      setDiagnosisHistory(isScroll ? [...diagnosisHistory, ...data] : data);
    }
  };

  useEffect(() => {
    setTagList(defaultData || []);
  }, [defaultData]);

  useEffect(() => {
    if (visible) {
      getDiagnosisHistory(currentScope);
    }
  }, [visible, currentScope]);

  const onPopupScroll = () => {
    if (diagnosisHistory.length < count.current) {
      getDiagnosisList(inputValue, true);
    }
  };

  return (
    <Modal
      width={1082}
      className="e-medical-data-entering-modal diagnosis diagnosis-modal"
      title={title}
      keyboard={true}
      destroyOnClose={true}
      okText="保存"
      cancelText="取消"
      cancelButtonProps={{
        hidden: true,
      }}
      visible={visible}
      id="data-entering-modal"
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div className="container">
        <div className="left">
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="请选择诊断"
            optionLabelProp="label"
            labelInValue={true}
            value={tagList}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            onSearch={handleSearch}
            options={diagnosisHistory}
            filterOption={false}
            onPopupScroll={
              useDebounceFn(onPopupScroll, {
                wait: 300,
              }).run
            }
            // filterOption={filterOption}
          />
          <div className="main">
            <div className="title">常见诊断</div>
            <div className="content">
              {data &&
                data?.map(item => (
                  <div
                    key={item?.id}
                    className={`item ${
                      tagList.includes(item.label)
                        ? 'e-medical-data-entering-active-item'
                        : ''
                    }`}
                    onClick={() => {
                      handleClick({
                        ...item,
                        active: item.id,
                      });
                    }}
                  >
                    {item.label}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="right">
          <div className="title">历史诊断</div>
          <div className="tab-list">
            {historyTabList.map(item => (
              <Button
                className={currentScope === item.key ? 'active' : ''}
                onClick={() => {
                  handleToggleScope(item.key);
                }}
              >
                {item.label}
              </Button>
            ))}
          </div>
          <div className="container-item">
            {diagnosisHistory.map(item => {
              return renderItem(item);
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Index;

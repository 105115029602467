import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { message } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import { formatDate } from '@/utils/rule';
import {
  Modal,
  VSEditableTable,
  VSEditableTableAddition,
  VSTableColumn,
} from '@vs/vsf-kit';
import { disabledFutureDate } from '@/utils/rule';
import { forbidEdit } from '@/pages/nurse/utils';

import gravidaServices from '@/services/gravida';
import staticCommonService from '@/module/common/services';
import Allergy from './index';
import { globalPageType, ROOT_ROUTER } from '@/config';

import './index.less';

/**
 * 过敏信息组件
 **/

const Index = props => {
  const { isHistory = false } = props;
  const { gravida, common } = vsf?.stores;
  const { allergyVisible, gravidaAllergyHistory } = gravida;
  const [dataSource, setDataSource] = useState(gravida.gravidaAllergyHistory);

  // // 基础信息 ref
  // formRef = [];
  const handleOk = () => {
    if (!forbidEdit(gravida?.gravidaRecord, common)) {
      const target = dataSource
        .filter(item => item.id && `${item?.id}`?.length > 6)
        .map(item => {
          const { id, ...rest } = item;
          rest.occurrenceTime = formatDate(rest?.occurrenceTime);
          rest.registrantTime = formatDate(rest?.registeredTime);
          rest.hisId = gravida?.gravidaInfo?.hisId;
          rest.registrantId = common?.doctorId;
          delete rest.registeredTime;
          return rest;
        });
      if (target && target?.length > 0) {
        gravidaServices
          .createGravidaAllergyHistory({
            btoParam: target,
          })
          .then(res => {
            message(res);
            if (res.code === 200) {
              vsf?.setStoreValue?.('gravida.allergyVisible', false);
              staticCommonService
                .getAllergyHistory({ hisId: gravida?.gravidaInfo?.hisId })
                .then(res => {
                  if (res.code === 200) {
                    gravida.gravidaAllergyHistory = res.data;
                  }
                });
            } else {
              vsf?.setStoreValue?.('gravida.allergyVisible', false);
            }
          });
      } else {
        vsf?.setStoreValue?.('gravida.allergyVisible', false);
      }
    } else {
      vsf?.setStoreValue?.('gravida.allergyVisible', false);
    }
  };
  const handleCancel = () => {
    vsf.setStoreValue?.('gravida.allergyVisible', false);
  };

  const handleChange = v => {
    setDataSource(v);
  };

  useEffect(() => {
    setDataSource(gravidaAllergyHistory);
  }, [gravidaAllergyHistory]);

  return (
    <Modal
      width={1032}
      className="e-medical-allergy-modal"
      title="过敏信息"
      keyboard={true}
      destroyOnClose={true}
      okText="保存"
      cancelText="取消"
      visible={allergyVisible}
      id="allergy-modal"
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      okButtonProps={{
        disabled: globalPageType.NURSE === common?.globalPageType,
      }}
    >
      <Allergy onChange={handleChange} />
    </Modal>
  );
};

export default definePage(Index);

import React from 'react';
import './index.less';

type importModalBlock = {
    title?: string;
    content?: string;
}

const Index = (props: importModalBlock) => {
    const { title, content } = props;
    const formatContent = content?.split('\X000d\\') || [];
  return (
    <div className="e-medical-import-modal-block">
      <div className="title">{title}</div>
      <div className="detail">
        {formatContent.map(item => (
          <div>{item.replace('\\', '')}</div>
        ))}
      </div>
    </div>
  );
};

export default Index;
import React, { ReactNode } from 'react';
import { Row, Col } from '@vs/vsf-kit';
import './index.less';

export interface LayoutType {
  padding?: number;
  labelCol?: number;
  children?: ReactNode | ReactNode[];
  columnCount?: number;
  gutter?: any;
  className?: string;
  columnSpan?: number[];
  layoutSpan?: number[];
  style?: any;
}

const Index = (props: LayoutType) => {
  const {
    children,
    columnCount = 2,
    gutter = [8, 0],
    padding = '0 0',
    className,
    labelCol,
    style = {}
  } = props;
  const colSpan = 24 / columnCount;
  let target: any = children;
  if (children && !Array.isArray(children)) {
    target = [children];
  }
  return (
    <Row
      gutter={gutter}
      style={{
        ...style,
        padding: padding,
      }}
      className={className}
    >
      {target &&
        target.map(item => {
          if (!item) return null;
          const selfColSpan = item?.props?.colSpan;
          const formatItem =
            labelCol && !item?.props?.labelCol
              ? {
                  ...item,
                  props: {
                    ...item.props,
                    labelCol,
                  },
                }
              : item;
          return <Col span={selfColSpan || colSpan}>{formatItem}</Col>;
        })}
    </Row>
  );
};

const sum = (arr: number[]) => {
  return arr.reduce(function(prev, curr, idx, arr) {
    return prev + curr;
  });
}

export const VsfFormLayoutDefine = (props: LayoutType) => {
  const {
    children,
    columnSpan = [1, 1],
    gutter = [8, 0],
    padding = '0 0',
    className,
    labelCol,
    layoutSpan = [0, 24],
  } = props;
  const columnSum = sum(columnSpan);
  const colSpan = 24 / columnSum;
  let target: any = children;
  if (children && !Array.isArray(children)) {
    target = [children];
  }
  return (
    <Row>
      <Col span={layoutSpan?.[0]}></Col>
      <Col span={layoutSpan?.[1]}>
        <Row
          gutter={gutter}
          style={{
            padding: padding,
          }}
          className={className}
        >
          {target &&
            target.map((item, index) => {
              const selfColSpan = item?.props?.colSpan;
              const formatItem =
                labelCol && !item?.props?.labelCol
                  ? {
                      ...item,
                      props: {
                        ...item.props,
                        labelCol,
                      },
                    }
                  : item;
              return (
                <Col span={selfColSpan || colSpan * columnSpan[index]}>
                  {formatItem}
                </Col>
              );
            })}
        </Row>
      </Col>
    </Row>
  );
};

export interface FormBlockType {
  children?: ReactNode | ReactNode[];
  title: string;
  extra?: any;
}

export const FormLayoutBlock = props => {
         const { children } = props;
         return (
           <div className="e-medical-form-layout-block-main">{children}</div>
         );
       };

export const FormBlock = (props: FormBlockType) => {
  const { children, title, extra } = props;
  return (
    <div className="e-medical-form-layout-main">
      <div className="title">
        <span className="word">{title}</span>
        <span>{extra}</span>
      </div>
      <div className="container">{children}</div>
    </div>
  );
};

export const FormBlock2 = (props: FormBlockType) => {
  const { children, title, extra } = props;
  return (
    <div className="e-medical-form-layout-main-second">
      <div className="title">
        <span className="word">{title}</span>
        <span>{extra}</span>
      </div>
      {children}
    </div>
  );
};

export default Index;

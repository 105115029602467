import { createServices } from "@vs/service";

export default createServices({
  getEnums: {
    method: "post",
    url: "/api/enums",
    parameterFomatter: (data?: { var: LevelAndStatus }) => data,
    responseFormatter: (
      _,
      __,
      data?: UtilsEnumsManagerDtoEnumeratingItemRootDto[]
    ) => data,
  },
  getComplaint: {
    method: "post",
    url: "/api/common/getComplaint",
    parameterFomatter: (data?: { status: boolean }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalCommonFieldManagerDtoCommonComplaintBaseDto[]
    ) => data,
  },
  getDiagnosis: {
    method: "post",
    url: "/api/common/getDiagnosis",
    parameterFomatter: (data?: { status: boolean }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalCommonFieldManagerDtoCommonDiagnosisBaseDto[]
    ) => data,
  },
});

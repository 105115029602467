import React, { useState, useRef, useEffect } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Layout,
  Page,
  Section,
  Modal,
  Search,
  Box,
  Placeholder,
  Card,
  VSFormItem,
  VSFormFooter,
  VSFormFooterSubmit,
  VSFormFooterReset,
  VSForm,
  VSStepForm,
  VSStepsForm,
  TabPane,
  Tabs,
  Button,
  Row,
  Col,
  VSEditableTable,
  VSTableToolbar,
  VSEditableTableAddition,
  VSTableColumn,
  message,
} from '@vs/vsf-kit';
import gravidaServices from '@/services/gravida';
import CollapseBlock from '@/components/collapse-block';
import VsfFormLayout, {
  FormBlock2,
} from '@/components/form-layout';
import moment from 'moment';
import './index.less';
// import { PlusOutlined } from '@vs/vsf-icons';

const Index = props => {
  const { visible, onCancel, onOk } = props;
  const [dataSource, setDataSource] = useState({});
  const handleOk = () => {
    onOk && onOk();
  };
  const handleCancel = () => {
    onCancel && onCancel();
  };

  const enums = props?.stores?.common?.enums;

  return (
    <Modal
      width={1032}
      className="e-medical-record-city-screening-modal"
      title="产前筛查申请"
      keyboard={true}
      destroyOnClose={true}
      okText="保存"
      cancelText="取消"
      visible={visible}
      id="city-screening-modal"
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
    >
      <VSForm dataType="static" layout="horizontal" initialValues={{}}>
        <CollapseBlock title="申请信息" description="" error="">
          <VsfFormLayout columnCount={3}>
            <VSFormItem
              label="申请单号"
              name={['bto_param', 'applyId']}
              valueType="text"
            ></VSFormItem>
            {/* <VSFormItem
              label="筛查申请状态"
              name={['bto_param', 'status']}
              valueType="custom"
            ></VSFormItem> */}
            <VSFormItem
              label="申请日期"
              name={['bto_param', 'applyTime']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="申请医生"
              name={['bto_param', 'applyDoctor']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="门诊号"
              name={['bto_param', 'hisRecordId']}
              valueType="text"
            ></VSFormItem>
          </VsfFormLayout>
          <VsfFormLayout columnCount={1}>
            <VSFormItem
              label="筛查类型"
              name={['bto_param', 'screeningType']}
              valueType="text"
            ></VSFormItem>
          </VsfFormLayout>
        </CollapseBlock>
        <CollapseBlock title="标本信息" description="" error="">
          <VsfFormLayout columnCount={3}>
            <VSFormItem
              label="标本编号"
              name={['bto_param', 'specimenId']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="采样日期"
              name={['bto_param', 'samplingTime']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="采样人"
              name={['bto_param', 'samplers']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="采样单位"
              name={['bto_param', 'samplingUnit']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="筛查医院"
              name={['bto_param', 'screeningHospital']}
              valueType="text"
            ></VSFormItem>
          </VsfFormLayout>
        </CollapseBlock>
        <CollapseBlock title="孕产妇信息" description="" error="">
          <VsfFormLayout columnCount={3}></VsfFormLayout>
        </CollapseBlock>
        <CollapseBlock title="筛查信息" description="" error="">
          <VsfFormLayout columnCount={3}>
            <VSFormItem
              label="筛查申请ID"
              name={['bto_param', 'applyId']}
              valueType="digit"
              fieldProps={{
                controls: false,
              }}
            ></VSFormItem>
            <VSFormItem
              label="筛查方法"
              name={['bto_param', 'screeningMethod']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="freebHCG"
              name={['bto_param', 'freebhcg']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="freebHCG_MoM"
              name={['bto_param', 'freebhcgMom']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="PAPP_A"
              name={['bto_param', 'pappA']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="PAPP_A_MoM"
              name={['bto_param', 'pappAMom']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="AFP"
              name={['bto_param', 'afp']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="AFP_MoM"
              name={['bto_param', 'afpMom']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="HCG"
              name={['bto_param', 'hcg']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="HCG_MoM"
              name={['bto_param', 'hcgMom']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="uE3"
              name={['bto_param', '"ue3"']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="uE3_MoM"
              name={['bto_param', '"ue3Mom"']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="21-三体风险值"
              name={['bto_param', '"trisomy21Risk"']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="21-三体筛查结果"
              name={['bto_param', '"trisomy21Result"']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="18-三体风险值"
              name={['bto_param', '"trisomy18Risk"']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="18-三体筛查结果"
              name={['bto_param', '"trisomy18Result"']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="13-三体风险值"
              name={['bto_param', '"trisomy13Risk"']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="13-三体筛查结果"
              name={['bto_param', '"trisomy13Result"']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="NTD风险值"
              name={['bto_param', 'ntdRisk']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="报告日期"
              name={['bto_param', 'reportTime']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="报告医生"
              name={['bto_param', 'reportDoctor']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="报告单位"
              name={['bto_param', 'reportOrg']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="录入日期"
              name={['bto_param', 'enterTime']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="录入人"
              name={['bto_param', 'enterPerson']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="录入机构"
              name={['bto_param', 'enterOrg']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="筛查时间"
              name={['bto_param', 'screeningTime']}
              valueType="text"
            ></VSFormItem>
          </VsfFormLayout>
        </CollapseBlock>
        <CollapseBlock title="报告信息" description="" error="">
          <VsfFormLayout columnCount={3}></VsfFormLayout>
        </CollapseBlock>

        <CollapseBlock title="填表相关" description="" error="">
          <VsfFormLayout columnCount={3}>
            <VSFormItem
              label="录入日期"
              name={['bto_param', 'enterTime']}
              valueType="text"
            ></VSFormItem>
            {/* <VSFormItem
          label="孕产档案ID"
          name={['bto_param', 'gravidaRecordId']}
          valueType="digit"
        ></VSFormItem> */}
            <VSFormItem
              label="录入人"
              name={['bto_param', 'enterPerson']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="录入机构"
              name={['bto_param', 'enterOrg']}
              valueType="text"
            ></VSFormItem>
            {/* <VSFormItem
          label="是否市内"
          name={['bto_param', 'local']}
          valueType="switch"
        ></VSFormItem> */}
          </VsfFormLayout>
        </CollapseBlock>
        {/* <VSFormItem
          label="筛查项目"
          name={['bto_param', 'screeningProgram']}
          valueType="text"
        ></VSFormItem> */}
      </VSForm>
    </Modal>
  );
};

export default definePage(Index);

import React, { useEffect, useState } from 'react';
import { RadioGroup, Input, InputNumber, Checkbox, Button } from '@vs/vsf-kit';
import Modal from './modal';
// import './index.less';

const recordPersonalHistoryBtoMap = {
  ccvdHistory: '有无心脑血管疾病史',
  endocrineDiseaseHistory: '有无内分泌疾病史',
  hepatopathyHistory: '有无肝脏病史',
  lungDiseaseHistory: '有无肺部病史',
  nephropathy: '有无肾脏病史',
  majorDiseaseHistory: '有无其他重大疾病史',
  infectiousDiseaseHistory: '有无传染性疾病史',
  operationHistory: '有无手术史',
  transfusionHistory: '有无输血史',
  drugAllergyHistory: '有无药物过敏史',
  foodAllergyHistory: '有无食物过敏史',
  traumaticHistory: '有无外伤史',
  drugHistoryDuringEarlyPregnancy: '有无早孕期服药史',
  gynaecologyOperationHistory: '有无妇科手术史',
  otherPastHistory:'其他',
};

export interface FetusHeartType {
  value: any;
  onChange: (value: any) => void;
  dataSource: any;
}

export const handleFormatTextStatic = v => {
  let str = '';
  const keys = Object.keys(v ? v : {});
  keys.forEach(key => {
    const target = v[key];
    if (target) { 
      if (target?.[key] && recordPersonalHistoryBtoMap[key]) {
        
        str += `${recordPersonalHistoryBtoMap[key]}: 有${
          target?.[key] && target?.[`${key}Detail`]
            ? `，${target?.[`${key}Detail`]}；`
            : '；'
        }`;
      }
    }
  });
  if(v?.otherPastHistoryDetail){
    str+=`${recordPersonalHistoryBtoMap['otherPastHistory']}:${v?.otherPastHistoryDetail};`
  }
  return str;
};

const Index = (props: FetusHeartType) => {
  const { value, onChange, dataSource } = props;
  const [visible, setVisible] = useState(false);
  const [value1, setValue1] = useState('');

  const [initFlag, setInitFlag] = useState(false);

  useEffect(() => {
    if (value) {
      setValue1(value?.pastHistory || '');
    }
  }, [value]);

  useEffect(() => {
    if (dataSource && !initFlag) {
      setInitFlag(true);
      handleFormatText(dataSource);
    }
  }, [dataSource]);

  const handleValue1Change = v => {
    // setValue1(v);
  };

  const handleFormatText = v => {
    const str = handleFormatTextStatic(v);
    setValue1(str);
    return str;
  };

  const handleModalChange = v => {
    if (v) {
      onChange({
        ...v,
        pastHistory: handleFormatText(v),
      });
    }
    setVisible(false);
  };

  return (
    <div className="e-medical-fetus-heart">
      <Modal visible={visible} onCancel={handleModalChange} data={dataSource} />
      <Input
        value={value1}
        onChange={handleValue1Change}
        placeholder="请输入"
        disabled
      />
      <Button
        style={{
          marginLeft: 8,
        }}
        onClick={() => {
          setVisible(true);
        }}
      >
        点击录入
      </Button>
    </div>
  );
};

export default Index;

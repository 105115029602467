import React, { useEffect, useState } from 'react';
import { RadioGroup, Input } from '@vs/vsf-kit';
import './index.less';

export interface RadioStrType {
  value?: string;
  onChange?: (value: any) => void;
  dataKey: string[];
  disabled?: boolean;
}

const Index = (props: RadioStrType) => {
  const { value, onChange, dataKey, disabled } = props;
  const [radioValue, setRadioValue] = useState(undefined);
  const [otherValue, setOtherValue] = useState('');

  useEffect(() => {
    let formatValue: any = value;
    if (formatValue) {
      setRadioValue(formatValue?.[dataKey?.[0]] ?? undefined);
      setOtherValue(formatValue?.[dataKey?.[1]] ?? '');
    } else {
      setRadioValue(undefined);
      setOtherValue('');
    }
  }, [value]);

  const handleChange = (v, type?: string) => {
    const value = {
      [dataKey?.[0]]: radioValue,
      [dataKey?.[1]]: otherValue,
    };

    if (type) { 
      value[type] = v;
    }

    if (type === dataKey?.[1]) { 
      value[dataKey?.[0]] = true as any;
    }

    if (type === dataKey?.[0]) { 
      if (typeof v === 'boolean' && v === false) { 
        value[dataKey?.[1]] = '';
      } 
    }

    onChange && onChange(value);
  };

  const handleRadioChange = v => {
    setRadioValue(v);
    handleChange(v, dataKey?.[0]);
  };
  const handleInputChange = e => {
    setOtherValue(e.target.value);
    // handleChange(e.target.value, dataKey?.[1]);
  };

  return (
    <div className="e-medical-radio-str">
      <RadioGroup
        value={radioValue}
        onChange={handleRadioChange}
        dataSource={[
          { label: '有', value: true },
          { label: '无', value: false },
        ]}
        disabled={disabled}
      ></RadioGroup>
      <Input
        disabled={radioValue === false || disabled}
        value={otherValue}
        onChange={v => handleInputChange(v)}
        placeholder="请输入"
        onBlur={handleChange}
      />
    </div>
  );
};

export default Index;

import React, { useEffect, useState } from 'react';
import { RadioGroup, Input, Select, InputNumber } from '@vs/vsf-kit';
import '../index.less';

interface RadioStrType {
  value: string;
  onChange: (value: string | null) => void;
}

interface AbnormalValueType {
  value1?: string; // 早筛21三体
  value2?: string; // 早筛18三体
}

export const EarlyDownsScreening = (props: RadioStrType) => {
  const { value, onChange } = props;
  const [radioValue, setRadioValue] = useState(undefined);
  const [otherValue, setOtherValue] = useState('');
  const [abnormalValue, setAbnormalValue] = useState({
    value1: '',
    value2: '',
  });

  useEffect(() => {
    let formatValue: any = {};
    try {
      formatValue = JSON.parse(value);
      setRadioValue(formatValue?.value);
      setOtherValue(formatValue?.otherInfo || '');
      setAbnormalValue(
        formatValue?.abnormalInfo || {
          value1: '',
          value2: '',
        },
      );
    } catch {}
  }, [value]);

  const handleChange = () => {
    const value = JSON.stringify({
      value: radioValue,
      otherInfo: otherValue,
      abnormalInfo: abnormalValue,
    });
    onChange && onChange(radioValue ? value : null);
  };

  const handleRadioChange = v => {
    setRadioValue(v);
  };
  const handleInputChange = e => {
    setOtherValue(e.target.value);
  };
  const handleInputAbnormalValueChange = v => {
    setAbnormalValue({
      ...abnormalValue,
      value1: v
    })
  };
  const handleInputAbnormalValue2Change = v => {
    setAbnormalValue({
      ...abnormalValue,
      value2: v
    });
  };

  return (
    <div
      className={`e-medical-assistant ${
        radioValue === '2' ? 'e-medical-assistant-vertical' : ''
      }`}
    >
      <RadioGroup
        value={radioValue}
        onChange={handleRadioChange}
        dataSource={[
          { label: '未查', value: '0' },
          { label: '正常', value: '1' },
          { label: '异常', value: '2' },
          { label: '其他', value: '3' },
        ]}
        onBlur={handleChange}
      ></RadioGroup>
      {radioValue === '2' && (
        <div className="e-medical-assistant-outline">
          <div className="e-medical-assistant-formitem-block">
            <div className="formitem-block-middle">
              <div className="label">早筛21三体：</div>
              <div className="formitem-nowrap">
                <Input className="molecule" value={1} disabled />
                <span className="slash">/</span>
                <InputNumber
                  min={0}
                  max={999}
                  value={abnormalValue?.value1}
                  onChange={handleInputAbnormalValueChange}
                  placeholder="请输入分母"
                  onBlur={handleChange}
                />
              </div>
            </div>
            <div className="formitem-block-middle">
              <div className="label">早筛18三体：</div>
              <div className="formitem-nowrap">
                <Input className="molecule" value={1} disabled />
                <span className="slash">/</span>
                <InputNumber
                  min={0}
                  max={999}
                  value={abnormalValue?.value2}
                  onChange={handleInputAbnormalValue2Change}
                  placeholder="请输入分母"
                  onBlur={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {(radioValue === '1' || radioValue === '3') && (
        <Input
          value={otherValue}
          onChange={v => handleInputChange(v)}
          placeholder="请输入"
          onBlur={handleChange}
        />
      )}
    </div>
  );
};

import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { message } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Modal,
  VSEditableTable,
  VSEditableTableAddition,
  VSTableColumn,
} from '@vs/vsf-kit';
import { formatDate, showDateFormat } from '@/utils/rule';

import gravidaServices from '@/services/gravida';

/**
 * 过敏信息组件
 **/

const Index = props => {
  const { isHistory = false, onChange } = props;
  const { gravida, common } = vsf?.stores;
  const { gravidaRecord } = gravida;
  const { doctorInfo, enums, allergyDictionary } = common;
  const mockId = useRef(1000000);
  const [dataSource, setDataSource] = useState(
    gravidaRecord?.pregnancyHistoryDtoList || [],
  );

  useEffect(() => {
    setDataSource(gravidaRecord?.pregnancyHistoryDtoList || []);
  }, [gravidaRecord?.pregnancyHistoryDtoList]);

  const editTableProps: any = {
    dataType: 'static',
    value: dataSource,
    onChange: v => {
      onChange && onChange(v);
      setDataSource(v);
    },
    onCanEdit: v => !v.hisId && !isHistory,
    saveAction: {
      onAction: data => {
        return new Promise<boolean>(resolve => {
          setTimeout(() => {
            resolve(true);
          }, 500);
        });
      },
      onError: e => {
        console.log(e.message);
      },
    },
    deleteAction: {
      onAction: data => {
        return new Promise<boolean>(resolve => {
          const newData = [...(dataSource || [])];
          const index = newData.findIndex(item => data.id === item.id);
          newData.splice(index, 1);
          onChange && onChange(newData);
          setDataSource(newData);
          resolve(true);
        });
      },
      onError: e => {
        console.log(e.message);
      },
    },
  };

  return (
    <VSEditableTable
      dataType="static"
      editType="multiple"
      value={dataSource}
      {...editTableProps}
    >
      <VSTableColumn
        title="孕次"
        valueType="digit"
        dataIndex="gravidity"
        fieldProps={{
          min: 1,
          max: 10,
          precision: 0,
          disabled: true,
          controls: false,
        }}
      ></VSTableColumn>
      <VSTableColumn
        title="生产日期"
        valueType="date"
        dataIndex="birthday"
        fieldProps={{
          format: showDateFormat,
          renderPreview: (v: any) =>
            v?.value ? moment(v?.value).format('YYYY-MM-DD') : '',
        }}
      ></VSTableColumn>
      <VSTableColumn
        title="足月产"
        valueType="select"
        dataIndex="mature"
        dataSource={enums['mature']}
        fieldProps={{
          placeholder: '请选择',
        }}
      ></VSTableColumn>
      <VSTableColumn
        title="早产"
        valueType="select"
        dataIndex="premature"
        dataSource={enums['premature']}
        fieldProps={{
          placeholder: '请选择',
        }}
      ></VSTableColumn>
      <VSTableColumn
        title="流产"
        valueType="select"
        dataIndex="abortion"
        dataSource={enums['abortion']}
        fieldProps={{
          placeholder: '请选择',
        }}
      ></VSTableColumn>
      <VSTableColumn
        title="其他异常妊娠"
        valueType="text"
        dataIndex="otherAbnormalPregnancy"
        fieldProps={{
          placeholder: '请输入',
        }}
      ></VSTableColumn>
      <VSTableColumn
        title="存活"
        valueType="select"
        dataIndex="survival"
        dataSource={enums['survival']}
        fieldProps={{
          placeholder: '请选择',
        }}
      ></VSTableColumn>
      <VSTableColumn
        title="分娩方式"
        valueType="select"
        dataIndex="birthMethod"
        dataSource={enums['birth_method']}
        fieldProps={{
          placeholder: '请选择',
        }}
      ></VSTableColumn>
      <VSTableColumn
        title="胎婴儿情况"
        valueType="select"
        dataIndex="childStatus"
        dataSource={enums['child_status']}
        fieldProps={{
          placeholder: '请选择',
        }}
      ></VSTableColumn>
      <VSTableColumn
        title="其他"
        valueType="text"
        dataIndex="comment"
        fieldProps={{
          placeholder: '请输入',
        }}
      ></VSTableColumn>
      <VSEditableTableAddition
        onAdd={() => {
          return new Promise((resolve, reject) => {
            const newData = [
              ...dataSource,
              {
                gravidity: dataSource.length + 1,
                id: mockId.current,
              },
            ];
            setDataSource(newData);
            onChange && onChange(newData);
            mockId.current += 1;
            resolve(true);
          });
        }}
      >
        新增
      </VSEditableTableAddition>
    </VSEditableTable>
  );
};

export default definePage(Index);

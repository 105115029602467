import React, { useRef } from 'react';
import { Button, VSForm, VSFormItem } from '@vs/vsf-kit';

const ReportSearchForm = ({
  ReportFormChange,
  handleClean,
  onInspectionListClick,
  selectedRowKeys
}: {
  ReportFormChange: (data: {
    bjSyncStatusIs: Record<string, any>;
    bjSyncTimeRangeIn: string;
    hisSyncTimeRangeIn: string;
  }) => void;
  handleClean: () => void;
  onInspectionListClick: () => void;
  selectedRowKeys: React.Key[];
}) => {
  const formRef = useRef<any>();

  const handleAdd = () => {
    const newData = formRef?.current?.getFieldsValue();

    if (newData.bjSyncTimeRangeIn) {
      const _syncTime = {
        begin: newData.bjSyncTimeRangeIn[0],
        end: newData.bjSyncTimeRangeIn[1],
        beginInclude: true,
        endInclude: true,
      };
      newData.bjSyncTimeRangeIn = _syncTime;
    }
    if (newData.hisSyncTimeRangeIn) {
      const _syncTime = {
        begin: newData.hisSyncTimeRangeIn[0],
        end: newData.hisSyncTimeRangeIn[1],
        beginInclude: true,
        endInclude: true,
      };
      newData.hisSyncTimeRangeIn = _syncTime;
    }

    ReportFormChange(newData);
  };

  return (
    <VSForm
      dataType="static"
      layout="horizontal"
      formRef={formRef}
      initialValues={{}}
    >
      <VSFormItem
        label="同步状态"
        name={['bjSyncStatusIs']}
        valueType="select"
        fieldProps={{
          width: 200,
          placeholder: '请选择同步状态',
        }}
        dataSource={[
          { label: '同步成功', value: 'SYNC_SUCCESS' },
          { label: '同步失败', value: 'SYNC_FAILURE' },
          { label: '未同步', value: 'NOT_SYNC' },
        ]}
      />
       <VSFormItem
        label="产检时间"
        name={['hisSyncTimeRangeIn']}
        valueType="dateRange"
        fieldProps={{
          placeholder: ['开始时间', '结束时间'],
          showTime: true,
        }}
      />
      <VSFormItem
        label="同步时间"
        name={['bjSyncTimeRangeIn']}
        valueType="dateRange"
        fieldProps={{
          placeholder: ['开始时间', '结束时间'],
          showTime: true,
        }}
      />
      <VSFormItem
        label="保健册号"
        name={['isbjhIs']}
        valueType="text"
        fieldProps={{
          width: 200,
          placeholder: '请输入保健册号',
        }}
      />
      <VSFormItem
        label="his记录id"
        name={['hisRecordIdIs']}
        valueType="text"
        fieldProps={{
          width: 200,
          placeholder: '请输入his记录id',
        }}
      />
      <VSFormItem
        label="姓名"
        name={['patientNameLike']}
        valueType="text"
        fieldProps={{
          width: 200,
          placeholder: '请输入姓名',
        }}
      />
      <VSFormItem
        label="就诊卡号"
        name={['patientCardIs']}
        valueType="text"
        fieldProps={{
          width: 200,
          placeholder: '请输入就诊卡号',
        }}
      />
      <VSFormItem
        label="患者手机号"
        name={['patientPhoneLike']}
        valueType="text"
        fieldProps={{
          width: 200,
          placeholder: '请输入患者手机号',
        }}
      />
      <Button type="primary" onClick={handleAdd}>
        查询
      </Button>
      <Button
        onClick={() => {
          formRef.current.resetFields();
          handleClean();
        }}
      >
        重置
      </Button>
      <Button
        onClick={onInspectionListClick}
        disabled={!selectedRowKeys?.length}
      >
        批量同步
      </Button>
    </VSForm>
  );
};
export default ReportSearchForm;

import { createServices } from '@vs/service';


export default createServices({
    test: {
        method: 'get',
        url: '/api/test',
        parameterFomatter: (data?: any) => data,
        responseFormatter: (_, __, data?: any) => data,
    },
    login: {
        method: 'post',
        url: '/api/login',
        parameterFomatter: (data?: any) => data,
        responseFormatter: (_, __, data?: any) => data,
    },
    register: {
        method: 'post',
        url: '/api/register',
        parameterFomatter: (data?: any) => data,
        responseFormatter: (_, __, data?: any) => data,
    },
    loginCode: {
        method: 'post',
        url: '/api/sendLoginCode',
        parameterFomatter: (data?: any) => data,
        responseFormatter: (_, __, data?: any) => data,
    },
    registerCode: {
        method: 'post',
        url: '/api/sendRegisterCode',
        parameterFomatter: (data?: any) => data,
        responseFormatter: (_, __, data?: any) => data,
    },
    nurseLogin: {
        method: 'post',
        url: '/api/nurseLogin',
        parameterFomatter: (data?: any) => data,
        responseFormatter: (_, __, data?: any) => data,
    },
});

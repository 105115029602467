import React, { useEffect, useState } from 'react';
import { RadioGroup, Input, Select } from '@vs/vsf-kit';
import '../index.less';

interface RadioStrType {
  value: string;
  onChange: (value: string | null) => void;
}

export const Nipt = (props: RadioStrType) => {
  const { value, onChange } = props;
  const [radioValue, setRadioValue] = useState(undefined);
  const [otherValue, setOtherValue] = useState('');

  useEffect(() => {
    let formatValue: any = {};
    try {
      formatValue = JSON.parse(value);
      setRadioValue(formatValue?.value);
      setOtherValue(formatValue?.otherInfo || '');
    } catch {}
  }, [value]);

  const handleChange = () => {
    const value = JSON.stringify({
      value: radioValue,
      otherInfo: otherValue,
    });
    onChange && onChange(radioValue ? value : null);
  };

  const handleRadioChange = v => {
    setRadioValue(v);
  };
  const handleInputChange = e => {
    setOtherValue(e.target.value);
  };

  return (
    <div className="e-medical-assistant">
      <RadioGroup
        value={radioValue}
        onChange={handleRadioChange}
        dataSource={[
          { label: '未查', value: '0' },
          { label: '正常', value: '1' },
          { label: '异常', value: '2' },
          { label: '其他', value: '3' },
        ]}
        onBlur={handleChange}
      ></RadioGroup>
      {radioValue !== '0' && (
        <Input
          value={otherValue}
          onChange={v => handleInputChange(v)}
          placeholder="请输入"
          onBlur={handleChange}
        />
      )}
    </div>
  );
};

import React from 'react';
import { Spin } from '@vs/vsf-kit';
import FormError from '@/components/form-error';
import './index.less';

export interface PageBlockType {
  width?: string;
  contentMinWidth?: string;
  title?: string | React.ReactNode;
  footer?: boolean | React.ReactNode;
  children?: React.ReactNode;
  anchor?: React.ReactNode;
  className?: string;
  anchorContainerId?: string;
  contentStyle?: any;
  height?: any;
  loading?: boolean;
  showError?: boolean;
  error?: any;
}

const Index = (props: PageBlockType) => {
  const classPrefix = 'e-medical-page-block';
  const {
    children,
    title,
    footer,
    width,
    contentMinWidth,
    anchor,
    anchorContainerId,
    contentStyle,
    height,
    loading,
    showError = false,
    error,
    ...rest
  } = props;
  return (
    <div
      className={classPrefix}
      style={{
        width,
      }}
      {...rest}
    >
      {title && <div className="title">{title}</div>}

      <div className="main">
        <div className="container">
          {showError && error && (
            <div className="header">
              <FormError content={error} />
            </div>
          )}
          <div className="content" id={anchorContainerId}>
            {loading ? (
              <div className="e-medical-spin">
                <Spin tip="数据加载中" />
              </div>
            ) : (
              <div
                style={{
                  minHeight: '100%',
                  minWidth: contentMinWidth,
                }}
              >
                {children}
              </div>
            )}
          </div>
          {footer && <div className="footer">{footer}</div>}
        </div>
        {anchor}
      </div>
    </div>
  );
};

export default Index;

import React, { useEffect } from 'react';
import Arrow from './anchor.png';
import { Image, Anchor } from '@vs/vsf-kit';
import './index.less';

const { Link } = Anchor;

type dataSourceType = {
  id: string;
  title: string;
};

type AnchorType = {
  container: string;
  dataSource: dataSourceType[];
};

const Index = (props: AnchorType) => {
  const { container, dataSource } = props;
  const classPrefix = 'e-medical-anchor-block';
  const handleClick = (
    e: React.MouseEvent<HTMLElement>,
    link: {
      title: React.ReactNode;
      href: string;
    },
  ) => {
    e.preventDefault();
  };

  useEffect(() => {
    setTimeout(() => {
      const jumpDom: any = document?.querySelector(
        '.anchor-physical > .ant-anchor-link-title ',
      );
      if (jumpDom) {
        jumpDom.click && jumpDom.click();
        console.log(jumpDom.click, 'jumpDom.click');
        jumpDom.click();
      }
    }, 0);
  }, [container, dataSource]);

  return (
    <div className={classPrefix}>
      <div className="top">
        <Image src={Arrow} preview={false} />
      </div>
      <div className="content">
        <Anchor
          onClick={handleClick}
          getContainer={() => {
            const dom: any = document.getElementById(container);
            return dom;
          }}
        >
          {dataSource &&
            dataSource.map(item => (
              <Link
                href={`#${item.id}`}
                title={item.title}
                className={`${item.id}`}
              />
            ))}
        </Anchor>
      </div>
      {/* <a
        onClick={e => {
          console.log(e);
        }}
        href={`#anchor-physical`}
        className="jump"
      ></a> */}
    </div>
  );
};

export default Index;

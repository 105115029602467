import React from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import dayjs from 'dayjs';
import { highriskLevel } from '@/config';
import './index.less';

const AntenatalPrint = props => {
  const { medical } = props?.stores;

  const renderData = time => {
    return time ? dayjs(time).format('YY-MM-DD') : '';
  };

  const renderAssessment = status => {
    if (typeof status === 'boolean') {
      return status ? '异常' : '无发现异常';
    }
    return '';
  };

  const renderReferral = status => {
    if (typeof status === 'boolean') {
      return status ? '有' : '无';
    }
    return '';
  };

  const renderGravidaSchool = status => {
    if (typeof status === 'boolean') {
      return status ? '是' : '否';
    }
    return '';
  };

  const renderPrintDom = value => {
    return (
      <div className="anteatalPrint-container">
        <div className="header">
          <h2>产科检查记录表</h2>
        </div>
        <div className="content">
          <table>
            <thead>
              <tr>
                <th>
                  <div className="unit">
                    <div>次数</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>日期</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>孕周</div>
                  </div>
                </th>
                <th className="bigTh">
                  <div className="unit">
                    <div>主诉</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>妊娠高危级别</div>
                  </div>
                </th>
                <th className="bigTh">
                  <div className="unit">
                    <div>血压</div>
                    <div>mmHg</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>体重</div>
                    <div>kg</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>宫高</div>
                    <div>cm</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>腹围</div>
                    <div>cm</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>胎位</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>胎心率次/分</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>先露固定</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>水肿</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>血红蛋白</div>
                    <div>g/L</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>尿蛋白</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>分类</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>转诊</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>孕妇学校</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>下次随访日期</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>产前检查单位</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>医生签名</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  {' '}
                  <div className="unit">
                    <div>{value?.frequency} </div>
                  </div>
                </th>
                <th>
                  <div className="unitrl">
                    <div>{renderData(value?.followupTime)} </div>
                  </div>
                </th>
                <th>
                  {Number.isFinite(value?.gestationalWeek)
                    ? `${Math.floor(
                        Math.floor(value?.gestationalWeek) / 7,
                      )}W${Math.floor(value?.gestationalWeek) % 7}D`
                    : ''}
                </th>
                <th className="bigTh">
                  <div className="unitrl bigTh">
                    <div>{value?.mainComplaint}</div>
                  </div>
                </th>
                <th>
                  <div className="unitrl">
                    <div>
                      {highriskLevel[value?.highRiskAssessment]?.label ?? ''}
                    </div>
                  </div>
                </th>
                <th className="bigTh">
                  <div className="unit">
                    <div>
                      {value?.systolicPressure && value?.diastolicPressure
                        ? `${value?.systolicPressure}/${value?.diastolicPressure}`
                        : ''}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>{value?.weight}</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>{value?.fuh}</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>{value?.abdominalCircumference}</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>{value?.fetusPosition}</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>{value?.fetusHeart}</div>
                  </div>
                </th>
                <th>
                  <div className="unitrl">
                    <div>{value?.presentation}</div>
                  </div>
                </th>
                <th>
                  <div className="unitrl">
                    <div>{value?.edema}</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>{value?.hemoglobinValue}</div>
                  </div>
                </th>
                <th>
                  <div className="unitrl">
                    <div>{value?.urineProtein}</div>
                  </div>
                </th>
                <th>
                  <div className="unitrl">
                    <div>{renderAssessment(value?.overallAssessment)}</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>{renderReferral(value?.referral)}</div>
                  </div>
                </th>
                <th>
                  <div className="unit">
                    <div>{renderGravidaSchool(value?.gravidaSchool)}</div>
                  </div>
                </th>
                <th>
                  <div className="unitrl">
                    <div>{renderData(value?.nextFollowupTime)}</div>
                  </div>
                </th>
                <th>
                  <div className="unitrl">
                    <div>嘉兴妇幼保健院</div>
                  </div>
                </th>
                <th>
                  <div className="unitrl">
                    <div></div>
                  </div>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  return (
    <div className="anteatalPrint-container">
      {medical?.printData?.map(v => {
        return renderPrintDom(v);
      })}
    </div>
  );
};
export default definePage(AntenatalPrint);

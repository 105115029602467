import React from 'react';
import { formatStatus, hisSyncType } from '.';

interface AuthColumnsProps {
  paginationData: { page: number; pageSize: number; total: number } | null;
  onRecordClick: (type: string, data: any) => void;
}

export const getReportColumns = (props: AuthColumnsProps) => {
  const { paginationData, onRecordClick } = props;
  const columns = [
    {
      title: '序号',
      key: 'index',
      width: 100,
      render: (text, record, index) => {
        let currentIndex =
          ((paginationData!.page || 1) - 1) * (paginationData!.pageSize || 10) +
          (index + 1);
        return <span>{currentIndex}</span>;
      },
    },
    { title: 'HIS记录ID', dataIndex: 'hisRecordId', key: 'hisRecordId' },
    { title: '保健册号', dataIndex: 'isbjh', key: 'isbjh' },
    { title: '姓名', dataIndex: 'patientName', key: 'patientName' },
    { title: '就诊卡号', dataIndex: 'patientCard', key: 'patientCard' },
    { title: '患者手机号', dataIndex: 'patientPhone', key: 'patientPhone' },
    {
      title: '数据变更类型',
      dataIndex: 'hisSyncType',
      key: 'hisSyncType',
      render: value => hisSyncType(value),
    },
    { title: '产检时间', dataIndex: 'hisSyncTime', key: 'hisSyncTime' },
    {
      title: '同步状态',
      dataIndex: 'bjSyncStatus',
      key: 'bjSyncStatus',
      render: value => formatStatus(value),
    },
    {
      title: '同步时间',
      dataIndex: 'bjSyncTime',
      key: 'bjSyncTime',
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <div className="table-actions">
            <span onClick={() => onRecordClick('async', record)}>同步</span>
            <span onClick={() => onRecordClick('detail', record)}>
              查看详情
            </span>
          </div>
        );
      },
    },
  ];
  return columns;
};

import React, { useEffect, useState } from 'react';
import {
  VSFormItem,
  VSFormDependency,
  Image,
  VSForm,
  message,
  Checkbox,
  CheckboxGroup,
  Button,
  Modal,
} from '@vs/vsf-kit';

import { SaveOutlined } from '@ant-design/icons';
import medicalServices from '@/services/medical';
import staticCommonService from '@/module/common/services';
import CollapseBlock from '@/components/collapse-block';
import GestationalWeek from '@/components/gestational-week';
import RetainEarlyEnough from '@/components/retain-early-enough';
import BloodPressure from '@/components/blood-pressure';
import Pmh from '@/components/pmh';
import Radiostr from '@/components/radiostr';
import Dayrange from '@/components/day-range';
import HighriskTag from '@/components/highrisk/tag';
import {
  disabledDate,
  disableEdcDate,
  formatDate,
  showDateFormat,
} from '@/utils/rule';
import moment from 'moment';
import VsfFormLayout, {
  FormBlock2,
  VsfFormLayoutDefine,
} from '@/components/form-layout';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Nt,
  Nipt,
  KaryotypeAnalysis,
  KaryotypeExamination,
  EarlyDownsScreening,
  MiddleDownsScreening,
} from '@/components/assistant';
import importHistoryPng from '@/static/import-history.png';
import HpiModal from './hpi';
import './index.less';
import { unwrapObject, wrapObject } from '@/components/utils';
import { useCallBackRef } from '@/utils';
import { labelColWidth } from '@/config';
import DrawMedical from '@/components/draw-medical';
import Otheradvice from '@/components/other-advice';
import Allergy from '@/pages/allergy';
import { radioDataSource, fetusNumArr } from '@/config';
import { cloneDeep, dropRight, set } from 'lodash';
import {
  handleFormatHpi,
  handleFormatError,
  caseStr,
  gravidaStr,
  outlineStr,
} from './utils';

import { handleSubmitHighrisk } from '@/pages/highrisk';
import { handleFormatTextStatic } from '@/components/pmh';
export const renderWeight = formRef => {
  return formRef?.current?.getFieldsValue()?.physicalInfo?.otherOptional
    ? false
    : true;
};

export const weigthType = [
  {
    value: 'WHEELCHAIR',
    label: '轮椅',
  },
  {
    value: 'PLATFORM',
    label: '平车',
  },
  {
    value: 'OTHER',
    label: '其他',
    type: 'OTHER',
  },
];
const abdominalPain = [
  {
    value: '无',
    label: '无',
  },
  {
    value: '规律',
    label: '规律',
  },
  {
    value: '不规律',
    label: '不规律',
  },
];

const Index = props => {
  const { isHistory = true, data, isFold = true } = props;

  const {
    complaint,
    mainDiagnosis,
    minorDiagnosis,
    medicalInfo,
    getMedicalDetail,
  } = props?.stores?.medical;
  const {
    gravidaRecord,
    gravidaHighrisk,
    getPhysicalList,
  } = props?.stores?.gravida;
  const { pregnancyInfo } = gravidaRecord;

  const {
    hisId,
    hisRecordId,
    gravidaRecordId,
    enums,
    isFirstMedical,
    showMedical,
    doctorId,
    doctorInfo,
  } = props?.stores?.common;

  const [hpiVisible, setHpiVisible] = useState(false);
  const [isFirst, setIsFirst] = useState(false);
  const [fold, setFold] = useState(isFold ?? !!data);

  const [epidemicStr, setEpidemicStr] = useState('');

  const [descObj, setDescObj] = useState({});

  const [isRef, formRef] = useCallBackRef();

  const [weightStatu, setWeightStatu] = useState(isHistory);
  const [otherOptionalStatu, setOtherOptionalStatu] = useState(isHistory);
  const [bellyacheDurationTime, setBellyacheDurationTime] = useState('');
  const [bellyacheIntervalTime, setBellyacheIntervalTime] = useState('');
  const [
    disabledMentalRetardationLevel,
    setDisabledMentalRetardationLevel,
  ] = useState({
    // 智障
    mentalRetardation: false,
    // 遗传病
    geneticHistory: false,
    // 肿瘤
    tumorHistory: false,
    // 传染病
    infectiousHistory: false,
    // 精神病史
    mentalDiseaseHistory: false,
    // 畸形
    malformation: false,
    // 其他
    other: false,
  });

  const [curretPersonalHistory, setCurretPersonalHistory] = useState(null);

  const handleDealHighrisk = async values => {
    Modal.confirm({
      title: '操作提醒',
      content: '当前患者未提交高危评估，继续操作将评估低风险，是否确定',
      async onOk() {
        const res = await handleSubmitHighrisk({
          gravidaRecordId,
          hasInfectious: false,
          highriskRecordItemBtoList: [],
          hisRecordId,
          level: null,
        });
        props?.stores?.gravida?.getGravidaHighrisk(gravidaRecordId);
        handleSubmitData(values);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  useEffect(() => {
    if (props.submitKey > 0) {
      handleSubmit();
    }
  }, [props.submitKey]);

  const handleSubmit = async () => {
    let values;
    let flag = false;

    try {
      values = await formRef?.current?.validateFields();
      flag = true;
      vsf.setStoreValue?.('medical.formError', null);
    } catch (err) {
      console.log(err, 'err');
      vsf.setStoreValue?.('medical.formError', err?.errorFields || []);
    }

    if (!flag) return;

    if (!gravidaHighrisk?.highriskCache) {
      handleDealHighrisk(values);
    } else {
      handleSubmitData(values);
    }
  };

  const handleSubmitData = values => {
    if (values?.familyHistorySnapshotBto?.mentalRetardation) {
      values.familyHistorySnapshotBto.mentalRetardationLevel =
        values?.familyHistorySnapshotBto?.mentalRetardation !== '有'
          ? undefined
          : values?.familyHistorySnapshotBto?.mentalRetardationLevel;
    }
    if (values?.medicalInfo?.otherAdvice) {
      try {
        const otherAdvice = JSON.parse(values?.medicalInfo?.otherAdvice);

        otherAdvice?.returnVisitPeriodUnit &&
          (values.medicalInfo.returnVisitPeriodUnit =
            otherAdvice?.returnVisitPeriodUnit);
        otherAdvice?.returnVisitPeriod &&
          (values.medicalInfo.returnVisitPeriod =
            otherAdvice?.returnVisitPeriod);
      } catch (error) {}
    }
    console.log('校验表单并返回格式化后的所有数据：', values);

    const {
      assistantInfo,
      extrapelvicMeasurementSnapshotBto,
      medicalInfo,
      physicalInfo,
      pregnancyInfoSnapshotBto,
      familyHistorySnapshotBto,
      mensesInfoSnapshotBto,
      personalHistorySnapshotBto,
    } = values;

    const {
      pregnancyInfo,
      extrapelvicMeasurement,
      recordFamilyHistory,
      recordPersonalHistory,
      recordAllergyHistory,
      recordMensesInfo,
      ...extraRest
    } = gravidaRecord;

    let formatExtrapelvicMeasurement = {
      multipara: false,
      scarUterus: false,
    };

    if (extrapelvicMeasurementState && extrapelvicMeasurementState.length > 0) {
      extrapelvicMeasurementState.forEach(item => {
        formatExtrapelvicMeasurement[item] = true;
      });
    } else {
      formatExtrapelvicMeasurement = {
        ...extrapelvicMeasurementState,
        ...extrapelvicMeasurementSnapshotBto,
      };
    }

    const formatData = {
      medicalInfo: {
        ...extraRest,
        ...medicalInfo,
        submitDoctorId: doctorId,
        submitDoctorName: doctorInfo?.name,
        pregnancyInfoSnapshotBto: {
          ...(pregnancyInfo || {}),
          ...(pregnancyInfoSnapshotBto || {}),
          edc: formatDate(
            (pregnancyInfoSnapshotBto || pregnancyInfo || {})?.edc,
          ),
          updateEdc: formatDate(
            (pregnancyInfoSnapshotBto || pregnancyInfo || {})?.updateEdc,
          ),
          ...(pregnancyInfoSnapshotBto?.mpas
            ? pregnancyInfoSnapshotBto?.mpas
            : {}),
        },
        extrapelvicMeasurementSnapshotBto: {
          ...(extrapelvicMeasurement || {}),
          ...unwrapObject(formatExtrapelvicMeasurement || {}),
        },
        familyHistorySnapshotBto: {
          ...(recordFamilyHistory || {}),
          ...unwrapObject(familyHistorySnapshotBto || {}),
        },
        personalHistorySnapshotBto: {
          ...(recordPersonalHistory || {}),
          ...unwrapObject(medicalInfo?.pastHistory || {}),
          ...unwrapObject(personalHistorySnapshotBto),
        },
        mensesInfoSnapshotBto: {
          ...(recordMensesInfo || {}),
          ...unwrapObject(mensesInfoSnapshotBto),
        },
        spouseInfoSnapshotBto: gravidaRecord?.spouseInfo,
        gravidaInfoSnapshotBto:
          medicalInfo?.gravidaInfoSnapshotDto || gravidaRecord?.gravidaInfo,
        status: 'ACTIVE',
        hisId,
        gravidaRecordId,
        hisRecordId,
        complaintStructure: complaint,
        diagnosisStructure: JSON.stringify({
          mainDiagnosis,
          minorDiagnosis,
        }),
        pastHistory: medicalInfo?.pastHistory?.pastHistory || '',
        auditStatus: 'DOCTOR_AUDIT',
        medicalRecordType: isFirst ? 'FIRST_VISIT' : 'RETURN_VISIT',
      },
      assistantInfo: {
        ...assistantInfo,
        ...unwrapObject(assistantInfo),
      },
      physicalInfo: {
        ...physicalInfo,
        source: 'DOCTOR_CHECK',
        gravidaRecordId,
        ...unwrapObject(physicalInfo),
        ...(physicalInfo?.bloodPressure || {}),
        ...(physicalInfo?.bloodPressure1 || {}),
        ...(physicalInfo?.bloodPressure2 || {}),

        fhnh: physicalInfo?.fhnh?.[0] ? true : false,
        fhnhTwo: physicalInfo?.fhnhTwo?.[0] ? true : false,
        fhnhThree: physicalInfo?.fhnhThree?.[0] ? true : false,
        hypertensionSymptom: physicalInfo?.hypertensionSymptom?.join(','),
      },
    };
    medicalServices.editMedicalRecord(formatData).then(res => {
      if (res?.code === 200) {
        message.success(res?.message);
        getPhysicalList();
        getMedicalDetail(hisRecordId);
      }
    });
  };

  const renderComplaint = async (mainData, minorData) => {
    const str = (mainData || []).concat(minorData || []).join(';');
    formRef?.current?.setFieldsValue({
      medicalInfo: {
        diagnosis: str,
      },
    });
  };

  const setMedicalInfo = async value => {
    const target = value ?? medicalInfo;
    const {
      physicalInspections,
      pastHistory,
      spouseInfoSnapshotDto,
      gravidaInfoSnapshotDto,
      assistantInspections,
      extrapelvicMeasurementSnapshotDto,
      familyHistorySnapshotDto,
      mensesInfoSnapshotDto,
      personalHistorySnapshotDto,
      pregnancyInfoSnapshotDto,
      complaintStructure,
      diagnosisStructure,
    } = target;

    const {
      vulva,
      vagina,
      cervix,
      uterus,
      adnexa,
      heart,
      lung,
      otherAbnormal,
      ...rest
    } = physicalInspections || {};
    const { multipara, scarUterus } = extrapelvicMeasurementSnapshotDto || {};
    let arr = [];
    if (multipara) {
      arr.push('multipara');
    }
    if (scarUterus) {
      arr.push('scarUterus');
    }
    // const hpiStr = handleFormatHpi({
    //   checked: epidemic?.epidemiologicalHistoryItemDtoList || [],
    //   all: enums['epidemiological_history'],
    // });
    // setEpidemicStr(hpiStr);
    setExtrapelvicMeasurementState(arr);
    const formData = {
      medicalInfo: {
        ...target,
        pastHistory: {
          pastHistory,
        },
        spouseInfoSnapshotBto: spouseInfoSnapshotDto,
        gravidaInfoSnapshotBto: gravidaInfoSnapshotDto,
      },
      physicalInfo: {
        ...wrapObject(rest),
        heart,
        lung,
        otherAbnormal,
        vulva,
        vagina,
        cervix,
        uterus,
        adnexa,

        bloodPressure:
          physicalInspections?.systolicPressure &&
          physicalInspections?.diastolicPressure
            ? {
                systolicPressure: physicalInspections?.systolicPressure,
                diastolicPressure: physicalInspections?.diastolicPressure,
              }
            : null,
        bloodPressure1:
          physicalInspections?.systolicPressureTwo &&
          physicalInspections?.diastolicPressureTwo
            ? {
                systolicPressureTwo: physicalInspections?.systolicPressureTwo,
                diastolicPressureTwo: physicalInspections?.diastolicPressureTwo,
              }
            : null,
        bloodPressure2:
          physicalInspections?.systolicPressureThree &&
          physicalInspections?.diastolicPressureThree
            ? {
                systolicPressureThree:
                  physicalInspections?.systolicPressureThree,
                diastolicPressureThree:
                  physicalInspections?.diastolicPressureThree,
              }
            : null,

        fhnh: physicalInspections?.fhnh ? [true] : null,
        fhnhTwo: physicalInspections?.fhnhTwo ? [true] : null,
        fhnhThree: physicalInspections?.fhnhThree ? [true] : null,
      },
      assistantInfo: assistantInspections,
      extrapelvicMeasurementSnapshotBto: extrapelvicMeasurementSnapshotDto,
      familyHistorySnapshotBto: familyHistorySnapshotDto,
      mensesInfoSnapshotBto: {
        ...wrapObject(mensesInfoSnapshotDto),
      },
      personalHistorySnapshotBto: {
        ...wrapObject(personalHistorySnapshotDto),
      },
      pregnancyInfoSnapshotBto: {
        ...pregnancyInfoSnapshotDto,
        mpas: {
          mature: pregnancyInfoSnapshotDto?.mature,
          premature: pregnancyInfoSnapshotDto?.premature,
          abortion: pregnancyInfoSnapshotDto?.abortion,
          survival: pregnancyInfoSnapshotDto?.survival,
        },
      },
    };
    setCurretPersonalHistory(wrapObject(personalHistorySnapshotDto));
    formRef?.current?.setFieldsValue(formData);

    setDisabledMentalRetardationLevel({
      mentalRetardation: familyHistorySnapshotDto?.mentalRetardation !== '有',
      // 遗传病
      geneticHistory: familyHistorySnapshotDto?.geneticHistory !== '有',
      // 肿瘤
      tumorHistory: familyHistorySnapshotDto?.tumorHistory !== '有',
      // 传染病
      infectiousHistory: familyHistorySnapshotDto?.infectiousHistory !== '有',
      // 精神病史
      mentalDiseaseHistory:
        familyHistorySnapshotDto?.mentalDiseaseHistory !== '有',
      // 畸形
      malformation: familyHistorySnapshotDto?.malformation !== '有',
      // 其他
      other: familyHistorySnapshotDto?.other !== '有',
    });
    setDescObj(handleFormatError(target));

    try {
      if (!value) {
        if (complaintStructure) {
          vsf?.setStoreValue('medical.complaint', complaintStructure);
        }
        const diagnosisObj = JSON.parse(diagnosisStructure);
        if (diagnosisStructure) {
          vsf?.setStoreValue(
            'medical.mainDiagnosis',
            diagnosisObj?.mainDiagnosis,
          );
          vsf?.setStoreValue(
            'medical.minorDiagnosis',
            diagnosisObj?.minorDiagnosis,
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
    return true;
  };

  const setGravidaRecord = () => {
    const {
      recordMensesInfo,
      pregnancyInfo,
      recordPersonalHistory,
      recordFamilyHistory,
      spouseInfo,
      gravidaInfo,
    } = gravidaRecord || {};
    // const target = handleFormatHpi({
    //   checked: epidemic?.epidemiologicalHistoryItemDtoList || [],
    //   all: enums['epidemiological_history'],
    // });
    // setEpidemicStr(target);
    const formData = {
      // medicalInfo: {
      //   hpi: target,
      // },
      medicalInfo: {
        pastHistory: {
          pastHistory: handleFormatTextStatic(
            wrapObject(recordPersonalHistory),
          ),
        },
      },
      mensesInfoSnapshotBto: wrapObject(recordMensesInfo),
      pregnancyInfoSnapshotBto: {
        ...pregnancyInfo,
        mpas: {
          mature: gravidaRecord?.pregnancyInfo?.mature,
          premature: gravidaRecord?.pregnancyInfo?.premature,
          abortion: gravidaRecord?.pregnancyInfo?.abortion,
          survival: gravidaRecord?.pregnancyInfo?.survival,
        },
      },
      personalHistorySnapshotBto: {
        ...wrapObject(recordPersonalHistory),
      },
      familyHistorySnapshotBto: {
        ...recordFamilyHistory,
      },
      spouseInfoSnapshotBto: spouseInfo,
      gravidaInfoSnapshotBto: gravidaInfo,
    };
    setCurretPersonalHistory(wrapObject(recordPersonalHistory));
    formRef?.current?.setFieldsValue(formData);
    // setDisabledMentalRetardationLevel(
    //   recordFamilyHistory?.mentalRetardation !== '有',
    // );
    setDisabledMentalRetardationLevel({
      mentalRetardation: recordFamilyHistory?.mentalRetardation !== '有',
      // 遗传病
      geneticHistory: recordFamilyHistory?.geneticHistory !== '有',
      // 肿瘤
      tumorHistory: recordFamilyHistory?.tumorHistory !== '有',
      // 传染病
      infectiousHistory: recordFamilyHistory?.infectiousHistory !== '有',
      // 精神病史
      mentalDiseaseHistory: recordFamilyHistory?.mentalDiseaseHistory !== '有',
      // 畸形
      malformation: recordFamilyHistory?.malformation !== '有',
      // 其他
      other: recordFamilyHistory?.other !== '有',
    });
    setDescObj(
      handleFormatError({
        familyHistorySnapshotDto: formData?.familyHistorySnapshotBto,
        mensesInfoSnapshotDto: formData?.mensesInfoSnapshotBto,
        personalHistorySnapshotDto: formData?.personalHistorySnapshotBto,
      }),
    );
  };

  const handleValuesChange = (record, allValues) => {
    const {
      physicalInfo,
      mensesInfoSnapshotBto,
      personalHistorySnapshotBto,
      familyHistorySnapshotBto,
    } = allValues;
    console.log(allValues, record, 'allValues');
    const obj = {};
    if (record?.medicalInfo?.pastHistory) {
      const { pastHistory, ...rest } = record?.medicalInfo?.pastHistory || {};
      setCurretPersonalHistory(rest);
    }

    if (record?.physicalInfo) {
      if (
        record?.physicalInfo?.virginalBleed ||
        record?.physicalInfo?.virginalDischarge ||
        record?.physicalInfo?.fetusMovement ||
        record?.physicalInfo?.bellyache ||
        record?.physicalInfo?.bellyacheDurationTime !== undefined ||
        record?.physicalInfo?.bellyacheIntervalTime !== undefined
      ) {
        obj.case = caseStr(physicalInfo);
        if (record?.physicalInfo?.bellyache === '规律') {
          const obj1 = {
            ...physicalInfo,
            bellyacheDurationTime,
            bellyacheIntervalTime,
          };
          obj.case = caseStr(obj1);
        }
        formRef?.current?.setFieldsValue({
          medicalInfo: {
            // hpi: `${epidemicStr};${obj.case}`,
            hpi: `${obj.case}`,
          },
        });
        // if()
        setDescObj({
          ...descObj,
          ...obj,
        });
      }
    }
    // const physicalInfoValue = formRef?.current?.getFieldsValue()?.physicalInfo;
    // if (physicalInfoValue?.bellyache === '规律') {
    //   console.log('====================')
    //   obj.case = caseStr(physicalInfoValue);
    //   formRef?.current?.setFieldsValue({
    //     medicalInfo: {
    //       // hpi: `${epidemicStr};${obj.case}`,
    //       hpi: `${obj.case}`,
    //     },
    //   });
    //   setDescObj({
    //     ...descObj,
    //     ...obj,
    //   });
    // }
    // console.log(formRef?.current?.getFieldsValue()?.physicalInfo?.bellyache
    // ,'formRef')

    if (record?.mensesInfoSnapshotBto) {
      obj.gravida = gravidaStr(mensesInfoSnapshotBto);
      setDescObj({
        ...descObj,
        ...obj,
      });
    }
    if (
      record?.personalHistorySnapshotBto ||
      record?.familyHistorySnapshotBto
    ) {
      const personalKey = Object.keys(
        record?.personalHistorySnapshotBto || {},
      )?.[0];
      const familyKey = Object.keys(
        record?.familyHistorySnapshotBto || {},
      )?.[0];
      if (
        (personalKey && personalKey.indexOf('Detail') < 0) ||
        (familyKey && familyKey.indexOf('Detail') < 0)
      ) {
        obj.outline = outlineStr({
          ...personalHistorySnapshotBto,
          extra: familyHistorySnapshotBto,
        });
        setDescObj({
          ...descObj,
          ...obj,
        });
      }
      if (
        record?.familyHistorySnapshotBto &&
        record?.familyHistorySnapshotBto[
          Object.keys(record?.familyHistorySnapshotBto)[0]
        ]
      ) {
        const target =
          record?.familyHistorySnapshotBto[
            Object.keys(record?.familyHistorySnapshotBto)[0]
          ];
        setDisabledMentalRetardationLevel({
          ...disabledMentalRetardationLevel,
          [Object.keys(record?.familyHistorySnapshotBto)[0]]: target !== '有',
        });
      }
    }
  };
  const [
    extrapelvicMeasurementState,
    setExtrapelvicMeasurementState,
  ] = useState();

  const onChangeExtrapelvicMeasurement = v => {
    formRef?.current?.setFields([
      {
        name: ['extrapelvicMeasurementSnapshotBto', 'interspinalDiameter'],
        errors: null,
      },
      {
        name: ['extrapelvicMeasurementSnapshotBto', 'intercristalDiameter'],
        errors: null,
      },
      {
        name: ['extrapelvicMeasurementSnapshotBto', 'externalConjugate'],
        errors: null,
      },
      {
        name: ['extrapelvicMeasurementSnapshotBto', 'transverseOutlet'],
        errors: null,
      },
    ]);
    setExtrapelvicMeasurementState(v);
  };

  const CheckExtrapelvicMeasurement = () => {
    return (
      <CheckboxGroup
        value={extrapelvicMeasurementState}
        onChange={onChangeExtrapelvicMeasurement}
        dataSource={[
          {
            label: '经产妇',
            value: 'multipara',
          },
          {
            label: '疤痕子宫',
            value: 'scarUterus',
          },
        ]}
      ></CheckboxGroup>
    );
  };

  const handleSaveHpi = () => {
    const value = formRef.current?.getFieldValue(['medicalInfo', 'otherHpi']);
    staticCommonService
      .createHpi({
        btoParam: {
          otherHpi: value,
          hisRecordId,
          doctorId,
        },
      })
      .then(res => {
        if (res?.code === 200) {
          message.success('提交成功');
        } else {
          message.error('提交失败');
        }
      });
    // formRef.current.get
  };

  useEffect(() => {
    renderComplaint(mainDiagnosis, minorDiagnosis);
  }, [mainDiagnosis, minorDiagnosis]);

  useEffect(() => {
    formRef?.current?.setFieldsValue({
      medicalInfo: {
        complaint,
      },
    });
  }, [complaint]);

  useEffect(() => {
    if (isRef) {
      if (data?.id) {
        setMedicalInfo(data);
      } else {
        if (!medicalInfo?.id) {
          setGravidaRecord();
        } else {
          setMedicalInfo();
        }
      }
    }
  }, [isFirstMedical, gravidaRecord, medicalInfo, data, isRef]);

  useEffect(() => {
    if (data?.id) {
      setIsFirst(data?.medicalRecordType === 'FIRST_VISIT');
    } else {
      setIsFirst(isFirstMedical);
    }
  }, [isFirstMedical, data]);

  const disabledExtrapelvicMeasurement =
    extrapelvicMeasurementState && extrapelvicMeasurementState.length > 0;
  return (
    showMedical && (
      <div
        className="e-medical-form-block"
        style={{ backgroundColor: 'transparent' }}
      >
        <HpiModal
          key={Math.random()}
          visible={hpiVisible}
          onCancel={v => {
            if (v && formRef?.current) {
              formRef?.current?.setFieldsValue({
                medicalInfo: {
                  otherHpi: v,
                },
              });
            }
            setHpiVisible(false);
          }}
          params={{
            doctorId,
          }}
        />
        <div className="content">
          <VSForm
            dataType="static"
            layout="horizontal"
            formRef={formRef}
            labelCol={labelColWidth[5]}
            onValuesChange={handleValuesChange}
            disabled={isHistory}
          >
            <CollapseBlock
              title="病历概况"
              description={descObj['case']}
              id="anchor-case"
              // collapseAuto={fold}
              defaultExpand={fold}
            >
              <FormBlock2 title="病历概况">
                <VsfFormLayout columnCount={1}>
                  <VSFormItem
                    label="主诉"
                    name={['medicalInfo', 'complaint']}
                    valueType="text"
                    fieldProps={{
                      disabled: true,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="诊断"
                    name={['medicalInfo', 'diagnosis']}
                    valueType="text"
                    fieldProps={{
                      disabled: true,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="现病史"
                    name={['medicalInfo', 'hpi']}
                    valueType="text"
                    fieldProps={{
                      disabled: true,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>

                  <VSFormItem
                    label="其他现病史"
                    name={['medicalInfo', 'otherHpi']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                      suffix: (
                        <>
                          <SaveOutlined
                            style={{
                              fontSize: 20,
                            }}
                            onClick={() => {
                              handleSaveHpi();
                            }}
                          />
                          <Image
                            onClick={() => {
                              !isHistory && setHpiVisible(true);
                            }}
                            className="import-history-image"
                            src={importHistoryPng}
                            preview={false}
                          />
                        </>
                      ),
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="既往史"
                    name={['medicalInfo', 'pastHistory']}
                    valueType="custom"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  >
                    <Pmh dataSource={curretPersonalHistory} />
                  </VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
            </CollapseBlock>

            <CollapseBlock
              title="孕产妇基本信息"
              description={isFirst ? descObj['gravida'] : ''}
              id="anchor-gravida"
              // collapseAuto={fold}
              defaultExpand={fold}
            >
              {isFirst && (
                <FormBlock2 title="月经史">
                  <VsfFormLayout>
                    <VSFormItem
                      label="月经初潮"
                      name={['mensesInfoSnapshotBto', 'menarche']}
                      valueType="digit"
                      fieldProps={{
                        min: 10,
                        max: 50,
                        addonAfter: '岁',
                        placeholder: '请输入',
                        controls: false,
                      }}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: '请填写月经初潮',
                      //   },
                      // ]}
                    ></VSFormItem>
                    <VSFormItem
                      label="月经周期"
                      name={['mensesInfoSnapshotBto', 'menstrualCycle']}
                      valueType="custom"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: '请填写月经周期',
                      //   },
                      // ]}
                    >
                      <Dayrange
                        dataKey={['menstrualCycleMin', 'menstrualCycleMax']}
                      />
                    </VSFormItem>
                    <VSFormItem
                      label="月经经期"
                      name={['mensesInfoSnapshotBto', 'menstrualPeriod']}
                      valueType="custom"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: '请填写月经经期',
                      //   },
                      // ]}
                    >
                      <Dayrange
                        dataKey={['menstrualPeriodMin', 'menstrualPeriodMax']}
                      />
                    </VSFormItem>

                    <VSFormItem
                      label="月经经量"
                      name={['mensesInfoSnapshotBto', 'menstrualVolume']}
                      valueType="select"
                      dataSource={enums['menstrual_volume']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: '请填写月经经量',
                      //   },
                      // ]}
                    ></VSFormItem>
                    <VSFormItem
                      label="痛经"
                      name={['mensesInfoSnapshotBto', 'dysmenorrhea']}
                      valueType="select"
                      dataSource={enums['dysmenorrhea']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: '请填写痛经',
                      //   },
                      // ]}
                    ></VSFormItem>

                    <VSFormItem
                      label="白带"
                      name={['mensesInfoSnapshotBto', 'leucorrhea']}
                      valueType="select"
                      dataSource={enums['leucorrhea']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: '请填写白带',
                      //   },
                      // ]}
                    ></VSFormItem>
                  </VsfFormLayout>
                </FormBlock2>
              )}

              <FormBlock2 title="怀孕信息">
                <VsfFormLayout>
                  <VSFormItem
                    label="孕周"
                    name={['pregnancyInfoSnapshotBto', 'gestationalWeek']}
                    valueType="custom"
                    labelCol={labelColWidth[10]}
                    fieldProps={{
                      disabled: true,
                    }}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: '请填写孕周',
                    //   },
                    // ]}
                  >
                    <GestationalWeek disabled={isHistory} />
                  </VSFormItem>
                  <VSFormItem
                    label="修正孕周"
                    name={['pregnancyInfoSnapshotBto', 'updateGestationalWeek']}
                    valueType="custom"
                  >
                    <GestationalWeek disabled={isHistory} />
                  </VSFormItem>
                  <VSFormItem
                    label="预产期"
                    name={['pregnancyInfoSnapshotBto', 'edc']}
                    valueType="date"
                    fieldProps={{
                      disabledDate: disableEdcDate,
                      placeholder: '请选择',
                      disabled: true,
                    }}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: '请填写预产期',
                    //   },
                    // ]}
                  ></VSFormItem>

                  <VSFormItem
                    label="修正预产期"
                    name={['pregnancyInfoSnapshotBto', 'updateEdc']}
                    valueType="date"
                    fieldProps={{
                      disabledDate: disableEdcDate,
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
                {isFirst && (
                  <VsfFormLayout columnCount={1}>
                    <VsfFormLayout>
                      <VSFormItem
                        label="孕前体重"
                        name={[
                          'pregnancyInfoSnapshotBto',
                          'weightBeforePregnancy',
                        ]}
                        fieldProps={{
                          precision: 2,
                          addonAfter: 'kg',
                          placeholder: '请输入',
                          controls: false,
                          min: 30,
                          max: 300,
                        }}
                        valueType="digit"
                        rules={[
                          {
                            required: true,
                            message: '请填写孕前体重',
                          },
                        ]}
                      ></VSFormItem>
                      <VSFormItem
                        label="孕次"
                        name={['pregnancyInfoSnapshotBto', 'gravidity']}
                        valueType="digit"
                        fieldProps={{
                          precision: 0,
                          addonAfter: '次',
                          placeholder: '请输入',
                          controls: false,
                          min: 1,
                          max: 10,
                        }}
                      ></VSFormItem>
                      <VSFormItem
                        label="产次"
                        name={['pregnancyInfoSnapshotBto', 'parity']}
                        fieldProps={{
                          precision: 0,
                          addonAfter: '次',
                          placeholder: '请输入',
                          controls: false,
                          min: 1,
                          max: 10,
                        }}
                        valueType="digit"
                      ></VSFormItem>
                      <VSFormItem
                        label="受孕方式"
                        name={['pregnancyInfoSnapshotBto', 'fereilizingMethod']}
                        valueType="select"
                        dataSource={enums['fereilizing_method']}
                        fieldProps={{
                          placeholder: '请选择',
                        }}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: '请填写受孕方式',
                        //   },
                        // ]}
                      ></VSFormItem>

                      <VSFormItem
                        label="计划分娩医院"
                        name={['pregnancyInfoSnapshotBto', 'deliveryLocal']}
                        valueType="select"
                        dataSource={enums['delivery_hospital']}
                        fieldProps={{
                          placeholder: '请选择',
                        }}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: '请填写计划分娩医院',
                        //   },
                        // ]}
                      ></VSFormItem>
                    </VsfFormLayout>
                    <VsfFormLayoutDefine columnSpan={[2, 2]}>
                      <VSFormItem
                        label="避孕史"
                        name={[
                          'pregnancyInfoSnapshotBto',
                          'contraceptionHistory',
                        ]}
                        valueType="radio"
                        dataSource={radioDataSource}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: '请填写避孕史',
                        //   },
                        // ]}
                        fieldProps={{
                          className: 'ant-radio-checkbox-border',
                        }}
                      ></VSFormItem>
                      <VSFormItem
                        label="避孕史详述"
                        name={[
                          'pregnancyInfoSnapshotBto',
                          'contraceptionHistoryDetail',
                        ]}
                        valueType="text"
                        fieldProps={{
                          placeholder: '请输入',
                        }}
                      ></VSFormItem>
                    </VsfFormLayoutDefine>
                    <VsfFormLayoutDefine columnSpan={[2, 2]}>
                      <VSFormItem
                        label="本次妊娠早孕期情况"
                        name={[
                          'pregnancyInfoSnapshotBto',
                          'earlyPregnancyInfo',
                        ]}
                        valueType="radio"
                        dataSource={radioDataSource}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: '请填写本次妊娠早孕期情况',
                        //   },
                        // ]}
                        fieldProps={{
                          className: 'ant-radio-checkbox-border',
                        }}
                      ></VSFormItem>
                      <VSFormItem
                        label="情况详述"
                        name={[
                          'pregnancyInfoSnapshotBto',
                          'earlyPregnancyInfoDetail',
                        ]}
                        valueType="text"
                        fieldProps={{
                          placeholder: '请输入',
                        }}
                      ></VSFormItem>
                    </VsfFormLayoutDefine>
                  </VsfFormLayout>
                )}
              </FormBlock2>

              <FormBlock2 title="高危信息">
                <VsfFormLayout columnCount={1}>
                  <VSFormItem
                    label="足早流存"
                    name={['pregnancyInfoSnapshotBto', 'mpas']}
                    valueType="custom"
                    fieldProps={{
                      disabled: true,
                    }}
                  >
                    <RetainEarlyEnough
                      disabled={true}
                      value={{
                        mature: pregnancyInfo?.mature || 0,
                        premature: pregnancyInfo?.premature || 0,
                        abortion: pregnancyInfo?.abortion || 0,
                        survival: pregnancyInfo?.survival || 0,
                      }}
                    />
                  </VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
            </CollapseBlock>

            {isFirst && (
              <>
                <CollapseBlock
                  title="概要"
                  description={descObj['outline']}
                  id="anchor-outline"
                  // collapseAuto={fold}
                  defaultExpand={fold}
                >
                  <FormBlock2 title="个人史">
                    <VsfFormLayout columnCount={1} labelCol={labelColWidth[7]}>
                      <VSFormItem
                        label="RH-阴性血"
                        name={['personalHistorySnapshotBto', 'rhNegative']}
                        valueType="custom"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: '请填写RH-阴性血',
                        //   },
                        // ]}
                      >
                        <Radiostr
                          dataKey={['rhNegative', 'rhNegativeDetail']}
                        />
                      </VSFormItem>
                      <VSFormItem
                        label="外地久居住史"
                        name={['personalHistorySnapshotBto', 'nllrHistory']}
                        valueType="custom"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: '请填写外地久居住史',
                        //   },
                        // ]}
                      >
                        <Radiostr
                          dataKey={['nllrHistory', 'nllrHistoryDetail']}
                        />
                      </VSFormItem>

                      <VSFormItem
                        label="疫水疫源接触史"
                        name={['personalHistorySnapshotBto', 'epidemicFocus']}
                        valueType="custom"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: '请填写疫水疫源接触史',
                        //   },
                        // ]}
                      >
                        <Radiostr
                          dataKey={['epidemicFocus', 'epidemicFocusDetail']}
                        />
                      </VSFormItem>
                      <VSFormItem
                        label="吸烟史"
                        name={['personalHistorySnapshotBto', 'smokingHistory']}
                        valueType="custom"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: '请填写吸烟史',
                        //   },
                        // ]}
                      >
                        <Radiostr
                          dataKey={['smokingHistory', 'smokingHistoryDetail']}
                        />
                      </VSFormItem>

                      <VSFormItem
                        label="喝酒史"
                        name={['personalHistorySnapshotBto', 'drinkingHistory']}
                        valueType="custom"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: '请填写喝酒史',
                        //   },
                        // ]}
                      >
                        <Radiostr
                          dataKey={['drinkingHistory', 'drinkingHistoryDetail']}
                        />
                      </VSFormItem>
                      <VSFormItem
                        label="经常情绪低落史"
                        name={['personalHistorySnapshotBto', 'oftenDepressed']}
                        valueType="custom"
                      >
                        <Radiostr
                          dataKey={['oftenDepressed', 'oftenDepressedDetail']}
                        />
                      </VSFormItem>

                      <VSFormItem
                        label="经常做事没兴趣"
                        name={[
                          'personalHistorySnapshotBto',
                          'oftenUninterested',
                        ]}
                        valueType="custom"
                      >
                        <Radiostr
                          dataKey={[
                            'oftenUninterested',
                            'oftenUninterestedDetail',
                          ]}
                        />
                      </VSFormItem>
                      <VSFormItem
                        label="预防接种史"
                        name={[
                          'personalHistorySnapshotBto',
                          'vaccinationHistory',
                        ]}
                        valueType="custom"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: '请填写预防接种史',
                        //   },
                        // ]}
                      >
                        <Radiostr
                          dataKey={[
                            'vaccinationHistory',
                            'vaccinationHistoryDetail',
                          ]}
                        />
                      </VSFormItem>
                      <VSFormItem
                        label="有毒有害物质"
                        name={[
                          'personalHistorySnapshotBto',
                          'harmfulSubstanceContactHistory',
                        ]}
                        valueType="custom"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: '请填写有毒有害物质',
                        //   },
                        // ]}
                      >
                        <Radiostr
                          dataKey={[
                            'harmfulSubstanceContactHistory',
                            'harmfulSubstanceContactHistoryDetail',
                          ]}
                        />
                      </VSFormItem>
                      <VSFormItem
                        label="接触放射线"
                        name={[
                          'personalHistorySnapshotBto',
                          'radiationContactHistory',
                        ]}
                        valueType="custom"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: '请填写接触放射线',
                        //   },
                        // ]}
                      >
                        <Radiostr
                          dataKey={[
                            'radiationContactHistory',
                            'radiationContactHistoryDetail',
                          ]}
                        />
                      </VSFormItem>
                      <VSFormItem
                        label="其他"
                        name={[
                          'personalHistorySnapshotBto',
                          'otherHistoryDetail',
                        ]}
                        valueType="text"
                        fieldProps={{
                          placeholder: '请输入',
                        }}
                      ></VSFormItem>
                    </VsfFormLayout>
                  </FormBlock2>

                  <FormBlock2 title="家族史">
                    <VsfFormLayout columnCount={1} labelCol={labelColWidth[7]}>
                      <VsfFormLayoutDefine columnSpan={[1, 1]}>
                        <VSFormItem
                          label="遗传病史"
                          name={['familyHistorySnapshotBto', 'geneticHistory']}
                          valueType="radio"
                          dataSource={radioDataSource}
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: '请填写遗传病史',
                          //   },
                          // ]}
                          fieldProps={{
                            className: 'ant-radio-checkbox-border',
                          }}
                        >
                          {/* <Radiostr
                          dataKey={['geneticHistory', 'geneticHistoryDetail']}
                        /> */}
                        </VSFormItem>
                        <VSFormItem
                          label=""
                          name={[
                            'familyHistorySnapshotBto',
                            'geneticHistoryDetail',
                          ]}
                          valueType="text"
                          fieldProps={{
                            placeholder: '请输入',
                          }}
                        ></VSFormItem>
                      </VsfFormLayoutDefine>

                      <VsfFormLayoutDefine columnSpan={[1, 1]}>
                        <VSFormItem
                          label="肿瘤病史"
                          name={['familyHistorySnapshotBto', 'tumorHistory']}
                          valueType="radio"
                          dataSource={radioDataSource}
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: '请填写肿瘤病史',
                          //   },
                          // ]}
                          fieldProps={{
                            className: 'ant-radio-checkbox-border',
                          }}
                        >
                          {/* <Radiostr
                          dataKey={['tumorHistory', 'tumorHistoryDetail']}
                        /> */}
                        </VSFormItem>
                        <VSFormItem
                          label=""
                          name={[
                            'familyHistorySnapshotBto',
                            'tumorHistoryDetail',
                          ]}
                          valueType="text"
                          fieldProps={{
                            placeholder: '请输入',
                          }}
                        ></VSFormItem>
                      </VsfFormLayoutDefine>

                      <VsfFormLayoutDefine columnSpan={[1, 1]}>
                        <VSFormItem
                          label="传染病史"
                          name={[
                            'familyHistorySnapshotBto',
                            'infectiousHistory',
                          ]}
                          valueType="radio"
                          dataSource={radioDataSource}
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: '请填写传染病史',
                          //   },
                          // ]}
                          fieldProps={{
                            className: 'ant-radio-checkbox-border',
                          }}
                        >
                          {/* <Radiostr
                          dataKey={[
                            'infectiousHistory',
                            'infectiousHistoryDetail',
                          ]}
                        /> */}
                        </VSFormItem>
                        <VSFormItem
                          label=""
                          name={[
                            'familyHistorySnapshotBto',
                            'infectiousHistoryDetail',
                          ]}
                          valueType="text"
                          fieldProps={{
                            placeholder: '请输入',
                          }}
                        ></VSFormItem>
                      </VsfFormLayoutDefine>
                      <VsfFormLayoutDefine columnSpan={[1, 1]}>
                        <VSFormItem
                          label="精神病史"
                          name={[
                            'familyHistorySnapshotBto',
                            'mentalDiseaseHistory',
                          ]}
                          valueType="radio"
                          dataSource={radioDataSource}
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: '请填写精神病史',
                          //   },
                          // ]}
                          fieldProps={{
                            className: 'ant-radio-checkbox-border',
                          }}
                        >
                          {/* <Radiostr
                          dataKey={[
                            'mentalDiseaseHistory',
                            'mentalDiseaseHistoryDetail',
                          ]}
                        /> */}
                        </VSFormItem>
                        <VSFormItem
                          label=""
                          name={[
                            'familyHistorySnapshotBto',
                            'mentalDiseaseHistoryDetail',
                          ]}
                          valueType="text"
                          fieldProps={{
                            placeholder: '请输入',
                          }}
                        ></VSFormItem>
                      </VsfFormLayoutDefine>

                      <VsfFormLayoutDefine columnSpan={[1, 1]}>
                        <VSFormItem
                          label="畸形"
                          name={['familyHistorySnapshotBto', 'malformation']}
                          valueType="radio"
                          dataSource={radioDataSource}
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: '请填写畸形',
                          //   },
                          // ]}
                          fieldProps={{
                            className: 'ant-radio-checkbox-border',
                          }}
                        >
                          {/* <Radiostr
                          dataKey={['malformation', 'malformationDetail']}
                        /> */}
                        </VSFormItem>
                        <VSFormItem
                          label=""
                          name={[
                            'familyHistorySnapshotBto',
                            'malformationDetail',
                          ]}
                          valueType="text"
                          fieldProps={{
                            placeholder: '请输入',
                          }}
                        ></VSFormItem>
                      </VsfFormLayoutDefine>

                      <VsfFormLayoutDefine columnSpan={[1, 1]}>
                        <VSFormItem
                          label="智障"
                          name={[
                            'familyHistorySnapshotBto',
                            'mentalRetardation',
                          ]}
                          valueType="radio"
                          dataSource={radioDataSource}
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: '请填写智障',
                          //   },
                          // ]}
                          fieldProps={{
                            className: 'ant-radio-checkbox-border',
                          }}
                        >
                          {/* <Radiostr
                          dataKey={[
                            'mentalRetardation',
                            'mentalRetardationDetail',
                          ]}
                        /> */}
                        </VSFormItem>
                        <VSFormItem
                          label=""
                          name={[
                            'familyHistorySnapshotBto',
                            'mentalRetardationDetail',
                          ]}
                          valueType="text"
                          fieldProps={{
                            placeholder: '请输入',
                          }}
                        ></VSFormItem>
                      </VsfFormLayoutDefine>

                      <VsfFormLayoutDefine columnSpan={[1, 1]}>
                        <VSFormItem
                          label="智障程度"
                          name={[
                            'familyHistorySnapshotBto',
                            'mentalRetardationLevel',
                          ]}
                          valueType="radio"
                          fieldProps={{
                            className: 'ant-radio-checkbox-border',
                            disabled:
                              disabledMentalRetardationLevel?.mentalRetardation,
                          }}
                          dataSource={[
                            {
                              label: '轻度',
                              value: '轻度',
                            },
                            {
                              label: '中度',
                              value: '中度',
                            },
                            {
                              label: '重度',
                              value: '重度',
                            },
                          ]}
                        ></VSFormItem>
                        {/* <VSFormItem
                        label="智障详述"
                        name={[
                          'recordFamilyHistoryBto',
                          'mentalRetardationDetail',
                        ]}
                        valueType="text"
                        fieldProps={{
                          placeholder: '请输入',
                        }}
                      ></VSFormItem> */}
                      </VsfFormLayoutDefine>

                      <VsfFormLayoutDefine columnSpan={[1, 1]}>
                        <VSFormItem
                          label="其他"
                          name={['familyHistorySnapshotBto', 'other']}
                          valueType="radio"
                          dataSource={radioDataSource}
                          fieldProps={{
                            className: 'ant-radio-checkbox-border',
                          }}
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: '请填写其他',
                          //   },
                          // ]}
                        >
                          {/* <Radiostr dataKey={['other', 'otherDetail']} /> */}
                        </VSFormItem>
                        <VSFormItem
                          label=""
                          name={['familyHistorySnapshotBto', 'otherDetail']}
                          valueType="text"
                          fieldProps={{
                            placeholder: '请输入',
                          }}
                        ></VSFormItem>
                      </VsfFormLayoutDefine>
                    </VsfFormLayout>
                    <VsfFormLayout labelCol={labelColWidth[7]}>
                      <VSFormItem
                        label="母亲"
                        name={['familyHistorySnapshotBto', 'motherHistory']}
                        valueType="select"
                        dataSource={enums['mother_history']}
                        fieldProps={{
                          placeholder: '请选择',
                        }}
                      ></VSFormItem>
                      <VSFormItem
                        label="父亲"
                        name={['familyHistorySnapshotBto', 'fatherHistory']}
                        valueType="select"
                        dataSource={enums['father_history']}
                        fieldProps={{
                          placeholder: '请选择',
                        }}
                      ></VSFormItem>
                      <VSFormItem
                        label="体健姐妹"
                        name={['familyHistorySnapshotBto', 'healthySisters']}
                        valueType="digit"
                        fieldProps={{
                          min: 0,
                          max: 10,
                          precision: 0,
                          addonAfter: '个',
                          placeholder: '请输入',
                          controls: false,
                        }}
                      ></VSFormItem>
                      <VSFormItem
                        label="体健兄弟"
                        name={['familyHistorySnapshotBto', 'healthyBrothers']}
                        valueType="digit"
                        fieldProps={{
                          min: 0,
                          max: 10,
                          precision: 0,
                          addonAfter: '个',
                          placeholder: '请输入',
                          controls: false,
                        }}
                      ></VSFormItem>
                      <VSFormItem
                        label="患病姐妹"
                        name={['familyHistorySnapshotBto', 'unhealthySisters']}
                        valueType="digit"
                        fieldProps={{
                          min: 0,
                          max: 10,
                          precision: 0,
                          addonAfter: '个',
                          placeholder: '请输入',
                          controls: false,
                        }}
                      ></VSFormItem>
                      <VSFormItem
                        label="患病兄弟"
                        name={['familyHistorySnapshotBto', 'unhealthyBrothers']}
                        valueType="digit"
                        fieldProps={{
                          min: 0,
                          max: 10,
                          precision: 0,
                          addonAfter: '个',
                          placeholder: '请输入',
                          controls: false,
                        }}
                      ></VSFormItem>
                      <VSFormItem
                        label="逝世姐妹"
                        name={['familyHistorySnapshotBto', 'diedSisters']}
                        valueType="digit"
                        fieldProps={{
                          min: 0,
                          max: 10,
                          precision: 0,
                          addonAfter: '个',
                          placeholder: '请输入',
                          controls: false,
                        }}
                      ></VSFormItem>
                      <VSFormItem
                        label="逝世兄弟"
                        name={['familyHistorySnapshotBto', 'diedBrothers']}
                        valueType="digit"
                        fieldProps={{
                          min: 0,
                          max: 10,
                          precision: 0,
                          addonAfter: '个',
                          placeholder: '请输入',
                          controls: false,
                        }}
                      ></VSFormItem>
                    </VsfFormLayout>
                  </FormBlock2>
                  <FormBlock2 title="过敏史">
                    <VsfFormLayout columnCount={1}>
                      <Allergy isHistory={true} />
                      {/* <VSFormItem
                        label="过敏史"
                        name={['recordAllergyHistoryBtoList']}
                        valueType="custom"
                      >
                        <Radiostr
                          dataKey={[
                            'drugAllergyHistory',
                            'drugAllergyHistoryDetail',
                          ]}
                        />
                      </VSFormItem> */}
                    </VsfFormLayout>
                  </FormBlock2>
                </CollapseBlock>
              </>
            )}

            <CollapseBlock
              title="体格检查"
              id="anchor-physical"
              // collapseAuto={fold}
              defaultExpand={fold}
              description={descObj['ply']}
            >
              {isFirst && (
                <FormBlock2
                  title="骨盆外测量"
                  extra={CheckExtrapelvicMeasurement()}
                >
                  <VsfFormLayout>
                    <VSFormItem
                      label="髂棘间径"
                      name={[
                        'extrapelvicMeasurementSnapshotBto',
                        'interspinalDiameter',
                      ]}
                      valueType="digit"
                      // rules={
                      //   disabledExtrapelvicMeasurement
                      //     ? []
                      //     : [
                      //         {
                      //           required: true,
                      //           message: '请填写髂棘间径',
                      //         },
                      //       ]
                      // }
                      fieldProps={{
                        min: 0,
                        placeholder: '请输入',
                        disabled: isHistory || disabledExtrapelvicMeasurement,
                        controls: false,
                        addonAfter: 'cm',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="髂嵴间径"
                      name={[
                        'extrapelvicMeasurementSnapshotBto',
                        'intercristalDiameter',
                      ]}
                      valueType="digit"
                      // rules={
                      //   disabledExtrapelvicMeasurement
                      //     ? []
                      //     : [
                      //         {
                      //           required: true,
                      //           message: '请填写髂嵴间径',
                      //         },
                      //       ]
                      // }
                      fieldProps={{
                        min: 0,
                        placeholder: '请输入',
                        disabled: isHistory || disabledExtrapelvicMeasurement,
                        controls: false,
                        addonAfter: 'cm',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="骶耻外径"
                      name={[
                        'extrapelvicMeasurementSnapshotBto',
                        'externalConjugate',
                      ]}
                      valueType="digit"
                      // rules={
                      //   disabledExtrapelvicMeasurement
                      //     ? []
                      //     : [
                      //         {
                      //           required: true,
                      //           message: '请填写骶耻外径',
                      //         },
                      //       ]
                      // }
                      fieldProps={{
                        min: 0,
                        placeholder: '请输入',
                        disabled: isHistory || disabledExtrapelvicMeasurement,
                        controls: false,
                        addonAfter: 'cm',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="出口横径"
                      name={[
                        'extrapelvicMeasurementSnapshotBto',
                        'transverseOutlet',
                      ]}
                      valueType="digit"
                      // rules={
                      //   disabledExtrapelvicMeasurement
                      //     ? []
                      //     : [
                      //         {
                      //           required: true,
                      //           message: '请填写出口横径',
                      //         },
                      //       ]
                      // }
                      fieldProps={{
                        min: 0,
                        placeholder: '请输入',
                        disabled: isHistory || disabledExtrapelvicMeasurement,
                        controls: false,
                        addonAfter: 'cm',
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                </FormBlock2>
              )}

              <FormBlock2 title="体征测量">
                <VsfFormLayout>
                  <VSFormItem
                    label="体温"
                    name={['physicalInfo', 'temperature']}
                    valueType="digit"
                    fieldProps={{
                      precision: 1,
                      addonAfter: '°C',
                      placeholder: '请输入',
                      controls: false,
                      min: 34,
                      max: 45,
                    }}
                  ></VSFormItem>
                  <VsfFormLayout>
                    <VSFormItem
                      label="体重"
                      name={['physicalInfo', 'weight']}
                      valueType="digit"
                      fieldProps={{
                        precision: 2,
                        addonAfter: 'kg',
                        placeholder: '请输入',
                        controls: false,
                        min: 30,
                        max: 300,
                        disabled: weightStatu,
                        onChange: e => {
                          setOtherOptionalStatu(true);
                          formRef.current?.setFieldsValue({
                            physicalInfo: {
                              otherOptional: null,
                            },
                          });
                        },
                      }}
                      rules={[
                        {
                          required: isHistory || renderWeight(formRef),
                          message: '请填写体重',
                        },
                      ]}
                    ></VSFormItem>
                    <VSFormItem
                      // label="其他"
                      name={['physicalInfo', 'otherOptional']}
                      valueType="select"
                      dataSource={weigthType}
                      fieldProps={{
                        placeholder: '请选择',
                        disabled: otherOptionalStatu,
                        onChange: e => {
                          formRef.current?.setFieldsValue({
                            physicalInfo: {
                              weight: '',
                            },
                          });
                          setWeightStatu(true);
                        },
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VSFormItem
                    label="血压(mmHg)"
                    name={['physicalInfo', 'bloodPressure']}
                    valueType="custom"
                    fieldProps={{
                      placeholder: '请输入',
                      min: 30,
                      max: 200,
                    }}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: '请填写血压',
                    //   },
                    // ]}
                  >
                    <BloodPressure
                      dataKey={['systolicPressure', 'diastolicPressure']}
                      onChange={e => {
                        if (e?.diastolicPressure && e.systolicPressure) {
                          if (formRef.current) {
                            formRef.current.setFieldsValue({
                              physicalInfo: {
                                bloodPressureDate: moment(new Date()).format(
                                  'YYYY-MM-DD hh:mm:ss',
                                ),
                              },
                            });
                          }
                        }
                      }}
                    />
                  </VSFormItem>
                  <VSFormItem
                    label="血压时间"
                    name={['physicalInfo', 'bloodPressureTime']}
                    // valueType="dateRange"
                    valueType="time"
                    fieldProps={{
                      showTime: true,
                      disabledDate: disableEdcDate,
                      format: ['YYYY-MM-DD HH:mm:ss'],
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="复测血压1(mmHg)"
                    name={['physicalInfo', 'bloodPressure1']}
                    valueType="custom"
                  >
                    <BloodPressure
                      onChange={e => {
                        if (e?.diastolicPressureTwo && e.systolicPressureTwo) {
                          if (formRef.current) {
                            formRef.current.setFieldsValue({
                              physicalInfo: {
                                bloodPressure1Date: moment(new Date()).format(
                                  'YYYY-MM-DD hh:mm:ss',
                                ),
                              },
                            });
                          }
                        }
                      }}
                      dataKey={['systolicPressureTwo', 'diastolicPressureTwo']}
                    />
                  </VSFormItem>
                  <VSFormItem
                    label="复测血压时间1"
                    name={['physicalInfo', 'bloodPressureTimeTwo']}
                    valueType="time"
                    // valueType="dateRange"
                    fieldProps={{
                      showTime: true,
                      disabledDate: disableEdcDate,
                      format: ['YYYY-MM-DD HH:mm:ss'],
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="复测血压2(mmHg)"
                    name={['physicalInfo', 'bloodPressure2']}
                    valueType="custom"
                  >
                    <BloodPressure
                      dataKey={[
                        'systolicPressureThree',
                        'diastolicPressureThree',
                      ]}
                      onChange={e => {
                        if (
                          e?.diastolicPressureThree &&
                          e.systolicPressureThree
                        ) {
                          if (formRef.current) {
                            formRef.current.setFieldsValue({
                              physicalInfo: {
                                bloodPressure2Date: moment(new Date()).format(
                                  'YYYY-MM-DD hh:mm:ss',
                                ),
                              },
                            });
                          }
                        }
                      }}
                    />
                  </VSFormItem>
                  <VSFormItem
                    label="复测血压时间2"
                    name={['physicalInfo', 'bloodPressureTimeThree']}
                    // valueType="dateRange"
                    valueType="time"
                    fieldProps={{
                      showTime: true,
                      disabledDate: disableEdcDate,
                      format: ['YYYY-MM-DD HH:mm:ss'],
                    }}
                  ></VSFormItem>
                  {/* <VSFormItem
                    label="血压(mmHg)"
                    name={['physicalInfo', 'bloodPressure']}
                    valueType="custom"
                    fieldProps={{
                      placeholder: '请输入',
                      min: 30,
                      max: 200,
                    }}
                    rules={[
                      {
                        required: true,
                        message: '请填写血压(mmHg)',
                      },
                    ]}
                  >
                    <BloodPressure
                      dataKey={['diastolicPressure', 'systolicPressure']}
                    />
                  </VSFormItem>
                  <VSFormItem
                    label="复测血压1(mmHg)"
                    name={['physicalInfo', 'bloodPressure1']}
                    valueType="custom"
                  >
                    <BloodPressure
                      dataKey={['diastolicPressureTwo', 'systolicPressureTwo']}
                    />
                  </VSFormItem>
                  <VSFormItem
                    label="复测血压2(mmHg)"
                    name={['physicalInfo', 'bloodPressure2']}
                    valueType="custom"
                  >
                    <BloodPressure
                      dataKey={[
                        'diastolicPressureThree',
                        'systolicPressureThree',
                      ]}
                    />
                  </VSFormItem> */}
                  <VSFormItem
                    label="脉搏"
                    name={['physicalInfo', 'pulse']}
                    valueType="digit"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: '请填写脉搏',
                    //   },
                    // ]}
                    fieldProps={{
                      min: 0,
                      max: 500,
                      placeholder: '请输入',
                      controls: false,
                      addonAfter: '次/分',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
                <CollapseBlock
                  title="其他"
                  inline
                  style={{
                    marginBottom: 24,
                  }}
                  defaultExpand={true}
                >
                  <VsfFormLayout>
                    <VSFormItem
                      label="宫高"
                      name={['physicalInfo', 'fuh']}
                      valueType="digit"
                      fieldProps={{
                        precision: 2,
                        addonAfter: 'cm',
                        placeholder: '请输入',
                        controls: false,
                        min: 0,
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="腹围"
                      name={['physicalInfo', 'abdominalCircumference']}
                      valueType="digit"
                      fieldProps={{
                        min: 0,
                        precision: 2,
                        addonAfter: 'cm',
                        placeholder: '请输入',
                        controls: false,
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="胎动"
                      name={['physicalInfo', 'fetusMovement']}
                      valueType="select"
                      dataSource={enums['fetus_movement']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="胎位"
                      name={['physicalInfo', 'fetusPosition']}
                      valueType="select"
                      dataSource={enums['fetus_position']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="胎数"
                      name={['physicalInfo', 'fetusNum']}
                      valueType="select"
                      dataSource={enums['fetus_num']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VsfFormLayout columnCount={1}>
                    <VSFormDependency name={['physicalInfo', 'fetusNum']}>
                      {record => {
                        const { fetusNum } = record?.physicalInfo || {};
                        const arr = dropRight(
                          fetusNumArr,
                          fetusNumArr.length - (fetusNum ?? 1),
                        );

                        return arr.map(item => {
                          return (
                            <VsfFormLayout>
                              <VSFormItem
                                label={`胎心描述${item.index}`}
                                name={['physicalInfo', item.desc]}
                                valueType="digit"
                                fieldProps={{
                                  addonAfter: '次/分',
                                  placeholder: '请输入',
                                  controls: false,
                                  min: 0,
                                }}
                              ></VSFormItem>
                              <VSFormItem
                                label={`胎心未及${item.index}`}
                                name={['physicalInfo', item.unexpected]}
                                valueType="checkbox"
                                dataSource={[
                                  {
                                    label: '未及',
                                    value: true,
                                  },
                                ]}
                              ></VSFormItem>
                            </VsfFormLayout>
                          );
                        });
                      }}
                    </VSFormDependency>
                  </VsfFormLayout>

                  <VsfFormLayout>
                    <VSFormItem
                      label="宫缩"
                      name={['physicalInfo', 'uterineContraction']}
                      valueType="select"
                      dataSource={enums['uterine_contraction']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="宫口"
                      name={['physicalInfo', 'cervical']}
                      valueType="digit"
                      fieldProps={{
                        precision: 2,
                        addonAfter: 'cm',
                        placeholder: '请输入',
                        controls: false,
                        min: 0,
                      }}
                      // dataSource={enums['cervical']}
                    ></VSFormItem>
                    <VSFormItem
                      label="先露"
                      name={['physicalInfo', 'presentation']}
                      valueType="select"
                      dataSource={enums['presentation']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="衔接"
                      name={['physicalInfo', 'engagement']}
                      valueType="select"
                      dataSource={enums['engagement']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VsfFormLayout>
                    <VSFormItem
                      label="腹痛"
                      name={['physicalInfo', 'bellyache']}
                      valueType="select"
                      dataSource={abdominalPain}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VsfFormLayout columnCount={1}>
                    <VSFormDependency name={['physicalInfo', 'bellyache']}>
                      {record => {
                        const { bellyache } = record?.physicalInfo || {};
                        console.log(bellyache);
                        return (
                          bellyache === '规律' && (
                            <VsfFormLayout>
                              <VSFormItem
                                label={`腹痛持续时间（秒）`}
                                name={['physicalInfo', 'bellyacheDurationTime']}
                                valueType="text"
                                fieldProps={{
                                  addonAfter: '秒',
                                  placeholder: '请输入',
                                  controls: false,
                                  min: 0,
                                  onChange: e => {
                                    console.log(e, 'e');
                                    setBellyacheDurationTime(
                                      e?.target?.value || '',
                                    );
                                  },
                                }}
                                rules={[
                                  {
                                    required: true,
                                    message: '请填写腹痛持续时间',
                                  },
                                ]}
                              ></VSFormItem>
                              <VSFormItem
                                label={`腹痛间隔时间（分钟`}
                                name={['physicalInfo', 'bellyacheIntervalTime']}
                                valueType="text"
                                fieldProps={{
                                  addonAfter: '分钟',
                                  placeholder: '请输入',
                                  controls: false,
                                  min: 0,
                                  onChange: e => {
                                    console.log(e, 'e');
                                    setBellyacheIntervalTime(
                                      e?.target?.value || '',
                                    );
                                  },
                                }}
                                rules={[
                                  {
                                    required: true,
                                    message: '请填写腹痛间隔时间',
                                  },
                                ]}
                              ></VSFormItem>
                            </VsfFormLayout>
                          )
                        );
                      }}
                    </VSFormDependency>
                  </VsfFormLayout>
                  <VsfFormLayout>
                    <VSFormItem
                      label="阴道流血"
                      name={['physicalInfo', 'virginalBleed']}
                      valueType="select"
                      dataSource={enums['virginal_bleed']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="阴道流液"
                      name={['physicalInfo', 'virginalDischarge']}
                      valueType="select"
                      dataSource={enums['virginal_discharge']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VsfFormLayout>
                    <VSFormItem
                      label="高血压症状"
                      name={['physicalInfo', 'hypertensionSymptom']}
                      valueType="select"
                      dataSource={enums['hypertension_symptoms']}
                      fieldProps={{
                        placeholder: '请选择',
                        mode: 'multiple',
                        onChange: e => {
                          if (e.length > 1) {
                            formRef?.current?.setFieldsValue({
                              physicalInfo: {
                                hypertensionSymptom: e?.filter(v => {
                                  return v !== '无';
                                }),
                              },
                            });
                          }
                        },
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label="浮肿"
                      name={['physicalInfo', 'edema']}
                      valueType="select"
                      dataSource={enums['edema']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    {isFirst && (
                      <VSFormItem
                        label="甲状腺"
                        name={['physicalInfo', 'thyroid']}
                        valueType="select"
                        dataSource={enums['thyroid']}
                        fieldProps={{
                          placeholder: '请选择',
                        }}
                      ></VSFormItem>
                    )}
                    {isFirst && (
                      <VSFormItem
                        label="乳头"
                        name={['physicalInfo', 'nipple']}
                        valueType="select"
                        dataSource={enums['nipple']}
                        fieldProps={{
                          placeholder: '请选择',
                        }}
                      ></VSFormItem>
                    )}
                    {isFirst && (
                      <VSFormItem
                        label="心率"
                        name={['physicalInfo', 'heartRate']}
                        valueType="digit"
                        fieldProps={{
                          min: 30,
                          max: 300,
                          precision: 0,
                          addonAfter: '次/分',
                          placeholder: '请输入',
                          controls: false,
                        }}
                      ></VSFormItem>
                    )}
                    {isFirst && (
                      <VSFormItem
                        label="心律"
                        name={['physicalInfo', 'heartRhythm']}
                        valueType="select"
                        dataSource={enums['heart_rhythm']}
                        fieldProps={{
                          placeholder: '请选择',
                        }}
                      ></VSFormItem>
                    )}
                    {isFirst && (
                      <VSFormItem
                        label="肝脏"
                        name={['physicalInfo', 'liver']}
                        valueType="text"
                        fieldProps={{
                          placeholder: '请输入',
                        }}
                      ></VSFormItem>
                    )}
                    {isFirst && (
                      <VSFormItem
                        label="脾脏"
                        name={['physicalInfo', 'spleen']}
                        valueType="text"
                        fieldProps={{
                          placeholder: '请输入',
                        }}
                      ></VSFormItem>
                    )}
                    {isFirst && (
                      <VSFormItem
                        label="呼吸运动"
                        name={['physicalInfo', 'breath']}
                        valueType="text"
                        fieldProps={{
                          placeholder: '请输入',
                        }}
                      ></VSFormItem>
                    )}
                    {!isFirst ? (
                      <VSFormItem
                        label="蛋白尿"
                        name={['physicalInfo', 'proteinuria']}
                        valueType="select"
                        dataSource={enums['edema']}
                        fieldProps={{
                          placeholder: '请选择',
                        }}
                      ></VSFormItem>
                    ) : null}
                  </VsfFormLayout>

                  {isFirst && (
                    <>
                      <VsfFormLayoutDefine columnSpan={[1, 1]}>
                        {/* <VSFormItem
                    label="心脏杂音"
                    name={['physicalInfo', 'heartMurmur']}
                    valueType="custom"
                  >
                    <Radiostr dataKey={['heartMurmur', 'heartMurmurDetail']} />
                  </VSFormItem> */}
                        <VSFormItem
                          label="心脏杂音"
                          name={['physicalInfo', 'heartMurmur']}
                          valueType="radio"
                          dataSource={radioDataSource}
                          fieldProps={{
                            className: 'ant-radio-checkbox-border',
                          }}
                        ></VSFormItem>
                        <VSFormItem
                          label="心脏杂音详述"
                          name={['physicalInfo', 'heartMurmurDetail']}
                          valueType="text"
                          fieldProps={{
                            placeholder: '请输入',
                          }}
                        ></VSFormItem>
                      </VsfFormLayoutDefine>
                      <VsfFormLayoutDefine columnSpan={[1, 1]}>
                        <VSFormItem
                          label="心脏"
                          name={['physicalInfo', 'heart']}
                          valueType="radio"
                          dataSource={radioDataSource}
                          fieldProps={{
                            className: 'ant-radio-checkbox-border',
                          }}
                        ></VSFormItem>
                        <VSFormItem
                          label="心脏详述"
                          name={['physicalInfo', 'heartDetail']}
                          valueType="text"
                          fieldProps={{
                            placeholder: '请输入',
                          }}
                        ></VSFormItem>
                      </VsfFormLayoutDefine>
                      <VsfFormLayoutDefine columnSpan={[1, 1]}>
                        <VSFormItem
                          label="肺部"
                          name={['physicalInfo', 'lung']}
                          valueType="radio"
                          dataSource={radioDataSource}
                          fieldProps={{
                            className: 'ant-radio-checkbox-border',
                          }}
                        ></VSFormItem>
                        <VSFormItem
                          label="肺部详述"
                          name={['physicalInfo', 'lungDetail']}
                          valueType="text"
                          fieldProps={{
                            placeholder: '请输入',
                          }}
                        ></VSFormItem>
                      </VsfFormLayoutDefine>
                      <VsfFormLayoutDefine columnSpan={[1, 1]}>
                        <VSFormItem
                          label="其他异常"
                          name={['physicalInfo', 'otherAbnormal']}
                          valueType="radio"
                          dataSource={radioDataSource}
                          fieldProps={{
                            className: 'ant-radio-checkbox-border',
                          }}
                        ></VSFormItem>
                        <VSFormItem
                          label="其他异常详述"
                          name={['physicalInfo', 'otherAbnormalDetail']}
                          valueType="text"
                          fieldProps={{
                            placeholder: '请输入',
                          }}
                        ></VSFormItem>
                      </VsfFormLayoutDefine>
                      <VsfFormLayout columnCount={2}>
                        <VSFormItem
                          label="外阴"
                          name={['physicalInfo', 'vulva']}
                          valueType="select"
                          dataSource={enums['vulva']}
                          fieldProps={{
                            placeholder: '请选择',
                          }}
                        ></VSFormItem>
                        <VSFormItem
                          label="外阴详述"
                          name={['physicalInfo', 'vulvaDetail']}
                          valueType="text"
                          fieldProps={{
                            placeholder: '请输入',
                          }}
                        ></VSFormItem>

                        <VSFormItem
                          label="阴道"
                          name={['physicalInfo', 'vagina']}
                          valueType="select"
                          dataSource={enums['vulva']}
                          fieldProps={{
                            placeholder: '请选择',
                          }}
                        ></VSFormItem>
                        <VSFormItem
                          label="阴道详述"
                          name={['physicalInfo', 'vaginaDetail']}
                          valueType="text"
                          fieldProps={{
                            placeholder: '请输入',
                          }}
                        ></VSFormItem>

                        <VSFormItem
                          label="宫颈"
                          name={['physicalInfo', 'cervix']}
                          valueType="select"
                          dataSource={enums['vulva']}
                          fieldProps={{
                            placeholder: '请选择',
                          }}
                        ></VSFormItem>
                        <VSFormItem
                          label="宫颈详述"
                          name={['physicalInfo', 'cervixDetail']}
                          valueType="text"
                          fieldProps={{
                            placeholder: '请输入',
                          }}
                        ></VSFormItem>

                        <VSFormItem
                          label="子宫"
                          name={['physicalInfo', 'uterus']}
                          valueType="select"
                          dataSource={enums['vulva']}
                          fieldProps={{
                            placeholder: '请选择',
                          }}
                        ></VSFormItem>
                        <VSFormItem
                          label="子宫详述"
                          name={['physicalInfo', 'uterusDetail']}
                          valueType="text"
                          fieldProps={{
                            placeholder: '请输入',
                          }}
                        ></VSFormItem>

                        <VSFormItem
                          label="附件"
                          name={['physicalInfo', 'adnexa']}
                          valueType="select"
                          dataSource={enums['vulva']}
                          fieldProps={{
                            placeholder: '请选择',
                          }}
                        ></VSFormItem>
                        <VSFormItem
                          label="附件详述"
                          name={['physicalInfo', 'adnexaDetail']}
                          valueType="text"
                          fieldProps={{
                            placeholder: '请输入',
                          }}
                        ></VSFormItem>
                      </VsfFormLayout>
                    </>
                  )}
                </CollapseBlock>
              </FormBlock2>
            </CollapseBlock>
            <CollapseBlock
              title="辅助检查"
              id="anchor-assistant"
              // collapseAuto={fold}
              defaultExpand={fold}
            >
              <FormBlock2 title="辅助检查">
                <VsfFormLayout columnCount={1}>
                  <VSFormDependency name={['physicalInfo', 'fetusNum']}>
                    {record => {
                      const { fetusNum } = record?.physicalInfo || {};

                      return (
                        <VSFormItem
                          label="NT检查"
                          name={['assistantInfo', 'nt']}
                          valueType="custom"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: '请填写NT检查',
                          //   },
                          // ]}
                        >
                          <Nt
                            dataSource={enums['nasal_bone']}
                            nums={fetusNum}
                          />
                        </VSFormItem>
                      );
                    }}
                  </VSFormDependency>

                  <VSFormItem
                    label="早期唐筛"
                    name={['assistantInfo', 'earlyDownsScreening']}
                    valueType="custom"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: '请填写早期唐筛',
                    //   },
                    // ]}
                  >
                    <EarlyDownsScreening />
                  </VSFormItem>
                  <VSFormItem
                    label="中期唐筛"
                    name={['assistantInfo', 'middleDownsScreening']}
                    valueType="custom"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: '请填中期唐筛',
                    //   },
                    // ]}
                  >
                    <MiddleDownsScreening
                      dataSource={{
                        ntd1: enums['mid_ntd_risk_one'],
                        ntd2: enums['mid_ntd_risk_two'],
                        middle: enums['mid_result'],
                      }}
                    />
                  </VSFormItem>
                  <VSFormItem
                    label="NIPT检查"
                    name={['assistantInfo', 'nipt']}
                    valueType="custom"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: '请填写NIPT检查',
                    //   },
                    // ]}
                  >
                    <Nipt />
                  </VSFormItem>
                  <VSFormItem
                    label="胎儿染色体核型检查"
                    name={['assistantInfo', 'karyotypeExamination']}
                    valueType="custom"
                  >
                    <KaryotypeExamination
                      dataSource={{
                        value1: enums['fetal_karyotype_specimen'],
                        value2: enums['fetal_karyotype_result'],
                      }}
                    />
                  </VSFormItem>
                  <VSFormItem
                    label="胎儿染色体核型分析"
                    name={['assistantInfo', 'karyotypeAnalysis']}
                    valueType="custom"
                  >
                    <KaryotypeAnalysis
                      dataSource={{
                        value1: enums['fetal_karyotype_specimen'],
                        value2: enums['fetal_karyotype_result'],
                      }}
                    />
                  </VSFormItem>
                  <VSFormItem
                    label="备注"
                    name={['assistantInfo', 'comment']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VsfFormLayout>
                    <VSFormItem
                      labelCol={{
                        span: 9,
                      }}
                      label="OGTT"
                      name={['assistantInfo', 'OGTTTitle']}
                      valueType="custorm"
                      className="no-error"
                    ></VSFormItem>
                    <VSFormItem
                      labelCol={{
                        span: 9,
                      }}
                      label=""
                      name={['assistantInfo', 'OGTTTitle2']}
                      valueType="custorm"
                      className="no-error"
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VsfFormLayout className="e-medical-form-outline">
                    <VSFormItem
                      labelCol={{
                        span: 9,
                      }}
                      label="空腹"
                      name={['assistantInfo', 'ogttFasting']}
                      valueType="digit"
                      fieldProps={{
                        min: 0,
                        precision: 1,
                        addonAfter: 'mmol/L',
                        controls: false,
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      labelCol={{
                        span: 9,
                      }}
                      label="餐后一小时"
                      name={['assistantInfo', 'ogtt1h']}
                      valueType="digit"
                      fieldProps={{
                        min: 0,
                        precision: 1,
                        addonAfter: 'mmol/L',
                        controls: false,
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      labelCol={{
                        span: 9,
                      }}
                      label="餐后二小时"
                      name={['assistantInfo', 'ogtt2h']}
                      valueType="digit"
                      fieldProps={{
                        min: 0,
                        precision: 1,
                        addonAfter: 'mmol/L',
                        placeholder: '请输入',
                        controls: false,
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                </VsfFormLayout>
              </FormBlock2>
            </CollapseBlock>
            {isHistory && (
              <CollapseBlock
                title="高危检查"
                id="anchor-highrisk"
                // collapseAuto={fold}
                defaultExpand={fold}
              >
                <VsfFormLayout columnCount={1}>
                  <VsfFormLayout>
                    <VSFormItem
                      label="是否高危"
                      name={['medicalInfo', 'confirmHighrisk']}
                      valueType="select"
                      dataSource={[
                        {
                          label: '是',
                          value: true,
                        },
                        {
                          label: '否',
                          value: false,
                        },
                      ]}
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VSFormItem
                    label="高危因素"
                    name={['medicalInfo', 'highriskFactors']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="其他高危因素"
                    name={['medicalInfo', 'otherHighriskFactors']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VsfFormLayout columnCount={2}>
                    <VSFormItem
                      label="高危评估"
                      name={['medicalInfo', 'highriskLevel']}
                      valueType="custom"
                    >
                      <HighriskTag />
                    </VSFormItem>
                    <VSFormItem
                      label="传染病"
                      name={['medicalInfo', 'hasInfectious']}
                      valueType="custom"
                      fieldProps={{
                        disabled: true,
                      }}
                    >
                      <HighriskTag />
                    </VSFormItem>
                  </VsfFormLayout>
                </VsfFormLayout>
              </CollapseBlock>
            )}
            <CollapseBlock
              title="其他检查"
              id="anchor-other"
              // collapseAuto={fold}
              defaultExpand={fold}
            >
              <DrawMedical
                key={hisRecordId}
                data={data?.id ? data : medicalInfo}
              />
              <VSFormItem
                label=""
                name={['medicalInfo', 'otherAdvice']}
                valueType="custom"
              >
                <Otheradvice />
              </VSFormItem>
            </CollapseBlock>
          </VSForm>
        </div>
      </div>
    )
  );
};
export default definePage(Index);

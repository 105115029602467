import React, { useEffect, useState } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { Tag, Image, DatePicker, Spin , Tooltip } from '@vs/vsf-kit';
import HighriskBar from '@/components/highrisk/bar';
import { RightOutlined } from '@vs/vsf-icons';
import { getGestationalWeek } from '@/utils';
import { highriskLevel, colorBarMap, highriskVisibleType, globalPageType } from '@/config';
import GestationalWeek from '@/components/gestational-week';
import gravidaService from '@/services/gravida';
import moment from 'moment';
import { disableEdcDate, disableLmpDate, formatDate } from '@/utils/rule';
import { forbidEdit } from '@/pages/nurse/utils';

import bookPng from '@/static/book.png';
import childPng from '@/static/left-baby.png';
import footerPng from '@/static/left-footer.png';
import recordPng from '@/static/record.png';
import bloodBg from '@/static/bloodPressure.png';
import pulseBg from '@/static/pulse.png';
import weightBg from '@/static/weight.png';

import './index.less';

const physicalMap = [
  {
    label: '血压',
    dataIndex: 'systolicPressure',
    icon: bloodBg,
    suffix: 'mmgh',
    render: data => {
      return data?.systolicPressure && data?.diastolicPressure
        ? `${data?.systolicPressure}/${data?.diastolicPressure}mmgh`
        : '--';
    },
  },
  {
    label: '脉搏',
    dataIndex: 'pulse',
    icon: pulseBg,
    suffix: '/分',
  },
  {
    label: '体重',
    dataIndex: 'weight',
    icon: weightBg,
    suffix: 'KG',
  },
];

const Index = props => {
  const { gravida, common, medical } = vsf?.stores;

  const {
    gravidaInfo,
    gravidaRecord,
    gravidaPhysicalList,
    gravidaHighrisk,
    gravidaAllergyHistory,
  } = gravida;

  const { gravidaRecordId, showLeft, isFirstMedical } = common;
  const { medicalInfo } = medical;

  const currentPhysical = gravidaPhysicalList[0];
  const pregnancyInfo = gravidaRecord?.pregnancyInfo;
  const highriskCache = gravidaHighrisk?.highriskCache || {};
  const { highest, latest } = highriskCache;
  const highestData = {
    date: highest?.recordTime,
    value: highest?.hasInfectious
      ? [highest?.level, colorBarMap.PURPLE]
      : [highest?.level ?? colorBarMap.GREEN],
  };
  const latestData = {
    date: latest?.recordTime,
    value: latest?.hasInfectious
      ? [latest?.level, colorBarMap.PURPLE]
      : [latest?.level ?? colorBarMap.GREEN],
  };

  const renderHighriskTag = data => {
    if (!data) { 
      return null;
    }
    const level = data?.level ?? 'GREEN';
    let infectiousNode: any = null;
    let node = highriskLevel[level] ? (
      <Tag color={colorBarMap[level]}>{highriskLevel[level]?.label}</Tag>
    ) : null;
    if (data?.hasInfectious) {
      infectiousNode = (
        <Tag color={colorBarMap.PURPLE}>{highriskLevel['PURPLE']?.label}</Tag>
      );
    }
    return (
      <>
        {node}
        {infectiousNode}
      </>
    );
  };

  const renderAllergyTag = data => {
    let str: string = '';
    data.forEach((item, index) => {
      str += `${item?.allergen}${index === data?.length - 1 ? '' : '、'}`;
    });
    return str ? showTooltip(str) : null;
  };
  const showTooltip = (str)=>{
    if(str.length > 5) {
      return <Tag><Tooltip overlayInnerStyle={{color:'#000'}} color='#fff' title={str}>{str.length>5?str.substring(0,5)+'...':str}</Tooltip></Tag>
    }else {
      return <Tag>{str}</Tag>
    }
  }

  const handleEditLmp = e => {
    const value = e?.target?.value;
    const edc = formatDate(moment(value).add(40, 'w'));
    gravidaService
      .updateLmpAndGestationalWeek({
        btoParam: {
          id: gravidaRecordId,
          pregnancyInfoBto: {
            id: pregnancyInfo?.id,
            lmp: formatDate(value),
            gestationalWeek: getGestationalWeek({
              lmp: value,
            }),
            edc,
            // gestationalConfirmTime: formatDate(new Date()),
          },
        },
        extApiParam: {},
      })
      .then(res => {
        gravida?.getRecord(gravida?.gravidaInfo?.id);
      });
  };

  const handleEditUpdateEdc = e => {
    const value = e?.target?.value;
    gravidaService
      .updateUpdateEdc({
        btoParam: {
          id: gravidaRecordId,
          pregnancyInfoBto: {
            id: pregnancyInfo?.id,
            updateEdc: formatDate(value),
          },
        },
      })
      .then(res => {
        gravida?.getRecord(gravida?.gravidaInfo?.id);
      });
  };

  const handleEditWeek = v => {
    gravidaService
      .updateUpdateGestationalWeek({
        btoParam: {
          id: gravidaRecordId,
          pregnancyInfoBto: {
            id: pregnancyInfo?.id,
            updateGestationalWeek: v,
            // updateGestationalConfirmTime: formatDate(new Date()),
          },
        },
        extApiParam: {},
      })
      .then(res => {
        gravida?.getRecord(gravida?.gravidaInfo?.id);
      });
  };

  const pregnancyMap = [
    {
      label: '末次月经',
      dataIndex: 'lmp',
      edit: true,
      render: v => (v?.lmp && v?.lmp?.split ? v?.lmp?.split(' ')?.[0] : '-'),
      renderEdit: value => {
        return (
          <DatePicker
            key={value}
            defaultValue={value}
            disabled={forbidEdit(gravida?.gravidaRecord, common)}
            onBlur={handleEditLmp}
            disabledDate={disableLmpDate}
          />
        );
      },
    },
    {
      label: '足早流存',
      dataIndex: '足早流存',
      render: v => {
        return v
          ? `${v?.mature || '0'}-${v?.premature || '0'}-${v?.abortion ||
              '0'}-${v?.survival || '0'}`
          : '-';
      },
    },

    {
      label: '预产期',
      dataIndex: 'edc',
      render: v => (v?.edc && v?.edc?.split ? v?.edc?.split(' ')?.[0] : '-'),
    },
    {
      label: '孕周',
      dataIndex: 'gestationalWeek',
      render: v => {
        return getGestationalWeek({
          lmp: v?.lmp,
          show: 0,
        });
      },
    },
    {
      label: '修正预产期',
      dataIndex: 'updateEdc',
      edit: true,
      render: v =>
        v?.updateEdc && v?.updateEdc?.split
          ? v?.updateEdc?.split(' ')?.[0]
          : '-',
      renderEdit: value => {
        return (
          <DatePicker
            key={value}
            defaultValue={value}
            disabled={forbidEdit(gravida?.gravidaRecord, common)}
            onBlur={handleEditUpdateEdc}
            disabledDate={disableEdcDate}
          />
        );
      },
    },
    {
      label: '修正孕周',
      edit: true,
      dataIndex: 'updateGestationalWeek',
      renderEdit: value => {
        return (
          <GestationalWeek
            layout="simple"
            value={value}
            disabled={forbidEdit(gravida?.gravidaRecord, common)}
            onBlur={handleEditWeek}
          />
        );
      },
    },
  ];

  const handleRenderRecordTag = (boolean?: boolean) => {
    let flag;
    if (common?.globalPageType === globalPageType.NURSE) {
      flag = !!gravidaRecordId;
    } else if (common?.globalPageType === globalPageType.DOCTOR) {
      flag = !!gravidaRecordId && gravidaRecord?.auditStatus === 'DOCTOR_AUDIT';
    }
    if (boolean) {
      return flag;
    } else {
      return flag ? '已建档' : '未建档';
    }
  }

  return (
    <div className="e-medical-left-block">
      <div className="content">
        {showLeft ? (
          <>
            <div className="left-gravida-info">
              <div
                className="title"
                onClick={() => {
                  vsf.setStoreValue?.('gravida.createRecordVisible', true);
                }}
              >
                <div className="bookbg">
                  <Image src={bookPng} preview={false} />
                </div>
                <div className="word">
                  孕产档案
                  <RightOutlined />
                </div>
              </div>
              <div className="info">
                <div className="name">
                  <span className="word">
                    <span>{gravidaInfo?.name || '-'}</span>
                  </span>
                </div>
                <div className="first">
                  <Image className="recordbg" src={recordPng} preview={false} />
                  <span>{gravidaInfo?.hisId || '-'}</span>
                </div>
                <div className="second">
                  {isFirstMedical && <Tag color="magenta">初诊</Tag>}
                  {gravidaRecord?.recordPersonalHistory?.rhNegative && (
                    <Tag color="magenta">RH阴性</Tag>
                  )}
                  <Tag color={handleRenderRecordTag(true) ? 'green' : ''}>
                    {handleRenderRecordTag()}
                  </Tag>
                </div>
              </div>
            </div>
            <div className="main">
              <div className="main-block">
                <div
                  className="tag-block pregnancy"
                  onClick={() => {
                    vsf.setStoreValue?.('gravida.pregnancyVisible', true);
                  }}
                >
                  <div className="tag-label">
                    <span className="title">妊娠指标</span>
                  </div>
                  <RightOutlined />
                </div>
                <div
                  className="tag-block allergy"
                  onClick={() => {
                    vsf.setStoreValue?.('gravida.allergyVisible', true);
                  }}
                >
                  <div className="tag-label">
                    <span className="title">过敏信息</span>
                    <span className="tag-content">
                      {renderAllergyTag(gravidaAllergyHistory)}
                    </span>
                  </div>
                  <RightOutlined />
                </div>
                <div
                  className="tag-block highrisk"
                  onClick={() => {
                    vsf.setStoreValue?.(
                      'gravida.highriskVisible',
                      highriskVisibleType.ENABLE,
                    );
                  }}
                >
                  <div className="tag-label">
                    <span className="title">高危评估</span>
                    <span className="tag-content">
                      {gravidaRecordId ? renderHighriskTag(latest) : null}
                    </span>
                  </div>
                  <RightOutlined />
                </div>
                <div
                  className="tag-block highrisk"
                  onClick={() => {
                    vsf.setStoreValue?.(
                      'gravida.highriskVisible',
                      highriskVisibleType.LATEST,
                    );
                  }}
                >
                  <div className="tag-label">
                    <span className="title">高危因素</span>
                    <span
                      className="text-content"
                      title={(latest?.highriskRecordItemDtoList || [latest ? { name: '无' } : {}])
                        .map(item => item.name)
                        .join()}
                    >
                      {(latest?.highriskRecordItemDtoList || [latest ? { name: '无' } : {}])
                        .map(item => item.name)
                        .join()}
                    </span>
                  </div>
                  {/* <RightOutlined /> */}
                </div>
                <div>
                  <HighriskBar max={highestData} lately={latestData} />
                </div>
                <div className="physical-info">
                  {physicalMap.map(item => (
                    <div key={item.dataIndex} className="physical-info-block">
                      <Image src={item.icon} preview={false} />
                      <div className="value">
                        {item?.render
                          ? item?.render(currentPhysical)
                          : (currentPhysical?.[item.dataIndex]
                          ? `${currentPhysical?.[item.dataIndex]}${item.suffix}`
                          : '--')}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="gravida-info">
                  {pregnancyMap.map((item: any) => (
                    <div
                      key={item.dataIndex}
                      className={`gravida-info-block ${
                        item?.edit ? 'gravida-info-block-edit' : ''
                      }`}
                    >
                      <div className="label">{item.label}</div>

                      {item?.renderEdit ? (
                        <div className="edit">
                          {item?.renderEdit(pregnancyInfo?.[item.dataIndex])}
                        </div>
                      ) : (
                        <div className="value">
                          {item?.render
                            ? item?.render(pregnancyInfo)
                            : pregnancyInfo?.[item?.dataIndex] || '-'}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="footer-block">
                <div className="content">
                  <Image className="child" src={childPng} preview={false} />
                  <Image className="footer" src={footerPng} preview={false} />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="e-medical-spin">
            <Spin tip="数据加载中" />
          </div>
        )}
      </div>
    </div>
  );
};

export default definePage(Index);

import React, { useEffect, useState } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { Modal, Image, Tabs, TabPane } from '@vs/vsf-kit';
import importPng from '@/static/import.png';
import staticCommonService from '@/module/common/services';
import { formatDate } from '@/utils/rule';
import { DeleteOutlined } from '@ant-design/icons';
import './index.less';

/**
 * 现病史弹框
 **/

const { confirm } = Modal;

const Index = props => {
  const { onCancel, params } = props;
  const { gravida, common } = vsf?.stores;
  const [data, setData] = useState([]);
  const [activeKey, setActiveKey] = useState('0');
  const [personData, setPersonData] = useState([]);

  const handleCancel = () => {
    onCancel && onCancel();
  };
  const handleGetHpiList = () => {
    staticCommonService
      .getHpiList({
        doctorId: params?.doctorId,
      })
      .then(res => {
        if (res?.code === 200) {
          setPersonData(res?.data);
        }
      });
  };
  const handleDeleteHpi = id => {
    staticCommonService
      .deleteHpi({
        btoParam: {
          id,
        },
      })
      .then(res => {
        if (res?.code === 200) {
          handleGetHpiList();
        }
      });
  };

  const handleRender = (list, dom) => {
    if (!list || list?.length === 0) {
      return <div className="empty-hpi">暂无数据</div>;
    } else {
      return dom;
    }
  };

  useEffect(() => {
    if (activeKey === '1') {
      handleGetHpiList();
    }
  }, [activeKey]);
  useEffect(() => {
    props.visible &&
      staticCommonService
        .getHistoryHpiList({ hisId: gravida?.gravidaInfo?.hisId })
        .then(res => {
          if (res.code === 200) {
            setData(
              res.data?.map(v => {
                return {
                  date: formatDate(v?.time, 'YYYY-MM-DD'),
                  department: v?.departmentName,
                  doctor: v?.doctorName,
                  content: v?.hpi,
                };
              }),
            );
          }
        });
  }, []);

  const handleOk = () => {};
  const handleCancel2 = () => {};
  return (
    <Modal
      width={520}
      className="e-medical-hpi-modal"
      title="现病史"
      keyboard={true}
      destroyOnClose={true}
      visible={props.visible}
      id="hpi-modal"
      onCancel={handleCancel}
      maskClosable={false}
      footer={false}
    >
      <div className="container">
        <div
          style={{
            marginTop: -16,
          }}
        >
          <Tabs
            type="line"
            centered={false}
            activeKey={activeKey}
            onTabClick={async akey => {
              setActiveKey(akey);
            }}
          >
            <TabPane key="0" tab="历史">
              {handleRender(
                data,
                <>
                  {data &&
                    data.map((item, index) => (
                      <div key={index} className="hpi-content-block">
                        <div className="date">{item.date}</div>
                        <div className="content">
                          <div className="header">
                            <div className="left">
                              <span>{item.department}</span>
                              <span>/</span>
                              <span>{item.doctor}</span>
                            </div>
                            <div
                              className="right"
                              onClick={() => {
                                onCancel && onCancel(item.content);
                              }}
                            >
                              <Image
                                className="import-image"
                                src={importPng}
                                preview={false}
                              />
                            </div>
                          </div>
                          <div className="text">{item.content}</div>
                        </div>
                      </div>
                    ))}
                </>,
              )}
            </TabPane>
            <TabPane key="1" tab="常用">
              {handleRender(
                personData,
                <>
                  {personData &&
                    personData.map((item, index) => (
                      <div key={index} className="hpi-content-block">
                        <div className="date">
                          {formatDate(item.createdAt, 'YYYY-MM-DD')}
                        </div>
                        <div className="content">
                          <div className="text">{item.otherHpi}</div>
                          <div className="footer">
                            <div
                              className="delete-hpi"
                              onClick={() => {
                                Modal.confirm({
                                  title: '操作提醒',
                                  content: '确定删除该条现病史吗？',
                                  onOk() {
                                    handleDeleteHpi(item?.id);
                                  },
                                  onCancel() {
                                    console.log('Cancel');
                                  },
                                });
                              }}
                            >
                              <DeleteOutlined
                                style={{
                                  fontSize: 20,
                                }}
                              />
                            </div>
                            <div
                              className="right"
                              onClick={() => {
                                onCancel && onCancel(item.otherHpi);
                              }}
                            >
                              <Image
                                className="import-image"
                                src={importPng}
                                preview={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>,
              )}
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Modal>
  );
};

export default definePage(Index);

import React, { useEffect, useRef } from 'react';
import {
  VSForm,
  VSFormFooter,
  VSFormItem,
  VSFormFooterSubmit,
  VSFormDependency,
  Button,
  Image,
} from '@vs/vsf-kit';
import VsfFormLayout from '@/components/form-layout';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import vsf, { definePage } from '@vs/vsf-boot';
import { message } from '@/utils';
import Icon from '@/components/icon';

import Login1 from '@/static/login1.png';
import Login2 from '@/static/login2.png';
import Logo from '@/static/logo.png';
import MedicalTitle from '@/static/medicalTitle.png';
import commonServices from '@/module/login/services';

import './index.less';

const Index = () => {
  const { nurse, common } = vsf?.stores;
  const formRef = useRef();
  const handleLogin = async () => {
    const values = await formRef?.current?.validateFields();

    if (values?.userName && values?.password) {
      const data = await nurse.login({
        ...values,
        userType: 'STAFF',
      });
      if (data) {
        common.doctorId = data?.id;
        if (window.location.pathname.includes('syncLogin')) {
          vsf?.navigateTo(`/sync`);
        } else {
          vsf?.navigateTo(`/patient`);
        }
      }
    }
  };

  // useEffect(() => {
  //   if (nurse?.user?.id) {
  //     vsf?.navigateTo(`/patient`);
  //   }
  // }, [nurse?.user]);

  return (
    <div className="e-medical-nurse-login">
      <Image src={Login1} preview={false} className="login-img-1" />
      <div className="login">
        <Image src={Login2} preview={false} className="login-img-2" />
        <div className="block">
          <div className="header">
            <Image src={Logo} preview={false} className="logo" />
            <Image src={MedicalTitle} preview={false} className="login-img-3" />
          </div>
          <VSForm
            dataType="static"
            layout="horizontal"
            formRef={formRef}
            initialValues={{}}
          >
            <VsfFormLayout columnCount={1}>
              <VSFormItem
                label=""
                name={['userName']}
                valueType="text"
                style={{
                  width: 400,
                  height: 44,
                  borderRadius: 8,
                  backgroundColor: '#fff',
                }}
                fieldProps={{
                  placeholder: '请输入工号',
                  prefix: (
                    <Icon
                      style={{
                        fontSize: 20,
                        marginRight: 6,
                      }}
                      type="icon-denglu"
                    />
                  ),
                  bordered: false,
                }}
              ></VSFormItem>
            </VsfFormLayout>
            <VsfFormLayout columnCount={1}>
              <VSFormItem
                label=""
                name={['password']}
                valueType="password"
                style={{
                  width: 400,
                  height: 44,
                  borderRadius: 8,
                  backgroundColor: '#fff',
                }}
                fieldProps={{
                  placeholder: '请输入密码',
                  prefix: (
                    <Icon
                      style={{
                        fontSize: 20,
                        marginRight: 6,
                      }}
                      type="icon-mima"
                    />
                  ),
                  bordered: false,
                }}
              ></VSFormItem>
            </VsfFormLayout>
            <VSFormDependency name={['userName', 'password']}>
              {record => {
                return (
                  <Button
                    type="primary"
                    style={{
                      width: '100%',
                      borderRadius: 8,
                      height: 44,
                    }}
                    onClick={handleLogin}
                    disabled={!record?.userName || !record?.password}
                  >
                    登 录
                  </Button>
                );
              }}
            </VSFormDependency>
          </VSForm>
        </div>
      </div>
    </div>
  );
};

export default definePage(Index);

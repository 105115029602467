import {
  Popover,
  VSTable,
  VSTableColumn,
  VSTableAction,
  Badge,
} from '@vs/vsf-kit';
import vsf, { definePage } from '@vs/vsf-boot';
import React, { useCallback, useEffect, useState } from 'react';
import { getGestationalWeek, dateAdd } from '@/utils';
import Modal from './modal';
import moment from 'moment';
import Icon from '@/components/icon';

import './index.less';

const generateItem: any = (lmp) => { 
  return [
    {
      label: '孕<13周',
      key: 1,
      min: 0,
      max: 15,
      overtime: 4,
      dataSource: [
        {
          planTime: dateAdd(lmp, 12 * 7),
          range: [12, 16],
        },
        {
          planTime: dateAdd(lmp, 8 * 7),
          range: [0, 12],
        },
      ],
    },
    {
      label: '孕14-28周',
      key: 2,
      min: 16,
      max: 29,
      overtime: 4,
      dataSource: [
        {
          planTime: dateAdd(lmp, 28 * 7),
          range: [28, 30],
        },
        {
          planTime: dateAdd(lmp, 24 * 7),
          range: [24, 28],
        },
        {
          planTime: dateAdd(lmp, 20 * 7),
          range: [20, 24],
        },
        {
          planTime: dateAdd(lmp, 16 * 7),
          range: [16, 20],
        },
      ],
    },
    {
      label: '孕29-36周',
      key: 3,
      min: 30,
      max: 36,
      overtime: 2,
      dataSource: [
        {
          planTime: dateAdd(lmp, 36 * 7),
          range: [36, 37],
        },
        {
          planTime: dateAdd(lmp, 34 * 7),
          range: [34, 36],
        },
        {
          planTime: dateAdd(lmp, 32 * 7),
          range: [32, 34],
        },
        {
          planTime: dateAdd(lmp, 30 * 7),
          range: [30, 32],
        },
      ],
    },
    {
      label: '孕37-41周',
      key: 4,
      min: 37,
      max: 41,
      overtime: 1,
      dataSource: [
        {
          planTime: dateAdd(lmp, 40 * 7),
          range: [40, 41],
        },
        {
          planTime: dateAdd(lmp, 39 * 7),
          range: [39, 40],
        },
        {
          planTime: dateAdd(lmp, 38 * 7),
          range: [38, 39],
        },
        {
          planTime: dateAdd(lmp, 37 * 7),
          range: [37, 38],
        },
      ],
    },
  ];
};

const App = (props) => {
  const {
    gravida,
    medical
  } = vsf?.stores;
    const { gravidaRecord } = gravida;
  const pregnancyInfo = gravidaRecord?.pregnancyInfo;
  const { medicalHistoryList } = medical;

  // const [current, setCurrent] = useState<number>();
  const [list, setList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState({});
  const [planList, setPlanList] = useState<any>([]);

  const renderContent = useCallback((data) => {
    return (
      <VSTable dataType="static" dataSource={data?.dataSource} className="e-medical-record-footer-table">
        <VSTableColumn
          title="计划时间"
          valueType="text"
          dataIndex="planTime"
        ></VSTableColumn>
        <VSTableColumn
          title="就诊时间"
          valueType="text"
          dataIndex="createdAt"
          render={(_, record) => {
            if (!record?.data || record?.data?.length === 0) return '-';
              return (record?.data || []).map(item => {
                const target = item || {};
                const overtime = moment(target?.createdAt).diff(
                  record?.planTime,
                  'day',
                );
                return (
                  <div
                    className={
                      target?.createdAt && overtime > data?.overtime
                        ? 'overtime cell-block'
                        : 'cell-block'
                    }
                  >
                    {target?.createdAt
                      ? moment(target?.createdAt).format('YYYY-MM-DD')
                      : '-'}
                  </div>
                );
              });
          }}
        ></VSTableColumn>
        <VSTableColumn
          title="接诊医生"
          valueType="text"
          dataIndex="submitter"
          render={(_, record) => {
            if (!record?.data || record?.data?.length === 0) return '-';
            return (record?.data || []).map(item => {
              const target = item || {};
              return (
                <div className="cell-block">{target?.submitter || '-'}</div>
              );
            });
          }}
        ></VSTableColumn>
        <VSTableColumn
          title="操作"
          valueType="option"
          dataIndex="operation"
          render={(_, record) => {
            if (!record?.data || record?.data?.length === 0) return '-';
            return (record?.data || []).map(item => {
              const target = item || {};
              return (
                <div
                  className="e-medical-record-footer-btn cell-block"
                  onClick={() => {
                    try {
                      setData(target);
                      setVisible(true);
                      // action?.reload();
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                >
                  <Icon type="icon-chakanbingli"/>
                </div>
              );
            });
          }}
        >
          {/* <VSTableAction
            onClick={(_, data, __, action) => {
              try {
                const target = data?.data?.[0] || {};
                setData(target);
                setVisible(true);
                action?.reload();
              } catch (err) {
                console.log(err);
              }
            }}
          >
            查看
          </VSTableAction> */}
        </VSTableColumn>
      </VSTable>
    );
  }, []);

  const formatMedicalHistoryList = (list, medicalHistoryList) => { 
    const historyList = medicalHistoryList?.filter(item => item?.auditStatus === 'DOCTOR_AUDIT')?.map(item => {
      return {
        ...item,
        gestationalWeek: getGestationalWeek({
          lmp: pregnancyInfo?.lmp,
          nowTimeValue: item.createdAt,
        }),
      };
    });
    const res = list?.map(item => ({
      ...item,
      dataSource: item?.dataSource?.map(dItem => {
        const data = historyList.filter(
          (litem: any) => litem.gestationalWeek / 7 >= dItem?.range?.[0] && litem.gestationalWeek / 7 < dItem?.range?.[1]);
        return {
          ...dItem,
          data: data
        }
      })
    }));

    return res;
  }

  const handleGetAbnormalData = item => {
    let sum = 0;
    const { overtime, dataSource = [] } = item || {};
    dataSource.forEach(item => {
      const { data = [], planTime } = item;
      data.forEach(v => {
        const diffTime = moment(v?.createdAt).diff(
                  planTime,
                  'day',
                );
        if (diffTime > overtime) {
          sum += 1;
        }
      })
    });
    return sum;
  };

  useEffect(() => {
    if (medicalHistoryList && pregnancyInfo?.lmp) { 
      const list = generateItem(pregnancyInfo?.lmp);
      setPlanList(formatMedicalHistoryList(list, medicalHistoryList));
    }
      
  }, [medicalHistoryList, pregnancyInfo]);

  return (
    <div className="vsf-layout-footer-main">
      {planList.map((item, index) => {
        return (
          <Popover
            overlayClassName="e-medical-footer-popover"
            placement="top"
            title={item?.label}
            content={renderContent(item)}
            trigger="hover"
          >
            {/* <Badge count={5}>
              <Avatar shape="square" size="large" />
            </Badge> */}
            <Badge
              count={handleGetAbnormalData(item)}
            >
              <div key={item?.key} className="item active">
                <span>{item.label}</span>
              </div>
            </Badge>
          </Popover>
        );
      })}
      <Modal visible={visible} onCancel={() => { setVisible(false); }} data={data} />
    </div>
  );
};

export default definePage(App);

import React from 'react';
import { createFromIconfontCN } from '@ant-design/icons';
import { iconUrl } from '@/config';
import './index.less';

const IconFont = createFromIconfontCN({
  scriptUrl: iconUrl,
});

type IconType = {
    type: string;
    style?: any;
}

const Index = (props: IconType) => {
  const { type, style } = props;
  return <IconFont className="icon" type={type} style={style} />;
};

export default Index;

import Root from '@/layouts';
import Index from '@/pages/index';
import NurseLogin from '@/pages/nurse/login';
import Patient from '@/pages/nurse/patient';
import Record from '@/pages/record/index';
import Info from '@/pages/record/pages/archives/info';
import Examination from '@/pages/record/pages/archives/examination';
// import MedicalRecord from '@/pages/record/pages/archives/components/medical';
import Pregnancy from '@/pages/record/pages/archives/components/pregnancy';
import City from '@/pages/record/pages/screening';
import Return from '@/pages/record/pages/antenatal/return';
import MedicalIndependent from '@/pages/medical/independent';
import AntenatalPrint from '@/pages/antenatalPrint';
import WomanReport from '@/pages/womanReport';
const routes = [
  { path: '/medical', component: MedicalIndependent, exact: true },
  { path: '/nurseLogin', component: NurseLogin, exact: true },
  { path: '/syncLogin', component: NurseLogin, exact: true },
  {
    path: '/',
    component: Root,
    routes: [
      {
        path: '/',
        exact: true,
        component: Index,
      },
      {
        path: '/nurse',
        exact: true,
        component: Index,
      },
      {
        path: '/antenatalPrint',
        exact: true,
        component: AntenatalPrint,
      },
      {
        path: '/patient',
        exact: true,
        component: Patient,
      },
      {
        path: '/sync',
        exact: true,
        component: WomanReport,
      },
      {
        path: '/record',
        component: Record,
        routes: [
          {
            path: '/record/archives/info',
            exact: true,
            component: Info,
          },
          // {
          //   path: '/record/archives/medical',
          //   exact: true,
          //   component: MedicalRecord,
          // },
          {
            path: '/record/archives/examination',
            exact: true,
            component: Examination,
          },
          {
            path: '/record/archives/pregnancy',
            exact: true,
            component: Pregnancy,
          },
          // {
          //   path: '/record/antenatal/first',
          //   exact: true,
          //   component: First,
          // },
          {
            path: '/record/antenatal/return',
            exact: true,
            component: Return,
          },
          {
            path: '/record/screening/city',
            exact: true,
            component: City,
          },
        ],
      },
      // { path: '/demo', component: Demo, exact: true },
    ],
  },
];

export default routes;

import React, { useEffect, useState } from 'react';
import { RadioGroup, Input, InputNumber, Select } from '@vs/vsf-kit';
import '../index.less';

interface RadioStrType {
  value: any;
  onChange: (value: any) => void;
}

interface ValueType {
  trisomy21?: string; // 21三体
  trisomy18?: string; // 18三体
  trisomy13?: string; // 13三体
  ntd?: string; // NTD
}

const Trisomy = (props: RadioStrType) => {
  const { value, onChange } = props;
  const [init, setInit] = useState(false);
  const [trisomy, setTrisomyValue] = useState<ValueType>({
    trisomy21: '',
    trisomy18: '',
    trisomy13: '',
    ntd: '',
  });

  useEffect(() => {
    if (!init && value) {
      setTrisomyValue(value);
    }
  }, [value]);

  useEffect(() => {
    onChange(trisomy);
  }, [trisomy]);

  const handleValueChange = (v, target) => {
    setInit(true);
    setTrisomyValue({
      ...trisomy,
      [target]: v,
    });
  };

  return (
    <div className="e-record-screening e-record-screening-trisomy">
      <div className="outline">
        <div className="formitem-block">
          <div className="formitem-block-middle">
            <div className="label">21-三体：</div>
            <div className="formitem-nowrap">
              <Input className="molecule" value={1} disabled />
              <span className="slash">/</span>
              <InputNumber
                value={trisomy?.trisomy21}
                onChange={e => {
                  handleValueChange(e, 'trisomy21');
                }}
                placeholder="请输入"
              />
            </div>
          </div>
          <div className="formitem-block-middle">
            <div className="label">18-三体：</div>
            <div className="formitem-nowrap">
              <Input className="molecule" value={1} disabled />
              <span className="slash">/</span>
              <InputNumber
                value={trisomy?.trisomy18}
                onChange={e => {
                  handleValueChange(e, 'trisomy18');
                }}
                placeholder="请输入"
              />
            </div>
          </div>
          <div className="formitem-block-middle formitem-full">
            <div className="label">13-三体：</div>
            <div className="formitem-nowrap">
              <Input className="molecule" value={1} disabled />
              <span className="slash">/</span>
              <InputNumber
                value={trisomy?.trisomy13}
                onChange={e => {
                  handleValueChange(e, 'trisomy13');
                }}
                placeholder="请输入"
              />
            </div>
          </div>
          <div className="formitem-block-middle">
            <div className="label">NTD：</div>
            <div className="formitem-nowrap">
              <InputNumber
                value={trisomy?.ntd}
                onChange={e => {
                  handleValueChange(e, 'ntd');
                }}
                placeholder="请输入"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trisomy;
export const STORAGE_KEY_PREFIX = 'e_medical_record-web';

export const iconUrl = '//at.alicdn.com/t/c/font_3668311_zoz14s2m5nh.js';

export const STORAGE_KEY_MAP = {
  globalPageType: 'e-medical-globalPageType',
  hisId: 'e-medical-hisId',
  hisRecordId: 'e-medical-hisRecordId',
  doctorId: 'e-medical-doctorId',
  history: 'e-medical-history',
};

export const ROOT_ROUTER = ['nurse', 'index'];

export const radioDataSource = [
  {
    label: '无',
    value: '无',
  },
  {
    label: '有',
    value: '有',
  },
  {
    label: '不详',
    value: '不详',
  },
];

export const radioDataSource1 = [
  {
    label: '无',
    value: false,
  },
  {
    label: '有',
    value: true,
  },
];

export const radioDataSource4 = [
  {
    label: '否',
    value: false,
  },
  {
    label: '是',
    value: true,
  },
];

export const radioDataSourCeoverallAssessment = [
  {
    label: '异常',
    value: true,
  },
  {
    label: '无发现异常',
    value: false,
  },
];

export const radioDataSource2 = [
  {
    label: '轻度',
    value: '轻度',
  },
  {
    label: '中度',
    value: '中度',
  },
  {
    label: '重度',
    value: '重度',
  },
];

export const radioDataSource3 = [
  {
    label: '有异常',
    value: true,
  },
  {
    label: '无异常',
    value: false,
  },
];

export const fetusNumArr = [
  {
    index: 1,
    desc: 'fetusHeart',
    unexpected: 'fhnh',
  },
  {
    index: 2,
    desc: 'fetusHeartTwo',
    unexpected: 'fhnhTwo',
  },
  {
    index: 3,
    desc: 'fetusHeartThree',
    unexpected: 'fhnhThree',
  },
  {
    index: 4,
    desc: 'fetusHeartFour',
    unexpected: 'fhnhFour',
  },
];

const labelStyle = v => {
  return {
    style: {
      width: 15 * (v - 1) + 30,
    },
  };
};

export const labelColWidth = {
  2: labelStyle(2),
  3: labelStyle(3),
  4: labelStyle(4),
  5: labelStyle(5),
  6: labelStyle(6),
  7: labelStyle(7),
  8: labelStyle(8),
  9: labelStyle(9),
};

export const highriskVisibleType = {
  NULL: 0,
  LATEST: 1,
  HIGHEST: 2,
  ENABLE: 3,
};

export const highriskLevel = {
  GREEN: {
    key: 0,
    label: '低风险',
    barColor: 'GREEN',
    tagColor: 'GREEN',
    cname: '绿色',
  },
  YELLOW: {
    key: 1,
    label: '一般风险',
    barColor: 'YELLOW',
    tagColor: 'YELLOW',
    cname: '黄色',
  },
  ORANGE: {
    key: 2,
    label: '较高风险',
    barColor: 'ORANGE',
    tagColor: 'ORANGE',
    cname: '橙色',
  },
  RED: {
    key: 3,
    label: '高风险',
    barColor: 'RED',
    tagColor: 'RED',
    cname: '红色',
  },
  PURPLE: {
    key: 4,
    label: '传染性疾病',
    barColor: 'PURPLE',
    tagColor: 'PURPLE',
    cname: '紫色',
  },
};

export const colorMap = {
  GREEN: 'green',
  YELLOW: 'gold',
  ORANGE: 'orange',
  RED: 'red',
  PURPLE: 'purple',
};

export const colorBarMap = {
  GREEN: '#52C41A',
  YELLOW: '#FFC552',
  ORANGE: '#FF964A',
  RED: '#FF6B58',
  PURPLE: '#907BFF',
};

export const globalPageType = {
  DOCTOR: '0',
  NURSE: '1',
  OTHER: '2',
};

export const doctorMedicalAnchor = [
  {
    id: 'anchor-case',
    title: '病',
  },
  {
    id: 'anchor-gravida',
    title: '孕',
  },
  {
    id: 'anchor-outline',
    title: '概',
  },
  {
    id: 'anchor-physical',
    title: '体',
  },
  {
    id: 'anchor-assistant',
    title: '辅',
  },
  // {
  //     id: 'anchor-highrisk',
  //     title: '高'
  // },
  {
    id: 'anchor-other',
    title: '其',
  },
];

export const doctorReturnMedicalAnchor = [
  {
    id: 'anchor-case',
    title: '病',
  },
  {
    id: 'anchor-gravida',
    title: '孕',
  },
  {
    id: 'anchor-physical',
    title: '体',
  },
  {
    id: 'anchor-assistant',
    title: '辅',
  },
  // {
  //     id: 'anchor-highrisk',
  //     title: '高'
  // },
  {
    id: 'anchor-other',
    title: '其',
  },
];

export const doctorMedicalViewAnchor = [
  {
    id: 'anchor-case',
    title: '病',
  },
  {
    id: 'anchor-gravida',
    title: '孕',
  },
  {
    id: 'anchor-outline',
    title: '概',
  },
  {
    id: 'anchor-physical',
    title: '体',
  },
  {
    id: 'anchor-assistant',
    title: '辅',
  },
  {
    id: 'anchor-highrisk',
    title: '高',
  },
  {
    id: 'anchor-other',
    title: '其',
  },
];

export const doctorReturnMedicalViewAnchor = [
  {
    id: 'anchor-case',
    title: '病',
  },
  {
    id: 'anchor-gravida',
    title: '孕',
  },
  {
    id: 'anchor-physical',
    title: '体',
  },
  {
    id: 'anchor-assistant',
    title: '辅',
  },
  {
    id: 'anchor-highrisk',
    title: '高',
  },
  {
    id: 'anchor-other',
    title: '其',
  },
];

export const nurseMedicalAnchor = [
  {
    id: 'anchor-base',
    title: '基',
  },
  {
    id: 'anchor-husband',
    title: '丈',
  },
  {
    id: 'anchor-pregnancy',
    title: '怀',
  },
  {
    id: 'anchor-menstruation',
    title: '月',
  },
  {
    id: 'anchor-pregnancy-history',
    title: '孕',
  },
  {
    id: 'anchor-outline',
    title: '概',
  },
  {
    id: 'anchor-physical',
    title: '预',
  },
  {
    id: 'anchor-emergency',
    title: '转',
  },
  {
    id: 'anchor-other',
    title: '其',
  },
];

export const nurseReturnMedicalAnchor = [
  {
    id: 'anchor-physical',
    title: '预',
  },
  {
    id: 'anchor-emergency',
    title: '转',
  },
  {
    id: 'anchor-other',
    title: '其',
  },
];

export const defaultfuh = {
  week: Array.from({ length: 21 }, (v, i) => i + 20),
  min: [
    15.5,
    16.3,
    17,
    17.8,
    18.5,
    19.3,
    20.1,
    21.1,
    22.1,
    22.9,
    23.7,
    24.4,
    25,
    26,
    27,
    27.9,
    28.8,
    29.1,
    29.3,
    29.7,
    30,
  ],
  max: [
    22,
    22.8,
    23.5,
    24.3,
    25,
    25.9,
    26.8,
    27.3,
    28.5,
    29.2,
    29.8,
    30.7,
    31.5,
    32.2,
    32.8,
    33.5,
    34.1,
    34.2,
    34.3,
    35.2,
    35.1,
  ],
};

export const defaultWeekChange = [
  {
    bmi: [0, 18.5],
    week: Array.from({ length: 40 }, (v, i) => i + 1),
    min: Array.from({ length: 40 }, (v, i) =>
      (i <= 14 ? 0.0769 * i : 0.4423 * (i + 1) - 5.1923).toFixed(2),
    ),
    max: Array.from({ length: 40 }, (v, i) =>
      (i <= 14 ? 0.2308 * i : 0.5769 * (i + 1) - 5.0769).toFixed(2),
    ),
  },
  {
    bmi: [18.5, 24.9],
    week: Array.from({ length: 40 }, (v, i) => i + 1),
    min: Array.from({ length: 40 }, (v, i) =>
      (i <= 14 ? 0.0769 * i : 0.4038 * (i + 1) - 4.6538).toFixed(2),
    ),
    max: Array.from({ length: 40 }, (v, i) =>
      (i <= 14 ? 0.2308 * i : 0.5 * (i + 1) - 4).toFixed(2),
    ),
  },
  {
    bmi: [25, 29.9],
    week: Array.from({ length: 40 }, (v, i) => i + 1),
    min: Array.from({ length: 40 }, (v, i) =>
      (i <= 14 ? 0.0769 * i : 0.2308 * (i + 1) - 2.2308).toFixed(2),
    ),
    max: Array.from({ length: 40 }, (v, i) =>
      (i <= 14 ? 0.2308 * i : 0.3269 * (i + 1) - 1.5769).toFixed(2),
    ),
  },
  {
    bmi: [30],
    week: Array.from({ length: 40 }, (v, i) => i + 1),
    min: Array.from({ length: 40 }, (v, i) =>
      (i <= 14 ? 0.0769 * i : 0.1538 * (i + 1) - 1.1538).toFixed(2),
    ),
    max: Array.from({ length: 40 }, (v, i) =>
      (i <= 14 ? 0.2308 * i : 0.2308 * (i + 1) - 0.2308).toFixed(2),
    ),
  },
];

// if (BMI < 18.5) {
//     down = ([0.00, 0.00, 0.08, 0.15, 0.23, 0.31, 0.38, 0.46, 0.54, 0.62, 0.69, 0.77, 0.85, 0.92, 1.00, 1.44, 1.88, 2.33, 2.77, 3.21, 3.65, 4.10, 4.54, 4.98, 5.42, 5.87, 6.31, 6.75, 7.19, 7.63, 8.08, 8.52, 8.96, 9.40, 9.85, 10.29, 10.73, 11.17, 11.62, 12.06, 12.50]);
//     up = ([0.00, 0.00, 0.23, 0.46, 0.69, 0.92, 1.15, 1.38, 1.62, 1.85, 2.08, 2.31, 2.54, 2.77, 3.00, 3.58, 4.15, 4.73, 5.31, 5.88, 6.46, 7.04, 7.61, 8.19, 8.77, 9.35, 9.92, 10.50, 11.08, 11.65, 12.23, 12.81, 13.38, 13.96, 14.54, 15.11, 15.69, 16.27, 16.85, 17.42, 18.00]);
// } else if (18.5 < BMI < 24.9) {
//     down = ([0.00, 0.00, 0.08, 0.15, 0.23, 0.31, 0.38, 0.46, 0.54, 0.62, 0.69, 0.77, 0.85, 0.92, 1.00, 1.40, 1.81, 2.21, 2.61, 3.02, 3.42, 3.83, 4.23, 4.63, 5.04, 5.44, 5.85, 6.25, 6.65, 7.06, 7.46, 7.86, 8.27, 8.67, 9.08, 9.48, 9.88, 10.29, 10.69, 11.09, 11.50]);
//     up = ([0.00, 0.00, 0.23, 0.46, 0.69, 0.92, 1.15, 1.38, 1.62, 1.85, 2.08, 2.31, 2.54, 2.77, 3.00, 3.50, 4.00, 4.50, 5.00, 5.50, 6.00, 6.50, 7.00, 7.50, 8.00, 8.50, 9.00, 9.50, 10.00, 10.50, 11.00, 11.50, 12.00, 12.50, 13.00, 13.50, 14.00, 14.50, 15.00, 15.50, 16.00]);

// } else if (25 < BMI < 29.9) {
//     down = ([0.00, 0.00, 0.08, 0.15, 0.23, 0.31, 0.38, 0.46, 0.54, 0.62, 0.69, 0.77, 0.85, 0.92, 1.00, 1.23, 1.46, 1.69, 1.92, 2.15, 2.39, 2.62, 2.85, 3.08, 3.31, 3.54, 3.77, 4.00, 4.23, 4.46, 4.69, 4.92, 5.15, 5.39, 5.62, 5.85, 6.08, 6.31, 6.54, 6.77, 7.00]);
//     up = ([0.00, 0.00, 0.23, 0.46, 0.69, 0.92, 1.15, 1.38, 1.62, 1.85, 2.08, 2.31, 2.54, 2.77, 3.00, 3.33, 3.65, 3.98, 4.31, 4.63, 4.96, 5.29, 5.61, 5.94, 6.27, 6.60, 6.92, 7.25, 7.58, 7.90, 8.23, 8.56, 8.88, 9.21, 9.54, 9.86, 10.19, 10.52, 10.85, 11.17, 11.50]);

// } else {
//     down = ([0.00, 0.00, 0.08, 0.15, 0.23, 0.31, 0.38, 0.46, 0.54, 0.62, 0.69, 0.77, 0.85, 0.92, 1.00, 1.15, 1.31, 1.46, 1.61, 1.77, 1.92, 2.08, 2.23, 2.38, 2.54, 2.69, 2.85, 3.00, 3.15, 3.31, 3.46, 3.61, 3.77, 3.92, 4.08, 4.23, 4.38, 4.54, 4.69, 4.84, 5.00]);
//     up = ([0.00, 0.00, 0.23, 0.46, 0.69, 0.92, 1.15, 1.38, 1.62, 1.85, 2.08, 2.31, 2.54, 2.77, 3.00, 3.23, 3.46, 3.69, 3.92, 4.15, 4.39, 4.62, 4.85, 5.08, 5.31, 5.54, 5.77, 6.00, 6.23, 6.46, 6.69, 6.92, 7.15, 7.39, 7.62, 7.85, 8.08, 8.31, 8.54, 8.77, 9.00]);
// }

export default {};

import { globalPageType } from '@/config';

export const forbidEdit = (data, config, disabled = false) => { 
    if (config.globalPageType === globalPageType?.NURSE) {
        return data?.auditStatus === 'DOCTOR_AUDIT' || config?.windowSearch?.disabled || disabled;
    } else if (config.globalPageType === globalPageType?.DOCTOR) {
        return config?.windowSearch?.disabled || disabled;
    } else { 
        return disabled;
    }
}
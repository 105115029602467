import React, { useEffect, useState } from 'react';
import { Modal, Tabs, TabPane, VSTable, VSTableColumn, Radio, RadioGroup } from '@vs/vsf-kit';
import Block from './block';
import './index.less';

export interface PageBlockType {
  title?: string | React.ReactNode;
  visible?: boolean;
    width?: number;
    onCancel: () => {};
  onOk: (v) => {};
  importData: any;
}

const Index = (props: PageBlockType) => {
  const { title, visible, width, onCancel, onOk, importData, ...rest } = props;
  const classPrefix = 'e-medical-import-modal';
    const [activeKey, setActiveKey] = useState<string>('inspection');
    const [radioValue, setRadioValue] = useState('0');
    const [selectedRows, setSelectedRows] = useState<any>([]);
  const [activeRecord, setActiveRecord] = useState<any>({
    examination: importData?.examination?.[0] || {},
    inspection: importData?.inspection?.[0] || {}
  });
    
    const handleModalCancel = () => {
        onCancel && onCancel();
  };
  const handleModalOk = () => {
        onOk &&
          onOk(
            activeKey === 'inspection'
              ? selectedRows
              : activeRecord?.examination,
          );
  };
    const handleRadioChange = (v) => {
        setRadioValue(v);
    };
  
  useEffect(() => {
    let examinationRes = {};
    let inspectionRes = [];
    try {
      const target = JSON.parse(importData?.examination?.[0]?.result);
      examinationRes = {
        ...target,
        examCode: JSON.parse(target.examCode),
        examName: JSON.parse(target.examName),
      };
      inspectionRes = JSON.parse(importData?.inspection?.[0]?.result);
    } catch { 
      
    }
    setActiveRecord({
      examination: {
        ...(importData?.examination?.[0] || {}),
        result: examinationRes,
      },
      inspection: {
        ...(importData?.inspection?.[0] || {}),
        result: inspectionRes,
      },
    });
  }, [importData]);

  return (
    <Modal
      width={width || 1400}
      className={classPrefix}
      title={title || ''}
      visible={visible}
      onCancel={handleModalCancel}
      onOk={handleModalOk}
    >
      <div className="container">
        <div className="left">
          <Tabs
            type="line"
            centered={false}
            activeKey={activeKey}
            onTabClick={async akey => {
              setActiveKey(akey);
            }}
          >
            <TabPane key="inspection" tab="检验">
              <div className="filter">
                <RadioGroup
                  value={radioValue}
                  onChange={handleRadioChange}
                  dataSource={[
                    { label: '最近一年', value: '1' },
                    { label: '最近三年', value: '2' },
                    { label: '全部', value: '0' },
                  ]}
                ></RadioGroup>
              </div>
              <VSTable
                scroll={{ y: 447 }}
                dataType="static"
                onRow={record => {
                  return {
                    onClick: event => {
                      let result = [];
                      try {
                        result = JSON.parse(record?.result);
                      } catch {}
                      setActiveRecord({
                        ...activeRecord,
                        inspection: {
                          ...record,
                          result,
                        },
                      });
                    }, // 点击行
                  };
                }}
                rowClassName={(record, index) =>
                  record?.id === activeRecord?.inspection?.id ? 'active' : ''
                }
                dataSource={importData?.inspection}
                // dataLoad={
                //   {
                //     request: recordService.getExamination,
                //     qtoParams: {
                //       gravidaRecordId: props?.stores?.common.gravidaRecordId,
                //     },
                //     extParams: {},
                //   }
                // }
              >
                <VSTableColumn
                  title="日期"
                  valueType="date"
                  dataIndex="submitTime"
                  columnKey="submitTime"
                  width={100}
                  key="submitTime"
                ></VSTableColumn>
                <VSTableColumn
                  title="检验名称"
                  valueType="text"
                  dataIndex="checkItemName"
                  columnKey="checkItemName"
                  key="checkItemName"
                ></VSTableColumn>
                <VSTableColumn
                  title="状态"
                  valueType="text"
                  dataIndex="examinationStatus"
                  columnKey="examinationStatus"
                  width={70}
                  key="examinationStatus"
                  render={(v, record) => {
                    return record?.examinationStatus === 'REPORT'
                      ? '已报告'
                      : '新开单';
                  }}
                ></VSTableColumn>
              </VSTable>
            </TabPane>
            <TabPane key="examination" tab="检查">
              <div className="filter">
                <RadioGroup
                  value={radioValue}
                  onChange={handleRadioChange}
                  dataSource={[
                    { label: '最近一年', value: '1' },
                    { label: '最近三年', value: '2' },
                    { label: '全部', value: '0' },
                  ]}
                ></RadioGroup>
              </div>
              <VSTable
                scroll={{ y: 447 }}
                dataType="static"
                dataSource={importData?.examination}
                onRow={record => {
                  return {
                    onClick: event => {
                      let result = [];
                      try {
                        result = JSON.parse(record?.result);
                      } catch {}
                      setActiveRecord({
                        ...activeRecord,
                        examination: {
                          ...record,
                          result,
                        },
                      });
                    }, // 点击行
                  };
                }}
                rowClassName={(record, index) =>
                  record?.id === activeRecord?.examination?.id ? 'active' : ''
                }
              >
                <VSTableColumn
                  title="日期"
                  valueType="date"
                  dataIndex="submitTime"
                  columnKey="submitTime"
                  width={100}
                  key="submitTime"
                ></VSTableColumn>
                <VSTableColumn
                  title="检验名称"
                  valueType="text"
                  dataIndex="checkItemName"
                  columnKey="checkItemName"
                  key="checkItemName"
                ></VSTableColumn>
                <VSTableColumn
                  title="状态"
                  valueType="text"
                  dataIndex="examinationStatus"
                  columnKey="examinationStatus"
                  width={70}
                  key="examinationStatus"
                  render={(v, record) => {
                    return record?.examinationStatus === 'REPORT'
                      ? '已报告'
                      : '新开单';
                  }}
                ></VSTableColumn>
              </VSTable>
            </TabPane>
          </Tabs>
        </div>
        {activeKey === 'inspection' ? (
          <div className="right">
            <div className="header">
              <div className="title">
                {activeRecord?.[activeKey]?.checkItemName}
              </div>
              <div className="desc">
                <div>
                  <span>报告时间：{activeRecord?.[activeKey]?.reportTime}</span>
                  <span>开单时间：{activeRecord?.[activeKey]?.submitTime}</span>
                </div>
                <div>
                  <span>NO.{activeRecord?.[activeKey]?.reportId}</span>
                </div>
              </div>
            </div>
            <div className="content">
              <VSTable
                dataType="static"
                selection={{
                  selectedRows,
                  onSelectedRowsChange: v => setSelectedRows(v),
                  selectType: 'multiple',
                }}
                scroll={{ y: 367 }}
                dataSource={activeRecord?.[activeKey].result}
              >
                <VSTableColumn
                  title="项目名称"
                  valueType="text"
                  dataIndex="labTestItemName"
                  columnKey="labTestItemName"
                  key="labTestItemName"
                ></VSTableColumn>
                <VSTableColumn
                  title="检查结果"
                  valueType="text"
                  dataIndex="labTestItemResult"
                  columnKey="labTestItemResult"
                  key="labTestItemResult"
                ></VSTableColumn>
                <VSTableColumn
                  title="参考区间"
                  valueType="text"
                  dataIndex="labTestItemReferRange"
                  columnKey="labTestItemReferRange"
                  key="labTestItemReferRange"
                ></VSTableColumn>
                <VSTableColumn
                  title="单位"
                  valueType="text"
                  dataIndex="labTestItemValueUnit"
                  columnKey="labTestItemValueUnit"
                  width={100}
                  key="labTestItemValueUnit"
                ></VSTableColumn>
              </VSTable>
            </div>
          </div>
        ) : (
          <div className="right">
            <div className="header">
              <div className="title">{activeRecord?.[activeKey]?.examName}</div>
              <div className="desc">
                <div>
                  <span>检查部位：{activeRecord?.[activeKey]?.examName}</span>
                  <span>报告时间：{activeRecord?.[activeKey]?.reportTime}</span>
                  <span>开单时间：{activeRecord?.[activeKey]?.submitTime}</span>
                </div>
                <div>
                  <span>NO.{activeRecord?.[activeKey]?.reportId}</span>
                </div>
              </div>
            </div>
            <div className="content">
              <Block
                title="检查描述"
                content={activeRecord?.[activeKey]?.result?.examDesc}
              />
              <Block
                title="检查报告"
                content={activeRecord?.[activeKey]?.result?.examResult}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Index;

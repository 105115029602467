import { createServices } from "@vs/service";

export default createServices({
  getGravidaRecord: {
    method: "post",
    url: "/api/gravida/getRecord",
    parameterFomatter: (data?: { var: GravidaIdAndStatus }) => data,
    responseFormatter: (
      _,
      __,
      data?: GravidaRecordManagerDtoGravidaRecordPublicDto[]
    ) => data,
  },
  getGravidaPhysicalList: {
    method: "post",
    url: "/api/gravida/getPhysicalList",
    parameterFomatter: (data?: { gravidaRecordId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordManagerDtoPhysicalInspectionsPublicDto[]
    ) => data,
  },
  getGravidaFetus: {
    method: "post",
    url: "/api/gravida/getFetus",
    parameterFomatter: (data?: { gravidaRecordId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordManagerDtoFetusRecordDto[]
    ) => data,
  },
  createGravidaRecord: {
    method: "post",
    url: "/api/gravida/createRecord",
    parameterFomatter: (data?: {
      userInfo: GravidaInfoServiceBtoCreateGravidaInfoAtWebBto,
      recordInfo: GravidaRecordServiceBtoCreateGravidaRecordAtWebBto,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  createGravidaRecordWithMedical: {
    method: "post",
    url: "/api/gravida/createRecordWithMedical",
    parameterFomatter: (data?: {
      userInfo: GravidaInfoServiceBtoCreateGravidaInfoAtWebBto,
      medicalInfo: GravidaRecordServiceBtoUpdateGravidaRecordWithMedicalRecordBto,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  updateGravidaRecord: {
    method: "post",
    url: "/api/gravida/updateRecord",
    parameterFomatter: (data?: {
      userInfo: GravidaInfoServiceBtoCreateGravidaInfoAtWebBto,
      recordInfo: GravidaRecordServiceBtoUpdateGravidaRecordAtWebBto,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  getHighriskInfo: {
    method: "post",
    url: "/api/gravida/getHighriskInfo",
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: GravidaRecordManagerDtoGravidaRecordHighriskDto
    ) => data,
  },
  creataGravidaHighriskInfo: {
    method: "post",
    url: "/api/gravida/createHighriskInfo",
    parameterFomatter: (data?: {
      btoParam: MedicalHighriskInfoServiceBtoCreateHighriskInfoBto,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  createGravidaAllergyHistory: {
    method: "post",
    url: "/api/gravida/createAllergyHistory",
    parameterFomatter: (data?: {
      btoParam: any,
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  getEpidemiologicalHistory: {
    method: "post",
    url: "/api/gravida/getEpidemiologicalHistory",
    parameterFomatter: (data?: { hisRecordId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalEpidemiologicalHistoryManagerDtoEpidemiologicalHistoryDto
    ) => data,
  },
  editEpidemiologicalHistory: {
    method: "post",
    url: "/api/gravida/editEpidemiologicalHistory",
    parameterFomatter: (data?: {
      btoParam: MedicalEpidemiologicalHistoryServiceBtoCreateEpidemiologicalHistoryBto,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  getGravidaInfo: {
    method: "post",
    url: "/api/gravida/getGravidaInfo",
    parameterFomatter: (data?: { hisId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: GravidaInfoManagerDtoGravidaBasicInfoDto
    ) => data,
  },
  updateLmpAndGestationalWeek: {
    method: "post",
    url: "/api/gravida/updateLmpAndGestationalWeek",
    parameterFomatter: (data?: {
      btoParam: GravidaRecordServiceBtoUpdateLmpAndGestationalWeekBto,
      extApiParam: any,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  updateUpdateEdc: {
    method: "post",
    url: "/api/gravida/updateUpdateEdc",
    parameterFomatter: (data?: {
      btoParam: GravidaRecordServiceBtoUpdateUpdateEdcBto,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  updateUpdateGestationalWeek: {
    method: "post",
    url: "/api/gravida/updateUpdateGestationalWeek",
    parameterFomatter: (data?: {
      btoParam: GravidaRecordServiceBtoUpdateUpdateGestationalWeekBto,
      extApiParam: any,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});

import React, { useState, useCallback } from 'react';
import { Modal, message as messageKit } from '@vs/vsf-kit';
import moment from 'moment';
import storage from './storage';

export const timeFormat = (stamp?: number | string | Date) => {
  if (!stamp) return '-';
  const time = new Date(stamp);
  const z = (n: number) => (n < 10 ? '0' + n : n);
  return `${time.getFullYear()}年${
    time.getMonth() + 1
  }月${time.getDate()}日 ${z(time.getHours())}:${z(time.getMinutes())}:${z(
    time.getSeconds(),
  )}`;
};

export const duplicateName = (fields: { name?: string | undefined }[]) => {
  const countList = fields.reduce<{ [key: string]: number }>(function (p, c) {
    if (c.name) {
      p[c.name] = (p[c.name] || 0) + 1;
    }
    return p;
  }, {});
  const result = fields.filter((o) => {
    return o.name ? countList[o.name] > 1 : false;
  });
  if (result.length !== 0) {
    const names = result
      .map((o) => o.name)
      .reduce<Array<string>>((p, c) => {
        if (c && !p.includes(c)) {
          p.push(c);
        }
        return p;
      }, []);
    return names;
  }
  return undefined;
};

export function useCallBackRef() {
  const [isRef, setIsRef] = useState<boolean>(false);
  const ref = useCallback(node => {
    if (node !== null) {
      setIsRef(!!node);
    }
  }, []);
  return [isRef, ref];
}

// 诡异，在mock的页面上，有时不能正常强制刷新
export function useForceUpdateOld() {
  const [updateValue, setUpdateValue] = React.useState(0);
  return () => {
    const value = updateValue;
    setUpdateValue(value + 1);
  };
}

// source from https://www.npmjs.com/package/use-force-update
export function useForceUpdate(): () => void {
  const [ , dispatch ] = React.useState<{}>(Object.create(null));

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = React.useCallback(
    (): void => {
      dispatch(Object.create(null));
    },
    [ dispatch ],
  );
  return memoizedDispatch;
}

export function useBoolean(initialState = false) {
  const [value, setValue] = useState(initialState);
  const on = useCallback(() => {
    setValue(true);
  }, []);
  const off = useCallback(() => {
    setValue(false);
  }, []);
  const toggle = useCallback(() => {
    setValue((prev) => !prev);
  }, []);
  return [value, { on, off, toggle }] as any;
}

export const randomColor = () => {
  const h = Math.floor(Math.random() * 360);
  return `hsl(${h}deg, 100%, 30%)`;
};

export const countDownConfirm = (config: {
  title?: string;
  content?: string;
  okText?: string;
  second?: number;
}, confirmedCallback: () => void) => {
  const { title, content, okText = '确定', second = 0 } = config;
  const modalProps = (second: number) => {
    return {
      title: title,
      content: content,
      okText: second > 0 ? `${okText}(${second})` : okText,
      okButtonProps: {
        disabled: second > 0,
      },
    };
  };
  
  let secondsToGo = second;
  const modal = (Modal as any).confirm({
    ...modalProps(secondsToGo),
    onOk: confirmedCallback,
  });
  if (second > 0) {
    const timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update(modalProps(secondsToGo));
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
    }, secondsToGo * 1000);
  }
};

export const deleteConfirm = (confirmedCallback: () => void, name?: string, immediately?: boolean, deleteText?: string) => {
  const text = deleteText || '删除';
  countDownConfirm({
    title: `${text}提示`,
    content: `确认${text}${name ? `「 ${name} 」` : '该数据'}吗？`,
    okText: text,
    second: 0, //immediately ? 0 : 5,
  }, confirmedCallback);
};


export function isPromise(obj) {
  return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function';
};

export function isToday(date) { 
  return moment(date).isSame(moment(), "day");
}

export const formatEnums = (enums) => { 
  let obj = {};
  const recursion = (list) => {
    return (list || []).map(item => {
      return {
        ...item,
        children: recursion(item?.enumeratingItemList || []),
        value: item?.item
      };
    });
  };
  enums?.forEach(item => {
    obj[item?.item] = recursion(item?.enumeratingItemList);
  });
  return obj;
};

const date2Long = (date) => { 
  return new Date(moment(date).format('YYYY-MM-DD 00:00:00')).valueOf()
}

/**
 * 
 * @param data 
 * @param now 
 * @param show 0: 2W2D 1: 3W 2: 4D
 * @returns 
 */
export const getGestationalWeek = (data: { 
  lmp: any,
  nowTimeValue?: any,
  show?: 0 | 1 | 2
}) => {
  const { lmp, nowTimeValue = new Date(), show = 2} = data;
  if (!lmp) return null;
  let gestationalWeek: any = null;
  const nowTime = moment(nowTimeValue).format('YYYY-MM-DD');
  const lmpTime = moment(lmp).format('YYYY-MM-DD');
  gestationalWeek = moment(nowTime).diff(lmpTime, 'day');

  if (show === 1) {
    return gestationalWeek / 7;
  } else if (show === 0) {
    return `${Math.floor(Math.floor(gestationalWeek) / 7)}W${Math.floor(gestationalWeek) % 7}D`
  } else { 
    return gestationalWeek;
  }
}

/**
 * 
 * @param data 
 * @param now 
 * @param show 0: 2W2D 1: 3W 2: 4D
 * @returns 
 */
export const getUpdateGestationalWeek = (data, now, show = 2) => {
  if (!data) return null;
  const long2Day = 3600 * 1000 * 24;
  const {
    updateGestationalWeek,
    updateGestationalConfirmTime,
    updateLmp,
    lmp
  } = data;
  let realGestationalWeek: any = null;
  if (updateGestationalWeek != null && updateGestationalConfirmTime !== null) {
    realGestationalWeek = updateGestationalWeek + (date2Long(now) - date2Long(updateGestationalConfirmTime)) / long2Day;
  } else if (updateLmp != null) {
    realGestationalWeek = (date2Long(now) - date2Long(updateLmp)) / long2Day;
  } else if (lmp != null) {
    realGestationalWeek = (date2Long(now) - date2Long(lmp)) / long2Day;
  } else {
    realGestationalWeek = null;
  }
  if (show === 1) {
    return realGestationalWeek / 7;
  } else if (show === 0) {
    return `${Math.floor(Math.floor(realGestationalWeek) / 7)}W${Math.floor(realGestationalWeek) % 7}D`
  } else {
    return realGestationalWeek;
  }
}


export const dateAdd = (date, day) => { 
  return moment(moment(date).add(day, "days")).format('YYYY-MM-DD');
}
export const dateSubtract = (date, day) => { 
  return moment(moment(date).subtract(day, "days")).format('YYYY-MM-DD');
}

export const message = (data) => {
  if (data.code === 200) {
    messageKit.success(data?.message || '提交成功');
  } else { 
    messageKit.error(data?.message || '提交失败');
  }
}

export const setValueAndReturn = (key: string, value: any) => { 
  // storage.set(key, value);
  return value;
}

export const getBirthday = (idCard) => {
  var birthday = "";
  if (idCard != null && idCard != "") {
    if (idCard.length == 15) {
      birthday = "19" + idCard.substr(6, 6);
    } else if (idCard.length == 18) {
      birthday = idCard.substr(6, 8);
    }

    birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
  }

  if (moment(new Date()).diff(birthday, 'day') <= 0) {
    return null;
  } else { 
    return birthday;
  }
};

/**
 * 通过身份证判断性别
 * @param {身份证号码} idCard
 */
export const getSex = (idCard) => {
  var sexStr = "";
  if (parseInt(idCard.slice(-2, -1)) % 2 == 1) {
    sexStr = "男";
  } else {
    sexStr = "女";
  }
  return sexStr;
};

/**
 * 通过身份证号计算年龄
 * @param {身份证号码} idCard
 */
export const getAge = (idCard) => {
  const birthday = getBirthday(idCard);
  const age = moment().diff(birthday, 'years') || '';
  return age <= 0 ? '' : age;
};
import React, { useState, useRef, useEffect } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { Modal, Image } from '@vs/vsf-kit';
import namePng from '@/static/nameblockbg2.png';
import recordPng from '@/static/record.png';
import Record from '@/pages/record/components/record';
import Icon from '@/components/icon';
import { forbidEdit } from '@/pages/nurse/utils';
import { handleScroll } from './components/record';
import { globalPageType } from '@/config';
import './index.less';

/**
 * 产妇建档弹框
 **/

const TabNumber = 5;

const Index = props => {
  const { common, gravida } = vsf?.stores;
  const {
    createRecordVisible,
    recordSure,
    gravidaInfo,
    gravidaRecord,
  } = gravida;
  const [activeKey, setActiveKey] = useState(0);
  const recordRef = useRef();

  const handleOk = async () => {
    recordRef && recordRef?.current?.handleOk();
    handleScroll();
  };

  const handleCancel = e => {
    recordRef && recordRef?.current?.handleCancel(e);
    handleScroll();
  };

  const handleKeyChange = v => {
    setActiveKey(v);
  };

  const handleRenderRecordTag = () => {
    let flag;
    if (common?.globalPageType === globalPageType.NURSE) {
      flag = !!common?.gravidaRecordId;
    } else if (common?.globalPageType === globalPageType.DOCTOR) {
      flag =
        !!common?.gravidaRecordId &&
        gravidaRecord?.auditStatus === 'DOCTOR_AUDIT';
    }
    return flag;
  };

  return (
    <Modal
      width={1032}
      className="e-medical-record-modal"
      title="孕产档案"
      keyboard={false}
      destroyOnClose={true}
      okText={activeKey < TabNumber ? '下一步' : '保存'}
      cancelText="上一步"
      visible={createRecordVisible && !recordSure}
      id="complaint-modal"
      onOk={handleOk}
      onCancel={handleCancel}
      cancelButtonProps={{
        hidden: activeKey === 0,
      }}
      style={{ top: '20px' }}
      maskClosable={false}
      closable={handleRenderRecordTag()}
    >
      <div>
        <div className="record-info-main-header">
          <div className="name">
            <div className="word">
              <div>{gravidaInfo?.name}</div>
            </div>
            <div className="bg">
              <Image src={namePng} preview={false} />
            </div>
          </div>
          <div className="idnum">
            <Icon
              type="icon-ID"
              style={{
                fontSize: 24,
                marginRight: 4,
              }}
            />
            <span>{gravidaInfo.hisId || '---'}</span>
          </div>
          {common?.gravidaRecordId && (
            <React.Fragment>
              <div className="idnum">
                <span>保健册号：</span>
                <span>{gravidaRecord?.healthBookId || '-'}</span>
              </div>
              <div className="idnum">
                <span>建册日期：</span>
                <span>{gravidaRecord?.recordTime || '-'}</span>
              </div>
              <div className="idnum">
                <span>建册机构：</span>
                <span>{gravidaRecord?.recordOrg || '-'}</span>
              </div>
              <div className="idnum">
                <span>建册医生：</span>
                <span>{gravidaRecord?.recordDoctor || '-'}</span>
              </div>
            </React.Fragment>
          )}
        </div>
        <Record recordRef={recordRef} handleKeyChange={handleKeyChange} />
      </div>
    </Modal>
  );
};

export default definePage(Index);

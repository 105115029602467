export const recordBaseInfoDefaultValue = {
    country: '中国',
    nation: '汉族',
    nativeLocation: '浙江嘉兴',
    idType: '身份证',
    maritalStatus: '已婚',
    sexualPartnerNum: '1',
    personnelStatus: '本地',
    current: ['浙江省', '浙江省-嘉兴市'],
    registered: ['浙江省', '浙江省-嘉兴市'],
    birthplace: ['浙江省', '浙江省-嘉兴市'],
    post: ['浙江省', '浙江省-嘉兴市'],
    manager: ['浙江省', '浙江省-嘉兴市'],

};

export const recordSpouseInfoDefaultValue = {
    country: '中国',
    nation: '汉族',
    idType: '身份证',
    registered: ['浙江省', '浙江省-嘉兴市'],
    healthyStatus: '健康'
};


export const recordPregnancyInfoDefaultValue = {
    parity: '',
    gravidity: '',
    fereilizingMethod: '自然受孕',
    fetusNum: '1',
    deliveryLocal: '本院',
    mpas: {
        mature: 0,
        premature: 0,
        abortion: 0,
        survival: 0,
    }
};

import React from 'react';
import './index.less';

const Index = (props: {
  data: any;
}) => {
  const { data } = props;
  // const needHidden = !data || Object.keys(data).length === 0;
  // if (needHidden) { 
  //   return null;
  // }
  const { examinationList, medicineList, treatmentList } = data;
    return (
      <div className="e-medical-recommend-block">
        <div className="block">
          <div className="item">
            <div className="title">推荐检验</div>
            {!examinationList || examinationList?.length === 0 ? (
              <div className="content">暂无推荐检验</div>
            ) : (
              <div className="content">
                {examinationList &&
                  examinationList.map(item => <div key={item}>{item}</div>)}
              </div>
            )}
          </div>
          <div className="item">
            <div className="title">推荐检查</div>
            {!treatmentList || treatmentList?.length === 0 ? (
              <div className="content">暂无推荐检查</div>
            ) : (
              <div className="content">
                {treatmentList &&
                  treatmentList.map(item => <div key={item}>{item}</div>)}
              </div>
            )}
          </div>
          <div className="item">
            <div className="title">推荐治疗</div>
            {!medicineList || medicineList?.length === 0 ? (
              <div className="content">暂无推荐检查</div>
            ) : (
              <div className="content">
                {medicineList &&
                  medicineList.map(item => <div key={item}>{item}</div>)}
              </div>
            )}
          </div>
        </div>
      </div>
    );
};

export default Index;
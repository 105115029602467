import { message } from '@vs/vsf-kit';
import service from '@vs/service';
import storage from '@/utils/storage';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

// service.defaults.baseURL = 'http://192.168.7.237:10011';
// service.defaults.withCredentials = true;

service.interceptors.response.use(
  res => {
    if (res?.data?.code === 401 && res?.data?.errMsg === 'Not logged in!')
      return res;
    if (res.status !== 200 || res?.data?.code !== 200) {
      message.error(res?.data?.message || '请求错误');
    }
    return res;
  },
  err => {
    if (err.response) {
      switch (err.response.status) {
        case 401:
          // 返回 401 清除token信息并跳转到登录页面
          storage.remove('userData');
          history.push(`/login?from=${window.location.pathname}`);
      }
    }
    return Promise.reject(err);
  },
);

service.interceptors.request.use(
  (config) => {
    config.withCredentials = true;
    const userData = storage.get('userData');
    if (userData) {
      config.headers['Authorization'] = `Bearer ${userData}`;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

export default service;

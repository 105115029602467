import React from 'react';
import { FormBlockType } from '.';
import { radioDataSource } from '@/config';
import { VSFormItem } from '@vs/vsf-kit';
import VsfFormLayout, {
  FormBlock2,
  FormBlock,
  VsfFormLayoutDefine,
} from '@/components/form-layout';

export const recordFamilyHistoryFormItem = data => {
  const { basic, formType, labelCol, extra, disabled,recordFamilyHistoryBto } = data;
  const { enums, addressData } = basic || {};
  let formatProps = {};
  let Box = <React.Fragment></React.Fragment>;
  if (formType) {
    Box = formType === FormBlockType.SIGN ? FormBlock : FormBlock2;
  }
  if (labelCol) {
    formatProps = {
      labelCol,
    };
  }

    const renderdisabled = (disabled,obj)=>{
      if(typeof disabled?.mentalRetardationLevel === 'boolean'){
        return disabled?.mentalRetardationLevel
      }else {
        return obj?.mentalRetardation === '无'
      }
    }
  return (
    <React.Fragment>
      <Box title="家族史" extra={extra?.family || null}>
        <VsfFormLayout columnCount={1} {...formatProps}>
          <VsfFormLayoutDefine columnSpan={[1, 2]}>
            <VSFormItem
              label="遗传病史"
              name={['recordFamilyHistoryBto', 'geneticHistory']}
              valueType="radio"
              dataSource={radioDataSource}
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写遗传病史',
              //   },
              // ]}
            ></VSFormItem>
            <VSFormItem
              label="遗传病史详述"
              name={['recordFamilyHistoryBto', 'geneticHistoryDetail']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
          </VsfFormLayoutDefine>
          <VsfFormLayoutDefine columnSpan={[1, 2]}>
            <VSFormItem
              label="肿瘤病史"
              name={['recordFamilyHistoryBto', 'tumorHistory']}
              valueType="radio"
              dataSource={radioDataSource}
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写肿瘤病史',
              //   },
              // ]}
            ></VSFormItem>
            <VSFormItem
              label="肿瘤病史详述"
              name={['recordFamilyHistoryBto', 'tumorHistoryDetail']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
          </VsfFormLayoutDefine>
          <VsfFormLayoutDefine columnSpan={[1, 2]}>
            <VSFormItem
              label="传染病史"
              name={['recordFamilyHistoryBto', 'infectiousHistory']}
              valueType="radio"
              dataSource={radioDataSource}
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写传染病史',
              //   },
              // ]}
            ></VSFormItem>
            <VSFormItem
              label="传染病史详述"
              name={['recordFamilyHistoryBto', 'infectiousHistoryDetail']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
          </VsfFormLayoutDefine>
          <VsfFormLayoutDefine columnSpan={[1, 2]}>
            <VSFormItem
              label="精神病史"
              name={['recordFamilyHistoryBto', 'mentalDiseaseHistory']}
              valueType="radio"
              dataSource={radioDataSource}
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写精神病史',
              //   },
              // ]}
            ></VSFormItem>
            <VSFormItem
              label="精神病史详述"
              name={['recordFamilyHistoryBto', 'mentalDiseaseHistoryDetail']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
          </VsfFormLayoutDefine>
          <VsfFormLayout columnCount={3}>
            <VSFormItem
              label="智障"
              name={['recordFamilyHistoryBto', 'mentalRetardation']}
              valueType="radio"
              dataSource={radioDataSource}
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写智障情况',
              //   },
              // ]}
            ></VSFormItem>
            <VSFormItem
              label="智障程度"
              name={['recordFamilyHistoryBto', 'mentalRetardationLevel']}
              valueType="radio"
              fieldProps={{
                disabled: renderdisabled(disabled,recordFamilyHistoryBto),
              }}
              dataSource={[
                {
                  label: '轻度',
                  value: '轻度',
                },
                {
                  label: '中度',
                  value: '中度',
                },
                {
                  label: '重度',
                  value: '重度',
                },
              ]}
            ></VSFormItem>
            <VSFormItem
              label="智障详述"
              name={['recordFamilyHistoryBto', 'mentalRetardationDetail']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
          </VsfFormLayout>
          <VsfFormLayoutDefine columnSpan={[1, 2]}>
            <VSFormItem
              label="畸形"
              name={['recordFamilyHistoryBto', 'malformation']}
              valueType="radio"
              dataSource={radioDataSource}
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写畸形情况',
              //   },
              // ]}
            ></VSFormItem>
            <VSFormItem
              label="畸形详述"
              name={['recordFamilyHistoryBto', 'malformationDetail']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
          </VsfFormLayoutDefine>
          <VsfFormLayoutDefine columnSpan={[1, 2]}>
            <VSFormItem
              label="其他"
              name={['recordFamilyHistoryBto', 'other']}
              valueType="radio"
              dataSource={radioDataSource}
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写其他情况',
              //   },
              // ]}
            ></VSFormItem>
            <VSFormItem
              label="其他详述"
              name={['recordFamilyHistoryBto', 'otherDetail']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
          </VsfFormLayoutDefine>
          <VsfFormLayout columnCount={3}>
            <VSFormItem
              label="父亲"
              name={['recordFamilyHistoryBto', 'fatherHistory']}
              valueType="select"
              dataSource={enums['father_history']}
              fieldProps={{
                placeholder: '请选择',
              }}
            ></VSFormItem>
            <VSFormItem
              label="母亲"
              name={['recordFamilyHistoryBto', 'motherHistory']}
              valueType="select"
              dataSource={enums['mother_history']}
              fieldProps={{
                placeholder: '请选择',
              }}
            ></VSFormItem>
            <VSFormItem
              label="健康兄弟"
              name={['recordFamilyHistoryBto', 'healthyBrothers']}
              valueType="digit"
              fieldProps={{
                precision: 0,
                min: 0,
                max: 10,
                addonAfter: '个',
                placeholder: '请输入',
                controls: false,
              }}
            ></VSFormItem>
            <VSFormItem
              label="健康姐妹"
              name={['recordFamilyHistoryBto', 'healthySisters']}
              valueType="digit"
              fieldProps={{
                precision: 0,
                min: 0,
                max: 10,
                addonAfter: '个',
                placeholder: '请输入',
                controls: false,
              }}
            ></VSFormItem>
            <VSFormItem
              label="患病兄弟"
              name={['recordFamilyHistoryBto', 'unhealthyBrothers']}
              valueType="digit"
              fieldProps={{
                precision: 0,
                min: 0,
                max: 10,
                addonAfter: '个',
                placeholder: '请输入',
                controls: false,
              }}
            ></VSFormItem>
            <VSFormItem
              label="患病姐妹"
              name={['recordFamilyHistoryBto', 'unhealthySisters']}
              valueType="digit"
              fieldProps={{
                precision: 0,
                min: 0,
                max: 10,
                addonAfter: '个',
                placeholder: '请输入',
                controls: false,
              }}
            ></VSFormItem>
            <VSFormItem
              label="逝世兄弟"
              name={['recordFamilyHistoryBto', 'diedBrothers']}
              valueType="digit"
              fieldProps={{
                precision: 0,
                min: 0,
                max: 10,
                addonAfter: '个',
                placeholder: '请输入',
                controls: false,
              }}
            ></VSFormItem>
            <VSFormItem
              label="逝世姐妹"
              name={['recordFamilyHistoryBto', 'diedSisters']}
              valueType="digit"
              fieldProps={{
                precision: 0,
                min: 0,
                max: 10,
                addonAfter: '个',
                placeholder: '请输入',
                controls: false,
              }}
            ></VSFormItem>
          </VsfFormLayout>
        </VsfFormLayout>
      </Box>
    </React.Fragment>
  );
};

import React, { useState, useRef } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { message, Modal, TabPane, Tabs, VSForm, VSFormItem } from '@vs/vsf-kit';
import Radiostr from '@/components/radiostr';
import VsfFormLayout from '@/components/form-layout';
// import './index.less';

/**
 * 既往史弹框
 **/

const Index = props => {
  const { onCancel, data } = props;
  const formRef = useRef<any>();
  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleSubmit = async () => {
    const values = await formRef.current.validateFields();
    onCancel && onCancel(values.recordPersonalHistoryBto);
  };

  return (
    <Modal
      width={1200}
      className="e-medical-pmh-modal"
      title="既往史"
      keyboard={true}
      destroyOnClose={true}
      visible={props.visible}
      id="pmh-modal"
      onCancel={handleCancel}
      maskClosable={false}
      okText="录入"
      onOk={handleSubmit}
      cancelButtonProps={{
        hidden: true,
      }}
    >
      <div className="container">
        <VSForm
          dataType="static"
          layout="horizontal"
          formRef={formRef}
          labelCol={{
            span: 7,
          }}
          initialValues={{
            recordPersonalHistoryBto: data,
          }}
        >
          <VsfFormLayout columnCount={2}>
            <VSFormItem
              label="有无心脑血管疾病史"
              name={['recordPersonalHistoryBto', 'ccvdHistory']}
              valueType="custom"
            >
              <Radiostr dataKey={['ccvdHistory', 'ccvdHistoryDetail']} />
            </VSFormItem>
            <VSFormItem
              label="有无内分泌疾病史"
              name={['recordPersonalHistoryBto', 'endocrineDiseaseHistory']}
              valueType="custom"
            >
              <Radiostr
                dataKey={[
                  'endocrineDiseaseHistory',
                  'endocrineDiseaseHistoryDetail',
                ]}
              />
            </VSFormItem>

            <VSFormItem
              label="有无肝脏病史"
              name={['recordPersonalHistoryBto', 'hepatopathyHistory']}
              valueType="custom"
            >
              <Radiostr
                dataKey={['hepatopathyHistory', 'hepatopathyHistoryDetail']}
              />
            </VSFormItem>
            <VSFormItem
              label="有无肺部病史"
              name={['recordPersonalHistoryBto', 'lungDiseaseHistory']}
              valueType="custom"
            >
              <Radiostr
                dataKey={['lungDiseaseHistory', 'lungDiseaseHistoryDetail']}
              />
            </VSFormItem>

            <VSFormItem
              label="有无肾脏病史"
              name={['recordPersonalHistoryBto', 'nephropathy']}
              valueType="custom"
            >
              <Radiostr dataKey={['nephropathy', 'nephropathyDetail']} />
            </VSFormItem>
            <VSFormItem
              label="有无其他重大疾病史"
              name={['recordPersonalHistoryBto', 'majorDiseaseHistory']}
              valueType="custom"
            >
              <Radiostr
                dataKey={['majorDiseaseHistory', 'majorDiseaseHistoryDetail']}
              />
            </VSFormItem>

            <VSFormItem
              label="有无传染性疾病史"
              name={['recordPersonalHistoryBto', 'infectiousDiseaseHistory']}
              valueType="custom"
            >
              <Radiostr
                dataKey={[
                  'infectiousDiseaseHistory',
                  'infectiousDiseaseHistoryDetail',
                ]}
              />
            </VSFormItem>
            <VSFormItem
              label="有无手术史"
              name={['recordPersonalHistoryBto', 'operationHistory']}
              valueType="custom"
            >
              <Radiostr
                dataKey={['operationHistory', 'operationHistoryDetail']}
              />
            </VSFormItem>

            <VSFormItem
              label="有无输血史"
              name={['recordPersonalHistoryBto', 'transfusionHistory']}
              valueType="custom"
            >
              <Radiostr
                dataKey={['transfusionHistory', 'transfusionHistoryDetail']}
              />
            </VSFormItem>
            <VSFormItem
              label="有无妇科手术史"
              name={['recordPersonalHistoryBto', 'gynaecologyOperationHistory']}
              valueType="custom"
            >
              <Radiostr
                dataKey={[
                  'gynaecologyOperationHistory',
                  'gynaecologyOperationHistoryDetail',
                ]}
              />
            </VSFormItem>
            <VSFormItem
              label="有无食物过敏史"
              name={['recordPersonalHistoryBto', 'foodAllergyHistory']}
              valueType="custom"
            >
              <Radiostr
                dataKey={['foodAllergyHistory', 'foodAllergyHistoryDetail']}
              />
            </VSFormItem>
            <VSFormItem
              label="有无药物过敏史"
              name={['recordPersonalHistoryBto', 'drugAllergyHistory']}
              valueType="custom"
            >
              <Radiostr
                dataKey={['drugAllergyHistory', 'drugAllergyHistoryDetail']}
              />
            </VSFormItem>

            <VSFormItem
              label="有无早孕期服药史"
              name={[
                'recordPersonalHistoryBto',
                'drugHistoryDuringEarlyPregnancy',
              ]}
              valueType="custom"
            >
              <Radiostr
                dataKey={[
                  'drugHistoryDuringEarlyPregnancy',
                  'drugHistoryDuringEarlyPregnancyDetail',
                ]}
              />
            </VSFormItem>
            <VSFormItem
              label="有无外伤史"
              name={['recordPersonalHistoryBto', 'traumaticHistory']}
              valueType="custom"
            >
              <Radiostr
                dataKey={['traumaticHistory', 'traumaticHistoryDetail']}
              />
            </VSFormItem>
            <VSFormItem
              label="其他"
              name={['recordPersonalHistoryBto', 'otherPastHistoryDetail']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
          </VsfFormLayout>
        </VSForm>
      </div>
    </Modal>
  );
};

export default definePage(Index);

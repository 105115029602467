import React, { useEffect, useState } from 'react';
import { RadioGroup, Input, Select } from '@vs/vsf-kit';
import '../index.less';

interface RadioStrType {
  value: string;
  onChange: (value: string | null) => void;
  dataSource?: any;
}

interface KaryotypeValue {
  value1?: string; // 分析标本
  value2?: string; // 分析结果
}

export const KaryotypeExamination = (props: RadioStrType) => {
  const { value, onChange, dataSource } = props;
  const [radioValue, setRadioValue] = useState(undefined);
  const [otherValue, setOtherValue] = useState({
    value1: '',
    value2: '',
  });

  useEffect(() => {
    let formatValue: any = {};
    try {
      formatValue = JSON.parse(value);
      setRadioValue(formatValue?.value);
      setOtherValue(
        formatValue?.otherInfo || {
          value1: '',
          value2: '',
        },
      );
    } catch {}
  }, [value]);

  const handleChange = () => {
    const value = JSON.stringify({
      value: radioValue,
      otherInfo: otherValue,
    });
    onChange && onChange(radioValue ? value : null);
  };

  const handleRadioChange = v => {
    setRadioValue(v);
  };
  const handleSelectChange = (v, target) => {
    setOtherValue({
      ...otherValue,
      [target]: v,
    });
  };

  return (
    <div
      className={`e-medical-assistant ${
        radioValue === '1' || radioValue === '2'
          ? 'e-medical-assistant-vertical'
          : ''
      }`}
    >
      <RadioGroup
        value={radioValue}
        onChange={handleRadioChange}
        dataSource={[
          { label: '未查', value: '0' },
          { label: '已查', value: '1' },
        ]}
        onBlur={handleChange}
      ></RadioGroup>
      {radioValue === '1' && (
        <div className="e-medical-assistant-outline">
          <div className="e-medical-assistant-formitem-block">
            <div>
              <div className="label">分析标本：</div>
              <Select
                value={otherValue?.value1 || undefined}
                onChange={v => handleSelectChange(v, 'value1')}
                dataSource={dataSource?.value1 || []}
                placeholder="请选择"
                onBlur={handleChange}
              />
            </div>
            <div>
              <div className="label">分析结果：</div>
              <Select
                value={otherValue?.value2 || undefined}
                onChange={v => handleSelectChange(v, 'value2')}
                dataSource={dataSource?.value2 || []}
                placeholder="请选择"
                onBlur={handleChange}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

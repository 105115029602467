import { SettingOutlined } from '@vs/vsf-icons';
import { Menu, Switch, Popover, Image, Dropdown, Button } from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import storage from '@/utils/storage';
import { globalPageType, ROOT_ROUTER } from '@/config';
import Settings from './settings';
import LogoImage from '@/static/header-logo.png';
import Avatar from '@/static/avatar.png';
import Icon from '@/components/icon';

import './index.less';
const items = [
  {
    label: '首页',
    key: 'index',
    path: '/',
  },
  {
    label: '孕产保健',
    key: 'record',
    path: '/record/archives/info',
  },
];

const itemsNurse = [
  {
    label: '首页',
    key: 'nurse',
    path: '/nurse',
  },
  {
    label: '孕产保健',
    key: 'record',
    path: '/record/archives/info',
  },
];

const syncItems = [
  {
    label: '复检记录',
    key: 'sync',
    path: '/sync',
  },
];

const App = props => {
  const { common, nurse } = vsf?.stores;
  const [current, setCurrent] = useState<string>('');
  const [formatItems, setFormatItems] = useState(items);

  const onClick = e => {
    // // setCurrent(e.key);
    const path = e.item?.props?.path;

    vsf.navigatorTo(
      path === '/patient' || path === '/sync'
        ? `${e.item?.props?.path}`
        : `${e.item?.props?.path}${window.location.search}`,
    );
  };

  const handleDisabledItems = items => {
    return items.map(item => ({
      ...item,
      disabled:
        ['nurse', 'record'].includes(item.key) &&
        !common?.hisId &&
        !common?.hisRecordId,
    }));
  };

  const logout = async () => {
    await nurse?.logout();
    if (window.location.pathname.includes('sync')) {
      vsf?.navigateTo(`/syncLogin`);
    } else {
      vsf?.navigateTo(`/nurseLogin`);
    }
  };

  const handleReload = async () => {
    window.location.reload();
  };

  useEffect(() => {
    const currentKey = window.location.pathname.split('/').filter(v => v)[0];
    setCurrent(currentKey || 'index');
  }, [window.location.pathname]);

  useEffect(() => {
    vsf?.setStoreValue('common.menuKey', current);
  }, [current]);

  useEffect(() => {
    if (window.location.pathname.includes('sync')) {
      setFormatItems(handleDisabledItems(syncItems));
    } else {
      if (common?.globalPageType === globalPageType.NURSE) {
        setFormatItems(
          handleDisabledItems(
            [
              {
                label: '患者列表',
                key: 'patient',
                path: '/patient',
              },
            ].concat(itemsNurse),
          ),
        );
      } else {
        setFormatItems(handleDisabledItems(items));
      }
    }
  }, [common?.globalPageType, common?.hisId, common?.hisRecordId]);

  // useEffect(() => {
  //   setFormatItems(handleDisabledItems(formatItems));
  // }, [common?.hisId, common?.hisRecordId]);
  const userItems = [
    {
      key: 'logout',
      label: (
        <div className="user-dropdown-item" onClick={logout}>
          登出
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="vsf-layout-header-main">
        <div className="logo">
          <div className="logo-img">
            <Image src={LogoImage} preview={false} />
          </div>
        </div>
        <Menu
          className="vsf-layout-header-menu"
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={formatItems}
        />
      </div>
      <div className="vsf-layout-header-right">
        <div onClick={handleReload}>
          <Icon
            type="icon-shuaxin"
            style={{
              fontSize: 20,
            }}
          />
        </div>
        <Dropdown
          menu={{ items: userItems }}
          placement="bottomLeft"
          className="header-user-dropdown"
        >
          <div className="user-container">
            <div className="avatar-img">
              <Image src={Avatar} preview={false} />
            </div>
            <div className="label">
              <span>管理员</span>
            </div>
          </div>
        </Dropdown>

        {/* <Popover
          placement="bottomRight"
          title="系统设置"
          content={<Settings />}
          trigger="hover"
        >
          <div className="vs-settings-container">
            <SettingOutlined
              style={{
                fontSize: 18,
              }}
            />
          </div>
        </Popover> */}
      </div>
    </React.Fragment>
  );
};

export default definePage(App);

import React from 'react';
import { VSTable, VSTableColumn } from '@vs/vsf-kit';
import { clearText } from '@/pages/record/pages/antenatal/utils';
import './index.less';

const Index = (props: { data: any }) => {
  const { data = {} } = props;

  const handleParseString = v => {
    let value: any;
    try {
      value = JSON.parse(v);
      console.log(value, 'value');
    } catch {
      value = v;
    }
    return value;
  };

  const handleJSON = v => {
    let value: any;
    try {
      value = JSON.parse(v);
      if (value && value?.length > 0) {
        value = value.map(v => {
          return JSON.parse(v);
        });
      }
    } catch {
      value = v;
    }
    return value;
  };
  const outpatientInspections = handleParseString(data?.outpatientInspections);
  const outpatientTreatment = handleParseString(data?.outpatientTreatment);
  const applyForm = handleParseString(data?.applyForm);
  const chineseMedicine = handleJSON(data?.chineseMedicine);
  const westernMedicine = handleJSON(data?.westernMedicine);
  const otherInspections = handleParseString(data?.otherInspections);

  console.log(chineseMedicine, westernMedicine, '====');

  return (
    <div className="e-medical-draw">
      <div className="block">
        <div className="title">其他检查</div>
        <div className="container">
          {/* {(outpatientInspections || []).map(item => (
              <div className="check-block word-thin">
                <span>[</span>
                <span>{item.checkDate}</span>
                <span>{item.checkName}</span>
                <span>{item.checkResult}</span>
                <span>]</span>
              </div>
            ))} */}
          {otherInspections && (
            <div className="check-block word-thin">
              <span>{clearText(otherInspections)}</span>
            </div>
          )}
        </div>
      </div>
      <div className="block">
        <div className="title">诊疗意见</div>
        <div className="container">
          <div className="inline-container">
            <div className="second-title">申请单</div>
            {applyForm ? (
              <div className="inline-block">
                {(applyForm || []).map(item => (
                  <div className="check-block word-thin">
                    <span>[</span>
                    <span>{item.requisitionDate}</span>
                    <span>{item.requisitionName}</span>
                    <span>]</span>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <div
                  className="check-block word-thin"
                  style={{
                    height: 22,
                  }}
                ></div>
              </div>
            )}
          </div>

          <div className="inline-container">
            <div className="second-title">门诊处方</div>
            {westernMedicine?.prescriptionStatus !== 'DELETE' && (
              <div className="inline-block">
                <div className="chinese-medicine">
                  <div className="title">西药</div>
                  {(westernMedicine || [])?.map((v, i) => {
                    return (
                      <div className="content">
                        {westernMedicine.length > 1 && (
                          <div className="content__title">处方{i + 1}</div>
                        )}
                        <VSTable
                          dataType="static"
                          dataSource={v?.westernMedicine || []}
                          className="e-draw-medical-table"
                        >
                          <VSTableColumn
                            title="类型"
                            valueType="text"
                            dataIndex="prescriptionType"
                            columnKey="prescriptionType"
                            width={100}
                            key="prescriptionType"
                            render={(v, record) => {
                              switch (record?.prescriptionType) {
                                case '1':
                                  return '西药';
                                case '2':
                                  return '成药';
                                case '3':
                                  return '草药';
                                default:
                                  return '';
                              }
                            }}
                          ></VSTableColumn>
                          <VSTableColumn
                            title="药品名称"
                            valueType="text"
                            dataIndex="medicineName"
                            columnKey="medicineName"
                            key="medicineName"
                          ></VSTableColumn>
                          <VSTableColumn
                            title="剂量"
                            valueType="text"
                            dataIndex="medicineAmount"
                            columnKey="medicineAmount"
                            key="medicineAmount"
                          ></VSTableColumn>
                          <VSTableColumn
                            title="频次"
                            valueType="text"
                            dataIndex="medicineRepeatPattern"
                            columnKey="medicineRepeatPattern"
                            key="medicineRepeatPattern"
                          ></VSTableColumn>
                          <VSTableColumn
                            title="给药方式"
                            valueType="text"
                            dataIndex="medicineUseMethod"
                            columnKey="medicineUseMethod"
                            key="medicineUseMethod"
                          ></VSTableColumn>
                          <VSTableColumn
                            title="用药天数"
                            valueType="text"
                            dataIndex="medicineDuration"
                            columnKey="medicineDuration"
                            key="medicineDuration"
                          ></VSTableColumn>
                          <VSTableColumn
                            title="数量"
                            valueType="text"
                            dataIndex="medicineQuantity"
                            columnKey="medicineQuantity"
                            key="medicineQuantity"
                          ></VSTableColumn>
                          <VSTableColumn
                            title="单位"
                            valueType="text"
                            dataIndex="medicinePriority"
                            columnKey="medicinePriority"
                            key="medicinePriority"
                          ></VSTableColumn>
                        </VSTable>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="inline-block">
              <div className="western-medicine">
                <div className="title">中药</div>
                {(chineseMedicine || []).map((v,i) => {
                  if (v?.prescriptionStatus !== 'DELETE') {
                    return (
                      <div className="content word-content">
                        <div>{chineseMedicine.length >1 && <div className='word-content__title'>{`中药方${i+1}`}</div>}</div>
                          <div className="chinese-item">
                            <div className="header">
                              <div className="label">
                                <div className="bold">
                                  <span>
                                    {v?.prescriptionName}
                                  </span>
                                  <span>[草药]</span>
                                </div>
                                <div className="desc word-thin">
                                  <span>
                                    {
                                      v?.chineseMedicine
                                        ?.prescriptionUseMethod
                                    }
                                  </span>
                                  <span>
                                    {
                                      v?.chineseMedicine
                                        ?.herbsRepeatPattern
                                    }
                                  </span>
                                </div>
                              </div>
                              {v?.chineseMedicine
                                ?.prescriptionQuantity && (
                                <div className="ps">
                                  <span>
                                    {
                                      v?.chineseMedicine
                                        ?.prescriptionQuantity
                                    }
                                    贴
                                  </span>
                                  <span>每次1贴</span>
                                </div>
                              )}
                            </div>
                            <div className="section">
                              {(
                                v?.chineseMedicine?.herbsList ||
                                []
                              ).map(citem => {
                                return (
                                  <div>
                                    {/* <span>[{citem?.herbherbsBatchNumber}]</span> */}
                                    <span className="name">
                                      {citem?.herbsName}
                                    </span>
                                    <span>（{citem?.herbsUseMethod}）</span>
                                    <span>{citem?.herbsAmount}</span>
                                    <span>{citem?.herbsAmountPriority}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                      </div>
                    );
                  } else {
                    return <div></div>;
                  }
                })}
              </div>
            </div>
          </div>
          <div className="inline-container">
            <div className="second-title">门诊处置</div>
            <div className="inline-block">
              {/* {(outpatientTreatment?.exam || []).map(item => (
                  <div className="check-block word-thin">
                    <span>[</span>
                    <span>{item.date}</span>
                    <span>{item.name}</span>
                    <span>]</span>
                  </div>
                ))} */}
              {outpatientInspections && (
                <div className="inline-block-div">
                  <div>检验:</div>
                  <div className="check-block word-thin">
                    <span>[</span>
                    <span>{outpatientInspections}</span>
                    <span>]</span>
                  </div>
                </div>
              )}
              {outpatientTreatment && (
                <div className="inline-block-div">
                  <div>检查:</div>
                  <div className="check-block word-thin">
                    <span>[</span>
                    <span>{outpatientTreatment}</span>
                    <span>]</span>
                  </div>
                </div>
              )}

              {/* {(outpatientTreatment?.labTest || []).map(item => (
                  <div className="check-block word-thin">
                    <span>[</span>
                    <span>{item.date}</span>
                    <span>{item.name}</span>
                    <span>]</span>
                  </div>
                ))} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;

import React, { useEffect, useState, useRef } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  VSTable,
  VSTableToolbar,
  VSTableColumn,
  VSTablePagination,
  VSTableAction,
  VSEditableTableAddition,
  message,
  Modal,
  Checkbox,
} from '@vs/vsf-kit';
import antenatalServices from '@/services/antenatal';
import { useSize } from 'ahooks';
import ReturnModal from './modal';
import FirstModal from '../first';
import {
  amyItems,
  assistantItems,
  ultrasoundItems,
  ecgItems,
} from '@/config/assistant';
import HighriskTag from '@/components/highrisk/tag';

const Index = props => {
  const { common, medical } = props?.stores;
  const [dataSource, setDataSource] = useState([]);
  const [visibleRecord, setVisibleRecord] = useState(false);
  const [visibleFirst, setVisibleFirst] = useState(false);
  const [importAssistantData, setImportAssistantData] = useState({});
  const [imporAmytData, setImportAmyData] = useState({});
  const [currentData, setCurrentData] = useState({});
  const [checkboxList, setCheckboxList] = useState([]);

  const ref = useRef(null);
  const size = useSize(ref);

  const onReturnCancel = () => {
    setVisibleRecord(false);
  };
  const onReturnOk = () => {
    setVisibleRecord(false);
  };
  const onFirstCancel = () => {
    setVisibleFirst(false);
  };
  const onFirstOk = () => {
    setVisibleFirst(false);
  };

  const classifyImportData = data => {
    const res = {
      examination: [],
      inspection: [],
    };
    const amy = {
      examination: [],
      inspection: [],
    };
    if (!data || !Array.isArray(data)) return res;
    data.forEach(item => {
      if (amyItems.includes(item?.checkItemId)) {
        if (item?.examineType === 'INSPECTION') {
          amy.inspection.push(item);
        }
      }
      if (assistantItems.includes(item?.checkItemId)) {
        if (item?.examineType === 'INSPECTION') {
          res.inspection.push(item);
        }
      }
      if (ultrasoundItems.includes(item?.checkItemId)) {
        if (item?.examineType === 'EXAMINATION') {
          res.examination.push(item);
        }
      }
      if (ecgItems.includes(item?.checkItemId)) {
        if (item?.examineType === 'EXAMINATION') {
          res.examination.push(item);
        }
      }
    });
    setImportAssistantData(res);
    setImportAmyData(amy);
  };

  const init = async () => {
    const hisExaminationData = await antenatalServices.getHisExaminationWithAssistant(
      {
        patientId: vsf?.stores?.common.hisId,
      },
    );
    classifyImportData(hisExaminationData?.data);
  };

  const getDataDetail = async data => {
    const { medicalRecordId } = data;
    if (data?.firstRecord) {
      const firstDetail = await antenatalServices.getFirstInspectionsRecordDetail(
        {
          medicalRecordId,
        },
      );
      setCurrentData(firstDetail.data);
      setVisibleFirst(true);
    } else {
      const returnDetail = await antenatalServices.getReturnInspectionsRecordDetail(
        {
          medicalRecordId,
        },
      );
      setCurrentData(returnDetail.data);
      setVisibleRecord(true);
    }
  };

  useEffect(() => {
    if (common?.hisId) {
      init();
    }
  }, [common?.hisId]);

  useEffect(() => {
    medical?.setPrintData(checkboxList);
  }, [checkboxList?.length]);

  const pagination = {
    showQuickJumper: true,
    showSizeChanger: true,
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSizeOptions: [10, 20, 30, 50, 100],
  };

  const handleChangeCheck = (e, v, record) => {
    if (e) {
      setCheckboxList([...checkboxList, record]);
    } else {
      setCheckboxList(
        checkboxList?.filter(
          v => v?.medicalRecordId !== record?.medicalRecordId,
        ),
      );
    }
  };

  const renderCheck = (checkList, value) => {
    return !!checkList?.find(v => {
      return v?.medicalRecordId === value?.medicalRecordId;
    });
  };

  const rendercheckbox = (v, record) => {
    return (
      <div>
        <Checkbox
          value={renderCheck(checkboxList, record)}
          onChange={e => {
            handleChangeCheck(e, v, record);
          }}
        ></Checkbox>
      </div>
    );
  };

  return (
    <div className="e-medical-record-detail-container">
      <div className="detail-content" ref={ref}>
        <VSTable
          className="city-screening-table"
          dataType="request"
          dataLoad={{
            request: antenatalServices.getReturnInspectionsRecordList,
            qtoParams: {
              gravidaRecordIdIs: props?.stores?.common.gravidaRecordId,
            },
            extParams: {},
          }}
          scroll={{ x: 1300, y: size?.height - 168 }}
        >
          <VSTableColumn
            title=""
            valueType="text"
            dataIndex="followupTime"
            width={40}
            fixed="left"
            render={rendercheckbox}
          ></VSTableColumn>
          <VSTableColumn
            title="检查日期"
            valueType="text"
            dataIndex="followupTime"
            width={150}
            fixed="left"
          ></VSTableColumn>
          <VSTableColumn
            title="孕周"
            valueType="text"
            dataIndex="gestationalWeek"
            width={150}
            fixed="left"
            render={(v, record) => {
              return Number.isFinite(record?.gestationalWeek)
                ? `${Math.floor(
                    Math.floor(record?.gestationalWeek) / 7,
                  )}周${Math.floor(record?.gestationalWeek) % 7}天`
                : '-';
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="类型"
            valueType="text"
            dataIndex="firstRecord"
            width={50}
            fixed="left"
            render={(v, record) => {
              return record?.firstRecord ? '初检' : '复检';
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="高危级别"
            valueType="text"
            dataIndex="highRiskAssessment"
            width={150}
            fixed="left"
            render={(v, record) => {
              return <HighriskTag value={record?.highRiskAssessment} />;
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="体重（kg）"
            valueType="text"
            dataIndex="weight"
          ></VSTableColumn>
          <VSTableColumn
            title="BMI"
            valueType="text"
            dataIndex="screeningProgram"
          ></VSTableColumn>
          <VSTableColumn
            title="体温（°C）"
            valueType="text"
            dataIndex="temperature"
          ></VSTableColumn>
          <VSTableColumn
            title="血压（mmgh）"
            valueType="date"
            dataIndex="pressure"
            render={(v, record) => {
              return record?.systolicPressure && record?.diastolicPressure
                ? `${record?.systolicPressure}-${record?.diastolicPressure}`
                : '-';
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="宫高（cm）"
            valueType="text"
            dataIndex="fuh"
          ></VSTableColumn>
          <VSTableColumn
            title="腹围（cm）"
            valueType="text"
            dataIndex="abdominalCircumference"
          ></VSTableColumn>
          <VSTableColumn
            title="胎方位"
            valueType="text"
            dataIndex="fetusPosition"
          ></VSTableColumn>
          <VSTableColumn
            title="胎先露"
            valueType="text"
            dataIndex="presentation"
          ></VSTableColumn>
          <VSTableColumn
            title="衔接"
            valueType="text"
            dataIndex="engagement"
          ></VSTableColumn>
          <VSTableColumn
            title="浮肿"
            valueType="text"
            dataIndex="edema"
          ></VSTableColumn>
          <VSTableColumn
            title="尿蛋白"
            valueType="text"
            dataIndex="urineProtein"
          ></VSTableColumn>
          <VSTableColumn
            title="血红蛋白"
            valueType="text"
            dataIndex="hemoglobinValue"
          ></VSTableColumn>
          <VSTableColumn
            title="操作"
            valueType="option"
            dataIndex="operation"
            width={150}
            fixed="right"
          >
            <VSTableAction
              onClick={(_, data, __, action) => {
                getDataDetail(data);
              }}
            >
              编辑
            </VSTableAction>
            {/* <VSTableAction
              onClick={(_, data, __, action) => {
                Modal.confirm({
                  title: '操作提醒',
                  content: '确定作废该条产检记录吗？',
                  onOk() {
                    console.log('OK');
                  },
                  onCancel() {
                    console.log('Cancel');
                  },
                });
              }}
            >
              作废
            </VSTableAction> */}
          </VSTableColumn>

          <VSTablePagination {...pagination} />
        </VSTable>
        <ReturnModal
          key={currentData?.id || ''}
          // key={currentData?.medicalRecordId || ''}
          visible={visibleRecord}
          onCancel={onReturnCancel}
          onOk={onReturnOk}
          importData={{
            assistant: importAssistantData,
            amy: imporAmytData,
          }}
          data={currentData}
        />
        <FirstModal
          key={currentData?.id || ''}
          // key={currentData?.medicalRecordId || ''}
          visible={visibleFirst}
          onCancel={onFirstCancel}
          onOk={onFirstOk}
          importData={{
            assistant: importAssistantData,
            amy: imporAmytData,
          }}
          data={currentData}
        />
      </div>
    </div>
  );
};

export default definePage(Index);

import React, { useEffect, useState } from 'react';
import { InputNumber, RadioGroup, TextArea, Select } from '@vs/vsf-kit';

import './index.less';

const Index = props => {
  const { value, onChange } = props;
  const [time, setTime] = useState();
  const [returnVisitPeriodUnit , SetReturnVisitPeriodUnit] = useState('周')
  const [returnVisitPeriod  , SetReturnVisitPeriod] = useState('')
  const [advice, setAdvice] = useState();
  const [comment, setComment] = useState(
    '',
  );

  const fixWord = `[定期产检，自数胎动，胎动异常、腹痛、阴道流血流液急诊就诊。及时参加孕妇学校线上、线下学习及考试。]`;

  useEffect(() => {
    if (value) { 
      try {
        const formatValue = JSON.parse(value);
        console.log(formatValue,'formatValue222222222')
        setTime(formatValue?.time);
        SetReturnVisitPeriodUnit(formatValue?.returnVisitPeriodUnit);
        SetReturnVisitPeriod(formatValue?.returnVisitPeriod);
        setAdvice(formatValue?.advice);
        setComment(formatValue?.comment ? formatValue?.comment.replace(fixWord, '') : '');
      } catch { 

      }
    }
  }, [value,returnVisitPeriodUnit]);

  const handleOnchange = () => {
    onChange &&
      onChange(
        JSON.stringify({
          time,
          advice,
          comment: `${fixWord}${comment}`,
          returnVisitPeriodUnit,
          returnVisitPeriod
        }),
      );
  };

  const handleInputChange = v => {
    setTime(v);
    SetReturnVisitPeriod(v)
  };
  const handleRadioChange = v => {
    setAdvice(v);

  };
  const handleTextChange = e => {
    setComment(e.target.value);
  };
  const selectChange = (e)=>{
    SetReturnVisitPeriodUnit(e)
  }
  const selectAfter = (
    <Select
      defaultValue={returnVisitPeriodUnit}
      style={{ width: 60 }}
      value={returnVisitPeriodUnit}
      dataSource={[
        {
          label: '周',
          value: '周',
        },
        {
          label: '天',
          value: '天',
        },
      ]}
      
      onChange={selectChange}
      onBlur={handleOnchange}
    ></Select>
  );
  
  return (
    <div className="e-medical-other-advice">
      <div className="first">
        <span className="label">其他：</span>
        <span className="word">建议</span>
        <span>
          <InputNumber
            addonAfter={selectAfter}
            min={0}
            value={time}
            onChange={handleInputChange}
            onBlur={handleOnchange}
            controls={false}
            placeholder="请输入"
          />
        </span>
        <span>后复诊</span>
        <span>
          <RadioGroup
            value={advice}
            onChange={handleRadioChange}
            dataSource={[
              { label: '其他', value: '其他' },
              { label: '住院', value: '住院' },
              { label: '急诊住院', value: '急诊住院' },
            ]}
            onBlur={handleOnchange}
          ></RadioGroup>
        </span>
      </div>
      <div className="second">
        <span>{fixWord}</span>
        <TextArea
          autoSize
          value={comment}
          onChange={handleTextChange}
          onBlur={handleOnchange}
        />
      </div>
    </div>
  );
};

export default Index;

import { createServices } from "@vs/service";
import { getExaminationFormat } from "@/pages/record/pages/archives/examination/format";

export default createServices({
  getGravidaInfo: {
    method: "post",
    url: "/api/record/getGravidaInfo",
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: GravidaRecordManagerDtoGravidaRecordForHealthcareScreeningDto
    ) => data,
  },
  getExamination: {
    method: "post",
    url: "/api/record/getExamination",
    parameterFomatter: (data?: {
      qto: MedicalRecordServiceQtoSearchMedicalRecordInListQto,
      ext: any,
    }) => getExaminationFormat(data),
    responseFormatter: (
      _,
      __,
      data?: {
        count: number,
        result: MedicalRecordManagerDtoMedicalRecordForSearchDto[],
        from: number,
        size: number,
        scrollId: string,
      }
    ) => data,
  },
});

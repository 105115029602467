import { defineStore } from '@vs/vsf-boot';
import storage from '@/utils/storage';
import { STORAGE_KEY_MAP } from '@/config';
import commonServices from '@/services/common';
import staticCommonService from '@/module/common/services';
import medicalService from '@/services/medical';
import { formatEnums, getGestationalWeek, setValueAndReturn } from '@/utils';
import queryString from 'query-string';
import moment from 'moment';
import gravida from './gravida';
import enums from '../../public/enums.json';
import address from '../../public/address.json';
import diagnosis from '../../public/diagnosis.json';
import nurse from './nurse';

const formatAddressData = list => {
    const recursion = (data, prefix) => {
        return data.map(item => {
            const newValue = prefix
                ? `${prefix}-${item.shortName}`
                : item.shortName;
            return {
                label: item.shortName,
                value: newValue,
                children: recursion(item.childList || item.leafList || [], newValue),
            };
        });
    };
    return list && Array.isArray(list) ? recursion(list, '') : [];
};

const formatDoctor = list => {
    const doctor: any = [];
    const organization: any = [];
    list.forEach(item => {
        const target = organization?.find((oitem: any) => oitem?.value === item?.orgId);
        if (!target) {
            organization.push({
                label: item?.orgName,
                value: item?.orgId,
                origin: item,
            })
        }
        doctor.push({
            label: item?.doctorName,
            value: item?.doctorId,
            origin: item,
        })
    });
    return {
        doctor,
        organization
    };
};

const common = defineStore({
    windowSearch: queryString.parse(window?.location?.search),
    // globalPageType
    // globalPageType: storage.get('e-medical-globalPageType') || globalPageType.NURSE,
    globalPageType: storage.get(STORAGE_KEY_MAP.globalPageType),
    // gravidaRecordId
    gravidaRecordId: 0,
    // hisId
    // hisId: storage.get('e-medical-hisId') || '1665232434797',
    hisId: storage.get(STORAGE_KEY_MAP.hisId) || '',
    // hisRecordId
    // hisRecordId: storage.get('e-medical-hisRecordId') || 'hr1665232434797',
    hisRecordId: storage.get(STORAGE_KEY_MAP.hisRecordId) || '',
    // doctorId
    // doctorId: 'd123456',
    doctorId: storage.get(STORAGE_KEY_MAP.doctorId) || nurse?.user?.id || '',
    history: storage.get(STORAGE_KEY_MAP.history) || '',
    // enums
    enums: [],
    // highriskenums
    highriskenums: {
        baseInfo: [],
        complication: [],
        infectious: [],
    },
    // doctorInfo
    doctorInfo: null,
    // hisdoctorInfo
    hisDoctorInfo: null,
    // allergyDictionary
    allergyDictionary: null,
    // complaint modal data
    complaintModalData: null,
    // diagnosis modal data
    diagnosisModalData: null,
    // diagnosis select data
    diagnosisSelectData: [],
    // complaint recommand data
    complaintRecommandData: null,
    // diagnosis recommand data
    diagnosisRecommandData: null,
    // recommandExamAndDrug data
    recommandExamAndDrug: null,
    // addressData
    addressData: [],
    // first flag
    isFirstMedical: true,
    // show medical form flag
    showMedical: false,
    // show left form flag
    showLeft: true,
    // print info
    printInfo: {
        key: 0
    },
    // 顶部菜单当前项
    menuKey: '',
    // 弹窗只出现在根目录, 其他页面需要禁止展示
    canShowModal: true,

    getDoctorInfo: (ids) => {
        staticCommonService.doctorInfo({
            doctorId: ids || [common.doctorId],
        }).then(res => {
            if (res?.code === 200) {
                common.doctorInfo = res.data;
            }
        }).catch(err => {
            common.doctorInfo = {};
        });
    },

    initBasic: async (defaultValue: any = {}) => {
        const currentWindowSearch = queryString.parse(window?.location?.search);
        common.globalPageType = setValueAndReturn(STORAGE_KEY_MAP.globalPageType, defaultValue?.globalPageType ?? currentWindowSearch?.type);
        common.hisId = setValueAndReturn(STORAGE_KEY_MAP.hisId, defaultValue?.hisId ?? currentWindowSearch?.hisId);
        common.hisRecordId = setValueAndReturn(STORAGE_KEY_MAP.hisRecordId, defaultValue?.hisRecordId ?? currentWindowSearch?.hisRecordId);
        common.doctorId = setValueAndReturn(STORAGE_KEY_MAP.doctorId, defaultValue?.doctorId ?? currentWindowSearch?.doctorId);
        common.history = setValueAndReturn(STORAGE_KEY_MAP.history, defaultValue?.history ? defaultValue?.history : (currentWindowSearch?.history ?? 0));

        const newEnums: any = formatEnums(enums || []);
        common.enums = newEnums;

        common.addressData = formatAddressData(address);

        common.diagnosisSelectData = (diagnosis as any[] || [])?.map(item => ({
            label: item?.name,
            value: item?.name,
            // value: `${item?.pinyin}-${item?.pinyinInitial}-${item?.name}`
            // keywords: [item?.pinyin, item?.pinyinInitial, item?.name]
            keywords: `${item?.pinyin}-${item?.pinyinInitial}-${item?.name}`
        }));

        if (newEnums?.highrisk_info) {
            common.highriskenums = {
                BASIC_INFO: newEnums?.highrisk_info?.[0]?.children || [],
                PREGNANCY_COMORBIDITY: newEnums?.highrisk_info?.[1]?.children || [],
                PREGNANCY_COMPLICATION: newEnums?.highrisk_info?.[2]?.children || [],
            };
        }

        staticCommonService.getAllergyDictionary().then(res => {
            if (res?.code === 200) {
                common.allergyDictionary = res.data;
            }
        }).catch(err => {
            common.allergyDictionary = [];
        });
    },

    handlePrint: async () => {
        const { hisRecordId, hisId } = common;
        const parentId = hisId;
        // const checkEpidemiological = await staticCommonService.getCheckEpidemiological(
        //     {
        //         hisRecordId,
        //         parentId,
        //     },
        // );
        const medicalPrint = await medicalService.getMedicalPrint({
            hisRecordId,
        });
        // const { data: checkEpidemiologicalData } = checkEpidemiological;
        const { data: medicalPrintData } = medicalPrint;
        const obj = {
            // allEpidemiologicalData: common.enums['epidemiological_history'],
            // checkEpidemiologicalData: checkEpidemiologicalData || {},
            medicalPrintData: medicalPrintData || {},
        };

        common.printInfo = {
            ...obj,
            key: common?.printInfo?.key + 1
        }
    },
    resetModal: () => {
        gravida.recordSure = false;
        gravida.createRecordVisible = false;
        gravida.pregnancyVisiblepregnancyVisible = false;
        gravida.allergyVisible = false;
    },
    getHisDoctorList: async () => {
        if (!common?.hisDoctorInfo) {
            // const date = moment(new Date()).format('YYYYMMDD');
            const res = await staticCommonService.getHisDoctorList({
                version: true
            })
            const formatData = formatDoctor(res?.data || []);
            common.hisDoctorInfo = formatData;
            return formatData;
        } else {
            return common?.hisDoctorInfo;
        }
    }
});

export default common;

import React, { useEffect, useState } from 'react';
import { ConfigProvider, Layout } from '@vs/vsf-kit';
import vsf, { definePage, renderRoutes } from '@vs/vsf-boot';
import queryString from 'query-string';
import Header from './header';
import Left from './left';
import Footer from './footer';
import Allergy from '@/pages/allergy/modal';
import Pregnancy from '@/pages/record/pages/archives/components/pregnancy-modal';
import Highrisk from '@/pages/highrisk';
import HighriskFormModal from '@/pages/highrisk/modal';

import { globalPageType } from '@/config';

import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import './index.less';

const { Sider } = Layout as any;

const Container: any = props => {
  const { gravida, common, nurse } = vsf?.stores;
  const { hisId, hisRecordId, doctorId } = common;
  const { route } = props;

  const [showLeftFooter, setShowLeftFooter] = useState(false);

  const initBasic = async (defaultValue = {}) => {
    await common.initBasic(defaultValue);
  };

  const init = async () => {
    await gravida.init();
  };

  useEffect(() => {
    if (common.history && common.history == 1) {
      vsf?.navigateTo(`/medical${window.location.search}`);
    }
  }, [common.history, common.globalPageType]);

  useEffect(() => {
    if (
      window.location.pathname?.indexOf('/patient') >= 0 ||
      window.location.pathname?.indexOf('/sync') >= 0
    ) {
      setShowLeftFooter(false);
      initBasic({
        globalPageType: globalPageType.NURSE,
      });
    } else {
      setShowLeftFooter(true);
      initBasic();
    }
    const search = queryString.parse(window?.location?.search);
    vsf.stores.common.windowSearch = search;
    // if (search?.type == '0' && window.location.pathname?.indexOf('/nurse') >= 0) {
    //   vsf?.navigateTo(`/${window.location.search}`);
    // }
    // if (search?.type == '1' && window.location.pathname?.indexOf('/nurse') < 0) {
    //   vsf?.navigateTo(`/nurse${window.location.search}`);
    // }
  }, [window.location.href]);

  useEffect(() => {
    if (hisId && hisRecordId) {
      init();
    }
  }, [hisId, hisRecordId]);

  useEffect(() => {
    if (doctorId || nurse?.user?.id) {
      const v = doctorId || nurse?.user?.id;
      common.getDoctorInfo([v]);
    }
  }, [doctorId, nurse?.user?.id]);

  return (
    <ConfigProvider locale={locale}>
      <div className="vsf-layout">
        <div className="vsf-layout-header">
          <Header />
          {/* {common?.globalPageType === globalPageType.DOCTOR && <Header />} */}
        </div>
        <div className="vsf-layout-content">
          {showLeftFooter && (
            <Sider
              className="vsf-layout-sider vsf-layout-sider-left"
              theme="light"
              width={340}
            >
              <Left />
            </Sider>
          )}

          <div className="vsf-layout-main">
            <div className="main-block">
              <div className="router">{renderRoutes(route.routes)}</div>
            </div>
            {showLeftFooter && (
              <div className="vsf-layout-footer">
                <Footer />
              </div>
            )}
          </div>
        </div>
        <Allergy />
        <Pregnancy />
        <Highrisk key={gravida?.highriskVisible} />
        <HighriskFormModal key={gravida?.highriskFormVisible} />
      </div>
    </ConfigProvider>
  );
};

export default definePage(Container);

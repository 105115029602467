import 'core-js/es';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import { runApp } from '@vs/vsf-boot';
import routes from '@/router/routes';
import * as stores from '@/stores/index';
import service from './api';

import '@vs/vsf-kit/dist/umd/index.css';
import './style/global.less';
import './style/reset.less';
import './style/vars.less';

runApp({
  boot: {
    mountElement: document.querySelector('#root'),
    qiankun: false,
  },

  stores,

  router: {
    type: 'browser',
    config: routes,
  },
});

import storage from '@/utils/storage';
import { defineStore } from '@vs/vsf-boot';
import medicalService from '@/services/medical';
import staticCommonService from '@/module/common/services';
import common from './common';
import { globalPageType } from '@/config';

const medical = defineStore({
  //折叠 key
  collapseKeys: storage.get('e-medical-collapse-keys') || [],
  // 病历信息
  medicalInfo: {},
  // 非活跃病历信息
  archiveMedicalInfo: {},
  // 病历历史信息
  medicalHistoryList: [],
  // 辅助信息
  assistantInfo: {},
  // 体格信息
  physicalInfo: {},
  // 主诉
  complaint: null,
  // 主诊断
  mainDiagnosis: null,
  // 次诊断
  minorDiagnosis: null,
  // 表单错误
  formError: null,
  // 产检记录打印数据
  printData: [],
  changeCollapse: (value, action) => {
    if (action === 'active') {
      medical.collapseKeys = [...new Set([...medical.collapseKeys, value])];
    } else {
      medical.collapseKeys = [
        ...new Set(medical.collapseKeys.filter(item => item !== value)),
      ];
    }
    storage.set('e-medical-collapse-keys', medical.collapseKeys);
  },

  getMedicalDetail: async id => {
    if (common?.globalPageType === globalPageType.DOCTOR) {
      const res = await staticCommonService.getMedicalDetailWithHisRecordIdDoctor(
        {
          hisRecordId: id,
        },
      );
      if (res.code === 200 && res.data) {
        if (res?.data?.status === 'ACTIVE') {
          medical.medicalInfo = res.data;
        } else {
          medical.archiveMedicalInfo = res.data;
        }
      }
      return res.data;
    } else {
      const res = await medicalService.getMedicalDetailWithHisRecordId({
        hisRecordId: id,
      });
      if (res.code === 200 && res.data) {
        if (res?.data?.status === 'ACTIVE') {
          medical.medicalInfo = res.data;
        } else {
          medical.archiveMedicalInfo = res.data;
        }
      }
      return res.data;
    }
  },

  getMedicalHistoryList: async id => {
    const res = await medicalService.getMedicalList({
      gravidaRecordId: id,
    });
    if (res.code === 200 && res.data) {
      medical.medicalHistoryList = res.data;
    }
    return res.data;
  },
  setPrintData: list => {
    medical.printData = list;
  },
});

export default medical;

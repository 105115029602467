import React, { useEffect, useState } from 'react';
import { RadioGroup, Input, InputNumber } from '@vs/vsf-kit';
import './index.less';

export interface RadioStrType {
  value: string;
  dataKey: string[];
  onChange: (value: any) => void;
}

const Index = (props: RadioStrType) => {
  const { value, dataKey ,onChange } = props;
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [value1State , setValue1State] = useState(false)
  const [value2State , setValue2State] = useState(false)
  

  useEffect(() => {
    if (value) {
      setValue1(value[dataKey?.[0]] || '');
      setValue2(value[dataKey?.[1]] || '');
    }
  }, [value]);

  const handleChange = () => {
    setValue1State(Number(value1) >140 || Number(value1) < 90)
    setValue2State(Number(value2) >90 || Number(value2) < 60)
    onChange &&
      onChange(value1 && value2 ? {
        [dataKey?.[0]]: value1,
        [dataKey?.[1]]: value2,
      }:null);
  };

  const handleValue1Change = v => {
    setValue1(v);
  };
  const handleValue2Change = v => {
    setValue2(v);
  };

  return (
    <div className="e-medical-blood-pressure">
      <InputNumber
        value={value1}
        max={200}
        min={30}
        precision={0}
        onChange={handleValue1Change}
        placeholder="收缩压"
        controls={false}
        onBlur={handleChange}
        style={{color: value1State ? 'red':''}}
      />
      <span>/</span>
      <InputNumber
        value={value2}
        max={200}
        min={30}
        precision={0}
        onChange={handleValue2Change}
        placeholder="舒张压"
        controls={false}
        onBlur={handleChange}
        style={{color: value2State  ? 'red':''}}
      />
    </div>
  );
};

export default Index;

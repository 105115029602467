import React, { useState, useRef } from 'react';
import { definePage } from '@vs/vsf-boot';
import { Button } from '@vs/vsf-kit';
import Record from '@/pages/record/components/record';

const Index = (props) => {
  const [submitKey, setSubmitKey] = useState(0);
  const [loading, setLoading] = useState(false);

  const recordRef = useRef();

  const handleSubmit = () => {
    recordRef && recordRef?.current?.handleOk();
  }
  const handleFormSubmit = () => {
    setLoading(false);
  };
  
    return (
      <div className="e-medical-record-detail-container gravida-record-container">
        <div className="detail-content detail-content-nopadding">
          {/* <Content
            key={props?.stores?.common.gravidaRecordId}
            submitKey={submitKey}
            onSubmit={handleFormSubmit}
          /> */}
          <Record
            key={props?.stores?.common.gravidaRecordId}
            recordRef={recordRef}
            handleFormSubmit={handleFormSubmit}
          />
        </div>
        <div className="e-medical-record-detail-footer">
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            保存
          </Button>
        </div>
      </div>
    );
};

export default definePage(Index);
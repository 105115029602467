import { createServices } from "@vs/service";

export default createServices({
  createFirstInspectionsRecord: {
    method: "post",
    url: "/api/record/createFirstInspectionsRecord",
    parameterFomatter: (data?: {
      btoParam: HealthcareFirstInspectionsRecordServiceBtoCreateFirstInspectionsRecordBto,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  updateFirstInspectionsRecord: {
    method: "post",
    url: "/api/record/updateFirstInspectionsRecord",
    parameterFomatter: (data?: {
      btoParam: HealthcareFirstInspectionsRecordServiceBtoUpdateFirstInspectionsRecordBto,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  getFirstInspectionsRecordList: {
    method: "post",
    url: "/api/record/getFirstInspectionsRecordList",
    parameterFomatter: (data?: { gravidaRecordId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: HealthcareFirstInspectionsRecordManagerDtoFirstInspectionsRecordDto[]
    ) => data,
  },
  createReturnInspectionsRecord: {
    method: "post",
    url: "/api/record/createReturnInspectionsRecord",
    parameterFomatter: (data?: {
      btoParam: HealthcareReturnInspectionsRecordServiceBtoCreateReturnInspectionsRecordBto,
      extApiParam: any,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  updateReturnInspectionsRecord: {
    method: "post",
    url: "/api/record/updateReturnInspectionsRecord",
    parameterFomatter: (data?: {
      btoParam: HealthcareReturnInspectionsRecordServiceBtoUpdateReturnInspectionsRecordBto,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  getReturnInspectionsRecordList: {
    method: "post",
    url: "/api/record/getReturnInspectionsRecordList",
    parameterFomatter: (data?: {
      qto: HealthcareReturnInspectionsRecordServiceQtoReturnInspectionsListQto,
      ext: any,
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number,
        result: HealthcareReturnInspectionsRecordManagerDtoReturnInspectionsRecordDto[],
        from: number,
        size: number,
        scrollId: string,
      }
    ) => data,
  },
  getReturnInspectionsRecordDetail: {
    method: "post",
    url: "/api/record/getReturnInspectionsRecordDetail",
    parameterFomatter: (data?: { medialRecordId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: HealthcareReturnInspectionsRecordManagerDtoReturnInspectionsRecordDto
    ) => data,
  },
  getFirstInspectionsRecordDetail: {
    method: "post",
    url: "/api/record/getFirstInspectionsRecordDetail",
    parameterFomatter: (data?: { medicalRecordId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: HealthcareFirstInspectionsRecordManagerDtoFirstInspectionsRecordDto
    ) => data,
  },
  getHisExaminationWithAssistant: {
    method: "post",
    url: "/api/record/getHisExaminationWithAssistant",
    parameterFomatter: (data?: { patientId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExternalSyncManagerDtoHisExaminationBaseDto[]
    ) => data,
  },
});

import { createServices } from "@vs/service";

export default createServices({
  createGravidaRecord: {
    method: "post",
    url: "/api/nurse/createGravidaRecord",
    parameterFomatter: (data?: {
      userInfo: GravidaInfoServiceBtoCreateGravidaInfoAtWebBto,
      recordInfo: GravidaRecordServiceBtoCreateGravidaRecordAtWebBto,
      physicalInfo: MedicalRecordServiceBtoCreatePhysicalInspectionsBto,
      medicalInfo: MedicalRecordServiceBtoCreateMedicalRecordAtNursePageBto,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  updateGravidaRecord: {
    method: "post",
    url: "/api/nurse/updateGravidaRecord",
    parameterFomatter: (data?: {
      userInfo: GravidaInfoServiceBtoCreateGravidaInfoAtWebBto,
      recordInfo: GravidaRecordServiceBtoUpdateGravidaRecordAtWebBto,
      physicalInfo: MedicalRecordServiceBtoCreatePhysicalInspectionsBto,
      medicalInfo: MedicalRecordServiceBtoCreateMedicalRecordAtNursePageBto,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  editGravidaRecord: {
    method: "post",
    url: "/api/nurse/editGravidaRecord",
    parameterFomatter: (data?: {
      btoParam: MedicalRecordServiceBtoCreateMedicalRecordAtNursePageBto,
      extApiParam: any,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  createMedicalRecord: {
    method: "post",
    url: "/api/record/createMedicalRecord",
    parameterFomatter: (data?: {
      btoParam: MedicalRecordServiceBtoCreateMedicalRecordAtNursePageBto,
      extApiParam: any,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  updateDeliveryLocal: {
    method: "post",
    url: "/api/nurse/updateDeliveryLocal",
    parameterFomatter: (data?: {
      btoParam: GravidaRecordServiceBtoUpdateDeliveryLocalBto,
      extApiParam: any,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  getPatientList: {
    method: "post",
    url: "/api/nurse/getPatientList",
    parameterFomatter: (data?: {
      qto: ExternalSyncServiceQtoSearchRegisteredListQto,
      ext: any,
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number,
        result: ExternalSyncManagerDtoHisRegisteredBaseDto[],
        from: number,
        size: number,
        scrollId: string,
      }
    ) => data,
  },
  login: {
    method: "post",
    url: "/api/nurse/login",
    parameterFomatter: (data?: {
      userType: string,
      userName: string,
      password: string,
    }) => data,
    responseFormatter: (_, __, data?: UserAccountManagerDtoUserProfileDto) =>
      data,
  },
});

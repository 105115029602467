export * from './baseinfo';
export * from './spouseInfo';
export * from './pregnancyInfo';
export * from './recordPersonalHistory';
export * from './recordFamilyHistory';

export const FormBlockType = {
  WORD: 'word',
  SIGN: 'sign',
};

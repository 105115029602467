import React, { useEffect, useRef, useState } from 'react';
import {
  VSFormItem,
  VSEditableTable,
  VSEditableTableAddition,
  VSTableColumn,
  VSForm,
  Checkbox,
} from '@vs/vsf-kit';
import { cloneDeep, set } from 'lodash';
import gravidaServices from '@/services/gravida';
import nurseServices from '@/services/nurse';
import staticCommonService from '@/module/common/services';
import CollapseBlock from '@/components/collapse-block';
import RetainEarlyEnough from '@/components/retain-early-enough';
import BloodPressure from '@/components/blood-pressure';
import {
  formatDate,
  showDateFormat,
  disableEdcDate,
  disableLmpDate,
} from '@/utils/rule';
import VsfFormLayout, { FormBlock2 } from '@/components/form-layout';
import vsf, { definePage } from '@vs/vsf-boot';
import FetusHeart from '@/components/fetus-heart';
import { useCallBackRef, getGestationalWeek } from '@/utils';
import FormError from '@/components/form-error';
import { message } from 'antd';
import moment from 'moment';
import './index.less';
import { unwrapObject, wrapObject } from '@/components/utils';
import {
  FormBlockType,
  baseInfoFormItem,
  spouseInfoFormItem,
  pregnancyInfoFormItem,
  recordPersonalHistoryFormItem,
  recordFamilyHistoryFormItem,
} from '@/pages/record/components/record/formitem';
import { labelColWidth } from '@/config';
import {
  recordPersonHistoryDefaultDeny,
  recordPersonHistoryDefault,
  recordPersonHistory2DefaultDeny,
  recordPersonHistory2Default,
  recordFamilyHistoryDefaultDeny,
  recordFamilyHistoryDefault,
} from '@/pages/record/components/record/config';
import RecordModal from '@/pages/record/modal';
import commonServices from '@/module/common/services';
import RecordSureModal from '@/pages/record/ask';
import { forbidEdit } from './utils';
import {
  handleFormatHpi,
  handleFormatError,
  caseStr,
  gravidaStr,
  outlineStr,
} from '@/pages/medical/utils';
import { renderServiceHisDoctorList } from '@/pages/record/components/record';
import { globalPageType } from '@/config';
import { renderWeight, weigthType } from '@/pages/medical/index';

const Index = props => {
  const { gravida, common, medical, nurse } = vsf?.stores || {};
  const {
    gravidaInfo,
    gravidaRecord,
    gravidaAllergyHistory: gravidaAllergyHistoryStores,
  } = gravida;
  const {
    hisId,
    hisRecordId,
    gravidaRecordId,
    enums,
    isFirstMedical,
    showMedical,
    allergyDictionary,
    addressData,
    windowSearch,
  } = common;
  const { medicalInfo } = medical;

  const [gravidaRecordInfo, setGravidaRecordInfo] = useState(
    gravidaRecord?.id
      ? {
          ...gravidaRecord,
          gravidaInfo: wrapObject(gravidaRecord?.gravidaInfo, 'address'),
          spouseInfo: wrapObject(gravidaRecord?.spouseInfo, 'address'),
        }
      : {},
  );
  const [doctorInfo, setDoctorInfo] = useState(
    props.stores.common?.doctorInfo || {},
  );

  const [gravidaAllergyHistory, setGravidaAllergyHistory] = useState(
    gravidaAllergyHistoryStores,
  );
  // 孕产史
  const [pregnancyHistory, setPregnancyHistory] = useState(
    gravidaRecord?.pregnancyHistoryDtoList || [],
  );

  const [isFirst, setIsFirst] = useState(false);

  const [isRef, formRef] = useCallBackRef();
  const [isRefMedical, formRefMedical] = useCallBackRef();

  const [descObj, setDescObj] = useState({});
  const [hisDoctorList, setHisDoctorList] = useState();
  const [withstatus, setWithstatus] = useState(false);

  const mockIdRef = useRef(1000000);

  useEffect(() => {
    console.log(descObj);
  }, [descObj]);

  useEffect(() => {
    commonServices
      .getHisDoctorList({
        version: true,
      })
      .then(res => {
        if (res.code === 200) {
          setHisDoctorList(res.data);
        }
      });
  }, []);

  useEffect(() => {
    if (props.submitKey > 0) {
      handleSubmit();
    }
  }, [props.submitKey]);

  const handleSubmit = async () => {
    const valuesRecord = await formRef?.current?.validateFields();

    let values;
    let flag = false;
    try {
      const valuesMedical = await formRefMedical?.current?.validateFields();
      values = {
        ...valuesRecord,
        ...valuesMedical,
      };
      flag = true;
      vsf.setStoreValue?.('nurse.formError', null);
    } catch (err) {
      console.log(err, 'err');
      vsf.setStoreValue?.('nurse.formError', err?.errorFields || []);
    }
    if (!flag) return;
    if (values?.userInfo?.manageOrg) {
      values.userInfo.manageOrg = renderServiceHisDoctorList(
        gravida?.gravidaInfo?.manageOrg,
        hisDoctorList,
      )?.orgId;
    }
    if (values?.spouseInfoBto?.mentalRetardation) {
      values.spouseInfoBto.mentalRetardationLevel =
        values?.spouseInfoBto?.mentalRetardation !== '有'
          ? undefined
          : values?.spouseInfoBto?.mentalRetardationLevel;
    }
    if (values?.recordFamilyHistoryBto?.mentalRetardation) {
      values.recordFamilyHistoryBto.mentalRetardationLevel =
        values?.recordFamilyHistoryBto?.mentalRetardation !== '有'
          ? undefined
          : values?.recordFamilyHistoryBto?.mentalRetardationLevel;
    }

    console.log('校验表单并返回格式化后的所有数据：', values);

    const {
      userInfo,
      spouseInfoBto,
      pregnancyInfoBto,
      recordMensesInfoBto,
      recordPersonalHistoryBto,
      recordFamilyHistoryBto,
      physicalInfo,
      medicalInfo,
      firstVisitTime,
      funding,
      ...rest
    } = values;

    const {
      gravidaInfo,
      spouseInfo,
      pregnancyInfo,
      recordPersonalHistory,
      recordFamilyHistory,
      recordMensesInfo,
      pregnancyHistoryDtoList,
      extrapelvicMeasurement,
      ...extraRest
    } = gravidaRecordInfo;

    const formatData = {
      isFirst,
      recordStatus: extraRest?.auditStatus,
      medicalStatus: medical?.medicalInfo?.auditStatus,
      userInfo: {
        ...gravidaInfo,
        ...unwrapObject(userInfo, [
          'current',
          'registered',
          'birthplace',
          'post',
          'manager',
        ]),
        status: true,
        hisId,
        birthday: formatDate(userInfo?.birthday),
        age: moment().diff(userInfo?.birthday, 'years'),
      },
      recordInfo: {
        ...extraRest,
        status: 'ACTIVE',
        auditStatus: 'NURSE_AUDIT',
        firstVisitTime: formatDate(
          firstVisitTime || gravidaRecordInfo?.firstVisitTime,
        ),
        funding: funding || gravidaRecordInfo?.funding,
        pregnancyHistoryBtoList: pregnancyHistory.map(item => {
          const formatItem = {
            ...item,
            ...(item?.birthday
              ? {
                  birthday: formatDate(item?.birthday),
                }
              : {}),
          };
          const { id, ...rest } = formatItem;
          if (`${id}`?.length > 6) {
            return rest;
          } else {
            return item;
          }
        }),
        spouseInfoBto: {
          ...unwrapObject(spouseInfo || {}, ['registered']),
          ...unwrapObject(spouseInfoBto || {}, ['registered']),
          birthday: formatDate(spouseInfoBto?.birthday),
          age: moment().diff(spouseInfoBto?.birthday, 'years'),
        },
        pregnancyInfoBto: {
          ...(pregnancyInfo || {}),
          ...unwrapObject(pregnancyInfoBto || {}),
          edc: formatDate(
            moment(formatDate(pregnancyInfoBto?.lmp || pregnancyInfo?.lmp)).add(
              40,
              'w',
            ),
          ),
          updateEdc: formatDate(
            pregnancyInfoBto?.updateEdc || pregnancyInfo?.updateEdc,
          ),
          lmp: formatDate(pregnancyInfoBto?.lmp || pregnancyInfo?.lmp),
        },
        recordMensesInfoBto: {
          ...(recordMensesInfo || {}),
          ...(unwrapObject(recordMensesInfoBto) || {}),
        },
        recordPersonalHistoryBto: {
          ...(recordPersonalHistory || {}),
          ...(unwrapObject(recordPersonalHistoryBto) || {}),
        },
        recordFamilyHistoryBto: {
          ...(recordFamilyHistory || {}),
          ...(unwrapObject(recordFamilyHistoryBto) || {}),
        },
        extrapelvicMeasurementBto: {
          ...(extrapelvicMeasurement || {}),
        },
      },
      physicalInfo: {
        ...physicalInfo,
        source: 'DOCTOR_CHECK',
        gravidaRecordId,
        ...(unwrapObject(physicalInfo) || {}),
      },
      medicalInfo: {
        ...medicalInfo,
        status: 'ACTIVE',
        auditStatus: 'NURSE_AUDIT',
        medicalRecordType: isFirst ? 'FIRST_VISIT' : 'RETURN_VISIT',
        hisId,
        gravidaId: gravida?.gravidaInfo?.id,
        gravidaRecordId,
        hisRecordId,
        turnToEmergencyRecordBto: unwrapObject(
          rest?.turnToEmergencyRecordBto || {},
        ),
        additionInfo: rest?.additionInfo,
      },
    };

    if (isFirst) {
      const target = (gravidaAllergyHistory || [])
        .filter(item => item.id && item?.id?.length > 6)
        .map(item => {
          const { id, ...rest } = item;
          rest.occurrenceTime = formatDate(rest?.occurrenceTime);
          rest.registrantTime = formatDate(rest?.registeredTime);
          rest.hisId = gravida?.gravidaInfo?.hisId;
          rest.registrantId = common?.doctorId;
          delete rest.registeredTime;
          return rest;
        });

      Promise.all([
        gravidaServices.createGravidaAllergyHistory({
          btoParam: target,
        }),
        ,
        nurseServices[
          common?.gravidaRecordId
            ? 'updateGravidaRecord'
            : 'createGravidaRecord'
        ](formatData),
      ]).then(res => {
        const target = res.filter(v => v);
        const allergyRes = target[0];
        if (allergyRes?.code === 200) {
          staticCommonService
            .getAllergyHistory({ hisId: gravida?.gravidaInfo?.hisId })
            .then(res => {
              if (res.code === 200) {
                gravida.gravidaAllergyHistory = res.data;
              }
            });
        }

        const recordInfoRes = target[1];
        // message(recordInfoRes);
        if (recordInfoRes.code === 200) {
          message.success(recordInfoRes.message);
        }
        gravida?.getRecord(gravida?.gravidaInfo?.id);
        gravida?.getPhysicalList(common.gravidaRecordId);
        medical?.getMedicalDetail(hisRecordId);
        updatePatientStatus();
      });
    } else {
      Promise.all([
        nurseServices.updateDeliveryLocal({
          btoParam: {
            id: gravidaRecordId,
            pregnancyInfoBto: {
              id: formatData?.recordInfo?.pregnancyInfoBto?.id,
              deliveryLocal:
                formatData?.recordInfo?.pregnancyInfoBto?.deliveryLocal,
            },
          },
        }),
        ,
        nurseServices[
          common?.gravidaRecordId
            ? 'updateGravidaRecord'
            : 'createGravidaRecord'
        ](formatData),
      ]).then(res => {
        const target = res.filter(v => v);
        const localRes = target[0];
        const recordInfoRes = target[1];
        if (recordInfoRes.code === 200) {
          message.success(recordInfoRes.message);
        }
        gravida?.getRecord(gravida?.gravidaInfo?.id);
        gravida?.getPhysicalList(common.gravidaRecordId);
        medical?.getMedicalDetail(hisRecordId);
        updatePatientStatus();
      });
    }
  };

  const updatePatientStatus = async () => {
    await commonServices.updateRegisteredStatus({
      btoParam: {
        hisRecordId: hisRecordId,
        ownStatus: 'RECEIVE',
      },
    });
  };

  useEffect(() => {
    setGravidaAllergyHistory(props.stores?.gravida?.gravidaAllergyHistory);
  }, [props.stores?.gravida?.gravidaAllergyHistory]);

  const setDataFormMedical = async => {
    const { turnToEmergencyRecordDto, physicalInspections } = medicalInfo;
    formRefMedical?.current?.setFieldsValue({
      medicalInfo,
      turnToEmergencyRecordBto: {
        ...turnToEmergencyRecordDto,
        fetusHeart1: {
          fetusHeart: turnToEmergencyRecordDto?.fetusHeart,
          fhnh: turnToEmergencyRecordDto?.fhnh,
        },
        fetusHeart2: {
          fetusHeartTwo: turnToEmergencyRecordDto?.fetusHeartTwo,
          fhnhTwo: turnToEmergencyRecordDto?.fhnhTwo,
        },
        fetusHeart3: {
          fetusHeartThree: turnToEmergencyRecordDto?.fetusHeartThree,
          fhnhThree: turnToEmergencyRecordDto?.fhnhThree,
        },
      },
      physicalInfo: {
        ...physicalInspections,
        bloodPressure:
          physicalInspections?.systolicPressure &&
          physicalInspections?.diastolicPressure
            ? {
                systolicPressure: physicalInspections?.systolicPressure,
                diastolicPressure: physicalInspections?.diastolicPressure,
              }
            : null,
        bloodPressure1:
          physicalInspections?.systolicPressureTwo &&
          physicalInspections?.diastolicPressureTwo
            ? {
                systolicPressureTwo: physicalInspections?.systolicPressureTwo,
                diastolicPressureTwo: physicalInspections?.diastolicPressureTwo,
              }
            : null,
        bloodPressure2:
          physicalInspections?.systolicPressureThree &&
          physicalInspections?.diastolicPressureThree
            ? {
                systolicPressureThree:
                  physicalInspections?.systolicPressureThree,
                diastolicPressureThree:
                  physicalInspections?.diastolicPressureThree,
              }
            : null,
      },
    });
    handleValueChange({}, {});
    setDescObj({
      ...descObj,
      ...handleFormatError({
        physicalInspections,
      }),
    });
  };

  const setDataFormGravida = async data => {
    if (data?.id) {
      const target = data;
      formRef?.current?.setFieldsValue({
        userInfo: target?.gravidaInfo,
        firstVisitTime: target?.firstVisitTime,
        funding: target?.funding,

        spouseInfoBto: target.spouseInfo,

        pregnancyInfoBto: {
          ...target.pregnancyInfo,
          mpas: {
            mature: target.pregnancyInfo?.mature,
            premature: target.pregnancyInfo?.premature,
            abortion: target.pregnancyInfo?.abortion,
            survival: target.pregnancyInfo?.survival,
          },
        },
        recordMensesInfoBto: wrapObject(target.recordMensesInfo),

        recordPersonalHistoryBto: wrapObject(target.recordPersonalHistory),

        recordFamilyHistoryBto: target.recordFamilyHistory,
      });
      handleValueChange({}, {});
      setDescObj({
        ...descObj,
        ...handleFormatError({
          mensesInfoSnapshotDto: wrapObject(target.recordMensesInfo),
          familyHistorySnapshotDto: target.recordFamilyHistory,
          personalHistorySnapshotDto: wrapObject(target.recordPersonalHistory),
        }),
      });
      if (medicalInfo?.id) {
        setDataFormMedical();
      }
    } else {
      formRef?.current?.resetFields();
    }
  };

  const pregnancyHistoryEditableProp = {
    editType: 'multiple',
    onCanEdit: v => true,
    onChange: v => {
      setPregnancyHistory(v);
    },
    deleteAction: {
      onAction: data => {
        return new Promise(resolve => {
          const newData = [...(pregnancyHistory || [])];
          const index = newData.findIndex(item => data.id === item.id);
          newData.splice(index, 1);
          setPregnancyHistory(newData);
          resolve(true);
        });
      },
    },
  };

  const recordAllergyHistoryProp = {
    dataType: 'static',
    value: gravidaAllergyHistory,
    editType: 'multiple',
    onChange: v => {
      setGravidaAllergyHistory(v);
    },
    onCanEdit: v => false,
    deleteAction: {
      onAction: data => {
        return new Promise(resolve => {
          const newData = [...(gravidaAllergyHistory || [])];
          const index = newData.findIndex(item => data.id === item.id);
          newData.splice(index, 1);
          setGravidaAllergyHistory(newData);
          resolve(true);
        });
      },
    },
  };

  useEffect(() => {
    if (isRef) {
      const target = gravidaRecord?.id
        ? {
            ...gravidaRecord,
            gravidaInfo: wrapObject(gravidaRecord?.gravidaInfo, 'address'),
            spouseInfo: wrapObject(gravidaRecord?.spouseInfo, 'address'),
          }
        : {};
      setGravidaRecordInfo(target);
      setPregnancyHistory(gravidaRecord?.pregnancyHistoryDtoList || []);
      setDataFormGravida(target);
      if (medicalInfo?.id && isRefMedical) {
        setDataFormMedical();
      }
    }
  }, [gravidaRecordId, medicalInfo, gravidaRecord, isRef, isRefMedical]);

  useEffect(() => {
    setIsFirst(isFirstMedical || false);
  }, [isFirstMedical]);

  const allergyDictionaryFormat = (allergyDictionary || []).map(item => ({
    label: item.allergenName,
    value: item.allergenId,
  }));

  const basicInfo = {
    enums,
    addressData,
  };

  const [allDenyPersonal, setAllDenyPersonal] = useState(false);
  const [allDenyPast, setAllDenyPast] = useState(false);
  const [allDenyFamily, setAllDenyFamily] = useState(false);

  const onChangePersonal = v => {
    setAllDenyPersonal(v);
    formRef.current.setFieldsValue({
      recordPersonalHistoryBto: v
        ? wrapObject(recordPersonHistoryDefaultDeny)
        : wrapObject(recordPersonHistoryDefault),
    });
  };

  const onChangePast = v => {
    setAllDenyPast(v);
    formRef.current.setFieldsValue({
      recordPersonalHistoryBto: v
        ? wrapObject(recordPersonHistory2DefaultDeny)
        : wrapObject(recordPersonHistory2Default),
    });
  };

  const onChangeFamily = v => {
    setAllDenyFamily(v);
    formRef.current.setFieldsValue({
      recordFamilyHistoryBto: v
        ? recordFamilyHistoryDefaultDeny
        : recordFamilyHistoryDefault,
    });
  };

  const CheckPersonal = () => {
    return (
      <Checkbox value={allDenyPersonal} onChange={onChangePersonal}>
        全选否定
      </Checkbox>
    );
  };

  const CheckPast = () => {
    return (
      <Checkbox value={allDenyPast} onChange={onChangePast}>
        全选否定
      </Checkbox>
    );
  };

  const CheckFamily = () => {
    const arr = Object.keys(recordFamilyHistoryDefault);
    let isCheck = false;
    if (formRef?.current?.getFieldsValue()?.recordFamilyHistoryBto) {
      const arr2 = arr.map(v => {
        return formRef?.current?.getFieldsValue()?.recordFamilyHistoryBto[v];
      });
      isCheck =
        arr2.filter(v => v).length === 7 &&
        !arr2.filter(v => v).some(v => v === '有' || v === '不祥');
    }
    return (
      <Checkbox value={allDenyFamily || isCheck} onChange={onChangeFamily}>
        全选否定
      </Checkbox>
    );
  };
  // 全局否定的监听
  const handleValueChange = (record, allValues) => {
    const formValue = medicalInfo.id
      ? {
          familyHistory: {
            ...medicalInfo?.familyHistorySnapshotDto,
            ...allValues?.recordFamilyHistoryBto,
          },
          personalHistory: {
            ...wrapObject(medicalInfo?.personalHistorySnapshotDto),
            ...allValues?.recordPersonalHistoryBto,
          },
        }
      : {
          familyHistory: {
            ...gravidaRecordInfo?.recordFamilyHistory,
            ...allValues?.recordFamilyHistoryBto,
          },
          personalHistory: {
            ...wrapObject(gravidaRecordInfo?.recordPersonalHistory),
            ...allValues?.recordPersonalHistoryBto,
          },
        };
    const { familyHistory, personalHistory } = formValue;
    // 数据源
    const target = Object.keys(personalHistory || {});
    // 既往相关字段合集
    const totalData = target.filter(
      key => typeof personalHistory[key] === 'object' && personalHistory[key],
    );
    // 相关字段合集（true）
    const filterTrueArr = totalData.filter(
      key =>
        typeof personalHistory[key]?.[key] === 'boolean' &&
        personalHistory[key]?.[key],
    );
    // 相关字段合集（false）
    const filterFalseArr = totalData.filter(
      item => !filterTrueArr.includes(item),
    );
    // 确定属于个人史的字段
    const flagCheck = filterTrueArr.some(item =>
      recordPersonHistoryDefault.hasOwnProperty(item),
    );
    // 确定属于既往史的字段
    const flag2Check = filterTrueArr.some(item =>
      recordPersonHistory2Default.hasOwnProperty(item),
    );
    setAllDenyPersonal(
      !flagCheck &&
        filterFalseArr.some(item =>
          recordPersonHistoryDefault.hasOwnProperty(item),
        ) &&
        totalData.filter(item =>
          recordPersonHistoryDefault.hasOwnProperty(item),
        )?.length === 10,
    );
    setAllDenyPast(
      !flag2Check &&
        filterFalseArr.some(item =>
          recordPersonHistory2Default.hasOwnProperty(item),
        ) &&
        totalData.filter(item =>
          recordPersonHistory2Default.hasOwnProperty(item),
        )?.length === 14,
    );
    // 数据源
    const target2 = Object.keys(familyHistory || {});
    // 家族史相关字段合集
    const familyArr = Object.keys(recordFamilyHistoryDefaultDeny).filter(
      key => recordFamilyHistoryDefaultDeny[key] === '无',
    );
    const totalData2 = target2.filter(key => familyArr.includes(key));
    const filterTrueArr2 = familyArr.filter(
      key =>
        typeof familyHistory[key] === 'string' &&
        ['有', '不详'].includes(familyHistory[key]),
    );
    const filterFalseArr2 = familyArr.filter(
      item => !filterTrueArr2.includes(item),
    );
    const flagCheck2 = filterTrueArr2.some(item =>
      recordFamilyHistoryDefault.hasOwnProperty(item),
    );

    setAllDenyFamily(
      !flagCheck2 &&
        filterFalseArr2.some(item =>
          recordFamilyHistoryDefault.hasOwnProperty(item),
        ) &&
        totalData2.filter(item =>
          recordFamilyHistoryDefault.hasOwnProperty(item),
        )?.length === 7 &&
        filterFalseArr2.map(v => familyHistory[v])?.filter(v => v)?.length ===
          7,
    );

    if (familyHistory || personalHistory) {
      setDescObj({
        ...descObj,
        ...handleFormatError({
          familyHistorySnapshotDto: familyHistory,
          personalHistorySnapshotDto: personalHistory,
        }),
      });
    }
    if (record?.recordMensesInfoBto) {
      setDescObj({
        ...descObj,
        ...handleFormatError({
          mensesInfoSnapshotDto: wrapObject({
            ...record?.recordMensesInfoBto,
            ...allValues?.recordMensesInfoBto,
          }),
        }),
      });
    }
  };
  const handleBaseInfoValuesChange = e => {
    const { pregnancyInfoBto } = e;
    if (pregnancyInfoBto?.lmp) {
      formRef.current[2].setFieldsValue({
        pregnancyInfoBto: {
          gestationalWeek: getGestationalWeek({
            lmp: pregnancyInfoBto?.lmp,
          }),
          edc: moment(pregnancyInfoBto?.lmp)
            .add(40, 'w')
            .format('YYYY-MM-DD'),
        },
      });
    }
  };
  const setFromValue = () => {
    if (formRef.current) {
      formRef.current.setFieldsValue({
        userInfo: {
          manageOrg: renderServiceHisDoctorList(
            gravidaInfo?.manageOrg,
            hisDoctorList,
            gravidaRecord?.auditStatus,
          ),
        },
      });
    }
  };

  setFromValue();
  return (
    <div
      className="e-medical-form-block"
      style={{ backgroundColor: 'transparent' }}
    >
      <div className="content">
        <VSForm
          dataType="static"
          layout="horizontal"
          formRef={formRef}
          labelCol={labelColWidth[5]}
          onValuesChange={handleValueChange}
          disabled={forbidEdit(gravidaRecordInfo, common)}
          initialValues={{
            userInfo: {
              manageOrg: renderServiceHisDoctorList(
                gravidaInfo?.manageOrg,
                hisDoctorList,
                gravidaRecord?.auditStatus,
              ),
            },
          }}
        >
          {isFirst ? (
            <>
              <CollapseBlock
                title="基本信息"
                description=""
                error=""
                id="anchor-base"
              >
                {baseInfoFormItem({
                  basic: basicInfo,
                  formType: FormBlockType.WORD,
                })}
              </CollapseBlock>

              <CollapseBlock
                title="配偶信息"
                description=""
                id="anchor-husband"
              >
                {spouseInfoFormItem({
                  basic: basicInfo,
                  formType: FormBlockType.WORD,
                  spouseInfoBto: formRef.current?.getFieldsValue()
                    ?.spouseInfoBto,
                })}
              </CollapseBlock>

              <CollapseBlock
                title="怀孕信息"
                id="anchor-pregnancy"
                description={descObj['gravida']}
              >
                <VSForm
                  onValuesChange={handleBaseInfoValuesChange}
                  dataType="static"
                  layout="horizontal"
                  formRef={ref => {
                    formRef.current[2] = ref;
                    if (formRef.current[2]) {
                      formRef.current[2].setFieldsValue({
                        pregnancyInfoBto: {
                          ...gravidaRecordInfo.pregnancyInfo,
                        },
                      });
                    }
                    return ref;
                    // if()
                  }}
                  labelCol={labelColWidth[5]}
                  initialValues={{
                    pregnancyInfoBto: {
                      ...gravidaRecordInfo.pregnancyInfo,
                    },
                    recordMensesInfoBto: wrapObject(
                      gravidaRecordInfo.recordMensesInfo,
                    ),
                  }}
                  disabled={forbidEdit(gravidaRecordInfo, common)}
                >
                  {pregnancyInfoFormItem({
                    basic: basicInfo,
                    formType: FormBlockType.WORD,
                    pregnancyInfoBto: { ...gravidaRecordInfo.pregnancyInfo },
                  })}
                </VSForm>
              </CollapseBlock>

              <CollapseBlock title="孕产史" id="anchor-pregnancy-history">
                <VSEditableTable
                  dataType="static"
                  value={pregnancyHistory}
                  {...pregnancyHistoryEditableProp}
                >
                  <VSTableColumn
                    title="孕次"
                    valueType="digit"
                    dataIndex="gravidity"
                    fieldProps={{
                      precision: 0,
                      addonAfter: '次',
                      placeholder: '请输入',
                      controls: false,
                      min: 1,
                      max: 10,
                    }}
                  ></VSTableColumn>
                  <VSTableColumn
                    title="生产日期"
                    valueType="date"
                    dataIndex="birthday"
                    fieldProps={{
                      format: showDateFormat,
                      renderPreview: v =>
                        v?.value ? moment(v.value).format('YYYY-MM-DD') : '',
                    }}
                  ></VSTableColumn>
                  <VSTableColumn
                    title="生产类别"
                    valueType="select"
                    dataIndex="birthMethod"
                  ></VSTableColumn>
                  <VSTableColumn
                    title="生产孕周"
                    valueType="digit"
                    dataIndex="birthWeek"
                    fieldProps={{
                      controls: false,
                    }}
                  ></VSTableColumn>
                  <VSTableColumn
                    title="小孩情况"
                    valueType="select"
                    dataIndex="childStatus"
                  ></VSTableColumn>
                  <VSTableColumn
                    title="备注"
                    valueType="text"
                    dataIndex="comment"
                  ></VSTableColumn>
                  <VSEditableTableAddition
                    onAdd={() => {
                      return new Promise((resolve, reject) => {
                        const newData = [
                          ...pregnancyHistory,
                          {
                            gravidity: pregnancyHistory.length + 1,
                            id: mockIdRef.current,
                          },
                        ];
                        setPregnancyHistory(newData);
                        mockIdRef.current += 1;
                        resolve(true);
                      });
                    }}
                  >
                    新增
                  </VSEditableTableAddition>
                </VSEditableTable>
              </CollapseBlock>
              <CollapseBlock
                title="概要"
                id="anchor-outline"
                description={descObj['outline']}
              >
                {recordPersonalHistoryFormItem({
                  basic: basicInfo,
                  formType: FormBlockType.WORD,
                  labelCol: labelColWidth[8],
                  extra: {
                    personal: CheckPersonal(),
                    past: CheckPast(),
                  },
                })}

                <FormBlock2 title="过敏史">
                  <VSEditableTable
                    dataType="static"
                    value={gravidaRecordInfo.recordAllergyHistoryBtoList}
                    {...recordAllergyHistoryProp}
                  >
                    <VSTableColumn
                      title="过敏源"
                      valueType="select"
                      dataIndex="allergen"
                      columnKey="allergen"
                      key="allergen"
                      dataSource={allergyDictionaryFormat}
                      fieldProps={{
                        showSearch: true,
                      }}
                    ></VSTableColumn>
                    <VSTableColumn
                      title="过敏症状"
                      valueType="select"
                      dataIndex="allergySymptom"
                      columnKey="allergySymptom"
                      key="allergySymptom"
                      dataSource={enums['allergy_symptom']}
                    ></VSTableColumn>
                    <VSTableColumn
                      title="严重程度"
                      valueType="select"
                      dataIndex="severity"
                      columnKey="severity"
                      key="severity"
                      dataSource={enums['allergy_severity']}
                    ></VSTableColumn>
                    <VSTableColumn
                      title="发生日期"
                      valueType="date"
                      dataIndex="occurrenceTime"
                      columnKey="occurrenceTime"
                      key="occurrenceTime"
                      width={150}
                      fieldProps={{
                        format: showDateFormat,
                        renderPreview: v =>
                          v?.value ? moment(v.value).format('YYYY-MM-DD') : '',
                      }}
                    ></VSTableColumn>
                    <VSTableColumn
                      title="来源"
                      valueType="text"
                      dataIndex="source"
                      columnKey="source"
                      key="source"
                    ></VSTableColumn>
                    <VSTableColumn
                      title="登记人"
                      valueType="text"
                      dataIndex="registrant"
                      editable={false}
                      columnKey="registrant"
                      key="registrant"
                    ></VSTableColumn>
                    <VSTableColumn
                      title="登记科室"
                      valueType="text"
                      dataIndex="registeredDepartment"
                      editable={false}
                      columnKey="registeredDepartment"
                      key="registeredDepartment"
                    ></VSTableColumn>
                    <VSTableColumn
                      title="登记日期"
                      valueType="text"
                      dataIndex="registeredTime"
                      editable={false}
                      columnKey="registeredTime"
                      key="registeredTime"
                      fieldProps={{
                        renderPreview: v =>
                          v?.value ? moment(v.value).format('YYYY-MM-DD') : '',
                      }}
                    ></VSTableColumn>
                    {globalPageType.NURSE !== common?.globalPageType && (
                      <VSEditableTableAddition
                        onAdd={() => {
                          return new Promise((resolve, reject) => {
                            setGravidaAllergyHistory([
                              ...(gravidaAllergyHistory || []),
                              {
                                id: mockIdRef.current,
                                registrant: doctorInfo?.name,
                                registeredDepartment: doctorInfo?.department,
                                registeredTime: moment(new Date()).format(
                                  'YYYY-MM-DD',
                                ),
                              },
                            ]);
                            mockIdRef.current += 1;
                            resolve(true);
                          });
                        }}
                      >
                        新增
                      </VSEditableTableAddition>
                    )}
                  </VSEditableTable>
                </FormBlock2>
                {recordFamilyHistoryFormItem({
                  basic: basicInfo,
                  formType: FormBlockType.WORD,
                  labelCol: labelColWidth[8],
                  extra: {
                    family: CheckFamily(),
                  },
                  recordFamilyHistoryBto: formRef.current?.getFieldsValue()
                    ?.recordFamilyHistoryBto,
                })}
              </CollapseBlock>
            </>
          ) : null}
        </VSForm>
        <div
          style={{
            height: 16,
            width: '100%',
          }}
        ></div>
        <VSForm
          dataType="static"
          layout="horizontal"
          formRef={formRefMedical}
          labelCol={labelColWidth[5]}
          disabled={forbidEdit(medicalInfo, common)}
        >
          <CollapseBlock title="预检信息" id="anchor-physical">
            <FormBlock2 title="预检信息">
              <VsfFormLayout columnCount={3}>
                <VSFormItem
                  label="体温"
                  name={['physicalInfo', 'temperature']}
                  valueType="digit"
                  fieldProps={{
                    precision: 1,
                    addonAfter: '°C',
                    controls: false,
                    placeholder: '请输入',
                    min: 34,
                    max: 45,
                  }}
                ></VSFormItem>
                <VsfFormLayout>
                  <VSFormItem
                    label="体重"
                    name={['physicalInfo', 'weight']}
                    valueType="digit"
                    fieldProps={{
                      precision: 2,
                      addonAfter: 'kg',
                      placeholder: '请输入',
                      controls: false,
                      min: 30,
                      max: 300,
                      disabled:
                        withstatus ||
                        formRefMedical?.current?.getFieldsValue()?.physicalInfo
                          ?.otherOptional,
                    }}
                    rules={[
                      {
                        required: formRefMedical?.current?.getFieldsValue()
                          ?.physicalInfo?.otherOptional
                          ? false
                          : true,
                        message: '请填写体重',
                      },
                    ]}
                  ></VSFormItem>
                  <VSFormItem
                    // label="其他"
                    name={['physicalInfo', 'otherOptional']}
                    valueType="select"
                    dataSource={weigthType}
                    fieldProps={{
                      placeholder: '请选择',
                      onChange: e => {
                        formRefMedical?.current?.setFieldsValue({
                          physicalInfo: {
                            weight: '',
                          },
                        });
                        setWithstatus(true);
                      },
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
                <VSFormItem
                  label="脉搏"
                  name={['physicalInfo', 'pulse']}
                  valueType="digit"
                  fieldProps={{
                    min: 0,
                    max: 500,
                    controls: false,
                    placeholder: '请输入',
                    addonAfter: '次/分',
                  }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: '请填写脉搏',
                  //   },
                  // ]}
                ></VSFormItem>
                <VSFormItem
                  label="血压(mmHg)"
                  name={['physicalInfo', 'bloodPressure']}
                  valueType="custom"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: '请填写血压',
                  //   },
                  // ]}
                >
                  <BloodPressure
                    dataKey={['systolicPressure', 'diastolicPressure']}
                    onChange={e => {
                      if (e?.diastolicPressure && e.systolicPressure) {
                        if (formRefMedical.current) {
                          formRefMedical.current.setFieldsValue({
                            physicalInfo: {
                              bloodPressureDate: moment(new Date()).format(
                                'YYYY-MM-DD hh:mm:ss',
                              ),
                            },
                          });
                        }
                      }
                    }}
                  />
                </VSFormItem>
                <VSFormItem
                  label="血压时间"
                  name={['physicalInfo', 'bloodPressureTime']}
                  valueType="time"
                  fieldProps={{
                    disabledDate: disableEdcDate,
                    format: ['YYYY-MM-DD HH:mm:ss'],
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="复测血压1(mmHg)"
                  name={['physicalInfo', 'bloodPressure1']}
                  valueType="custom"
                >
                  <BloodPressure
                    onChange={e => {
                      if (e?.diastolicPressureTwo && e.systolicPressureTwo) {
                        if (formRefMedical.current) {
                          formRefMedical.current.setFieldsValue({
                            physicalInfo: {
                              bloodPressure1Date: moment(new Date()).format(
                                'YYYY-MM-DD hh:mm:ss',
                              ),
                            },
                          });
                        }
                      }
                    }}
                    dataKey={['systolicPressureTwo', 'diastolicPressureTwo']}
                  />
                </VSFormItem>
                <VSFormItem
                  label="复测血压时间1"
                  name={['physicalInfo', 'bloodPressureTimeTwo']}
                  valueType="time"
                  fieldProps={{
                    disabledDate: disableEdcDate,
                    format: ['YYYY-MM-DD HH:mm:ss'],
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="复测血压2(mmHg)"
                  name={['physicalInfo', 'bloodPressure2']}
                  valueType="custom"
                >
                  <BloodPressure
                    dataKey={[
                      'systolicPressureThree',
                      'diastolicPressureThree',
                    ]}
                    onChange={e => {
                      if (
                        e?.diastolicPressureThree &&
                        e.systolicPressureThree
                      ) {
                        if (formRefMedical.current) {
                          formRefMedical.current.setFieldsValue({
                            physicalInfo: {
                              bloodPressure2Date: moment(new Date()).format(
                                'YYYY-MM-DD hh:mm:ss',
                              ),
                            },
                          });
                        }
                      }
                    }}
                  />
                </VSFormItem>
                <VSFormItem
                  label="复测血压时间2"
                  name={['physicalInfo', 'bloodPressureTimeThree']}
                  valueType="time"
                  fieldProps={{
                    // showTime:true,
                    disabledDate: disableEdcDate,
                    format: ['YYYY-MM-DD HH:mm:ss'],
                  }}
                ></VSFormItem>
                {!isFirst && (
                  <VSFormItem
                    label="计划分娩医院"
                    name={['pregnancyInfoBto', 'deliveryLocal']}
                    valueType="select"
                    dataSource={enums['delivery_hospital']}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: '请选择计划分娩医院',
                    //   },
                    // ]}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                )}
              </VsfFormLayout>
            </FormBlock2>
          </CollapseBlock>
          <CollapseBlock title="转急症记录" id="anchor-emergency">
            <FormBlock2 title="转急症记录">
              <VsfFormLayout columnCount={3}>
                <VSFormItem
                  label="宫缩"
                  name={['turnToEmergencyRecordBto', 'uterineContraction']}
                  valueType="select"
                  dataSource={enums['uterine_contraction']}
                  fieldProps={{
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="宫口"
                  name={['turnToEmergencyRecordBto', 'cervical']}
                  valueType="digit"
                  fieldProps={{
                    precision: 2,
                    addonAfter: 'cm',
                    placeholder: '请输入',
                    controls: false,
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="胎心描述1"
                  name={['turnToEmergencyRecordBto', 'fetusHeart1']}
                  valueType="custom"
                >
                  <FetusHeart dataKey={['fetusHeart', 'fhnh']} />
                </VSFormItem>
                <VSFormItem
                  label="胎心描述2"
                  name={['turnToEmergencyRecordBto', 'fetusHeart2']}
                  valueType="custom"
                >
                  <FetusHeart dataKey={['fetusHeartTwo', 'fhnhTwo']} />
                </VSFormItem>
                <VSFormItem
                  label="胎心描述3"
                  name={['turnToEmergencyRecordBto', 'fetusHeart3']}
                  valueType="custom"
                >
                  <FetusHeart dataKey={['fetusHeartThree', 'fhnhThree']} />
                </VSFormItem>
              </VsfFormLayout>
            </FormBlock2>
          </CollapseBlock>

          <CollapseBlock title="其他症状、体征及重要检查" id="anchor-other">
            <FormBlock2 title="其他症状、体征及重要检查">
              <VsfFormLayout columnCount={1} labelCol={labelColWidth[8]}>
                <VSFormItem
                  label="其他症状、体征及重要检查"
                  name={['additionInfo']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
              </VsfFormLayout>
            </FormBlock2>
          </CollapseBlock>
        </VSForm>
      </div>
    </div>
  );
};
export default definePage(Index);

import { assistantMap } from '@/config/assistant';
import { ultrasoundItems, ecgItems } from '@/config/assistant';

export const clearText = (str: string) => { 
    const reg = /\\\\?X000d\\\\?/g;
    return str.replace(reg, '');
}

export const formatImportData = (data) => {
    const res: any = {};
    if (!Array.isArray(data)) {
        const { checkItemId, result } = data || {};
        if (ultrasoundItems.includes(checkItemId)) {
            res['ultrasound'] = clearText(`${result?.examDesc}${result?.examResult}`)
        }
        if (ecgItems.includes(checkItemId)) {
            res['ecg'] = clearText(`${result?.examDesc}${result?.examResult}`)
        }
    } else { 
        data.forEach(item => {
            const { labTestItemCode: itemCode, labTestItemReferRange: itemRange, labTestItemResult: itemValue, labTestItemValueUnit: itemUnit } = item || {};
            const target = assistantMap.find(item => item.code === itemCode);
            const { type, dataIndex = '', format, value, default: defaultValue, range } = target || {};
            switch (type) {
                case 'value': res[dataIndex] = itemValue; break;
                case 'enum': res[dataIndex] = itemValue ? value?.[itemValue] : defaultValue; break;
                case 'range': {
                    let rangeArr = range;
                    if (range === 'labTestItemReferRange') {
                        rangeArr = (itemRange || '')?.split('-');
                    }
                    if (rangeArr?.length === 2) {
                        res[dataIndex] = Number(itemValue) >= rangeArr[0] && Number(itemValue) <= rangeArr[1] ? value?.[0] : value?.[1];
                    }
                }; break;
                case 'static':
                    res[dataIndex] = value;
                    break;
                default: break;
            }
        });
    };
    
    return res;
}
import React, { useEffect, useState } from 'react';
import { RadioGroup, Input, InputNumber, Checkbox } from '@vs/vsf-kit';
import './index.less';

export interface FetusHeartType {
  value: string;
  dataKey: string[];
  onChange: (value: any) => void;
}

const Index = (props: FetusHeartType) => {
  const { value, dataKey ,onChange } = props;
  const [init, setInit] = useState(false);
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState(false);

  useEffect(() => {
    if (!init && value) {
      setValue1(value[dataKey?.[0]] || '');
      setValue2(value[dataKey?.[1]] || false);
    }
  }, [value]);

  useEffect(() => {
    onChange({
      [dataKey?.[0]]: value1,
      [dataKey?.[1]]: value2,
    });
  }, [value1, value2]);

  const handleValue1Change = v => {
    setInit(true);
    setValue1(v);
  };
  const handleValue2Change = v => {
    setInit(true);
    setValue2(v);
  };

  return (
    <div className="e-medical-fetus-heart">
      <InputNumber
        value={value1}
        max={999}
        min={0}
        precision={0}
        onChange={handleValue1Change}
        placeholder="请输入"
        addonAfter="次/分"
        controls={false}
      />
      <Checkbox value={value2} onChange={handleValue2Change}>
        胎心未及
      </Checkbox>
    </div>
  );
};

export default Index;

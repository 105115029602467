import React, { useEffect, useState, useRef } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { useReactToPrint } from 'react-to-print';
import { useDebounceFn } from 'ahooks';
import {
  globalPageType,
  doctorMedicalAnchor,
  doctorReturnMedicalAnchor,
  nurseMedicalAnchor,
  nurseReturnMedicalAnchor,
} from '@/config';
import { Layout, Image, Button } from '@vs/vsf-kit';
import PageBlock from '@/components/page-block';
import Anchor from '@/components/anchor';
import Recommend from '@/components/recommend';
import Complaint from '@/components/data-entering/complaint';
import Diagnosis from '@/components/data-entering/diagnosis';
import Medical from './medical';
import MedicalNurse from './nurse';
import Print from './print';

import staticCommonService from '@/module/common/services';

import RecordModal from '@/pages/record/modal';
import RecordSureModal from '@/pages/record/ask';
import Epidemic from '@/components/epidemic';
import Icon from '@/components/icon';

import './index.less';

const Index = () => {
  const { common, medical, gravida, nurse } = vsf?.stores;
  const { medicalInfo, formError } = medical;
  const {
    complaintRecommandData,
    diagnosisRecommandData,
    showMedical,
    hisRecordId,
    printInfo,
    gravidaRecordId,
  } = common;
  const [medicalSubmitKey, setMedicalSubmitKey] = useState(0);
  const [recommandExamAndDrug, setRecommandExamAndDrug] = useState({});
  const printRef = useRef(null);

  // 模糊搜索主诉（防抖）
  const { run } = useDebounceFn(
    value => {
      handleSaveStore(value);
    },
    {
      wait: 300,
    },
  );

  const handlePrint = useReactToPrint({
    content: () => {
      return printRef.current;
    },
    onBeforeGetContent: async () => {
      await common.handlePrint();
      return Promise.resolve();
    },
  });

  const handleSubmit = () => {
    setMedicalSubmitKey(medicalSubmitKey + 1);
  };

  const handleDiagnosisChange = v => {
    run({
      value: v,
      type: 'mainDiagnosis',
    });
    if (v && v?.length > 0) {
      getRecommandExamAndDrug(v?.[0]);
    } else {
      setRecommandExamAndDrug({});
    }
  };
  // const getRecommandComplaint = async () => {
  //   const res = await staticCommonService.getRecommandComplaint({
  //     patientId: common.hisId,
  //     size: 10,
  //   });
  //   if (res.code === 200) {
  //     const formatData = res?.data.map(item => ({
  //       label: item,
  //       id: `complaint-recommand-${item}`,
  //     }));
  //     vsf.stores.common.complaintRecommandData = formatData;
  //   }
  // };

  // const getRecommandDiagnosis = async () => {
  //   const res = await staticCommonService.getRecommandDiagnosis({
  //     patientId: common.hisId,
  //     size: 10,
  //   });
  //   if (res.code === 200) {
  //     const formatData = res?.data.map(item => ({
  //       label: item,
  //       id: `diagnosis-recommand-${item}`,
  //     }));
  //     vsf.stores.common.diagnosisRecommandData = formatData;
  //   }
  // };

  const getRecommandExamAndDrug = async v => {
    const res = await staticCommonService.getRecommandExamAndDrug({
      diagnosisName: v,
      size: 10,
    });
    if (res.code === 200) {
      vsf.stores.common.recommandExamAndDrug = res?.data;
      setRecommandExamAndDrug(res?.data);
    }
  };

  const handleSaveStore = data => {
    vsf.stores.medical[data?.type] = data?.value;
  };

  useEffect(() => {
    // getRecommandComplaint();
    // getRecommandDiagnosis();
  }, [common.hisId]);

  useEffect(() => {
    return () => {
      common.resetModal();
    };
  }, []);

  useEffect(() => {
    if (vsf?.stores?.medical?.mainDiagnosis?.[0]) {
      getRecommandExamAndDrug(vsf?.stores?.medical?.mainDiagnosis?.[0]);
    }
  }, [vsf?.stores?.medical?.mainDiagnosis]);

  const pageType = common?.globalPageType;

  return (
    <Layout
      className={`e-medical-main ${
        pageType === globalPageType.NURSE ? 'e-medical-main-nurse' : ''
      }`}
    >
      {pageType !== globalPageType.NURSE ? (
        <>
          <PageBlock
            key={1}
            title={
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Icon
                  type="icon-chuzhi"
                  style={{
                    fontSize: 24,
                    marginRight: 4,
                  }}
                />
                <span>处置</span>
              </span>
            }
            width={450}
            footer={false}
            loading={!showMedical}
            // contentMinWidth="760px"
          >
            <Complaint
              className="e-medical-complaint"
              title="主诉"
              key="complaint"
              value={vsf?.stores?.medical?.complaint}
              dataSource={complaintRecommandData}
              onChange={v => {
                run({
                  value: v,
                  type: 'complaint',
                });
              }}
            />
            <Diagnosis
              className="e-medical-diagnose"
              title="主诊断"
              key="main-diagnosis"
              value={vsf?.stores?.medical?.mainDiagnosis}
              dataSource={diagnosisRecommandData}
              max={1}
              onChange={handleDiagnosisChange}
            />
            <Diagnosis
              className="e-medical-diagnose secondary-diagnosis"
              title="次诊断"
              key="minor-diagnosis"
              value={vsf?.stores?.medical?.minorDiagnosis}
              dataSource={diagnosisRecommandData}
              onChange={v => {
                run({
                  value: v,
                  type: 'minorDiagnosis',
                });
              }}
            />
            <Recommend data={recommandExamAndDrug} />
          </PageBlock>
          <PageBlock
            key={2}
            title={
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Icon
                  type="icon-binglimoban"
                  style={{
                    fontSize: 24,
                    marginRight: 4,
                  }}
                />
                <span>病历模板</span>
              </span>
            }
            // contentStyle={{
            //   padding: '24px 16px 48px 16px'
            // }}
            footer={
              <div className="e-medical-form-submit">
                <Button onClick={handleSubmit}>提交</Button>
                <Button type="primary" onClick={handlePrint}>
                  打印
                </Button>
              </div>
            }
            showError={true}
            error={formError}
            loading={!showMedical}
            contentMinWidth="772px"
            anchor={
              <Anchor
                container="doctor-medical-anchor-container"
                dataSource={
                  vsf?.stores?.common?.isFirstMedical
                    ? doctorMedicalAnchor
                    : doctorReturnMedicalAnchor
                }
              />
            }
            anchorContainerId="doctor-medical-anchor-container"
          >
            <Medical
              key={hisRecordId}
              submitKey={medicalSubmitKey}
              isHistory={false}
            />
          </PageBlock>
        </>
      ) : (
        <PageBlock
          key={2}
          title={
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Icon
                type="icon-binglimoban"
                style={{
                  fontSize: 24,
                  marginRight: 4,
                }}
              />
              <span>病历模板</span>
            </span>
          }
          footer={
            medicalInfo?.auditStatus !== 'DOCTOR_AUDIT' ? (
              <div className="e-medical-form-submit">
                <Button onClick={handleSubmit}>提交</Button>
              </div>
            ) : null
          }
          showError={true}
          error={nurse?.formError}
          anchor={
            <Anchor
              container="nurse-medical-anchor-container"
              dataSource={
                vsf?.stores?.common?.isFirstMedical
                  ? nurseMedicalAnchor
                  : nurseReturnMedicalAnchor
              }
            />
          }
          anchorContainerId="nurse-medical-anchor-container"
        >
          <MedicalNurse key={gravidaRecordId} submitKey={medicalSubmitKey} />
        </PageBlock>
      )}
      <div ref={printRef} className="e-medical-print-container">
        <Print
          key={printInfo?.key}
          hisRecordId={hisRecordId}
          data={printInfo}
        ></Print>
      </div>
      <RecordSureModal key={gravida?.recordSure} />
      <RecordModal key={gravida?.createRecordVisible} />
      {/* <Epidemic onSubmit={gravida?.editEpidemiological} /> */}
    </Layout>
  );
};
export default definePage(Index);

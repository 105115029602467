import React, { useEffect, useState } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import moment from 'moment';
import { highriskVisibleType, highriskLevel } from '@/config';
import { cloneDeep } from 'lodash';
// import { message } from '@/utils';
import { Modal, TabPane, Tabs, Input } from '@vs/vsf-kit';
import HighriskCheck from '@/components/highrisk/check';
import { forbidEdit } from '@/pages/nurse/utils';
import { globalPageType } from '@/config';
import { message } from 'antd';

import gravidaServices from '@/services/gravida';

import './index.less';

/**
 * 主诉、诊断弹框组件
 **/

const EmptyData = [
  {
    label: '黄色',
    children: [],
    checked: [],
  },
  {
    label: '橙色',
    children: [],
    checked: [],
  },
  {
    label: '红色',
    children: [],
    checked: [],
  },
  {
    label: '紫色',
    children: [],
    checked: [],
  },
];

const levelEnum = {
  绿色: 'GREEN',
  黄色: 'YELLOW',
  橙色: 'ORANGE',
  红色: 'RED',
  紫色: 'PURPLE',
};

const { confirm } = Modal;

const TabNumber = 4;

export const handleSubmitHighrisk = async formData => {
  if (!formData?.level) {
    formData.level = 'GREEN';
  }
  const formatData = {
    ...formData,
    highriskInfo: null,
    medicalRecordId: null,
    recordGestationalWeek: null,
    recordPerson: null,
    recordTime: moment(new Date()).format('YYYY-MM-DD 00:00:00'),
    score: null,
    status: true,
  };
  const res = await gravidaServices.creataGravidaHighriskInfo({
    btoParam: formatData,
  });
  return res;
};

const Index = () => {
  const { gravida, common } = vsf?.stores;
  const { highriskVisible, gravidaHighrisk } = gravida;
  const { highriskenums, gravidaRecordId, hisRecordId } = common;
  const [visible, setVisible] = useState(false);
  const [activeKey, setActiveKey] = useState(0);
  const [keyArray, setKeyArray] = useState([
    'BASIC_INFO',
    'PREGNANCY_COMORBIDITY',
    'PREGNANCY_COMPLICATION',
    'selected',
  ]);
  const [highriskData, setHighriskData] = useState({
    BASIC_INFO: highriskenums?.BASIC_INFO || [],
    PREGNANCY_COMORBIDITY: highriskenums?.PREGNANCY_COMORBIDITY || [],
    PREGNANCY_COMPLICATION: highriskenums?.PREGNANCY_COMPLICATION || [],
  });

  const [keyword, setKeyword] = useState('');

  const [selected, setSelected] = useState(EmptyData);
  const [canEdit, setCanEdit] = useState(true);

  console.log('selected', selected);

  // 基础信息 ref
  const formRef = [];

  const getLevel = list => {
    let res = '';
    (list || []).forEach(item => {
      if (
        item?.checked &&
        item?.checked?.length > 0 &&
        item?.label !== '紫色'
      ) {
        res = item?.label;
      }
    });
    return levelEnum[res];
  };

  const renderValue = (str, level = '') => {
    if (str?.indexOf('$') !== -1 && str.split('$')?.length === 3) {
      return {
        name: str.split('$')[1] || '',
        category: str.split('$')[2] || 'BASIC_INFO',
        label: str.split('$')[0],
      };
    } else {
      return {
        name: str || '',
        category: 'BASIC_INFO',
        label: level,
      };
    }
  };

  const getList = list => {
    console.log(list);
    let res = [];
    (list || []).forEach(item => {
      const level = levelEnum[item.label];
      const arr = [];
      item?.checked.length > 0 &&
        item?.checked.forEach(item1 => {
          if (!item.children.some(v => v.item === item1)) {
            arr.push(item1);
          }
        });
      (item?.children || []).forEach(citem => {
        res.push({
          name: citem.item,
          category: citem?.category,
          level,
        });
      });
      arr.length > 0 &&
        arr.forEach(v => {
          res.push({
            name: renderValue(v)?.name,
            category: renderValue(v)?.category,
            level: levelEnum[renderValue(v).label],
          });
        });
    });
    return res;
  };

  const handleSubmit = async () => {
    const hasInfectious =
      selected?.find(item => item.label === '紫色')?.checked.length > 0;
    const highriskItems = getList(selected);
    const formatData = {
      gravidaRecordId,
      hasInfectious,
      highriskRecordItemBtoList: highriskItems,
      hisRecordId,
      level: getLevel(selected),
    };
    console.log(formatData, 'formatData');
    if(globalPageType.NURSE !== common?.globalPageType) {
      const res = await handleSubmitHighrisk(formatData);
      // message(res);
      if (res.code === 200) {
        message.success(res.message)
        vsf?.setStoreValue('gravida.highriskVisible', highriskVisibleType.NULL);
        gravida.getGravidaHighrisk();
        // Modal.confirm({
        //   title: '专案管理',
        //   content: '该患者为橙色高危妊娠风险人群，请注意填写【高危专案】',
        //   okText: '上报',
        //   cancelText: '取消',
        //   onOk: () => {
        //     vsf?.setStoreValue('gravida.highriskFormVisible', true);
        //     vsf?.setStoreValue('gravida.highriskVisible', highriskVisibleType.NULL);
        //   },
        //   onCancel: () => {
        //     vsf?.setStoreValue('gravida.highriskVisible', highriskVisibleType.NULL);
        //   },
        // });
      }
    }
    // const res = await handleSubmitHighrisk(formatData);
    // message(res);
    // if (res.code === 200) {
    //   vsf?.setStoreValue('gravida.highriskVisible', highriskVisibleType.NULL);
    //   gravida.getGravidaHighrisk();
    //   // Modal.confirm({
    //   //   title: '专案管理',
    //   //   content: '该患者为橙色高危妊娠风险人群，请注意填写【高危专案】',
    //   //   okText: '上报',
    //   //   cancelText: '取消',
    //   //   onOk: () => {
    //   //     vsf?.setStoreValue('gravida.highriskFormVisible', true);
    //   //     vsf?.setStoreValue('gravida.highriskVisible', highriskVisibleType.NULL);
    //   //   },
    //   //   onCancel: () => {
    //   //     vsf?.setStoreValue('gravida.highriskVisible', highriskVisibleType.NULL);
    //   //   },
    //   // });
    // }
  };
  const handleOk = () => {
    if (activeKey < 3) {
      setActiveKey(activeKey + 1);
    } else {
      if (canEdit && !forbidEdit(gravida?.gravidaRecord, common)) {
        if (globalPageType.NURSE !== common?.globalPageType) {
          confirm({
            title: '新增高危评估确认框',
            content: '新增高危评估不可更改，是否确认新增',
            onOk: () => {
              handleSubmit();
            },
          });
        } else {
          vsf?.setStoreValue(
            'gravida.highriskVisible',
            highriskVisibleType.NULL,
          );
        }
      } else {
        vsf?.setStoreValue('gravida.highriskVisible', highriskVisibleType.NULL);
      }
    }
  };
  const handleCancel = e => {
    if (e.target.nodeName === 'SPAN') {
      if (activeKey > 0) {
        setActiveKey(activeKey - 1);
      }
    } else {
      vsf?.setStoreValue('gravida.highriskVisible', highriskVisibleType.NULL);
    }
  };
  const renderChecked = (checked, children, type, data) => {
    if (checked) {
      return (checked || []).map(v => {
        if (!(children || []).some(item => item.item === v)) {
          return `${data.item || data.label || data.value}$${v}$${type}`;
        } else {
          return v;
        }
      });
    }
  };

  const handleMergeData = data => {
    const { BASIC_INFO, PREGNANCY_COMORBIDITY, PREGNANCY_COMPLICATION } = data;
    const formatData = (data, type) => {
      const { children, checked } = data;
      const res = [];
      (children || []).forEach(item => {
        if ((checked || []).includes(item?.item)) {
          res.push(item);
        }
      });
      return {
        children: res,
        checked: renderChecked(checked, res, type, data) || [],
        type,
      };
    };
    const newList = EmptyData.map(item => {
      const label = item.label;
      const baseData = formatData(
        BASIC_INFO.find(bitem => bitem.item === label),
        'BASIC_INFO',
      );
      const complicationData = formatData(
        PREGNANCY_COMORBIDITY.find(citem => citem.item === label),
        'PREGNANCY_COMORBIDITY',
      );
      const infectiousData = formatData(
        PREGNANCY_COMPLICATION.find(iitem => iitem.item === label),
        'PREGNANCY_COMPLICATION',
      );

      return {
        ...item,
        children: []
          .concat(
            (baseData?.children || [])?.map(item => ({
              ...item,
              category: 'BASIC_INFO',
            })),
          )
          .concat(
            (complicationData?.children || []).map(item => ({
              ...item,
              category: 'PREGNANCY_COMORBIDITY',
            })),
          )
          .concat(
            (infectiousData?.children || []).map(item => ({
              ...item,
              category: 'PREGNANCY_COMPLICATION',
            })),
          ),
        checked: []
          .concat(baseData.checked)
          .concat(complicationData.checked)
          .concat(infectiousData.checked)
          .filter(v => v),
        checkedObj: []
          .concat(baseData)
          .concat(complicationData)
          .concat(infectiousData),
      };
    });
    return newList;
  };

  const onChange = data => {
    const targetKey = keyArray[activeKey];
    const targetIndex = highriskData[targetKey].findIndex(
      item => item?.item === data.item,
    );
    const newList = cloneDeep([...highriskData[targetKey]]);
    newList[targetIndex] = {
      ...newList[targetIndex],
      checked: data?.checked,
    };
    // newList.splice(targetIndex, 1, data);
    setHighriskData({
      ...highriskData,
      [targetKey]: newList,
    });

    setSelected(
      handleMergeData({
        ...highriskData,
        [targetKey]: newList,
      }),
    );
  };

  const setDefaultData = (type, data) => {
    if (type === highriskVisibleType.NULL) return;
    let targetData = [];
    const itemObj = cloneDeep(highriskData);
    if (type === highriskVisibleType.ENABLE) {
      targetData =
        data?.highriskRecordList?.[0]?.highriskRecordItemDtoList ?? [];
      setCanEdit(true);
    }
    if (type === highriskVisibleType.LATEST) {
      targetData = data?.highriskCache?.latest?.highriskRecordItemDtoList ?? [];
      setCanEdit(false);
    }
    if (type === highriskVisibleType.HIGHEST) {
      targetData =
        data?.highriskCache?.highest?.highriskRecordItemDtoList ?? [];
      setCanEdit(false);
    }
    targetData.forEach(item => {
      const target = itemObj[item.category];
      const itemTarget = target.find(
        data => data.item === highriskLevel?.[item.level]?.cname,
      );
      if (itemTarget) {
        if (!itemTarget?.checked) {
          itemTarget.checked = [item.name];
        } else {
          itemTarget.checked.push(item.name);
        }
      }
    });
    setHighriskData(itemObj);
    setSelected(handleMergeData(itemObj));
  };

  useEffect(() => {
    setVisible(highriskVisible !== highriskVisibleType.NULL);
    setDefaultData(highriskVisible, gravidaHighrisk);
  }, [highriskVisible]);

  // useEffect(() => {
  //   const arr = highriskData?.BASIC_INFO;
  //   const target = arr[1];
  //   if (target) {
  //     target.checked = ['年龄≥40岁'];
  //     arr.splice(1, 1, target);
  //     setHighriskData({
  //       ...highriskData,
  //       BASIC_INFO: arr,
  //     });
  //     onChange(target);
  //   }
  // }, [highFastModalData]);

  const handleSearch = v => {
    setKeyword(v);
  };
  console.log(keyword,'key')
  return (
    <Modal
      width={1032}
      className="e-medical-record-modal"
      title="高危评估"
      keyboard={true}
      destroyOnClose={true}
      visible={visible}
      id="complaint-modal"
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      okText={
        activeKey < TabNumber - 1
          ? '下一步'
          : canEdit && !forbidEdit(gravida?.gravidaRecord, common)
          ? '保存'
          : '关闭'
      }
      cancelText="上一步"
      cancelButtonProps={{
        hidden: activeKey === 0,
      }}
    >
      <div className="e-medical-highrisk-container">
        <Tabs
          type="line"
          centered={false}
          activeKey={`${activeKey}`}
          onTabClick={akey => {
            setActiveKey(Number(akey));
          }}
        >
          <TabPane key="0" tab="基本信息">
            <HighriskCheck
              onChange={onChange}
              items={(highriskData?.BASIC_INFO || []).map(item => ({
                ...item,
                children: (item?.children || []).filter(
                  citem => citem.value.indexOf(keyword) >= 0,
                ),
                type: 'BASIC_INFO',
              }))}
              keyword = {keyword}
              disabled={
                !canEdit || globalPageType.NURSE === common?.globalPageType
              }
            />
          </TabPane>
          <TabPane key="1" tab="妊娠合并症">
            <HighriskCheck
              onChange={onChange}
              items={(highriskData?.PREGNANCY_COMORBIDITY || []).map(item => ({
                ...item,
                children: (item?.children || []).filter(
                  citem => citem.value.indexOf(keyword) >= 0,
                ),
                type: 'PREGNANCY_COMORBIDITY',
              }))}
              keyword = {keyword}
              disabled={
                !canEdit || globalPageType.NURSE === common?.globalPageType
              }
            />
          </TabPane>
          <TabPane key="2" tab="妊娠并发症">
            <HighriskCheck
              onChange={onChange}
              items={(highriskData?.PREGNANCY_COMPLICATION || []).map(item => ({
                ...item,
                children: (item?.children || []).filter(
                  citem => citem.value.indexOf(keyword) >= 0,
                ),
                type: 'PREGNANCY_COMPLICATION',
              }))}
              keyword = {keyword}
              disabled={
                !canEdit || globalPageType.NURSE === common?.globalPageType
              }
            />
          </TabPane>
          <TabPane key="3" tab="已选因素">
            <HighriskCheck onChange={onChange} items={selected} disabled keyword = {keyword} />
          </TabPane>
        </Tabs>
        <div className="e-medical-highrisk-search">
          <Input.Search onSearch={handleSearch} />
        </div>
      </div>
    </Modal>
  );
};

export default definePage(Index);

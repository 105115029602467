
const getActiveElement = (doc?: any) => {
    doc = doc || (typeof document !== 'undefined' ? document : undefined);
    if (typeof doc === 'undefined') {
        return null;
    }
    try {
        return doc.activeElement || doc.body;
    } catch (e) {
        return doc.body;
    }
}

export function getSelection(input) {
    let selection;

    if ('selectionStart' in input) {
        selection = {
            start: input.selectionStart,
            end: input.selectionEnd,
        };
    }

    return selection || { start: 0, end: 0 };
}

export function setSelection(input, offsets) {
    const start = offsets.start;
    let end = offsets.end;
    if (end === undefined) {
        end = start;
    }

    if ('selectionStart' in input) {
        input.selectionStart = start;
        input.selectionEnd = Math.min(end, input.value.length);
    }
}


export function getSelectionInformation() {
    const focusedElem = getActiveElement();
    return {
        focusedElem: focusedElem,
        selectionRange: getSelection(focusedElem) ?? null
    };
}

export function restoreSelection(priorSelectionInformation) {
    const curFocusedElem = getActiveElement();
    const priorFocusedElem = priorSelectionInformation.focusedElem;
    const priorSelectionRange = priorSelectionInformation.selectionRange;
    if (curFocusedElem !== priorFocusedElem) {
        if (
            priorSelectionRange !== null
        ) {
            setSelection(priorFocusedElem, priorSelectionRange);
        }

        const ancestors: any = [];
        let ancestor: any = priorFocusedElem;
        while ((ancestor = ancestor.parentNode)) {
            if (ancestor.nodeType === 1) {
                ancestors.push({
                    element: ancestor,
                    left: ancestor.scrollLeft,
                    top: ancestor.scrollTop,
                });
            }
        }

        if (typeof priorFocusedElem.focus === 'function') {
            priorFocusedElem.focus();
        }

        for (let i = 0; i < ancestors.length; i++) {
            const info = ancestors[i];
            info.element.scrollLeft = info.left;
            info.element.scrollTop = info.top;
        }
    }
}
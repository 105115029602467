import React, { useEffect, useRef, useState } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { globalPageType } from '@/config';
import {
  VSForm,
  VSFormItem,
  Tabs,
  VSTable,
  VSTableColumn,
  VSTablePagination,
  Button,
  Image,
  Select,
  message,
} from '@vs/vsf-kit';
import { useSize } from 'ahooks';
import PatientReception from '@/static/patient-reception.png';
import PatientNotReception from '@/static/patient-not-reception.png';
import PatientView from '@/static/patient-view.png';
import nurseServices from '@/services/nurse';
import commonServices from '@/module/common/services';
import { useDebounceFn } from 'ahooks';
import { disabledDate, disableEdcDate, formatDate } from '@/utils/rule';
import moment from 'moment';
import querystring from 'query-string';
import { cloneDeep, pickBy, set } from 'lodash';
import { Delete } from '@vs/vsf-icons';

const OutpatientStatusEnum = {
  WAITING: 'WAITING', // 未就诊
  ADMIT: 'ADMIT', // 就诊中
  RECEIVE: 'RECEIVE', // 已就诊
  REFUND: 'REFUND', // 已拒绝
};

const items = [
  {
    label: '全部',
    key: 'all',
    children: '',
  },
  {
    label: '未处理',
    key: OutpatientStatusEnum.WAITING,
    children: '',
  },
  {
    label: '处理中',
    key: OutpatientStatusEnum.ADMIT,
    children: '',
  },
  {
    label: '已处理',
    key: OutpatientStatusEnum.RECEIVE,
    children: '',
  },
];

const Index = () => {
  const { nurse } = vsf?.stores;
  const formRef = useRef();
  const ref = useRef(null);
  const size = useSize(ref);
  const [data, setData] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [tabActiveKey, setTabActiveKey] = useState('all');
  const statusIn = useRef({});
  const listQuery = useRef();
  const currentPage = useRef(1);
  const currentPageSize = useRef(10);
  // const [listQuery, setListQuery] = useState({});

  const defaultPagination = {
    showQuickJumper: true,
    showSizeChanger: true,
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSizeOptions: [10, 20, 30, 50, 100],
    onChange: (page, pageSize) => {
      currentPage.current = page;
      currentPageSize.current = pageSize;
      getPatientList({
        ...listQuery?.current,
        ...statusIn?.current,
        from: page,
        size: pageSize,
      });
    },
    total: 0,
  };
  const [pagination, setPagination] = useState(defaultPagination);

  useEffect(() => {
    if (vsf) {
      vsf.stores.common.globalPageType = globalPageType.NURSE;
    }
  }, [vsf]);

  const getPatientList = async (query = {}) => {
    const formatQuery = pickBy(query, v => v);
    const qtoQuery = {
      size: currentPageSize.current,
      from: currentPage.current,
      admitTimeRangeIn: {
        begin: moment(new Date()).format('YYYY-MM-DD 00:00:00'),
        end: moment(new Date()).format('YYYY-MM-DD 23:59:59'),
        beginInclude: true,
        endInclude: true,
      },
      ...formatQuery,
      orderList: [{ fieldName: 'sequence', asc: true }],
    };
    if (query.admitTimeRangeIn === null) {
      Reflect.deleteProperty(qtoQuery, 'admitTimeRangeIn');
    }
    const res = await nurseServices.getPatientList({
      qto: qtoQuery,
      ext: {},
    });
    if (res?.code === 301) {
      vsf?.navigateTo(`/nurseLogin`);
    } else if (res?.code === 200) {
      setPagination({
        ...pagination,
        total: res?.data?.count,
      });
      setData(res?.data?.result || []);
    }
  };

  const getPatientDoctorList = async () => {
    const res = await commonServices.patientDoctorList({
      status: 'ONLINE',
    });
    const list = (cloneDeep(res?.data) || []).map(item => ({
      label: item.name,
      value: item.doctorId,
      origin: item,
    }));
    setDoctorList(list);
  };

  const getPatientpDepartmentList = async () => {
    const res = await commonServices.patientDepartmentList({
      status: true,
    });
    setDepartmentList(
      (res?.data || []).map(item => ({
        label: item.departmentName,
        value: item.departmentId,
        origin: item,
      })),
    );
  };

  // 模糊搜索主诉（防抖）
  const { run } = useDebounceFn(
    value => {
      getPatientList(value);
    },
    {
      wait: 200,
    },
  );

  useEffect(() => {

    if (tabActiveKey === 'all') {
      statusIn.current = {};
    } else {
      statusIn.current = {
        ownStatusIn: [tabActiveKey],
      };
    }
    currentPage.current = 1;
    const tabQuery = {
      ...listQuery?.current,
      ...statusIn?.current,
      from: 1,
    }
    if (tabActiveKey === 'all') {
      Reflect.deleteProperty(tabQuery, 'ownStatusIn');
    }
    getPatientList(tabQuery);
  }, [tabActiveKey]);

  useEffect(() => {
    getPatientDoctorList();
    getPatientpDepartmentList();

    // 将fromErron清空
    nurse?.formError?.length > 0 && (nurse.formError = null);
  }, []);

  const handleReset = () => {
    formRef.current.resetFields();
    // setListQuery({});
    currentPage.current = 1;
    currentPageSize.current = 10;
    listQuery.current = {};
    getPatientList({
      ...statusIn?.current,
    });
  };

  const handleDealStatus = async data => {
    const { id: currentNurseId } = nurse?.user || {};
    const { ownStatus, nurseId } = data;
    let result = {
      status: true,
      message: '',
    };
    if (ownStatus === OutpatientStatusEnum.WAITING) {
      const res = await commonServices.updateRegisteredStatus({
        btoParam: {
          hisRecordId: data?.hisRecordId,
          ownStatus: 'ADMIT',
        },
      });
      result = {
        status: res?.code === 200,
        message: res?.message,
      };
    }
    if (ownStatus === OutpatientStatusEnum.ADMIT) {
      if (nurseId !== currentNurseId) {
        result = {
          status: false,
          message: '该患者处理中',
        };
      }
    }
    return result;
  };

  const handleToNurse = async (data, extra) => {
    const res = await commonServices.getPatientDetail({
      id: data?.id,
    });
    if (res?.code === 200) {
      const result = res?.data;
      const query = {
        hisId: result?.patientId,
        // hisId: result?.patientCard,
        hisRecordId: result?.hisRecordId,
        type: 1,
        doctorId: result?.nurseId,
        history: 0,
      };
      const statusQuery = await handleDealStatus(result);
      if (statusQuery?.status) {
        vsf?.navigateTo(
          `/nurse?${querystring.stringify({
            ...query,
            ...(statusQuery.params || {}),
            ...(extra || {}),
          })}`,
        );
      } else {
        message.warning(statusQuery?.message);
      }
    }
  };

  const handleValuesChange = (record, allValues) => {
    const formatQuery = {
      ...statusIn?.current,
    };

    Object.keys(allValues).forEach(key => {
      switch (key) {
        case 'patient':
          if (allValues[key] !== undefined && allValues[key] !== null) {
            formatQuery.patientNameLike = allValues[key];
          }
          break;
        case 'time':
          if (
            allValues[key]?.length === 2 &&
            allValues[key][0] &&
            allValues[key][1]
          ) {
            formatQuery.admitTimeRangeIn = {
              begin: moment(allValues[key][0]).format('YYYY-MM-DD HH:mm:00'),
              end: moment(allValues[key][1]).format('YYYY-MM-DD HH:mm:00'),
              beginInclude: true,
              endInclude: true,
            };
          } else {
            formatQuery.admitTimeRangeIn = null;
          }
          break;
        case 'registeredDoctorNameIn':
          const target = doctorList?.find(
            item => item?.origin?.doctorId === allValues[key],
          );
          formatQuery.registeredDoctorNameIn = [target?.label];
          break;
        case 'shiftIn':
          formatQuery.shiftIn =
            allValues[key] && allValues[key]?.length > 0
              ? allValues[key]
              : null;
          break;
        default:
          formatQuery[key] = allValues[key];
          break;
      }
    });
    currentPage.current = 1;
    listQuery.current = formatQuery;
    if (record?.patient !== undefined && record?.patient !== null) {
      run(formatQuery);
    } else {
      getPatientList(formatQuery);
    }
  };

  return (
    <div className="e-medical-nurse-patient">
      <div className="top-search">
        <VSForm
          dataType="static"
          layout="horizontal"
          formRef={formRef}
          initialValues={{}}
          onValuesChange={handleValuesChange}
        >
          <VSFormItem
            label=""
            name={['patient']}
            valueType="text"
            fieldProps={{
              width: 300,
              placeholder: '搜索卡号/患者姓名',
            }}
          ></VSFormItem>
          <VSFormItem
            label="挂号科室"
            name={['departmentIdIs']}
            valueType="select"
            fieldProps={{
              width: 200,
              // allowClear: true,
              showSearch: true,
              placeholder: '请选择挂号科室',
            }}
            dataSource={departmentList}
          ></VSFormItem>
          <VSFormItem
            label="挂号医生"
            name={['registeredDoctorNameIn']}
            valueType="select"
            fieldProps={{
              width: 200,
              // allowClear: true,
              showSearch: true,
              placeholder: '请选择挂号医生',
            }}
            dataSource={doctorList}
          ></VSFormItem>
          <VSFormItem
            label="挂号时间"
            name={['time']}
            valueType="dateRange"
            fieldProps={{
              width: 300,
              placeholder: ['开始时间', '结束时间'],
              showTime: true,
              defaultValue: [
                moment(new Date()).format('YYYY-MM-DD 00:00:00'),
                moment(new Date()).format('YYYY-MM-DD 23:59:59'),
              ],
            }}
          ></VSFormItem>
          <VSFormItem
            label=""
            name={['shiftIn']}
            valueType="checkbox"
            dataSource={[
              {
                label: '上午',
                value: '0',
              },
              {
                label: '下午',
                value: '1',
              },
            ]}
          ></VSFormItem>
          <Button type="primary" onClick={handleReset}>
            重置
          </Button>
        </VSForm>
      </div>
      <div className="main-table">
        <Tabs
          defaultActiveKey="all"
          items={items}
          onChange={e => {
            setTabActiveKey(e);
            // this.props.changeLoginMethod(e);
          }}
        ></Tabs>
        <div className="patient-table" ref={ref}>
          <VSTable
            scroll={{ y: size?.height - 130 }}
            dataType="static"
            rowClassName={(record, index) => 'table-row'}
            dataSource={data}
          >
            <VSTableColumn
              title="操作"
              valueType="date"
              dataIndex="operation"
              columnKey="operation"
              width={100}
              fixed="left"
              key="operation"
              render={(v, record) => {
                return (
                  <div className="patient-operation">
                    {record?.ownStatus === OutpatientStatusEnum.WAITING && (
                      <Image
                        src={PatientReception}
                        preview={false}
                        onClick={() => {
                          handleToNurse(record);
                        }}
                      />
                    )}
                    {record?.ownStatus === OutpatientStatusEnum.ADMIT && (
                      <>
                        {nurse?.user?.id === record?.nurseId ? (
                          <Image
                            src={PatientReception}
                            preview={false}
                            onClick={() => {
                              handleToNurse(record);
                            }}
                          />
                        ) : (
                          <Image
                            src={PatientNotReception}
                            preview={false}
                            className="patient-reception-ing"
                            onClick={() => {
                              // handleToNurse(record);
                            }}
                          />
                        )}
                      </>
                    )}
                    {record?.ownStatus === OutpatientStatusEnum.RECEIVE && (
                      <>
                        {nurse?.user?.id === record?.nurseId ? (
                          <Image
                            src={PatientReception}
                            preview={false}
                            onClick={() => {
                              handleToNurse(record);
                            }}
                          />
                        ) : (
                          <Image
                            src={PatientNotReception}
                            preview={false}
                            className="patient-reception-ing"
                            onClick={() => {
                              // handleToNurse(record);
                            }}
                          />
                        )}
                        <Image
                          src={PatientView}
                          preview={false}
                          onClick={() => {
                            handleToNurse(record, {
                              disabled: 1,
                            });
                          }}
                        />
                      </>
                    )}

                    {record?.ownStatus === OutpatientStatusEnum.REFUND && (
                      <Image
                        src={PatientNotReception}
                        preview={false}
                        className="patient-reception-ing"
                        onClick={() => {
                          // handleToNurse(record);
                        }}
                      />
                    )}
                  </div>
                );
              }}
            ></VSTableColumn>
            <VSTableColumn
              title="就诊状态"
              valueType="text"
              dataIndex="ownStatus"
              columnKey="ownStatus"
              width={100}
              key="ownStatus"
              render={(v, record) => {
                switch (record?.ownStatus) {
                  case OutpatientStatusEnum.WAITING:
                    return '未处理';

                  case OutpatientStatusEnum.ADMIT:
                    return '处理中';

                  case OutpatientStatusEnum.RECEIVE:
                    return '已处理';

                  case OutpatientStatusEnum.REFUND:
                    return '退号';

                  default:
                    return '';
                }
              }}
            ></VSTableColumn>
            <VSTableColumn
              title="候诊序号"
              valueType="text"
              dataIndex="sequence"
              columnKey="sequence"
              width={100}
              key="sequence"
            ></VSTableColumn>
            <VSTableColumn
              title="卡号"
              valueType="text"
              dataIndex="patientCard"
              columnKey="patientCard"
              key="patientCard"
              width={100}
            ></VSTableColumn>
            <VSTableColumn
              title="患者姓名"
              valueType="text"
              dataIndex="patientName"
              columnKey="patientName"
              key="patientName"
              width={100}
            ></VSTableColumn>
            <VSTableColumn
              title="年龄"
              valueType="text"
              dataIndex="patientAge"
              columnKey="patientAge"
              key="patientAge"
              width={100}
            ></VSTableColumn>
            <VSTableColumn
              title="费用类型"
              valueType="text"
              dataIndex="expenseType"
              columnKey="expenseType"
              key="expenseType"
              width={100}
              render={(v, record) => {
                switch (record?.expenseType) {
                  case '0':
                    return '自费';
                  case '1':
                    return '医保';
                  default:
                    return '';
                }
              }}
            ></VSTableColumn>
            <VSTableColumn
              title="挂号科室"
              valueType="text"
              dataIndex="departmentName"
              columnKey="departmentName"
              key="departmentName"
              width={100}
            ></VSTableColumn>
            <VSTableColumn
              title="挂号医生"
              valueType="text"
              dataIndex="registeredDoctorName"
              columnKey="registeredDoctorName"
              key="registeredDoctorName"
              width={100}
            ></VSTableColumn>
            {/* <VSTableColumn
              title="上次接诊医生(缺失)"
              valueType="text"
              dataIndex="checkItemName"
              columnKey="checkItemName"
              key="checkItemName"
              width={100}
            ></VSTableColumn> */}
            <VSTableColumn
              title="本次诊断"
              valueType="text"
              dataIndex="diagnosis"
              columnKey="diagnosis"
              key="diagnosis"
              width={300}
            ></VSTableColumn>
            <VSTableColumn
              title="上下午"
              valueType="text"
              dataIndex="shift"
              columnKey="shift"
              key="shift"
              width={100}
              render={(v, record) => {
                switch (record?.shift) {
                  case '0':
                    return '上午';
                  case '1':
                    return '下午';
                  default:
                    return '';
                }
              }}
            ></VSTableColumn>
            <VSTableColumn
              title="是否建档"
              valueType="text"
              dataIndex="hasGravidaRecord"
              columnKey="hasGravidaRecord"
              key="hasGravidaRecord"
              width={100}
              render={(v, record) => {
                return record?.hasGravidaRecord ? '已建档' : '未建档';
              }}
            ></VSTableColumn>
            <VSTableColumn
              title="本次挂号日期"
              valueType="text"
              dataIndex="receiveTime"
              columnKey="receiveTime"
              key="receiveTime"
              width={100}
            ></VSTableColumn>
            <VSTablePagination
              {...pagination}
              current={currentPage.current}
              pageSize={currentPageSize.current}
            />
          </VSTable>
        </div>
      </div>
    </div>
  );
};

export default definePage(Index);

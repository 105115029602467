import React, { useEffect, useState } from 'react';
import { Tag } from '@vs/vsf-kit';
import { highriskLevel, colorBarMap, highriskVisibleType } from '@/config';
import './index.less';

export interface TagType {
  value: string;
  onChange: (value: any) => void;
}

const Index = (props: TagType) => {
  const { value, onChange } = props;

  if (!value) return null;

  const level = value;
  let node = highriskLevel[level] ? (
    <Tag color={colorBarMap[level]}>{highriskLevel[level]?.label}</Tag>
  ) : null;
  if (typeof value === 'boolean' && value) {
    node = (
      <Tag color={colorBarMap.PURPLE}>{highriskLevel['PURPLE']?.label}</Tag>
    );
  }
  return <>{node}</>;
};

export default Index;

import { cloneDeep } from 'lodash';

export const removeLastLetter = str => {
  return typeof str === 'string' && /;|；/g.test(str?.[str?.length - 1])
    ? str.substring(str?.length - 1, 0)
    : str;
};

export const handleFormatHpi = data => {
  const { checked, all } = data;
  const checkedArr = checked.map(item => item?.itemName);
  // const checkedArr = checked.map(item => item?.itemName?.replace('无', '有'));
  const uncheckedArr = (all || [])
    .filter(
      item => checked.findIndex(citem => citem.itemName === item.name) < 0,
    )
    .map(item => {
      let newName = item.name || '';
      if (newName.indexOf('有') > 0) {
        newName = newName.replace('有', '无');
      } else {
        newName = newName.replace('无', '有');
      }
      return newName;
    });
  return `${checkedArr.join(';')};${uncheckedArr.join(';')}`;
};

export const handleFormatError = data => {
  const {
    physicalInspections,
    familyHistorySnapshotDto,
    mensesInfoSnapshotDto,
    personalHistorySnapshotDto,
  } = data;

  const res = {};
  if (physicalInspections) {
    res['case'] = removeLastLetter(caseStr(physicalInspections));
  }
  if (mensesInfoSnapshotDto) {
    res['gravida'] = removeLastLetter(gravidaStr(mensesInfoSnapshotDto));
  }
  if (personalHistorySnapshotDto || familyHistorySnapshotDto) {
    res['outline'] = removeLastLetter(
      outlineStr({
        ...personalHistorySnapshotDto,
        extra: familyHistorySnapshotDto,
      }),
    );
  }
  if (physicalInspections) {
    res['phy'] = removeLastLetter(' ');
  }

  return res;
};

export const caseStr = data => {
  const { virginalBleed, virginalDischarge, fetusMovement , bellyache , bellyacheDurationTime , bellyacheIntervalTime } = data || {};
  console.log(data,'data')
  let str = '';
  if (virginalBleed) {
    virginalBleed !== '无'
      ? (str += '有阴道流血;')
      : (str += `${virginalBleed}阴道流血;`);
  }
  if (virginalDischarge) {
    virginalDischarge !== '无'
    ? (str += '有阴道流液;')
    : (str += `${virginalDischarge}阴道流液;`);
  }
  if (fetusMovement) {
    str += `胎动${fetusMovement};`;
  }
  if(bellyache){
    bellyache !== '规律' ?str += `${bellyache}下腹痛`:str += `${bellyache}下腹痛:${ bellyacheDurationTime ? `持续时间${bellyacheDurationTime}秒,`:''}${bellyacheIntervalTime ?`间隔时间${bellyacheIntervalTime}分钟`:''}`
  }
  return str;
};

export const renderBloodPressureStr = data => {
  if (data.diastolicPressure && data.diastolicPressure) {
  }
};
export const renderBloodPressureUnusual = (
  diastolicPressure,
  systolicPressure,
) => {
  if (diastolicPressure > 126) {
    return '偏高';
  }
};

export const gravidaStr = data => {
  const { menstrualVolume, dysmenorrhea, leucorrhea } = data || {};
  const menstrualVolumeStr = menstrualVolume ? `经量${menstrualVolume}；` : '';
  const dysmenorrheaStr = dysmenorrhea ? `痛经${dysmenorrhea}；` : '';
  const leucorrheaStr = leucorrhea ? `白带${leucorrhea}；` : '';
  return `${menstrualVolumeStr}${dysmenorrheaStr}${leucorrheaStr}`;
};

const outlineMap = {
  // rhNegative: 'RH-阴性血',
  // nllrHistory: '外地久居住史',
  epidemicFocus: '疫水疫源接触史',
  smokingHistory: '吸烟史',
  drinkingHistory: '喝酒史',
  // oftenDepressed: '经常情绪低落史',
  // oftenUninterested: '经常做事没兴趣',
  vaccinationHistory: '预防接种史',
  harmfulSubstanceContactHistory: '有毒有害物质',
  radiationContactHistory: '接触放射线',
  geneticHistory: '家族遗传病史',
  tumorHistory: '家族肿瘤病史',
  infectiousHistory: '家族传染病史',
};

export const outlineStr = data => {
  const { extra } = data;
  const rhNegativeStr = getValue(data, 'rhNegative') ? `RH阴性血；` : '';
  const nllrHistoryStr = `${
    getValue(data, 'nllrHistory')
      ? '有外地久居史；'
      : getValue(data, 'nllrHistory') === false
      ? '无外地久居史；'
      : ''
  }`;
  const oftenDepressedStr =
    getValue(data, 'oftenDepressed') === false ? '否认经常情绪低落；' : '';
  const oftenUninterestedStr =
    getValue(data, 'oftenUninterested') === false
      ? `否认经常做事没有兴趣；`
      : '';
  const staticStr = `${rhNegativeStr}${nllrHistoryStr}`;
  const target = Object.keys(data);
  let otherStr = '';
  target.forEach(key => {
    if (outlineMap[key]) {
      if (getValue(data, key)) {
        otherStr += `${getValue(data, key) ? '有' : '无'}${outlineMap[key]}；`;
      }
    }
  });
  const extraTarget = Object.keys(extra ?? {});
  let extraStr = '';
  extraTarget.forEach(key => {
    if (outlineMap[key]) {
      if (getValue(extra, key)) {
        extraStr += `${getValue(extra, key)}${outlineMap[key]}；`;
      }
    }
  });
  return `${staticStr}${otherStr}${oftenDepressedStr}${oftenUninterestedStr}${extraStr}`;
};

export const getValue = (data, key, returnDetail) => {
  const target = data[key];
  if (typeof target === 'object') {
    return returnDetail ? target?.[`${key}Detail`] : target?.[key];
  }
  return returnDetail ? data[`${key}Detail`] : data[key];
};

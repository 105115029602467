import { Modal } from '@vs/vsf-kit';
import React, { memo, useEffect, useRef, useState } from 'react';
import reportServices from '@/services/report';
import ReportForm from './ReportForm';

interface ModalFromProps {
  modalData: {
    visible: boolean;
    id?: string;
  };
  onClose: (type: string, value?: any) => void;
}
const ModalForm = memo(({ modalData, onClose }: ModalFromProps) => {
  const [formItemData, setFormItemData] = useState<Record<string, any> | null>(
    null,
  );
  const formRef = useRef<any>(null);
  useEffect(() => {
    (async function fn() {
      const { data } = await reportServices.getRepeatInspectionRecordById({
        id: modalData.id as string,
      });
      data && setFormItemData(data);
    })();
  }, []);

  const onFinsh = async () => {
    if (formRef.current) {
      const values = await formRef.current.formWarpRef.validateFields();
      onClose('finish', {
        ...values,
        zhidao: values.zhidao && Array.isArray(values.zhidao) ? values.zhidao.join(',') : values.zhidao,
        gaoweiysdm: values.gaoweiysdm && Array.isArray(values.gaoweiysdm) ? values.gaoweiysdm.join(',') : values.gaoweiysdm,
        id: modalData.id
      });
    }
  };
  return (
    <Modal
      width={'80vw'}
      title="复检记录"
      keyboard={true}
      destroyOnClose={true}
      okText="保存"
      cancelText="取消"
      open={modalData.visible}
      onCancel={() => onClose('close')}
      onOk={onFinsh}
      bodyStyle={{ height: '70vh', overflowY: 'auto' }}
    >
      {formItemData ? (
        <ReportForm ref={formRef} formItemData={formItemData} />
      ) : null}
    </Modal>
  );
});

export default ModalForm;

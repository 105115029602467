import React, { useEffect, useState, useRef } from 'react';
import image from '@/static/image.png';
import { chronicDiseaseArray } from './config';
import staticCommonService from '@/module/common/services';
import moment from 'moment';
import vsf, { definePage } from '@vs/vsf-boot';
import './index.less';

const Index = props => {
  const { data } = props;
  const [print, setPrint] = useState<any>(null);

  // 婚姻状况
  const handleRenderMarriage = data => {
    const {
      gravidaInfoSnapshotDto,
      pregnancyInfoSnapshotDto,
      spouseInfoSnapshotForPrintDto,
      pregnancyInfoSnapshotForPrintDto,
    } = data || {};
    const arr: string[] = [
      gravidaInfoSnapshotDto?.maritalStatus,
      pregnancyInfoSnapshotForPrintDto?.parity > 1 ? '已育' : '未育',
    ];
    if (spouseInfoSnapshotForPrintDto?.healthyStatus) {
      arr.push(`配偶健康状况 ${spouseInfoSnapshotForPrintDto?.healthyStatus}`);
    }
    return arr.join('，');
  };

  // 食物药物病史
  const handleRenderAllergy = data => {
    return {
      drug: data?.personalHistorySnapshotDto?.drugAllergyHistory ? '有' : '无',
      food: data?.personalHistorySnapshotDto?.foodAllergyHistory ? '有' : '无',
    };
  };

  // 慢性病史
  const handleChronicDisease = data => {
    const { personalHistorySnapshotDto } = data || {};
    const res = chronicDiseaseArray?.map((v, i) => {
      return `${personalHistorySnapshotDto?.[v?.name] ? '有' : '无'}${
        v?.cname
      }`;
    });
    return res.join('，');
  };

  // 检查/检验
  const handleRenderExam = data => {
    const { outpatientTreatment, outpatientInspections } = data || {};
    let outpatientTreatmentStr;
    let outpatientInspectionsStr;
    try {
      outpatientTreatmentStr = JSON.parse(outpatientTreatment);
      outpatientInspectionsStr = JSON.parse(outpatientInspections);
    } catch {
      outpatientTreatmentStr = outpatientTreatment;
      outpatientInspectionsStr = outpatientInspections;
    }

    return {
      exam: outpatientTreatmentStr,
      labTest: outpatientInspectionsStr,
    };
  };

  // // 体格检查
  const handleRenderPhysical = data => {
    const { physicalInspectionsForPrintDto } = data || {};
    const { diastolicPressure, systolicPressure, weight, pulse, temperature } =
      physicalInspectionsForPrintDto || {};
    const res: string[] = [];
    if (diastolicPressure && systolicPressure) {
      res.push(`血压：${systolicPressure}/${diastolicPressure}`);
    }
    if (weight) {
      res.push(`体重：${weight}kg`);
    }
    if (pulse) {
      res.push(`脉搏：${pulse}次/分`);
    }
    if (temperature) {
      res.push(`体温：${temperature}℃`);
    }
    return res.join('，');
  };

  // 流行病
  const handleEpidemic = data => {
    const {
      allEpidemiologicalData,
      checkEpidemiologicalData,
      medicalPrintData,
    } = data || {};
    let arr: string[] = [];
    const { physicalInspectionsForPrintDto } = medicalPrintData || {};
    // const { epidemiologicalHistoryItemDtoList } =
    //   checkEpidemiologicalData || {};

    // let choiceEpidemicArr = allEpidemiologicalData;
    // let initialEpidemicArr =
    //   checkEpidemiologicalData && Array.isArray(checkEpidemiologicalData)
    //     ? checkEpidemiologicalData
    //     : [];
    // let notSelectedArr = (initialEpidemicArr || [])?.filter(v => {
    //   return !(
    //     choiceEpidemicArr?.epidemiologicalHistoryItemDtoList || []
    //   )?.find(item => v?.item === item?.itemName);
    // });
    // notSelectedArr = notSelectedArr.map(v => {
    //   if (v?.item && typeof v?.item === 'string') {
    //     if (v.item.charAt(0) === '无') {
    //       v.itemName = v.item.replace('无', '有');
    //     } else if (v.item.charAt(0) === '近') {
    //       v.itemName = `否认${v.item}`;
    //     }
    //     arr.push(v.itemName);
    //   }
    //   return v;
    // });
    // choiceEpidemicArr = choiceEpidemicArr?.epidemiologicalHistoryItemDtoList?.map(
    //   v => {
    //     if (v?.itemName && typeof v?.item === 'string') {
    //       if (v.itemName.charAt(0) === '无') {
    //         v.itemName = v.itemName.replace('无', '否认');
    //       } else if (v.itemName.charAt(0) === '近') {
    //         v.itemName = `有${v.itemName}`;
    //       }
    //     }
    //     arr.push(v.itemName);
    //   },
    // );
    const last = [
      {
        name: '阴道流液',
        item: 'virginalDischarge',
        itemname: '',
      },
      {
        name: '阴道流血',
        item: 'virginalBleed',
        itemname: '',
      },
      {
        name: '胎动',
        item: 'fetusMovement',
        itemname: '',
      },
    ];
    last.map(v => {
      if (
        physicalInspectionsForPrintDto?.[v.item] &&
        physicalInspectionsForPrintDto?.[v.item] === '无'
      ) {
        v.itemname = `无${v.name}`;
      } else if (
        physicalInspectionsForPrintDto?.[v.item] &&
        physicalInspectionsForPrintDto?.[v.item] !== '无'
      ) {
        v.itemname = `${v.name}${physicalInspectionsForPrintDto?.[v.item]}`;
      }
      arr.push(v.itemname);
    });
    return arr.filter(v => v).join('，');
  };

  const initPrintObj = data => {
    const { medicalPrintData } = data;
    const formatData: any = {
      printTime: moment(new Date()).format('YYYY/MM/DD'),
      complaint: medicalPrintData?.complaintStructure,
      chineseMedicine: medicalPrintData?.chineseMedicine,
      westernMedicine: medicalPrintData?.westernMedicine,
      diagnosisStructure: medicalPrintData?.diagnosis,
      physicalInspectionsInListDto: handleRenderPhysical(medicalPrintData),
      gravidaInfoSnapshotDto: medicalPrintData?.gravidaInfoSnapshotDto || '',
      No: medicalPrintData?.hisRecordId || '',
      cardNo: medicalPrintData?.gravidaRecordId || '',
      hpi: medicalPrintData?.hpi,
      departmentName: props?.stores?.gravida?.externalName?.departmentName,
      createdAt: medicalPrintData?.createdAt
        ? moment(medicalPrintData?.createdAt).format('YYYY/MM/DD')
        : '',
      marriage: handleRenderMarriage(medicalPrintData),
      medicalRecordType:
        medicalPrintData?.medicalRecordType === 'FIRST_VISIT' ? '初诊' : '复诊',
      allergy: handleRenderAllergy(medicalPrintData),
      chronicDisease: handleChronicDisease(medicalPrintData),
      epidemic: handleEpidemic(data),
      doctorOrder: handleRenderExam(medicalPrintData),
    };

    try {
      formatData.chineseMedicine = JSON.parse(
        medicalPrintData?.chineseMedicine,
      );
      formatData.westernMedicine = JSON.parse(
        medicalPrintData?.westernMedicine,
      );
    } catch {}
    console.log(formatData, 'formatData');
    setPrint(formatData);
  };

  useEffect(() => {
    initPrintObj(data);
  }, [data]);

  if (!print || !print?.cardNo) return null;

  return (
    <div className="e-medical-print">
      <table>
        <thead>
          <tr>
            <td>
              <div className="top"></div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="PrintTop">
                <div>
                  <div className="printTitle">
                    <span className="frequency">
                      {`(${print?.medicalRecordType})`}
                    </span>
                    <div className="title">嘉兴市妇幼保健院门诊病历</div>
                    <div className="code">
                      <img src={image}></img>
                      <span>就医体验反馈</span>
                    </div>
                  </div>
                  <div className="information">
                    <div className="Columns">
                      <div className="Column1">
                        NO：<span>{print?.No}</span>
                      </div>
                      <div className="Column2">
                        就诊卡号：
                        <span>{print?.gravidaInfoSnapshotDto?.hisId}</span>
                      </div>
                      <div className="Column3">
                        科别：<span>{print?.departmentName}</span>
                      </div>
                    </div>
                    <div className="Columns">
                      <div className="Column1">
                        姓名：
                        <span>{print?.gravidaInfoSnapshotDto?.name}</span>
                      </div>
                      <div className="Column2">
                        性别：<span>女</span>
                      </div>
                      <div className="Column3">
                        年龄：
                        <span>{print?.gravidaInfoSnapshotDto?.age}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="PrintMiddle">
                  <div className="chiefComplaint">
                    <span className="title">【主诉】</span>
                    <span className="content">{print.complaint || '无'}</span>
                  </div>
                  <div className="currentDisease">
                    <span className="title">【现病史】</span>
                    <span className="content">{print.hpi}</span>
                  </div>
                  <div className="epidemic">
                    <span>{print?.epidemic}</span>
                  </div>
                  <div className="past">
                    <div className="pasttitle">
                      <span className="title">【既往史】</span>
                      <span className="pasttitle1">慢性病史：</span>
                      <span className="content">{print?.chronicDisease}</span>
                    </div>
                    <div className="Subitem">
                      <span>过敏史：</span>
                      <span className="content">
                        药物过敏史:{print?.allergy?.drug}
                      </span>
                      <span className="content">
                        食物过敏史:{print?.allergy?.food}
                      </span>
                    </div>
                    <div className="Subitem">
                      <span>婚育史：</span>
                      <span className="content">{print?.marriage}</span>
                    </div>
                  </div>
                  <div className="physique">
                    <span className="title">【体格检查】</span>
                    <span className="content">
                      {print?.physicalInspectionsInListDto}
                    </span>
                  </div>
                  <div className="Preliminary">
                    <span className="title">【初步诊断】</span>
                    <span className="content">
                      {print?.diagnosisStructure || '无'}
                    </span>
                  </div>
                  <div className="prescription">
                    <span className="title">【门诊处方】</span>
                    {print?.westernMedicine?.prescriptionStatus !==
                      'DELETE' && (
                      <>
                        <span className="bold">{'<西药>'}：</span>
                        <div className="medicine">
                          {print?.westernMedicine?.westernMedicine
                            ? (
                                print?.westernMedicine?.westernMedicine || []
                              )?.map((v, i) => {
                                return (
                                  <div className="list" key={i}>
                                    <span className="medicineName">
                                      {v?.medicineName}
                                    </span>
                                    <span>
                                      {v?.medicineAmount}
                                      {'(' +
                                        v?.medicineQuantity +
                                        v?.medicinePriority +
                                        ')'}
                                    </span>
                                    <span>{v?.medicineRepeatPattern}</span>
                                    <span>{v?.medicineUseMethod}</span>
                                  </div>
                                );
                              })
                            : ''}
                        </div>
                      </>
                    )}
                    {print?.chineseMedicine?.prescriptionStatus !==
                      'DELETE' && (
                      <div className="ChineseMedicine">
                        <span className="bold">{'<中药>'}：</span>
                        <div>
                          {print?.chineseMedicine?.chineseMedicine?.herbsList
                            ? (
                                print?.chineseMedicine?.chineseMedicine
                                  ?.herbsList || []
                              )?.map(v => {
                                return (
                                  <div>
                                    <div className="name">
                                      <span className="DrugName">
                                        {v?.herbsName}
                                      </span>
                                      <span className="use">
                                        {v?.herbsUseMethod}
                                      </span>
                                      <span>
                                        {v?.herbsAmount}
                                        {v?.herbsAmountPriority}
                                      </span>
                                      <span>{v?.herbsRepeatPattern}</span>
                                    </div>
                                  </div>
                                );
                              })
                            : ''}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="inspect">
                    <span className="title">【检查医嘱】</span>
                    <div className="doctorOrder">
                      {print?.doctorOrder?.exam}
                    </div>
                  </div>
                  <div className="test">
                    <span className="title">【检验医嘱】</span>
                    <div className="doctorOrder">
                      {print?.doctorOrder?.labTest}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          {/*  */}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className="foot">&nbsp;</div>
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="PrintFoot">
        <div>
          就诊时间：<span>{print?.createdAt}</span>
        </div>
        <div>
          打印时间：<span>{print?.printTime}</span>
        </div>
        <div className="name">
          医生签名：<span></span>
        </div>
      </div>
    </div>
  );
};

export default definePage(Index);

import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { formatDate, showDateFormat } from '@/utils/rule';
import { message } from '@/utils';
import moment from 'moment';
import { unwrapObject, wrapObject, pickObject } from '@/components/utils';
import { labelColWidth } from '@/config';
import {
  VSForm,
  TabPane,
  Tabs,
  VSEditableTable,
  VSEditableTableAddition,
  VSTableColumn,
  Checkbox,
  VSFormItem,
} from '@vs/vsf-kit';
import { FormBlock } from '@/components/form-layout';
import {
  FormBlockType,
  baseInfoFormItem,
  spouseInfoFormItem,
  pregnancyInfoFormItem,
  recordPersonalHistoryFormItem,
  recordFamilyHistoryFormItem,
} from '@/pages/record/components/record/formitem';
import Allergy from '@/pages/allergy';
import Radiostr from '@/components/radiostr';
import { getGestationalWeek, getBirthday, getAge } from '@/utils';
import { globalPageType } from '@/config';
import { forbidEdit } from '@/pages/nurse/utils';

import gravidaServices from '@/services/gravida';
import staticCommonService from '@/module/common/services';
import {
  recordPersonHistoryDefaultDeny,
  recordPersonHistoryDefault,
  recordPersonHistory2DefaultDeny,
  recordPersonHistory2Default,
  recordFamilyHistoryDefaultDeny,
  recordFamilyHistoryDefault,
} from './config';

import {
  recordBaseInfoDefaultValue,
  recordSpouseInfoDefaultValue,
  recordPregnancyInfoDefaultValue,
} from '@/config/formitem';

import '../../index.less';
import Pregnancy from '@/pages/pregnancy';

/**
 * 产妇建档弹框
 **/

const TabNumber = 5;

export const renderServiceHisDoctorList = (str, arr, auditStatus) => {
  const defaultObj = {
    orgId: '330402004',
    orgName: '嘉兴市妇幼保健院',
  };
  // auditStatus !== 'HEALTH_BOOK_AUDIT'
  if (!str && auditStatus !== 'HEALTH_BOOK_AUDIT') return defaultObj;
  const obj = (arr ?? []).filter(v => {
    return str === v.orgId;
  });
  return (
    (obj.length > 0 && obj[0]) || {
      orgId: str,
      orgName: '',
    }
  );
};

export const handleScroll = () => {
  const target = document?.querySelector(
    '.e-medical-record-modal .ant-tabs-content-holder',
  );
  if (target) {
    target.scrollTo(0, 0);
    target.scrollTop = 0;
  }
};

const Index = props => {
  const { gravida, common, medical } = vsf?.stores;

  const {
    gravidaInfo,
    gravidaRecord,
    gravidaAllergyHistory: gravidaAllergyHistoryStores,
  } = gravida;
  const {
    gravidaRecordId,
    hisId,
    hisRecordId,
    doctorInfo,
    addressData,
    enums,
    allergyDictionary,
  } = common;
  const { recordRef, handleKeyChange, handleFormSubmit } = props;
  const [activeKey, setActiveKey] = useState(0);
  const [hisDoctorList, setHisDoctorList] = useState();

  const [gravidaUserInfo, setGravidaUserInfo] = useState({
    ...recordBaseInfoDefaultValue,
    ...wrapObject(gravidaInfo),
  });

  const [gravidaRecordInfo, setGravidaRecordInfo] = useState({
    ...gravidaRecord,
    spouseInfo: {
      ...recordSpouseInfoDefaultValue,
      ...wrapObject(gravidaRecord?.spouseInfo, 'address'),
    },
    pregnancyInfo: {
      ...recordPregnancyInfoDefaultValue,
      ...pickObject(gravidaRecord?.pregnancyInfo),
      mpas: common.gravidaRecordId
        ? {
            mature: gravidaRecord?.pregnancyInfo?.mature,
            premature: gravidaRecord?.pregnancyInfo?.premature,
            abortion: gravidaRecord?.pregnancyInfo?.abortion,
            survival: gravidaRecord?.pregnancyInfo?.survival,
          }
        : recordPregnancyInfoDefaultValue?.mpas,
    },
  });

  const [gravidaAllergyHistory, setGravidaAllergyHistory] = useState(
    gravidaAllergyHistoryStores,
  );
  // 孕产史
  const [pregnancyHistory, setPregnancyHistory] = useState(
    gravidaRecord?.pregnancyHistoryDtoList || [],
  );
  const [formTotalValues, setFormTotalValues] = useState({});

  // 基础信息 ref
  const formRef = useRef([]);

  const validateForm = async () => {
    const target = formRef.current[activeKey];
    if (target) {
      try {
        const values = await target?.validateFields();

        if (values?.userInfo?.manageOrg) {
          values.userInfo.manageOrg = renderServiceHisDoctorList(
            gravidaInfo?.manageOrg,
            hisDoctorList,
            gravidaRecord?.auditStatus,
          )?.orgId;
        }
        if (values?.spouseInfoBto?.mentalRetardation) {
          values.spouseInfoBto.mentalRetardationLevel =
            values?.spouseInfoBto?.mentalRetardation !== '有'
              ? undefined
              : values?.spouseInfoBto?.mentalRetardationLevel;
        }
        if (values?.recordFamilyHistoryBto?.mentalRetardation) {
          values.recordFamilyHistoryBto.mentalRetardationLevel =
            values?.recordFamilyHistoryBto?.mentalRetardation !== '有'
              ? undefined
              : values?.recordFamilyHistoryBto?.mentalRetardationLevel;
        }
        console.log('校验表单并返回格式化后的所有数据：', values);
        setFormTotalValues({
          ...formTotalValues,
          ...values,
        });
        return values;
      } catch (e) {
        console.log(e);
        return false;
      }
    }
  };

  const handleOk = async () => {
    const formValues = await validateForm();
    if (!formValues) return;
    if (activeKey < TabNumber) {
      setActiveKey(activeKey + 1);
    } else {
      const {
        userInfo,
        spouseInfoBto,
        pregnancyInfoBto,
        recordMensesInfoBto,
        recordPersonalHistoryBto,
        recordFamilyHistoryBto,
        firstVisitTime,
        funding,
      } = {
        ...formTotalValues,
        ...formValues,
      };
      const {
        spouseInfo,
        pregnancyInfo,
        recordPersonalHistory,
        recordFamilyHistory,
        recordMensesInfo,
        pregnancyHistoryDtoList,
        extrapelvicMeasurement,
        ...extraRest
      } = gravidaRecordInfo;

      const formatData = {
        userInfo: {
          ...gravidaUserInfo,
          ...unwrapObject(userInfo, [
            'current',
            'registered',
            'birthplace',
            'post',
            'manager',
          ]),
          status: true,
          hisId,
          birthday: formatDate(userInfo?.birthday || gravidaUserInfo?.birthday),
        },
        recordInfo: {
          ...extraRest,
          status: 'ACTIVE',
          auditStatus:
            common?.globalPageType === globalPageType.NURSE
              ? 'NURSE_AUDIT'
              : 'DOCTOR_AUDIT',
          submitter: common?.doctorId,
          firstVisitTime: formatDate(
            firstVisitTime || gravidaRecordInfo?.firstVisitTime,
          ),
          funding: funding || gravidaRecordInfo?.funding,
          pregnancyHistoryBtoList: pregnancyHistory
            .map(item => {
              const formatItem = {
                ...item,
                ...(item?.birthday
                  ? {
                      birthday: formatDate(item?.birthday),
                    }
                  : {}),
              };
              const { id, ...rest } = formatItem;
              if (
                rest?.mature ||
                rest?.premature ||
                rest?.abortion ||
                rest?.survival
              ) {
                if (`${id}`?.length > 6) {
                  return rest;
                } else {
                  return item;
                }
              } else {
                return undefined;
              }
            })
            .filter(v => v),
          spouseInfoBto: {
            ...unwrapObject(spouseInfo || {}, ['registered']),
            ...unwrapObject(spouseInfoBto || {}, ['registered']),
            birthday: formatDate(
              spouseInfoBto?.birthday || spouseInfo?.birthday,
            ),
          },
          pregnancyInfoBto: {
            ...(pregnancyInfo || {}),
            ...(pregnancyInfoBto || {}),
            updateEdc: formatDate(
              pregnancyInfoBto?.updateEdc || pregnancyInfo?.updateEdc,
            ),
            lmp: formatDate(pregnancyInfoBto?.lmp || pregnancyInfo?.lmp),
            edc: formatDate(pregnancyInfoBto?.edc || pregnancyInfo?.edc),
            ...(pregnancyInfoBto?.mpas ? pregnancyInfoBto?.mpas : {}),
            gestationalConfirmTime: formatDate(new Date()),
            updateGestationalConfirmTime: formatDate(new Date()),
          },
          recordMensesInfoBto: {
            ...(recordMensesInfo || {}),
            ...(unwrapObject(recordMensesInfoBto) || {}),
          },
          recordPersonalHistoryBto: {
            ...(recordPersonalHistory || {}),
            ...(unwrapObject(recordPersonalHistoryBto) || {}),
          },
          recordFamilyHistoryBto: {
            ...(recordFamilyHistory || {}),
            ...(unwrapObject(recordFamilyHistoryBto) || {}),
          },
          extrapelvicMeasurementBto: {
            ...(extrapelvicMeasurement || {}),
          },
        },
      };

      if (common?.globalPageType === globalPageType.DOCTOR) {
        formatData.recordInfo['submitDoctorId'] = common?.doctorId;
        formatData.recordInfo['submitDoctorName'] = common?.doctorInfo?.name;
      }

      // const formatData = mock;
      // 过敏史相关
      const target = (gravidaAllergyHistory || [])
        .filter(item => item.id && `${item?.id}`?.length > 6)
        .map(item => {
          const { id, ...rest } = item;
          rest.occurrenceTime = formatDate(rest?.occurrenceTime);
          rest.registrantTime = formatDate(rest?.registeredTime);
          rest.hisId = gravida?.gravidaInfo?.hisId;
          rest.registrantId = common?.doctorId;
          delete rest.registeredTime;
          return rest;
        });

      // 提交 form 数据 和 过敏史
      Promise.all([
        gravidaServices.createGravidaAllergyHistory({
          btoParam: target,
        }),
        ,
        gravidaServices[
          formatData.recordInfo?.id
            ? 'updateGravidaRecord'
            : 'createGravidaRecord'
        ](formatData),
      ]).then(res => {
        const target = res.filter(v => v);
        const allergyRes = target[0];
        const recordInfoRes = target[1];

        if (allergyRes.code === 200) {
          vsf?.setStoreValue('gravida.allergyVisible', false);
          message(recordInfoRes);
        }
        if (recordInfoRes?.code === 200) {
          vsf?.setStoreValue('gravida.isRecordExist', true);
          vsf?.setStoreValue('gravida.createRecordVisible', false);
          if (!formatData.recordInfo?.id) {
            gravida?.init();
          }
          gravida?.gravidaInfo?.id &&
            gravida?.getRecord(gravida?.gravidaInfo?.id);

          medical?.getMedicalDetail(hisRecordId);
        }
        handleFormSubmit && handleFormSubmit();
      });
    }
  };

  const handleChange = v => {
    setGravidaAllergyHistory(v);
  };
  const handlePregnancyChange = v => {
    setPregnancyHistory(v);
  };

  // const handleHighriskInfo = data => {
  //   if (data?.age >= 41) {
  //     Modal.confirm({
  //       title: '高危提示',
  //       content: `该患者年龄${data?.age}岁，请注意橙色高危妊娠风险评估！`,
  //       okText: '引用',
  //       cancelText: '取消',
  //       onOk: () => {
  //         vsf?.setStoreValue('gravida.highFastModalData', [
  //           {
  //             label: '年龄',
  //             value: 41,
  //           },
  //         ]);
  //         vsf?.setStoreValue('gravida.highriskVisible', true);
  //         vsf?.setStoreValue('gravida.isRecordExist', true);
  //         vsf?.setStoreValue('gravida.createRecordVisible', false);
  //       },
  //       onCancel: () => {
  //         vsf?.setStoreValue('gravida.isRecordExist', true);
  //         vsf?.setStoreValue('gravida.createRecordVisible', false);
  //       },
  //     });
  //   }
  // };

  const handleCancel = e => {
    if (e.target.nodeName === 'SPAN') {
      if (activeKey > 0) {
        setActiveKey(activeKey - 1);
      }
    } else {
      // if (vsf?.stores?.gravida?.isRecordExist) {
      //   vsf?.setStoreValue('gravida.createRecordVisible', false);
      // }
      vsf?.setStoreValue('gravida.createRecordVisible', false);
    }
  };

  useImperativeHandle(recordRef, () => ({
    handleOk,
    handleCancel,
  }));

  useEffect(() => {
    // setGravidaRecordInfo({
    //   ...gravidaRecord,
    //   spouseInfo: wrapObject(gravidaRecord?.spouseInfo, 'address'),
    // });
    handleRecordPersonHistoryChange(
      {},
      wrapObject(gravidaRecord?.recordPersonalHistory),
    );
    handleRecordFamilyHistoryChange({}, gravidaRecord?.recordFamilyHistory);
    setSpouseInfoDisabledMentalRetardationLevel(
      gravidaRecord?.spouseInfo?.mentalRetardation !== '有',
    );
    setFamilyDisabledMentalRetardationLevel(
      gravidaRecord?.recordFamilyHistory?.mentalRetardation !== '有',
    );
  }, [gravidaRecord?.id]);

  useEffect(() => {
    setGravidaAllergyHistory(gravidaAllergyHistoryStores);
  }, [gravidaAllergyHistoryStores]);

  useEffect(() => {
    handleKeyChange && handleKeyChange(activeKey);
  }, [activeKey]);

  const basicInfo = {
    enums,
    addressData,
  };

  const [allDenyPersonal, setAllDenyPersonal] = useState(false);
  const [allDenyPast, setAllDenyPast] = useState(false);
  const [allDenyFamily, setAllDenyFamily] = useState(false);

  const [
    familyDisabledMentalRetardationLevel,
    setFamilyDisabledMentalRetardationLevel,
  ] = useState(false);
  const [
    spouseInfoDisabledMentalRetardationLevel,
    setSpouseInfoDisabledMentalRetardationLevel,
  ] = useState(false);

  const setFromValue = () => {
    if (formRef.current[0]) {
      formRef.current[0].setFieldsValue({
        userInfo: {
          manageOrg: renderServiceHisDoctorList(
            gravidaInfo?.manageOrg,
            hisDoctorList,
            gravidaRecord?.auditStatus,
          ),
        },
      });
    }
  };

  useEffect(() => {
    staticCommonService
      .getHisDoctorList({
        version: true,
      })
      .then(res => {
        if (res.code === 200) {
          setHisDoctorList(res.data);
        }
      });
  }, []);

  const onChangePersonal = v => {
    // if (v) {
    //   setAllDenyPersonal(v);
    //   formRef.current[3].setFieldsValue({
    //     recordPersonalHistoryBto: v
    //       ? wrapObject(recordPersonHistoryDefaultDeny)
    //       : wrapObject(recordPersonHistoryDefault),
    //   });
    // }
    setAllDenyPersonal(v);
    formRef.current[3].setFieldsValue({
      recordPersonalHistoryBto: v
        ? wrapObject(recordPersonHistoryDefaultDeny)
        : wrapObject(recordPersonHistoryDefault),
    });
  };

  const onChangePast = v => {
    // if (v) {
    //   setAllDenyPast(v);
    //   formRef.current[3].setFieldsValue({
    //     recordPersonalHistoryBto: v
    //       ? wrapObject(recordPersonHistory2DefaultDeny)
    //       : wrapObject(recordPersonHistory2Default),
    //   });
    // }
    setAllDenyPast(v);
    formRef.current[3].setFieldsValue({
      recordPersonalHistoryBto: v
        ? wrapObject(recordPersonHistory2DefaultDeny)
        : wrapObject(recordPersonHistory2Default),
    });
  };

  const onChangeFamily = v => {
    // if (v) {
    //   setAllDenyFamily(v);
    //   formRef.current[4].setFieldsValue({
    //     recordFamilyHistoryBto: v
    //       ? recordFamilyHistoryDefaultDeny
    //       : recordFamilyHistoryDefault,
    //   });
    // }
    setAllDenyFamily(v);
    formRef.current[4].setFieldsValue({
      recordFamilyHistoryBto: v
        ? recordFamilyHistoryDefaultDeny
        : recordFamilyHistoryDefault,
    });
  };

  const CheckPersonal = () => {
    return (
      <Checkbox value={allDenyPersonal} onChange={onChangePersonal}>
        全选否定
      </Checkbox>
    );
  };

  const CheckPast = () => {
    return (
      <Checkbox value={allDenyPast} onChange={onChangePast}>
        全选否定
      </Checkbox>
    );
  };

  const CheckFamily = () => {
    const arr = Object.keys(recordFamilyHistoryDefault);
    let isCheck = false;
    if (formRef.current[4]?.getFieldsValue()?.recordFamilyHistoryBto) {
      const arr2 = arr.map(v => {
        return formRef.current[4]?.getFieldsValue()?.recordFamilyHistoryBto[v];
      });
      isCheck =
        arr2.filter(v => v).length === 7 &&
        !arr2.filter(v => v).some(v => v === '有' || v === '不祥');
    }
    return (
      <Checkbox value={allDenyFamily || isCheck} onChange={onChangeFamily}>
        全选否定
      </Checkbox>
    );
  };

  // 既往史 全局否定的监听
  const handleRecordPersonHistoryChange = (record, allValues) => {
    const formValue = {
      ...wrapObject(gravidaRecordInfo?.recordPersonalHistory),
      ...allValues?.recordPersonalHistoryBto,
    };
    // 数据源
    const target = Object.keys(formValue || {});
    // 既往相关字段合集
    const totalData = target.filter(
      key => typeof formValue[key] === 'object' && formValue[key],
    );
    // 相关字段合集（true）
    const filterTrueArr = totalData.filter(
      key =>
        typeof formValue[key]?.[key] === 'boolean' && formValue[key]?.[key],
    );
    // 相关字段合集（false）
    const filterFalseArr = totalData.filter(
      item => !filterTrueArr.includes(item),
    );
    // 确定属于个人史的字段
    const flagCheck = filterTrueArr.some(item =>
      recordPersonHistoryDefault.hasOwnProperty(item),
    );
    // 确定属于既往史的字段
    const flag2Check = filterTrueArr.some(item =>
      recordPersonHistory2Default.hasOwnProperty(item),
    );
    setAllDenyPersonal(
      !flagCheck &&
        filterFalseArr.some(item =>
          recordPersonHistoryDefault.hasOwnProperty(item),
        ) &&
        totalData.filter(item =>
          recordPersonHistoryDefault.hasOwnProperty(item),
        )?.length === 10,
    );
    setAllDenyPast(
      !flag2Check &&
        filterFalseArr.some(item =>
          recordPersonHistory2Default.hasOwnProperty(item),
        ) &&
        totalData.filter(item =>
          recordPersonHistory2Default.hasOwnProperty(item),
        )?.length === 14,
    );
  };

  // 家族史 全局否定的监听
  const handleRecordFamilyHistoryChange = (record, allValues) => {
    const formValue = {
      ...gravidaRecordInfo?.recordFamilyHistory,
      ...allValues?.recordFamilyHistoryBto,
    };
    const target = Object.keys(formValue || {});
    // 家族史相关字段合集
    const familyArr = Object.keys(recordFamilyHistoryDefaultDeny).filter(
      key => recordFamilyHistoryDefaultDeny[key] === '无',
    );
    const totalData = target.filter(key => familyArr.includes(key));
    const filterTrueArr = familyArr.filter(
      key =>
        typeof formValue[key] === 'string' &&
        ['有', '不详'].includes(formValue[key]),
    );
    const filterFalseArr = familyArr.filter(
      item => !filterTrueArr.includes(item),
    );
    const flagCheck = filterTrueArr.some(item =>
      recordFamilyHistoryDefault.hasOwnProperty(item),
    );
    setAllDenyFamily(
      !flagCheck &&
        filterFalseArr.some(item =>
          recordFamilyHistoryDefault.hasOwnProperty(item),
        ) &&
        totalData.filter(item => {
          return recordFamilyHistoryDefault.hasOwnProperty(item);
        })?.length === 7 &&
        filterFalseArr.map(v => formValue[v])?.filter(v => v)?.length === 7,
    );

    if (record?.recordFamilyHistoryBto?.mentalRetardation) {
      const target = record?.recordFamilyHistoryBto?.mentalRetardation;
      setFamilyDisabledMentalRetardationLevel(target !== '有');
    }
  };

  const handleBaseInfoValuesChange = (record, allValues) => {
    const { userInfo } = record;
    if (userInfo?.idNum) {
      formRef.current[0].setFieldsValue({
        userInfo: {
          birthday: getBirthday(userInfo?.idNum),
          age: getAge(userInfo?.idNum),
        },
      });
    }
    if (userInfo?.birthday) {
      formRef.current[0].setFieldsValue({
        userInfo: {
          age: moment().diff(userInfo?.birthday, 'years') || '',
        },
      });
    }
  };

  const handleSpouseInfoValuesChange = (record, allValues) => {
    const { spouseInfoBto } = record;
    if (spouseInfoBto?.idNum) {
      formRef.current[1].setFieldsValue({
        spouseInfoBto: {
          birthday: getBirthday(spouseInfoBto?.idNum),
          age: getAge(spouseInfoBto?.idNum),
        },
      });
    }
    if (spouseInfoBto?.birthday) {
      formRef.current[1].setFieldsValue({
        spouseInfoBto: {
          age: moment().diff(spouseInfoBto?.birthday, 'years') || '',
        },
      });
    }
    if (spouseInfoBto?.mentalRetardation) {
      const target = spouseInfoBto?.mentalRetardation;
      setSpouseInfoDisabledMentalRetardationLevel(target !== '有');
    }
  };

  const handlePregnancyInfoValuesChange = (record, allValues) => {
    const { pregnancyInfoBto } = record;
    if (pregnancyInfoBto?.lmp) {
      formRef.current[2].setFieldsValue({
        pregnancyInfoBto: {
          gestationalWeek: getGestationalWeek({
            lmp: pregnancyInfoBto?.lmp,
          }),
          edc: moment(pregnancyInfoBto?.lmp)
            .add(40, 'w')
            .format('YYYY-MM-DD'),
        },
      });
    }
  };
  setFromValue();

  return (
    <Tabs
      type="line"
      className="e-medical-record-modal-tab"
      centered={false}
      activeKey={`${activeKey}`}
      tabPosition="top"
      onChange={e => {
        setTimeout(() => {
          handleScroll();
        }, 0);
      }}
      onTabClick={async akey => {
        if (gravidaRecordId) {
          const formValues = await validateForm();
          if (formValues) {
            setActiveKey(Number(akey));
          }
        }
      }}
    >
      <TabPane key="0" tab="基本信息">
        <VSForm
          dataType="static"
          layout="horizontal"
          formRef={ref => {
            formRef.current[0] = ref;
          }}
          initialValues={{
            userInfo: {
              ...gravidaUserInfo,
              birthday:
                gravidaUserInfo?.birthday ??
                getBirthday(gravidaUserInfo?.idNum),
              age: gravidaUserInfo?.age ?? getAge(gravidaUserInfo?.idNum),
              manageOrg: renderServiceHisDoctorList(
                gravidaInfo?.manageOrg,
                hisDoctorList,
                gravidaRecord?.auditStatus,
              ),
            },
            firstVisitTime: gravidaRecordInfo?.firstVisitTime,
            funding: gravidaRecordInfo?.funding,
          }}
          labelCol={labelColWidth[5]}
          onValuesChange={handleBaseInfoValuesChange}
          disabled={forbidEdit(gravidaRecordInfo, common)}
        >
          {baseInfoFormItem({
            basic: basicInfo,
            formType: FormBlockType.SIGN,
          })}
        </VSForm>
      </TabPane>
      <TabPane key="1" tab="配偶信息">
        <VSForm
          dataType="static"
          layout="horizontal"
          formRef={ref => {
            formRef.current[1] = ref;
          }}
          labelCol={labelColWidth[5]}
          initialValues={{
            spouseInfoBto: {
              ...gravidaRecordInfo.spouseInfo,
              birthday:
                gravidaRecordInfo?.spouseInfo?.birthday ??
                getBirthday(gravidaRecordInfo?.spouseInfo?.idNum),
              age:
                gravidaRecordInfo?.spouseInfo?.age ??
                getAge(gravidaRecordInfo?.spouseInfo?.idNum),
            },
          }}
          onValuesChange={handleSpouseInfoValuesChange}
          disabled={forbidEdit(gravidaRecordInfo, common)}
        >
          {spouseInfoFormItem({
            basic: basicInfo,
            formType: FormBlockType.SIGN,
            disabled: {
              mentalRetardationLevel: spouseInfoDisabledMentalRetardationLevel,
            },
          })}
        </VSForm>
      </TabPane>
      <TabPane key="2" tab="怀孕信息">
        <VSForm
          dataType="static"
          layout="horizontal"
          formRef={ref => {
            formRef.current[2] = ref;
          }}
          labelCol={labelColWidth[5]}
          initialValues={{
            pregnancyInfoBto: {
              ...gravidaRecordInfo.pregnancyInfo,
            },
            recordMensesInfoBto: wrapObject(gravidaRecordInfo.recordMensesInfo),
          }}
          onValuesChange={handlePregnancyInfoValuesChange}
          disabled={forbidEdit(gravidaRecordInfo, common)}
        >
          {pregnancyInfoFormItem({
            basic: basicInfo,
            formType: FormBlockType.SIGN,
            disabled: forbidEdit(gravidaRecordInfo, common),
            pregnancyInfoBto: gravidaRecord?.pregnancyInfo,
          })}
          <FormBlock title="孕产史">
            <Pregnancy onChange={handlePregnancyChange} />
          </FormBlock>
        </VSForm>
      </TabPane>
      <TabPane key="3" tab="既往史">
        <VSForm
          dataType="static"
          layout="horizontal"
          formRef={ref => {
            formRef.current[3] = ref;
          }}
          labelCol={labelColWidth[8]}
          initialValues={{
            recordPersonalHistoryBto: wrapObject(
              gravidaRecordInfo.recordPersonalHistory,
            ),
          }}
          onValuesChange={handleRecordPersonHistoryChange}
          disabled={forbidEdit(gravidaRecordInfo, common)}
        >
          {recordPersonalHistoryFormItem({
            basic: basicInfo,
            formType: FormBlockType.SIGN,
            extra: {
              personal: CheckPersonal(),
              past: CheckPast(),
            },
          })}
        </VSForm>
      </TabPane>
      <TabPane key="4" tab="家族史">
        <VSForm
          dataType="static"
          layout="horizontal"
          formRef={ref => {
            formRef.current[4] = ref;
          }}
          labelCol={labelColWidth[7]}
          initialValues={{
            recordFamilyHistoryBto: gravidaRecordInfo.recordFamilyHistory,
          }}
          onValuesChange={handleRecordFamilyHistoryChange}
          disabled={forbidEdit(gravidaRecordInfo, common)}
        >
          {recordFamilyHistoryFormItem({
            basic: basicInfo,
            formType: FormBlockType.SIGN,
            extra: {
              family: CheckFamily(),
            },
            disabled: {
              mentalRetardationLevel: familyDisabledMentalRetardationLevel,
            },
            recordFamilyHistoryBto: gravidaRecordInfo.recordFamilyHistory,
          })}
        </VSForm>
      </TabPane>
      <TabPane key="5" tab="过敏史">
        <VSForm
          dataType="static"
          layout="horizontal"
          formRef={ref => {
            formRef.current[5] = ref;
          }}
          disabled={forbidEdit(gravidaRecordInfo, common)}
        >
          <FormBlock title="过敏史">
            <Allergy onChange={handleChange} />
          </FormBlock>
        </VSForm>
      </TabPane>
    </Tabs>
  );
};

export default definePage(Index);

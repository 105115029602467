import moment from 'moment';

export const IdNumRegExp = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;

export const phoneRegExp = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;

export const disableLmpDate = (current) => { 
    return current && (current > moment().endOf('day') || current < moment().subtract(11, 'month').endOf('day'));
}

export const disableEdcDate = (current) => { 
    return current && (current < moment().subtract(1, 'day').endOf('day') || current > moment().add(11, 'month').endOf('day'));
}

export const disabledDate = current => {
    // Can not select days before today and today
    return current && current < moment().subtract(1, 'day').endOf('day');
};

export const disabledFutureDate = current => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
};

export const showDateFormat = ['YYYY-MM-DD', 'YYYY-MM-DD 00:00:00'];

export const formatDate = (v, format?: string): any => { 
    if (!v) {
        return null
    } else {
        return moment(v).format(format ?? 'YYYY-MM-DD 00:00:00');
    }
};
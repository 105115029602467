export const recordPersonHistoryDefaultDeny = {
  rhNegative: false,
  rhNegativeDetail: '',
  nllrHistory: false,
  nllrHistoryDetail: '',
  epidemicFocus: false,
  epidemicFocusDetail: '',
  smokingHistory: false,
  smokingHistoryDetail: '',
  drinkingHistory: false,
  drinkingHistoryDetail: '',
  oftenDepressed: false,
  oftenDepressedDetail: '',
  oftenUninterested: false,
  oftenUninterestedDetail: '',
  vaccinationHistory: false,
  vaccinationHistoryDetail: '',
  harmfulSubstanceContactHistory: false,
  harmfulSubstanceContactHistoryDetail: '',
  radiationContactHistory: false,
  radiationContactHistoryDetail: '',
};

export const recordPersonHistoryDefault = {
  rhNegative: '',
  rhNegativeDetail: '',
  nllrHistory: '',
  nllrHistoryDetail: '',
  epidemicFocus: '',
  epidemicFocusDetail: '',
  smokingHistory: '',
  smokingHistoryDetail: '',
  drinkingHistory: '',
  drinkingHistoryDetail: '',
  oftenDepressed: '',
  oftenDepressedDetail: '',
  oftenUninterested: '',
  oftenUninterestedDetail: '',
  vaccinationHistory: '',
  vaccinationHistoryDetail: '',
  harmfulSubstanceContactHistory: '',
  harmfulSubstanceContactHistoryDetail: '',
  radiationContactHistory: '',
  radiationContactHistoryDetail: '',
};

export const recordPersonHistory2DefaultDeny = {
  ccvdHistory: false,
  ccvdHistoryDetail: '',
  endocrineDiseaseHistory: false,
  endocrineDiseaseHistoryDetail: '',
  hepatopathyHistory: false,
  hepatopathyHistoryDetail: '',
  lungDiseaseHistory: false,
  lungDiseaseHistoryDetail: '',
  nephropathy: false,
  nephropathyDetail: '',
  majorDiseaseHistory: false,
  majorDiseaseHistoryDetail: '',
  infectiousDiseaseHistory: false,
  infectiousDiseaseHistoryDetail: '',
  operationHistory: false,
  operationHistoryDetail: '',
  transfusionHistory: false,
  transfusionHistoryDetail: '',
  drugAllergyHistory: false,
  drugAllergyHistoryDetail: '',
  foodAllergyHistory: false,
  foodAllergyHistoryDetail: '',
  traumaticHistory: false,
  traumaticHistoryDetail: '',
  drugHistoryDuringEarlyPregnancy: false,
  drugHistoryDuringEarlyPregnancyDetail: '',
  gynaecologyOperationHistory: false,
  gynaecologyOperationHistoryDetail: '',
  otherHistory: false,
  otherHistoryDetail: '',
};

export const recordPersonHistory2Default = {
  ccvdHistory: '',
  ccvdHistoryDetail: '',
  endocrineDiseaseHistory: '',
  endocrineDiseaseHistoryDetail: '',
  hepatopathyHistory: '',
  hepatopathyHistoryDetail: '',
  lungDiseaseHistory: '',
  lungDiseaseHistoryDetail: '',
  nephropathy: '',
  nephropathyDetail: '',
  majorDiseaseHistory: '',
  majorDiseaseHistoryDetail: '',
  infectiousDiseaseHistory: '',
  infectiousDiseaseHistoryDetail: '',
  operationHistory: '',
  operationHistoryDetail: '',
  transfusionHistory: '',
  transfusionHistoryDetail: '',
  drugAllergyHistory: '',
  drugAllergyHistoryDetail: '',
  foodAllergyHistory: '',
  foodAllergyHistoryDetail: '',
  traumaticHistory: '',
  traumaticHistoryDetail: '',
  drugHistoryDuringEarlyPregnancy: '',
  drugHistoryDuringEarlyPregnancyDetail: '',
  gynaecologyOperationHistory: '',
  gynaecologyOperationHistoryDetail: '',
  otherHistory: '',
  otherHistoryDetail: '',
};

export const recordFamilyHistoryDefaultDeny = {
  geneticHistory: '无',
  geneticHistoryDetail: '',
  tumorHistory: '无',
  tumorHistoryDetail: '',
  infectiousHistory: '无',
  infectiousHistoryDetail: '',
  mentalDiseaseHistory: '无',
  mentalDiseaseHistoryDetail: '',
  mentalRetardation: '无',
  mentalRetardationLevel: null,
  mentalRetardationDetail: '',
  malformation: '无',
  malformationDetail: '',
  other: '无',
  otherDetail: '',
};

export const recordFamilyHistoryDefault = {
  geneticHistory: '',
  geneticHistoryDetail: '',
  tumorHistory: '',
  tumorHistoryDetail: '',
  infectiousHistory: '',
  infectiousHistoryDetail: '',
  mentalDiseaseHistory: '',
  mentalDiseaseHistoryDetail: '',
  mentalRetardation: '',
  mentalRetardationLevel: '',
  mentalRetardationDetail: '',
  malformation: '',
  malformationDetail: '',
  other: '',
  otherDetail: '',
};

import React, { useEffect, useRef, useState } from 'react';
import { definePage } from '@vs/vsf-boot';
import ReportSearchForm from './components/ReportSearchForm';
import reportServices from '@/services/report';
import './index.less';
import { Table } from '@vs/vsf-kit';
import { useSize } from 'ahooks';
import { getReportColumns } from './common/reportColums';
import { filterNullUndefined, initPagation } from './common';
import ModalForm from './components/ModalForm';

interface PaginationDataProps {
  page: number;
  pageSize: number;
  total: number;
  bjSyncStatusIs?: string;
  bjSyncTimeRangeIn?: Record<string, any>;
}

const WomanReport = () => {
  const [tableData, setTableData] = useState<Record<string, any>[]>([]);
  const [
    paginationData,
    setPaginationData,
  ] = useState<PaginationDataProps | null>(initPagation);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>();
  const tabelRef = useRef(null);
  const size = useSize(tabelRef);
  const reportForm = useRef<{
    bjSyncStatusIs?: string;
    bjSyncTimeRangeIn?: Record<string, any>;
  }>();

  const [modalData, setModalData] = useState<{ visible: boolean; id?: string }>(
    { visible: false },
  );
  useEffect(() => {
    getTableData(initPagation);
  }, []);

  const ReportFormChange = (value: any) => {
    reportForm.current = { ...value };
    getTableData(initPagation);
  };

  const getTableData = async (query: any) => {
    const qtoQuery = {
      size: query.pageSize,
      from: query.page - 1,
    };
    const res = await reportServices.getReportList({
      qto: { ...qtoQuery, ...reportForm.current },
    });
    if (res?.code === 301) {
      // err dosomething
    } else if (res?.code === 200) {
      const { data } = res;
      setTableData(data?.result || []);
      setPaginationData({
        page: data!.from + 1,
        pageSize: data!.size,
        total: data!.count,
      });
    }
  };

  const handleClean = () => {
    reportForm.current = {};
    getTableData(initPagation);
  };

  const tabelSelectChange = (
    selectedRowKeys: React.Key[],
    selectedRows: any[],
  ) => {
    //dosomething
    setSelectedRowKeys(selectedRowKeys)
  };

  const paginationObject = {
    showQuickJumper: true,
    pageSizeOptions: [10, 20, 30, 50, 100],
    showSizeChanger: true,
    current: paginationData?.page,
    total: paginationData?.total,
    pageSize: paginationData?.pageSize,
    onChange: (page: number, pageSize: any) => handlePageChange(page, pageSize),
  };

  const handlePageChange = (page: number, pageSize: any) => {
    getTableData({ page, pageSize });
  };

  const onRecordClick = async (type: string, data: any) => {
    const { id } = data;
    if (type === 'async') {
      await reportServices.syncRepeatInspectionRecord({
        repeatInspectionRecordId: id,
      });
      getTableData(paginationData);
    } else {
      setModalData({ visible: true, id });
    }
  };

  const onInspectionListClick = async () => {
    await reportServices.syncRepeatInspectionRecordList({
      repeatInspectionRecordIds: selectedRowKeys,
    });
    getTableData(paginationData);
  };

  const reportColums = getReportColumns({ paginationData, onRecordClick });

  const modalVisibleChange = async (type: string, value?: any) => {
    if (type === 'finish') {
      const _value = filterNullUndefined(value);
      await reportServices.updateRepeatInspectionRecord({
        btoParam: _value,
      });
      getTableData(paginationData);
    }
    setModalData({ visible: false });
  };
  return (
    <>
      <div className="woman-report">
        <span className="report-header">复检记录</span>
        <ReportSearchForm
          ReportFormChange={ReportFormChange}
          handleClean={handleClean}
          onInspectionListClick={onInspectionListClick}
          selectedRowKeys={selectedRowKeys}
        />
        <div className="patient-table" ref={tabelRef}>
          <Table
            rowSelection={{
              type: 'checkbox',
              onChange: tabelSelectChange,
            }}
            scroll={size.height ? { y: size.height - 100 } : undefined}
            rowKey="id"
            options={tableData}
            columns={reportColums}
            pagination={paginationObject}
          />
        </div>
      </div>
      {modalData.visible ? (
        <ModalForm modalData={modalData} onClose={modalVisibleChange} />
      ) : null}
    </>
  );
};

export default definePage(WomanReport);

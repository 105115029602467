import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Tabs, TabPane, Popover } from '@vs/vsf-kit';
import * as echarts from 'echarts';
import { getGestationalWeek } from '@/utils';
import { defaultfuh, defaultWeekChange } from '@/config';
import moment from 'moment';
import '../index.less';
import { cloneDeep } from 'lodash';

const weigthType = {
  'WHEELCHAIR':{
    name:'轮椅',
    type:'WHEELCHAIR'
  },
  'PLATFORM':{
    name:'平车',
    type:'PLATFORM'
  },
  'OTHER':{
    name:'轮椅',
    type:'OTHER'
  }
}

const Index = props => {
  const { gravidaRecord, gravidaPhysicalList } = vsf?.stores?.gravida;
  const { isModal, hideFooter } = props;
  const [activeKey, setActiveKey] = useState('fuh');
  const [width, setWidth] = useState(isModal ? 1000 : 1298);
  const [height, setHeight] = useState(isModal ? 800 : 946);
  const [fuhData, setFuhData] = useState([]);
  const [bmiData, setBmiData] = useState([]);
  const [weightRiseData, setWeightRiseData] = useState([]);
  const [pressureData, setPressureData] = useState([]);
  const [xList, setXList] = useState([]);
  const [fuhData20, setFuhData20] = useState([]);
  const [weightTypeList, setWeightTypeList] = useState([]);
  const ref = {
    fuhRef: useRef(),
    bmiRef: useRef(),
    weightRef: useRef(),
    pressureRef: useRef(),
  };

  function compareFn(a, b) {
    if (moment(a?.createdAt).isBefore(b?.createdAt)) {
      return -1;
    }
    if (moment(b?.createdAt).isBefore(a?.createdAt)) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }

  const formatData = (list, gestationalData) => {
    if (!Array.isArray(list)) return;
    const formatList = cloneDeep(list);
    const fuhRes = new Array(40).fill(undefined);
    const bmiRes = new Array(40).fill(undefined);
    const weightRiseRes = new Array(40).fill(undefined);
    const pressureRes = new Array(40).fill(null);
    const gestationalWeekObj = {};
    const formatXList = [];
    const weightxType = new Array(40).fill(undefined);
    (formatList || []).sort(compareFn).forEach((item, index) => {
      const gestationalWeek = item?.createdAt
        ? getGestationalWeek({
            lmp: gestationalData?.lmp,
            nowTimeValue: item?.createdAt,
          })
        : null;
      if (gestationalWeek) {
        const formatGestationalWeek = Math.floor(gestationalWeek / 7);
        if (
          !gestationalWeekObj[formatGestationalWeek] ||
          gestationalWeekObj[formatGestationalWeek] <= gestationalWeek
        ) {
          gestationalWeekObj[formatGestationalWeek] = gestationalWeek;
          fuhRes[formatGestationalWeek] = item.fuh;
          // bmiRes[formatGestationalWeek] =
          //   item.weight /
          //   Math.pow(
          //     gravidaRecord?.pregnancyInfo?.heightBeforePregnancy / 100,
          //     2,
          //   );
            
          if (item.weight) {
            bmiRes[formatGestationalWeek] =
            item.weight /
            Math.pow(
              gravidaRecord?.pregnancyInfo?.heightBeforePregnancy / 100,
              2,
            );
            weightRiseRes[formatGestationalWeek] =
              item.weight -
              (gravidaRecord?.pregnancyInfo?.weightBeforePregnancy ?? 0);
          }else {
            bmiRes[formatGestationalWeek] ='-'
            weightRiseRes[formatGestationalWeek] = '-'
          }
          if(item?.otherOptional) {
            weightxType[formatGestationalWeek] = {
              index: formatGestationalWeek,
              text: weigthType[item?.otherOptional]?.name,
            }
          }

          pressureRes[formatGestationalWeek] = {
            diastolicPressure: item?.diastolicPressure,
            systolicPressure: item?.systolicPressure,
          };
          if (!formatXList.find(item => item === formatGestationalWeek)) {
            formatXList.push(formatGestationalWeek);
          }
        }
      }
    });
    setXList(formatXList);
    setFuhData(fuhRes.filter((v, index) => index >= 19 && index <= 39));
    setFuhData20(fuhRes.filter((v, index) => index < 19));
    setBmiData(bmiRes);
    setWeightRiseData(weightRiseRes);
    setPressureData(pressureRes);
    setWeightTypeList(weightxType);
  };
  useEffect(() => {
    formatData(gravidaPhysicalList || [], gravidaRecord?.pregnancyInfo || {});
  }, [gravidaPhysicalList, gravidaRecord?.pregnancyInfo]);

  useEffect(() => {
    const chartDom = ref[`${activeKey}Ref`]?.current;
    const echartObj = {
      fuh: {
        yAxisName: '宫高(cm)',
        data: fuhData,
      },
      bmi: {
        yAxisName: 'bmi',
        data: bmiData,
      },
      weight: {
        yAxisName: '体重增长',
        data: weightRiseData,
      },
      pressure: {
        yAxisName: '血压',
        data: pressureData,
      },
    };
    if (chartDom) {
      if (!width && !height) {
        setWidth(chartDom.clientWidth);
        setHeight(chartDom.clientHeight);
      }
      const myChart = echarts.init(chartDom);
      const option = {
        title: {
          // text: 'Stacked Line',
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['实际', '上限', '下限'],
          right: 120,
          padding: 15,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          name: '孕周',
          type: 'category',
          boundaryGap: false,
          data: xList,
        },
        yAxis: {
          name: echartObj[activeKey].yAxisName,
          type: 'value',
        },
        series: [
          {
            name: '实际',
            type: 'line',
            stack: 'Total',
            data: echartObj[activeKey].data,
            connectNulls: true,
          },
        ],
      };
      if (activeKey === 'fuh') {
        option.xAxis.data = defaultfuh.week;
        option.series = option.series.concat([
          {
            name: '上限',
            type: 'line',
            data: gravidaPhysicalList?.length > 0 ? defaultfuh.max : [],
          },
          {
            name: '下限',
            type: 'line',
            data: gravidaPhysicalList?.length > 0 ? defaultfuh.min : [],
          },
        ]);
      } else if (activeKey === 'weight') {
        const weekList = cloneDeep(defaultWeekChange[0].week);
        if (weightTypeList.length > 0) {
          weightTypeList.forEach(v => {
            if (v && typeof v == 'object') {
              weekList.splice(v.index, 1, v.text);
            }
          });
        }
        option.xAxis.data = weekList;
        if (
          echartObj[activeKey].data?.find(v => {
            return v === '-';
          })
        ) {
          option.series[0].connectNulls = false;
        }
        option.series = option.series.concat([
          {
            name: '上限',
            type: 'line',
            data:
              gravidaPhysicalList?.length > 0 ? defaultWeekChange[0].max : [],
          },
          {
            name: '下限',
            type: 'line',
            data:
              gravidaPhysicalList?.length > 0 ? defaultWeekChange[0].min : [],
          },
        ]);
      } else if (activeKey === 'pressure') {
        option.legend = {
          data: ['舒张压', '收缩压'],
          right: 120,
          padding: 15,
        };
        option.series = [
          {
            name: '舒张压',
            type: 'line',
            data: xList.map((v, i) => {
              return echartObj?.pressure.data[v].diastolicPressure;
            }),
          },
          {
            name: '收缩压',
            type: 'line',
            data: xList.map((v, i) => {
              return echartObj?.pressure.data[v].systolicPressure;
            }),
          },
        ];
      }else {
        const weekList = cloneDeep(defaultWeekChange[0].week);
        if (weightTypeList.length > 0) {
          weightTypeList.forEach(v => {
            if (v && typeof v == 'object') {
              weekList.splice(v.index, 1, v.text);
            }
          });
        }
        option.xAxis.data = weekList;
        if (
          echartObj[activeKey].data?.find(v => {
            return v === '-';
          })
        ) {
          option.series[0].connectNulls = false;
        }
      }
      option && myChart.setOption(option);
    }
  }, [activeKey, fuhData, bmiData, pressureData, weightRiseData]);

  const handleRenderEmpty = dom => {
    if (gravidaPhysicalList?.length > 0) {
      return dom;
    } else {
      return (
        <div
          style={{
            width,
            height,
            textAlign: 'center',
            lineHeight: `${height}px`,
            fontSize: 20,
          }}
        >
          暂无数据
        </div>
      );
    }
  };

  const handleRenderFuh20 = () => {
    return (
      <div className="e-medical-record-fuh20">
        <div>
          <div>孕周</div>
          <div>宫高</div>
        </div>
        {fuhData20.map((item, index) => {
          if (item) {
            return (
              <div>
                <div>{index + 1}</div>
                <div>{item}</div>
              </div>
            );
          }
          return;
        })}
      </div>
    );
  };

  return (
    <div className="e-medical-record-detail-container">
      <Tabs
        type="line"
        centered={false}
        activeKey={activeKey}
        onTabClick={async akey => {
          setActiveKey(akey);
        }}
      >
        <TabPane forceRender key="fuh" tab="宫底高度">
          {handleRenderEmpty(
            <>
              <Popover
                placement="bottomLeft"
                title=""
                content={handleRenderFuh20()}
                trigger="hover"
              >
                <div className="fuh-20">孕早期宫高</div>
              </Popover>
              <div
                ref={ref?.fuhRef}
                className="pregnancy-echars"
                id="pregnancy-echarts-fuh"
                style={
                  width && height
                    ? {
                        width,
                        height,
                      }
                    : {}
                }
              ></div>
            </>,
          )}
        </TabPane>
        <TabPane forceRender key="bmi" tab="BMI">
          {handleRenderEmpty(
            <div
              ref={ref?.bmiRef}
              className="pregnancy-echars"
              id="pregnancy-echarts-bmi"
              style={
                width && height
                  ? {
                      width,
                      height,
                    }
                  : {}
              }
            ></div>,
          )}
        </TabPane>
        <TabPane forceRender key="weight" tab="体重增长">
          {handleRenderEmpty(
            <div
              ref={ref?.weightRef}
              className="pregnancy-echars"
              id="pregnancy-echarts-weight"
              style={
                width && height
                  ? {
                      width,
                      height,
                    }
                  : {}
              }
            ></div>,
          )}
        </TabPane>
        <TabPane forceRender key="pressure" tab="血压">
          {handleRenderEmpty(
            <div
              ref={ref?.pressureRef}
              className="pregnancy-echars"
              id="pregnancy-echarts-pressure"
              style={
                width && height
                  ? {
                      width,
                      height,
                    }
                  : {}
              }
            ></div>,
          )}
        </TabPane>
      </Tabs>
      {!hideFooter && (
        <div className="e-medical-record-detail-footer">
          <Button type="primary">打印</Button>
        </div>
      )}
    </div>
  );
};

export default definePage(Index);

import React from 'react';
import {
  IdNumRegExp,
  phoneRegExp,
  disabledFutureDate,
  showDateFormat,
} from '@/utils/rule';
import moment from 'moment';
import { FormBlockType } from '.';
import { VSFormItem, VSFormDependency } from '@vs/vsf-kit';
import { Cascader } from 'antd';
import VsfFormLayout, { FormBlock2, FormBlock } from '@/components/form-layout';
import Dayrange from '@/components/day-range';

export const baseInfoFormItem = (data) => {
  const { basic, formType, labelCol, extra } = data;
  const { enums, addressData } = basic || {};
  let formatProps = {};
  let Box = <React.Fragment></React.Fragment>;
  if (formType) {
    Box = formType === FormBlockType.SIGN ? FormBlock : FormBlock2;
  }
  if (labelCol) {
    formatProps = {
      labelCol,
    };
  }
  return (
    <React.Fragment>
      <Box title="基本信息">
        <VsfFormLayout columnCount={1} {...formatProps}>
          <VsfFormLayout className="e-medical-record-form-base" columnCount={3}>
            <VSFormItem
              label="国籍"
              name={['userInfo', 'country']}
              valueType="select"
              dataSource={enums['country']}
              fieldProps={{
                showSearch: true,
                placeholder: '请选择',
              }}
            ></VSFormItem>
            <VSFormItem
              label="民族"
              name={['userInfo', 'nation']}
              valueType="select"
              dataSource={enums['nation']}
              fieldProps={{
                showSearch: true,
                placeholder: '请选择',
              }}
            ></VSFormItem>
            <VSFormItem
              label="籍贯"
              name={['userInfo', 'nativeLocation']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
            <VSFormItem
              label="证件类型"
              name={['userInfo', 'idType']}
              valueType="select"
              dataSource={enums['id_type']}
              // rules={[
              //   {
              //     required: true,
              //     message: '请选择证件类型',
              //   },
              // ]}
              fieldProps={{
                placeholder: '请选择',
              }}
            ></VSFormItem>
            <VSFormItem
              label="证件号码"
              name={['userInfo', 'idNum']}
              valueType="text"
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写证件号码',
              //   },
              //   // {
              //   //   pattern: IdNumRegExp,
              //   //   message: '证件号码格式错误',
              //   // },
              // ]}
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
            <VSFormItem
              label="出生年月"
              name={['userInfo', 'birthday']}
              valueType="date"
              fieldProps={{
                disabledDate: disabledFutureDate,
                format: showDateFormat,
                placeholder: '请选择',
              }}
              // rules={[
              //   {
              //     required: true,
              //     message: '请选择出生年月',
              //   },
              // ]}
            ></VSFormItem>
            <VSFormItem
              label="年龄"
              name={['userInfo', 'age']}
              valueType="digit"
              fieldProps={{
                min: 1,
                precision: 0,
                addonAfter: '岁',
                placeholder: '请输入',
                controls: false,
              }}
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写年龄',
              //   },
              // ]}
            ></VSFormItem>
            <VSFormItem
              label="联系电话"
              name={['userInfo', 'cellphone']}
              valueType="text"
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写联系电话',
              //   },
              //   {
              //     pattern: phoneRegExp,
              //     message: '电话格式错误',
              //   },
              // ]}
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
            <VSFormItem
              label="文化程度"
              name={['userInfo', 'education']}
              valueType="select"
              dataSource={enums['educational_level']}
              fieldProps={{
                placeholder: '请选择',
              }}
            ></VSFormItem>
            <VSFormItem
              className=""
              label="结婚年龄"
              name={['userInfo', 'marriageAge']}
              valueType="digit"
              fieldProps={{
                min: 1,
                precision: 0,
                addonAfter: '岁',
                placeholder: '请输入',
                controls: false,
              }}
            ></VSFormItem>
            <VSFormItem
              label="职业"
              name={['userInfo', 'occupation']}
              valueType="select"
              dataSource={enums['occupation']}
              fieldProps={{
                placeholder: '请选择',
              }}
            ></VSFormItem>
            <VSFormItem
              label="婚姻状况"
              name={['userInfo', 'maritalStatus']}
              valueType="select"
              dataSource={enums['marriage_status']}
              fieldProps={{
                placeholder: '请选择',
              }}
            ></VSFormItem>
            <VSFormItem
              label="初诊日期"
              name={['firstVisitTime']}
              fieldProps={{
                disabledDate: disabledFutureDate,
                format: showDateFormat,
                placeholder: '请选择',
              }}
              valueType="date"
            ></VSFormItem>
            <VSFormItem
              label="性伴侣数"
              name={['userInfo', 'sexualPartnerNum']}
              valueType="select"
              fieldProps={{
                // min: 1,
                // precision: 0,
                placeholder: '请选择',
                // controls: false,
              }}
              dataSource={enums['sex_partner_number']}
            ></VSFormItem>
            <VSFormItem
              label="工作单位"
              name={['userInfo', 'workplace']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
            <VSFormItem
              label="农业户籍"
              name={['userInfo', 'accountType']}
              dataSource={enums['account_type']}
              valueType="select"
              fieldProps={{
                placeholder: '请选择',
              }}
            ></VSFormItem>
            <VSFormItem
              label="户籍分类"
              name={['userInfo', 'householdType']}
              valueType="select"
              dataSource={enums['household_type']}
              fieldProps={{
                placeholder: '请选择',
              }}
            ></VSFormItem>
            <VSFormItem
              label="生育基金"
              name={['funding']}
              valueType="select"
              dataSource={enums['funding']}
              fieldProps={{
                placeholder: '请选择',
              }}
            ></VSFormItem>
            <VSFormItem
              label="人员状态"
              name={['userInfo', 'personnelStatus']}
              valueType="select"
              dataSource={enums['personnel_status']}
              fieldProps={{
                placeholder: '请选择',
              }}
              // rules={[
              //   {
              //     required: true,
              //     message: '请选择人员状态',
              //   },
              // ]}
            ></VSFormItem>
            <VSFormItem
              label="是否单亲"
              name={['userInfo', 'singleParent']}
              valueType="select"
              dataSource={enums['single_parent']}
              fieldProps={{
                placeholder: '请选择',
              }}
            ></VSFormItem>
            <VSFormItem
              label="管辖机构"
              name={['userInfo', 'manageOrg','orgName']}
              valueType="text"
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写管辖机构',
              //   },
              // ]}
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
          </VsfFormLayout>

          <VsfFormLayout columnCount={2}>
            <VSFormItem
              label="现住地址"
              name={['userInfo', 'current']}
              valueType="custom"
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写现住地址',
              //   },
              // ]}
              fieldProps={{
                placeholder: '请选择',
                allowClear: true,
              }}
            >
              <Cascader placeholder="请选择" options={addressData} />
            </VSFormItem>
            <VSFormItem
              label=""
              name={['userInfo', 'currentDetailAddress']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>

            <VSFormItem
              label="户籍地址"
              name={['userInfo', 'registered']}
              valueType="custom"
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写户籍地址',
              //   },
              // ]}
              fieldProps={{
                placeholder: '请选择',
              }}
            >
              <Cascader placeholder="请选择" options={addressData} />
            </VSFormItem>
            <VSFormItem
              label=""
              name={['userInfo', 'registeredDetailAddress']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>

            <VSFormItem
              label="出生地址"
              name={['userInfo', 'birthplace']}
              valueType="custom"
              fieldProps={{
                placeholder: '请选择',
              }}
            >
              <Cascader placeholder="请选择" options={addressData} />
            </VSFormItem>
            <VSFormItem
              label=""
              name={['userInfo', 'birthplaceDetailAddress']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>

            <VSFormItem
              label="产后修养地址"
              name={['userInfo', 'post']}
              valueType="custom"
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写产后修养地址',
              //   },
              // ]}
              fieldProps={{
                placeholder: '请选择',
              }}
            >
              <Cascader placeholder="请选择" options={addressData} />
            </VSFormItem>
            <VSFormItem
              label=""
              name={['userInfo', 'postDetailAddress']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>

            <VSFormItem
              label="管辖地址"
              name={['userInfo', 'manager']}
              valueType="custom"
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写管辖地址',
              //   },
              // ]}
              fieldProps={{
                placeholder: '请选择',
              }}
            >
              <Cascader placeholder="请选择" options={addressData} />
            </VSFormItem>
            <VSFormItem
              label=""
              name={['userInfo', 'managerDetailAddress']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
          </VsfFormLayout>
        </VsfFormLayout>
      </Box>
    </React.Fragment>
  );
};

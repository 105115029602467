import { createServices } from '@vs/service';

interface getReportListProps {
  size: number;
  from: number;
  bjSyncStatusIs?: string;
  bjSyncTimeRangeIn?: Record<string, any>;
}

export default createServices({
  /**
   *@name getReportList
   * 获取复检记录列表
   */
  getReportList: {
    method: 'post',
    url: '/api/report/getReportList',
    parameterFomatter: (data?: { qto: getReportListProps }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ExternalSyncManagerDtoHisRegisteredBaseDto[];
        from: number;
        size: number;
        scrollId: string;
      },
    ) => data,
  },

  /**
   *
   * 同步
   */
  syncRepeatInspectionRecord: {
    method: 'post',
    url: '/api/report/syncRepeatInspectionRecord',
    parameterFomatter: (data?: { repeatInspectionRecordId: string }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  /**
   *
   * 同步
   */
  syncRepeatInspectionRecordList: {
    method: 'post',
    url: '/api/report/syncRepeatInspectionRecordList',
    parameterFomatter: (data?: { repeatInspectionRecordIds: string[] }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  /**
   *
   * 获取详情
   */
  getRepeatInspectionRecordById: {
    method: 'post',
    url: `/api/report/getRepeatInspectionRecordById`,
    parameterFomatter: (data?: { id: string }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },

  /**
   *
   * 更新
   */

  updateRepeatInspectionRecord: {
    method: 'post',
    url: `/api/report/updateRepeatInspectionRecord`,
    parameterFomatter: (data?: { btoParam: Record<string, any> }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
});

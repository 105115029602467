import React, { useEffect, useState } from 'react';
import { RadioGroup, Input, Select, InputNumber } from '@vs/vsf-kit';
import '../index.less';

interface RadioStrType {
  value: string;
  onChange: (value: string | null) => void;
  nums?: number;
  dataSource?: any[];
}

interface NtValue {
  value1?: string; // 厚度
  value2?: string; // 鼻骨
}

const defaultValue = Array.from({ length: 3 }).fill({
  value1: '',
  value2: undefined,
});

export const Nt = (props: RadioStrType) => {
  const { value, onChange, nums = 1, dataSource } = props;
  const [radioValue, setRadioValue] = useState(undefined);
  const [otherValue, setOtherValue] = useState('');
  const [arrayValue, setArrayValue] = useState<any[]>(defaultValue);

  useEffect(() => {
    let formatValue: any = {};
    try {
      formatValue = JSON.parse(value);
      setRadioValue(formatValue?.value );
      setOtherValue(formatValue?.otherInfo || '');
      setArrayValue(defaultValue.map((item, index) => {
        return formatValue?.info?.[index] || ''
      })|| []);
    } catch {}
  }, [value]);

  const handleChange = () => {
    const value = JSON.stringify({
      value: radioValue,
      otherInfo: otherValue,
      info: arrayValue,
    });
    onChange && onChange(radioValue ? value : null);
  };

  const handleRadioChange = v => {
    setRadioValue(v);
  };
  const handleInputChange = e => {
    setOtherValue(e.target.value);
  };
  const handleInput2Change = (v, index) => {
    const newArrayValue =
      arrayValue &&
      arrayValue.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            value1: v
          };
        } else {
          return item;
        }
      });
    setArrayValue(newArrayValue);
  };
  const handleSelectChange = (v, index) => {
    const newArrayValue =
      arrayValue &&
      arrayValue.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            value2: v,
          };
        } else {
          return item;
        }
      });
    setArrayValue(newArrayValue);
  };

  return (
    <div
      className={`e-medical-assistant ${
        radioValue === '1' || radioValue === '2'
          ? 'e-medical-assistant-vertical'
          : ''
      }`}
    >
      <RadioGroup
        value={radioValue}
        onChange={handleRadioChange}
        dataSource={[
          { label: '未查', value: '0' },
          { label: '正常', value: '1' },
          { label: '异常', value: '2' },
          { label: '其他', value: '3' },
        ]}
        onBlur={handleChange}
      ></RadioGroup>
      {(radioValue === '1' || radioValue === '2') && (
        <div className="e-medical-assistant-outline">
          {Array.from({ length: nums }) &&
            Array.from({ length: nums }).map((item, index) => {
              return (
                <div className="e-medical-assistant-formitem-block">
                  <div>
                    <div className="label">厚度{index + 1}(mm)约：</div>
                    <InputNumber
                      value={arrayValue?.[index]?.value1}
                      onChange={v => handleInput2Change(v, index)}
                      placeholder="请输入"
                      min={0}
                      onBlur={handleChange}
                      controls={false}
                    />
                  </div>
                  <div>
                    <div className="label">胎儿鼻骨{index + 1}：</div>
                    <Select
                      value={arrayValue?.[index]?.value2}
                      onChange={v => handleSelectChange(v, index)}
                      dataSource={dataSource || []}
                      placeholder="请选择"
                      onBlur={handleChange}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {radioValue === '3' && (
        <Input
          value={otherValue}
          onChange={v => handleInputChange(v)}
          placeholder="请输入"
          onBlur={handleChange}
        />
      )}
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Modal,
  Image
} from '@vs/vsf-kit';
import { ROOT_ROUTER } from '@/config';
import { forbidEdit } from '@/pages/nurse/utils';

import Warning from '@/static/Warning.png';
/**
 * 是否需要建档弹框
 **/

const Index = props => {
  const {
    gravida,
    common
  } = vsf?.stores;
  const { recordSure, epidemic } = gravida;

  const handleOk = () => {
    vsf?.setStoreValue('gravida.recordSure', false);
    vsf?.setStoreValue('gravida.createRecordVisible', true);
    // if (common?.globalPageType === globalPageType.NURSE) {
    // } else {
    //   vsf?.setStoreValue('gravida.createRecordVisible', true);
    // }
  };
  const handleCancel = () => {
    vsf?.setStoreValue('gravida.recordSure', false);
  };

  return (
    <Modal
      width={480}
      className="e-medical-create-record-modal"
      title="接诊确认"
      keyboard={false}
      destroyOnClose={true}
      okText="立即建档"
      cancelText="普通就诊"
      cancelButtonProps={{
        hidden: true,
      }}
      visible={recordSure && !forbidEdit(gravida?.gravidaRecord, common)}
      id="ask-createRecord-modal"
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      closable={false}
    >
      <div className="e-medical-create-record-ask-container">
        <Image src={Warning} preview={false} />
        <span>患者无怀孕信息，请立即建档</span>
      </div>
    </Modal>
  );
};

export default definePage(Index);

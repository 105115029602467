import React, { useState, useRef, useEffect } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Modal,
  VSEditableTable,
  VSTableToolbar,
  VSEditableTableAddition,
  VSTableColumn,
  message,
} from '@vs/vsf-kit';
import Pregnancy from './pregnancy';
import '../index.less';
// import { PlusOutlined } from '@vs/vsf-icons';

/**
 * 过敏信息组件
 **/

const Index = props => {
  const { common } = vsf?.stores;
  const [visible, setVisible] = useState(false);

  const handleOk = () => {
    vsf.setStoreValue?.('gravida.pregnancyVisible', false);
  };
  const handleCancel = () => {
    vsf.setStoreValue?.('gravida.pregnancyVisible', false);
  };

  useEffect(() => {
    setVisible(props.stores?.gravida?.pregnancyVisible);
  }, [props.stores?.gravida?.pregnancyVisible]);

  return (
    <Modal
      width={1032}
      className="e-medical-pregnancy-modal"
      title="妊娠指标"
      keyboard={true}
      destroyOnClose={true}
      okText="返回"
      cancelText="取消"
      visible={visible}
      id="pregnancy-modal"
      onOk={handleOk}
      onCancel={handleCancel}
      cancelButtonProps={{
        hidden: true,
      }}
      maskClosable={false}
    >
      <Pregnancy {...props} isModal hideFooter />
    </Modal>
  );
};

export default definePage(Index);

import { defineStore } from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';
import { highriskVisibleType } from '@/config';
import gravidaServices from '@/services/gravida';
import staticCommonService from '@/module/common/services';
import common from './common';
import medical from './medical';

import { formatEnums, getGestationalWeek } from '@/utils';
import { globalPageType } from '@/config';

const isFirstVisit = (current, history) => {
  if (current?.id) {
    return current?.medicalRecordType === 'FIRST_VISIT';
  } else {
    const target = (history || []).filter(
      item => item?.auditStatus !== 'NURSE_AUDIT',
    );
    return target.length === 0;
  }
};

const gravida = defineStore({
  // 是否存在建档数据
  isRecordExist: false,
  // 建档确认
  recordSure: false,
  // 是否开始建档
  createRecordVisible: false,
  // 妊娠图
  pregnancyVisible: false,
  // 过敏信息
  allergyVisible: false,
  // 高危评估显示枚举 0: 不显示；1: 显示最近；2: 显示最高；3: 显示最近记录且能编辑
  highriskVisible: highriskVisibleType.NULL,
  // 产妇个人信息
  gravidaInfo: {},
  // 产妇档案信息
  gravidaRecord: {},
  // 产妇体格信息
  gravidaPhysicalList: [],
  // 产妇宝宝信息
  gravidaFetus: {},
  // 产妇高危信息
  gravidaHighrisk: {},
  // 产妇过敏信息
  gravidaAllergyHistory: [],

  allergen: {},

  // 产妇高危快捷数据
  highFastModalData: [],
  // 产妇高危快捷数据
  highriskFormVisible: false,
  // 流行病学史
  epidemic: {},

  reset: async () => {
    gravida.gravidaPhysicalList = [];
    gravida.gravidaFetus = {};
    gravida.gravidaHighrisk = {};
    gravida.gravidaAllergyHistory = [];
    gravida.allergen = {};
    gravida.gravidaRecord = {};
    gravida.gravidaInfo = {};
    common.gravidaRecordId = 0;
    gravida.isRecordExist = false;
    gravida.recordSure = false;
    gravida.createRecordVisible = false;
    gravida.pregnancyVisible = false;
    gravida.allergyVisible = false;
    medical.medicalInfo = {};
    // 非活跃病历信息
    medical.archiveMedicalInfo = {};
    // 病历历史信息
    medical.medicalHistoryList = [];
    // 辅助信息
    medical.assistantInfo = {};
    // 体格信息
    medical.physicalInfo = {};
    // 主诉
    medical.complaint = null;
    // 主诊断
    medical.mainDiagnosis = null;
    // 次诊断
    medical.minorDiagnosis = null;
    // 表单错误
    medical.formError = null;
  },

  init: async () => {
    gravida.reset();
    if (common.history == 0) {
      const gravidaInfo = await gravidaServices.getGravidaInfo({
        hisId: common.hisId,
      });

      const gravidaData = gravidaInfo.data;
      gravida?.getExternalName(common);
      if (gravidaData) {
        gravida.gravidaInfo = gravidaInfo?.data;
        await gravida.getAllergyHistory(common.hisId);
        // await gravida.getEpidemiologicalHistory(common.hisRecordId);
        const isExistId = await gravida.getRecord(gravidaInfo?.data?.id);

        if (isExistId) {
          gravida?.getGravidaHighrisk(isExistId.gravidaRecordId);
          gravida?.getPhysicalList(isExistId.gravidaRecordId);
          const currentData = await medical?.getMedicalDetail(
            common?.hisRecordId,
          );

          const historyData = await medical?.getMedicalHistoryList(
            isExistId.gravidaRecordId,
          );
          // await gravida.getAllergyHistory(common.hisId);
          const isFirst = isFirstVisit(currentData, historyData);
          common.isFirstMedical = isFirst;
          // gravida?.getGravidaFetus(isExistId.gravidaRecordId);
          common.showMedical = true;
          common.showLeft = true;
        } else {
          common.isFirstMedical = true;
        }
      }
    }
  },

  getRecord: async id => {
    const res = await gravidaServices.getGravidaRecord({
      var: {
        gravidaId: id || gravida?.gravidaInfo?.id,
        status: 'ACTIVE' as GravidaRecordStatusEnum,
      },
    });
    if (res.code === 200 && res.data) {
      if (Array.isArray(res.data)) {
        const target = res?.data?.filter(item => item.status === 'ACTIVE');
        if (target && target[0]) {
          gravida.gravidaRecord = {
            ...target[0],
            pregnancyInfo: {
              ...target[0]?.pregnancyInfo,
              gestationalWeek: getGestationalWeek({
                lmp: target[0]?.pregnancyInfo?.lmp,
              }),
            },
          };

          const gravidaRecordInfo = target[0];
          common.gravidaRecordId = gravidaRecordInfo.id;
          gravida.gravidaInfo = gravidaRecordInfo?.gravidaInfo;

          if (gravidaRecordInfo?.auditStatus === 'DOCTOR_AUDIT') {
            gravida.isRecordExist = true;
            gravida.recordSure = false;
          } else if (gravidaRecordInfo?.auditStatus === 'NURSE_AUDIT') {
            if (common?.globalPageType === globalPageType.NURSE) {
              gravida.isRecordExist = false;
              gravida.recordSure = false;
            } else {
              gravida.isRecordExist = false;
              gravida.recordSure = true;
            }
          } else {
            gravida.isRecordExist = false;
            gravida.recordSure = true;
          }

          return {
            gravidaRecordId: gravidaRecordInfo.id,
          };
        } else {
          gravida.isRecordExist = false;
          gravida.recordSure = true;
          return false;
        }
      }
      return false;
    }
    return false;
  },

  getPhysicalList: async (id = common.gravidaRecordId) => {
    const res = await gravidaServices.getGravidaPhysicalList({
      gravidaRecordId: id,
    });
    if (res.code === 200 && res.data) {
      gravida.gravidaPhysicalList = res.data;
    }
  },

  getGravidaFetus: async id => {
    const res = await gravidaServices.getGravidaFetus({
      gravidaRecordId: id,
    });
    if (res.code === 200 && res.data) {
      gravida.gravidaFetus = res.data;
    }
  },

  getGravidaHighrisk: async (id = common.gravidaRecordId) => {
    const res = await gravidaServices.getHighriskInfo({
      id,
    });
    if (res.code === 200 && res.data) {
      gravida.gravidaHighrisk = res.data;
    }
  },

  getAllergyHistory: async id => {
    const res = await staticCommonService.getAllergyHistory({
      hisId: id,
    });
    if (res.code === 200 && res.data) {
      gravida.gravidaAllergyHistory = res.data;
    }
  },

  getExternalName: async data => {
    const { hisRecordId } = common;
    const res = await staticCommonService.getExternalName({
      hisRecordId: hisRecordId,
    });
    if (res.code === 200) {
      gravida.externalName = res.data;
    }
  },

  // getEpidemiologicalHistory: async id => {
  //     const res = await gravidaServices.getEpidemiologicalHistory({
  //         hisRecordId: id,
  //     });
  //     if (res.code === 200 && res.data) {
  //         //  触发流行病史弹框
  //         gravida.epidemic = res.data;
  //     } else {
  //         gravida.epidemic = null;
  //     }
  // },

  editEpidemiological: async data => {
    const { hisRecordId, hisId, gravidaRecordId } = common;
    const res = await gravidaServices.editEpidemiologicalHistory({
      btoParam: {
        hisRecordId,
        hisId,
        gravidaRecordId,
        epidemiologicalHistoryItemBtoList: data.map(item => ({
          itemName: item,
        })),
      },
    });
    if (res?.code === 200 && res?.data) {
      gravida.epidemic = data;
    }
  },
});

export default gravida;

import React, { useState, useEffect, useRef } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSTable,
  VSTableToolbar,
  VSTableColumn,
  VSTableAction,
  VSTablePagination,
  Tag,
  Drawer,
  Button,
  Image,
} from '@vs/vsf-kit';
import archivesService from '@/services/archives';
import { highriskLevel, colorMap } from '@/config';
import { createPortal } from 'react-dom';
import Pregnancy from '../components/pregnancy';
import Medical from '@/pages/medical';
import PageBlock from '@/components/page-block';
import Anchor from '@/components/anchor';
import {
  doctorMedicalViewAnchor,
  doctorReturnMedicalViewAnchor,
} from '@/config';
import medicalServices from '@/services/medical';
import { useSize } from 'ahooks';
import moment from 'moment';
import { getGestationalWeek } from '@/utils';
import storage from '@/utils/storage';

const Btn = props => {
  const { onShow } = props;
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    if (document.getElementById('e-medical-record-header-extra')) {
      setFlag(true);
    }
  }, [document.getElementById('e-medical-record-header-extra')]);
  if (!flag) return null;
  return createPortal(
    <div className="e-medical-pregnancy-btn">
      <Button onClick={onShow}>妊娠指标</Button>
    </div>,
    document.getElementById('e-medical-record-header-extra'),
  );
};

const Index = props => {
  const { gravidaRecordId } = vsf?.stores?.common;
  const [pregnancyVisible, setPregnancyVisible] = useState(false);
  const [medicalVisible, setMedicalVisible] = useState(false);
  const [medicalData, setMedicalData] = useState({});
  const [heightISize, setHeightISize] = useState();

  const ref = useRef(null);

  useEffect(() => {
    setHeightISize(renderHeight(ref, storage.get('heightISize')));
  }, [ref]);

  const renderHeight = (ref, height) => {
    if (ref.current?.clientHeight) {
      return ref.current?.clientHeight;
    } else if (height) {
      return height;
    } else {
      return 500;
    }
  };

  window.onresize = function(e) {
    setHeightISize(ref.current?.clientHeight);
    storage.set('heightISize', ref.current?.clientHeight);
  };

  const renderHighriskTag = (list, isReason = false) => {
    let current = 'GREEN';
    let currentText = '';
    let hasInfectious = false;
    let infectiousText = '';
    list.forEach(item => {
      const level = item.level;
      if (level !== 'PURPLE') {
        if (
          highriskLevel[level] &&
          highriskLevel[level]?.key > highriskLevel[current]?.key
        ) {
          current = level;
          currentText = item.name;
        }
      } else {
        hasInfectious = true;
        infectiousText = item.name;
      }
    });
    let node = current ? (
      <Tag color={colorMap[current]}>{highriskLevel[current]?.label}</Tag>
    ) : (
      '--'
    );
    if (!isReason) {
      return !!node ? (
        <>
          {node}
          {hasInfectious && (
            <Tag color={colorMap.PURPLE}>{highriskLevel['PURPLE']?.label}</Tag>
          )}
        </>
      ) : null;
    } else {
      return currentText
        ? `${currentText}${infectiousText ? '、' + infectiousText : ''}`
        : '无';
    }
  };

  // "checkTimeRangeIn": {
  //     "begin": "2022-10-01 05:36:30",
  //     "end": "2022-10-10 05:36:33",
  //     "beginInclude": true,
  //     "endInclude": false
  //   }

  const onPregnancyShow = () => {
    setPregnancyVisible(true);
  };

  const onPregnancyClose = () => {
    setPregnancyVisible(false);
  };

  // getMedicalDetailWithMedicalRecordId;
  const onMedicalShow = async data => {
    if (data?.id) {
      const res = await medicalServices.getMedicalDetailWithMedicalRecordId({
        id: data?.id,
      });
      setMedicalData(res?.data);
      setMedicalVisible(true);
    }
  };

  const onMedicalClose = () => {
    setMedicalVisible(false);
  };

  const pagination = {
    showQuickJumper: true,
    showSizeChanger: true,
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSizeOptions: [10, 20, 30, 50, 100],
  };

  if (!gravidaRecordId) {
    return null;
  }

  return (
    <div className="e-medical-record-detail-container e-medical-record-examination">
      <Btn onShow={onPregnancyShow} />
      <Drawer
        width={1298}
        title="妊娠指标"
        placement="right"
        onClose={onPregnancyClose}
        open={pregnancyVisible}
        className="e-medical-drawer-nopadding"
      >
        <Pregnancy {...props} hideFooter />
      </Drawer>
      <Drawer
        width={1298}
        title="查看病历"
        placement="right"
        onClose={onMedicalClose}
        open={medicalVisible}
        className="e-medical-drawer-nopadding"
      >
        <PageBlock
          key={2}
          height="calc(100vh - 55px)"
          anchor={
            <Anchor
              container="doctor-medical-anchor-container"
              dataSource={
                medicalData?.medicalRecordType !== 'RETURN_VISIT'
                  ? doctorMedicalViewAnchor
                  : doctorReturnMedicalViewAnchor
              }
            />
          }
          anchorContainerId="doctor-medical-anchor-container"
        >
          <Medical key="medical-archives" data={medicalData} isFold={false} />
        </PageBlock>
      </Drawer>
      <div className="detail-content" ref={ref}>
        <VSTable
          dataLoad={{
            request: archivesService.getExamination,
            qtoParams: {
              gravidaRecordIdIs: gravidaRecordId,
              auditStatusIs: 'DOCTOR_AUDIT',
            },
            extParams: {},
          }}
          scroll={{ x: 1300, y: heightISize && heightISize - 168 }}
          onSubmit={v => {}}
        >
          <VSTableColumn
            hideInTable
            search
            title="检查日期"
            valueType="dateRange"
            dataIndex="createdAtRangeIn"
            columnKey="createdAtRangeIn"
            width={150}
            key="createdAtRangeIn"
            fieldProps={{
              placeholder: ['开始日期', '结束日期'],
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="检查日期"
            valueType="date"
            dataIndex="createdAt"
            columnKey="createdAt"
            width={150}
            key="createdAt"
            fixed="left"
            sorter={{
              compare: (a, b) => moment(a.createdAt).diff(b.createdAt, 'day'),
              multiple: 2,
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="类型"
            valueType="date"
            dataIndex="medicalRecordType"
            columnKey="medicalRecordType"
            width={150}
            key="medicalRecordType"
            fixed="left"
            render={(v, record) => {
              return record?.medicalRecordType === 'RETURN_VISIT'
                ? '复诊'
                : '初诊';
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="高危级别"
            valueType="select"
            dataIndex="highriskRecordItemList"
            columnKey="highriskLevelIn"
            key="highriskRecordItemList"
            width={200}
            render={(v, record) => {
              return renderHighriskTag([
                {
                  level: record?.highriskLevel,
                  name: record?.highriskFactors,
                },
                {
                  level: record?.hasInfectious ? 'PURPLE' : '',
                  name: '',
                },
              ]);
            }}
            fixed="left"
            filter="multiple"
            filters={[
              {
                text: '低风险',
                value: 'GREEN',
              },
              {
                text: '一般风险',
                value: 'YELLOW',
              },
              {
                text: '较高风险',
                value: 'ORANGE',
              },
              {
                text: '高风险',
                value: 'RED',
              },
              {
                text: '传染病',
                value: 'PURPLE',
              },
            ]}
          ></VSTableColumn>
          <VSTableColumn
            title="高危因素"
            valueType="text"
            dataIndex="highriskLevel"
            columnKey="highriskLevel"
            key="highriskLevel"
            width={200}
            render={(v, record) => {
              return renderHighriskTag(
                [
                  {
                    level: record?.highriskLevel,
                    name: record?.highriskFactors,
                  },
                ],
                true,
              );
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="孕周"
            valueType="text"
            dataIndex="gestationalWeek"
            columnKey="gestationalWeek"
            width={150}
            key="gestationalWeek"
            // sorter={{
            //   compare: (a, b) => moment(a.createdAt).diff(b.createdAt, 'day'),
            //   multiple: 2,
            // }}
            render={(v, record) => {
              return getGestationalWeek({
                lmp: record?.pregnancyInfoSnapshot?.lmp,
                show: 0,
              });
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="身高（cm）"
            valueType="text"
            dataIndex="height"
            columnKey="height"
            width={150}
            key="height"
            render={(v, record) => {
              return record?.physicalInspectionsInfo?.height;
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="体重（kg）"
            valueType="text"
            dataIndex="weight"
            columnKey="weight"
            width={150}
            key="weight"
            render={(v, record) => {
              return record?.physicalInspectionsInfo?.weight;
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="其他"
            valueType="text"
            dataIndex="otherOptional"
            columnKey="otherOptional"
            width={150}
            key="otherOptional"
            render={(v, record) => {
              return record?.physicalInspectionsInfo?.otherOptional;
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="BMI"
            valueType="text"
            dataIndex="bmi"
            columnKey="bmi"
            width={150}
            key="bmi"
            render={(v, record) => {
              return record?.physicalInspectionsInfo?.weight &&
                record?.physicalInspectionsInfo?.height
                ? Math.floor(
                    record?.physicalInspectionsInfo?.weight /
                      Math.pow(
                        record?.physicalInspectionsInfo?.height / 100,
                        2,
                      ),
                  )
                : '-';
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="体温（°C）"
            valueType="text"
            dataIndex="temperature"
            columnKey="temperature"
            width={150}
            key="temperature"
            render={(v, record) => {
              return record?.physicalInspectionsInfo?.temperature || '-';
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="血压（mmgh）"
            valueType="text"
            dataIndex="bloodPressure"
            columnKey="bloodPressure"
            width={150}
            key="bloodPressure"
            render={(v, record) => {
              return record?.physicalInspectionsInfo?.systolicPressure &&
                record?.physicalInspectionsInfo?.diastolicPressure
                ? `${record?.physicalInspectionsInfo?.systolicPressure}/${record?.physicalInspectionsInfo?.diastolicPressure}`
                : '-';
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="血压时间"
            valueType="time"
            dataIndex="bloodPressureTime"
            columnKey="bloodPressureTime"
            width={150}
            key="bloodPressureTime"
          ></VSTableColumn>
          <VSTableColumn
            title="复测血压1（mmgh）"
            valueType="text"
            dataIndex="bloodPressure1"
            columnKey="bloodPressure1"
            width={150}
            key="bloodPressure1"
            render={(v, record) => {
              return record?.physicalInspectionsInfo?.systolicPressureTwo &&
                record?.physicalInspectionsInfo?.diastolicPressureTwo
                ? `${record?.physicalInspectionsInfo?.systolicPressureTwo}/${record?.physicalInspectionsInfo?.diastolicPressureTwo}`
                : '-';
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="复测血压时间1"
            valueType="time"
            dataIndex="bloodPressureTimeTwo"
            columnKey="bloodPressureTimeTwo"
            width={150}
            key="bloodPressureTimeTwo"
          ></VSTableColumn>
          <VSTableColumn
            title="复测血压2（mmgh）"
            valueType="text"
            dataIndex="bloodPressure2"
            columnKey="bloodPressure2"
            width={150}
            key="bloodPressure2"
            render={(v, record) => {
              return record?.physicalInspectionsInfo?.systolicPressureThree &&
                record?.physicalInspectionsInfo?.diastolicPressureThree
                ? `${record?.physicalInspectionsInfo?.systolicPressureThree}/${record?.physicalInspectionsInfo?.diastolicPressureThree}`
                : '-';
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="复测血压时间2"
            valueType="time"
            dataIndex="bloodPressureTimeThree"
            columnKey="bloodPressureTimeThree"
            width={150}
            key="bloodPressureTimeThree"
          ></VSTableColumn>
          <VSTableColumn
            title="宫底高度（cm）"
            valueType="text"
            dataIndex="fuh"
            columnKey="fuh"
            width={150}
            key="fuh"
            render={(v, record) => {
              return record?.physicalInspectionsInfo?.fuh || '-';
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="腹围（cm）"
            valueType="text"
            dataIndex="abdominalCircumference"
            columnKey="abdominalCircumference"
            width={150}
            key="abdominalCircumference"
            render={(v, record) => {
              return (
                record?.physicalInspectionsInfo?.abdominalCircumference || '-'
              );
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="胎心率"
            valueType="text"
            dataIndex="heartRate"
            columnKey="heartRate"
            width={150}
            key="heartRate"
            render={(v, record) => {
              return record?.physicalInspectionsInfo?.heartRate || '-';
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="胎先露"
            valueType="text"
            dataIndex="presentation"
            columnKey="presentation"
            width={150}
            key="presentation"
            render={(v, record) => {
              return record?.physicalInspectionsInfo?.presentation || '-';
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="衔接"
            valueType="text"
            dataIndex="engagement"
            columnKey="engagement"
            width={150}
            key="engagement"
            render={(v, record) => {
              return record?.physicalInspectionsInfo?.engagement || '-';
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="浮肿"
            valueType="text"
            dataIndex="edema"
            columnKey="edema"
            width={150}
            key="edema"
            render={(v, record) => {
              return record?.physicalInspectionsInfo?.edema || '-';
            }}
          ></VSTableColumn>
          <VSTableColumn
            title="操作"
            valueType="option"
            dataIndex="operation"
            fixed="right"
            render={(v, record) => {
              // return null;
              return record?.id ? (
                <Button
                  type="primary"
                  onClick={() => {
                    onMedicalShow(record);
                  }}
                >
                  查看病历
                </Button>
              ) : null;
            }}
          ></VSTableColumn>
          <VSTablePagination {...pagination} />
        </VSTable>
      </div>
    </div>
  );
};

export default definePage(Index);

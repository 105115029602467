import React, { useState, useEffect, useRef } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';

import {
  Button,
  VSForm,
  VSFormFooter,
  VSFormFooterSubmit,
  VSFormFooterReset,
  VSFormItem,
  message,
  Modal,
} from '@vs/vsf-kit';
import {
  IdNumRegExp,
  phoneRegExp,
  disabledDate,
  formatDate,
  showDateFormat,
} from '@/utils/rule';
import { DownloadOutlined } from '@ant-design/icons';
import ImportModal from '@/components/import-modal';
import antenatalServices from '@/services/antenatal';
import CollapseBlock from '@/components/collapse-block';
import staticCommonService from '@/module/common/services';
import GestationalWeek from '@/components/gestational-week';
import HighriskTag from '@/components/highrisk/tag';
import BloodPressure from '@/components/blood-pressure';
import Dayrange from '@/components/day-range';
import { useCallBackRef } from '@/utils';
import VsfFormLayout, {
  VsfFormLayoutDefine,
  FormBlock,
  FormBlock2,
} from '@/components/form-layout';
import { labelColWidth, radioDataSource4 } from '@/config';
import { formatImportData } from '../utils';
import { unwrapObject } from '@/components/utils';

const radioDataSource1 = [
  {
    label: '有',
    value: true,
  },
  {
    label: '无',
    value: false,
  },
];

const radioDataSourCeoverallAssessment = [
  {
    label: '异常',
    value: true,
  },
  {
    label: '无发现异常',
    value: false,
  },
];

const Index = props => {
  const { common } = vsf?.stores;
  const { enums, addressData } = common;
  const { visible, onCancel, onOk, data, importData } = props;
  const [visibleImport, setVisibleImport] = useState(false);
  const [currentImportData, setCurrentImportData] = useState();

  const [hisDoctorInfo, setHisDoctorInfo] = useState(common?.hisDoctorInfo);

  const [isRef, formRef] = useCallBackRef();

  const handleSubmit = async () => {
    const values = await formRef?.current?.validateFields();
    console.log('校验表单并返回格式化后的所有数据：', values);

    const btoParam = values?.bto_param;
    const formatData = {
      ...unwrapObject(btoParam),
      source: 'DOCTOR_ENTER',
      status: 'NORMAL',
      gravidaRecordId: vsf?.stores?.common?.gravidaRecordId,
      followupTime: formatDate(btoParam?.followupTime),
      nextFollowupTime: formatDate(btoParam?.nextFollowupTime),
      enterTime: formatDate(btoParam?.enterTime),
      ...(btoParam?.bloodPressure || {}),
      healthGuidance: (btoParam?.healthGuidance || []).join(),
      healthGuidanceDetail: JSON.stringify(btoParam.healthGuidanceDetail || []),
      id: data?.id,
      medicalRecordId: data?.medicalRecordId,
    };
    antenatalServices
      .createReturnInspectionsRecord({
        btoParam: formatData,
      })
      .then(res => {
        if (res?.code === 200) {
          vsf?.setStoreValue('gravida.createRecordVisible', false);
          message.success(res?.message);
          onOk && onOk();
        } else {
          message.error(res?.message);
        }
      });
  };

  const handleOk = () => {
    handleSubmit();
  };
  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleSetValues = data => {
    setTimeout(() => {
      formRef?.current?.setFieldsValue({
        bto_param: {
          ...data,
          healthGuidance: data?.healthGuidance?.split(',') || [],
          healthGuidanceDetail: JSON.parse(data?.healthGuidanceDetail),
          ...(data.diastolicPressure && data.systolicPressure
            ? {
                bloodPressure: {
                  diastolicPressure: data.diastolicPressure,
                  systolicPressure: data.systolicPressure,
                },
              }
            : {}),
          enterTime: formatDate(new Date()),
        },
      });
    }, 0);
  };

  const handleImportData = data => {
    const value = formatImportData(data);
    formRef?.current?.setFieldsValue({
      bto_param: value,
    });
    message.success('导入成功');
  };

  const getHisDoctorList = async () => {
    const result = await common.getHisDoctorList();
    setHisDoctorInfo(result);
  };

  const handleChange = (record, allValues) => {
    if (record?.bto_param?.enterPerson) {
      const current = hisDoctorInfo?.doctor?.find(
        item => item.value === record?.bto_param?.enterPerson,
      );
      formRef?.current?.setFieldsValue({
        bto_param: {
          enterOrg: current?.origin?.orgId,
        },
      });
    }
  };

  useEffect(() => {
    if (isRef) {
      getHisDoctorList();
      handleSetValues(data);
    }
  }, [data, isRef]);

  return (
    <Modal
      width={1336}
      className="e-medical-record-city-screening-modal"
      title="复诊产检记录"
      keyboard={true}
      destroyOnClose={true}
      okText="保存"
      cancelText="取消"
      visible={visible}
      id="city-screening-modal"
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
    >
      <div className="e-medical-record-detail-container">
        <div className="detail-content">
          <ImportModal
            title="数据导入"
            visible={visibleImport}
            importData={currentImportData}
            onCancel={() => {
              setVisibleImport(false);
            }}
            onOk={v => {
              handleImportData(v);
              setVisibleImport(false);
            }}
          />
          <VSForm
            layout="horizontal"
            formRef={formRef}
            labelCol={labelColWidth[6]}
            onValuesChange={handleChange}
          >
            {/* <VSFormFooter key="footer">
            <VSFormFooterSubmit
              children="提交"
              action={{
                request: antenatalServices.createReturnInspectionsRecord,
              }}
            />
            <VSFormFooterReset children="重置" />
          </VSFormFooter> */}
            <CollapseBlock
              title="基本信息"
              description=""
              error=""
              id="anchor-base"
            >
              <VsfFormLayout columnCount={3}>
                <VSFormItem
                  label="随访日期"
                  name={['bto_param', 'followupTime']}
                  valueType="date"
                  fieldProps={{
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="产检孕周"
                  name={['bto_param', 'gestationalWeek']}
                  valueType="custom"
                >
                  <GestationalWeek />
                </VSFormItem>
                <VSFormItem
                  label="进行本次检查"
                  name={['bto_param', 'doCheck']}
                  valueType="radio"
                  dataSource={radioDataSource1}
                ></VSFormItem>
                <VSFormItem
                  label="未进行体检原因"
                  name={['bto_param', 'notCheckReason']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="主诉"
                  name={['bto_param', 'mainComplaint']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="血压"
                  name={['bto_param', 'bloodPressure']}
                  valueType="custom"
                >
                  <BloodPressure
                    dataKey={['systolicPressure', 'diastolicPressure']}
                  />
                </VSFormItem>
                <VSFormItem
                  label="体重"
                  name={['bto_param', 'weight']}
                  valueType="digit"
                  fieldProps={{
                    addonAfter: 'kg',
                    precision: 1,
                    controls: false,
                    placeholder: '请输入',
                    min: 30,
                    max: 300,
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="宫高"
                  name={['bto_param', 'fuh']}
                  valueType="digit"
                  fieldProps={{
                    min: 0,
                    addonAfter: 'cm',
                    controls: false,
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="腹围"
                  name={['bto_param', 'abdominalCircumference']}
                  valueType="digit"
                  fieldProps={{
                    min: 0,
                    addonAfter: 'cm',
                    controls: false,
                    placeholder: '请输入',
                  }}
                ></VSFormItem>

                <VSFormItem
                  label="先露固定"
                  name={['bto_param', 'presentation']}
                  valueType="select"
                  dataSource={enums['presentation']}
                  fieldProps={{
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="浮肿"
                  name={['bto_param', 'edema']}
                  valueType="select"
                  dataSource={enums['edema']}
                  fieldProps={{
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
              </VsfFormLayout>
              <VsfFormLayoutDefine columnSpan={[2, 1]}>
                <VsfFormLayout columnCount={2}>
                  <VSFormItem
                    label="胎心率"
                    name={['bto_param', 'fetusHeart']}
                    valueType="digit"
                    fieldProps={{
                      min: 0,
                      addonAfter: '次/分',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="胎位"
                    name={['bto_param', 'fetusPosition']}
                    valueType="select"
                    dataSource={enums['fetus_movement']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="多胎胎心率1"
                    name={['bto_param', 'fetusHeartTwo']}
                    valueType="digit"
                    fieldProps={{
                      min: 0,
                      addonAfter: '次/分',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="多胎胎位1"
                    name={['bto_param', 'fetusPositionTwo']}
                    valueType="select"
                    dataSource={enums['fetus_movement']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="多胎胎心率2"
                    name={['bto_param', 'fetusHeartThree']}
                    valueType="digit"
                    fieldProps={{
                      min: 0,
                      addonAfter: '次/分',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="多胎胎位2"
                    name={['bto_param', 'fetusPositionThree']}
                    valueType="select"
                    dataSource={enums['fetus_movement']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="多胎胎心率3"
                    name={['bto_param', 'fetusHeartFour']}
                    valueType="digit"
                    fieldProps={{
                      min: 0,
                      addonAfter: '次/分',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="多胎胎位3"
                    name={['bto_param', 'fetusPositionFour']}
                    valueType="select"
                    dataSource={enums['fetus_movement']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
                <VsfFormLayout></VsfFormLayout>
              </VsfFormLayoutDefine>
            </CollapseBlock>

            <CollapseBlock
              title="辅助检查"
              description=""
              error=""
              id="anchor-assistant"
              // rightContent={
              //   <div>
              //     <Button
              //       icon={<DownloadOutlined />}
              //       onClick={e => {
              //         e.stopPropagation();
              //         setCurrentImportData(importData.assistant);
              //         setVisibleImport(true);
              //       }}
              //     >
              //       数据导入
              //     </Button>
              //   </div>
              // }
            >
              <FormBlock2 title="血常规">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="血红蛋白质值"
                    name={['bto_param', 'hemoglobinValue']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'g/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="白细胞计数值"
                    name={['bto_param', 'whiteBloodCellCount']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'x10^9/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="血小板计数值"
                    name={['bto_param', 'plateletCount']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'x10^9/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="血常规其他"
                    name={['bto_param', 'otherBloodRoutine']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="贫血三项">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="铁蛋白"
                    name={['bto_param', 'ferritin']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'ug/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="糖化血红蛋白">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="糖化血红蛋白"
                    name={['bto_param', 'glycatedHemoglobin']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mmol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="血糖相关">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="血糖"
                    name={['bto_param', 'bloodSugarType']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mmol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="餐后两小时"
                    name={['bto_param', 'bloodSugarType2h']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mmol/L',
                      placeholder: '请输入',
                      controls: false,
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="OGTT">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="OGTT血糖1："
                    name={['bto_param', 'ogtt1h']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mmol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="OGTT血糖2："
                    name={['bto_param', 'ogtt2h']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mmol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="OGTT血糖3："
                    name={['bto_param', 'ogtt3h']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mmol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="血型">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="ABO血型"
                    name={['bto_param', 'aboBloodType']}
                    valueType="select"
                    dataSource={enums['abo_blood_type']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="RH+"
                    name={['bto_param', 'rhBloodType']}
                    valueType="select"
                    dataSource={enums['rh_blood_type']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="尿常规">
                <VsfFormLayout columnCount={2} labelCol={labelColWidth[6]}>
                  <VsfFormLayout columnCount={2}>
                    <VSFormItem
                      label="尿蛋白"
                      name={['bto_param', 'urineProtein']}
                      valueType="select"
                      dataSource={enums['urine_protein']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label=""
                      name={['bto_param', 'urineProteinValue']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VsfFormLayout columnCount={2}>
                    <VSFormItem
                      label="尿糖"
                      name={['bto_param', 'urineSugar']}
                      valueType="select"
                      dataSource={enums['urine_protein']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label=""
                      name={['bto_param', 'urineSugarValue']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VsfFormLayout columnCount={2}>
                    <VSFormItem
                      label="尿酮体"
                      name={['bto_param', 'urineKetone']}
                      valueType="select"
                      dataSource={enums['urine_protein']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label=""
                      name={['bto_param', 'urineKetoneValue']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VsfFormLayout columnCount={2}>
                    <VSFormItem
                      label="尿潜血"
                      name={['bto_param', 'urineOccultBlood']}
                      valueType="select"
                      dataSource={enums['urine_protein']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label=""
                      name={['bto_param', 'urineOccultBloodValue']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>
                  <VsfFormLayout columnCount={2}>
                    <VSFormItem
                      label="尿白血球"
                      name={['bto_param', 'urineWhiteBloodCell']}
                      valueType="select"
                      dataSource={enums['urine_protein']}
                      fieldProps={{
                        placeholder: '请选择',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label=""
                      name={['bto_param', 'urineWhiteBloodCellValue']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '请输入',
                      }}
                    ></VSFormItem>
                  </VsfFormLayout>

                  <VSFormItem
                    label="尿常规其他"
                    name={['bto_param', 'otherUrineRoutine']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                    // dataSource={enums['urine_protein']}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="肝功能">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="血清谷丙转氨酶"
                    name={['bto_param', 'alt']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'U/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="血清谷草转氨酶"
                    name={['bto_param', 'ast']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'U/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="白蛋白"
                    name={['bto_param', 'albumin']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'g/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="总胆红素"
                    name={['bto_param', 'totalBilirubin']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'umol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="结合胆红素"
                    name={['bto_param', 'conjugatedBilirubin']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'umol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="总胆汁酸"
                    name={['bto_param', 'bileAcid']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'umol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="肾功能">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="血清肌酐"
                    name={['bto_param', 'serumCreatinine']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'umol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="血尿素氮"
                    name={['bto_param', 'bloodUreaNitrogen']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mmol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="阴道感染">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="阴道分泌物"
                    name={['bto_param', 'virginalDischarge']}
                    valueType="select"
                    dataSource={enums['virginal_discharge']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="阴道清洁度"
                    name={['bto_param', 'virginalCleanliness']}
                    valueType="select"
                    dataSource={enums['virginal_cleanliness']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="沙眼衣原体"
                    name={['bto_param', 'trachomatis']}
                    valueType="select"
                    dataSource={enums['trachomatis']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="淋球菌检测"
                    name={['bto_param', 'gonorrhoeaeTest']}
                    valueType="select"
                    dataSource={enums['gonorrhoeae_test']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="甲状腺功能">
                <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="甲状腺功能"
                    name={['bto_param', 'thyroidFunction']}
                    valueType="select"
                    dataSource={enums['urine_protein']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="TSH"
                    name={['bto_param', 'tsh']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mlU/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="FT3"
                    name={['bto_param', 'ft3']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'pmol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="TT3"
                    name={['bto_param', 'tt3']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mlU/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="FT4"
                    name={['bto_param', 'ft4']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'mlU/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="TT4"
                    name={['bto_param', 'tt4']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'pmol/L',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="ATG"
                    name={['bto_param', 'atg']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'IU/ML',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="ATPO"
                    name={['bto_param', 'atpo']}
                    valueType="digit"
                    fieldProps={{
                      addonAfter: 'IU/ML',
                      controls: false,
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
              <FormBlock2 title="TCT检查">
                <VsfFormLayoutDefine
                  labelCol={labelColWidth[6]}
                  columnSpan={[1, 2]}
                >
                  <VSFormItem
                    label="宫颈脱落细胞检查"
                    name={['bto_param', 'cervicalExfoliationTest']}
                    valueType="select"
                    dataSource={enums['cervical_exfoliation_test']}
                    fieldProps={{
                      placeholder: '请选择',
                    }}
                  ></VSFormItem>
                  <VSFormItem
                    label="其他检查详述"
                    name={['bto_param', 'otherInspectionDetail']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayoutDefine>
                <VsfFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="B超"
                    name={['bto_param', 'ultrasound']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
                <VsfFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                  <VSFormItem
                    label="心电图"
                    name={['bto_param', 'ecg']}
                    valueType="text"
                    fieldProps={{
                      placeholder: '请输入',
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </FormBlock2>
            </CollapseBlock>

            {/* <CollapseBlock
              title="免费超声检查"
              description=""
              error=""
              id="anchor-highrisk"
            >
              <VsfFormLayout columnCount={3}>
                <VSFormItem
                  label="免费超声检查"
                  name={['bto_param', 'freeUltrasound']}
                  valueType="select"
                  dataSource={(enums['free_ultrasound'] || []).map(item => ({
                    ...item,
                    label: item.value == 1 ? '有' : '无',
                  }))}
                  fieldProps={{
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
              </VsfFormLayout>
            </CollapseBlock> */}

            <CollapseBlock
              title="高危检查"
              description=""
              error=""
              id="anchor-highrisk"
            >
              <VsfFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                <VsfFormLayout columnCount={3}>
                  <VSFormItem
                    label="是否高危"
                    name={['bto_param', 'highRisk']}
                    valueType="select"
                    dataSource={enums['high_risk']}
                    fieldProps={{
                      disabled: true,
                    }}
                  ></VSFormItem>
                </VsfFormLayout>
              </VsfFormLayout>
              <VsfFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                <VSFormItem
                  label="高危因素"
                  name={['bto_param', 'highRiskFactors']}
                  valueType="text"
                  fieldProps={{
                    disabled: true,
                  }}
                ></VSFormItem>
              </VsfFormLayout>
              <VsfFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                <VSFormItem
                  label="其他高危因素"
                  name={['bto_param', 'otherHighRiskFactors']}
                  valueType="text"
                  fieldProps={{
                    disabled: true,
                  }}
                ></VSFormItem>
              </VsfFormLayout>
              <VsfFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                <VSFormItem
                  label="高危评估"
                  name={['bto_param', 'highRiskAssessment']}
                  valueType="custom"
                >
                  <HighriskTag />
                </VSFormItem>
                <VSFormItem
                  label="传染病"
                  name={['bto_param', 'infectious']}
                  valueType="custom"
                  fieldProps={{
                    disabled: true,
                  }}
                >
                  <HighriskTag />
                </VSFormItem>
              </VsfFormLayout>
            </CollapseBlock>

            <CollapseBlock
              title="总体评估和保健指导"
              description=""
              error=""
              id="anchor-comment"
            >
              <VsfFormLayoutDefine
                labelCol={labelColWidth[6]}
                columnSpan={[1, 2]}
              >
                <VSFormItem
                  label="总体评估"
                  name={['bto_param', 'overallAssessment']}
                  valueType="radio"
                  dataSource={radioDataSourCeoverallAssessment}
                  fieldProps={{
                    className: 'ant-radio-checkbox-border',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="总体评估详述"
                  name={['bto_param', 'overallAssessmentDetail']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
              </VsfFormLayoutDefine>
              <VsfFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                <VSFormItem
                  label="保健指导"
                  name={['bto_param', 'healthGuidance']}
                  valueType="checkbox"
                  dataSource={enums['healthcare_guidance']}
                  fieldProps={{
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
              </VsfFormLayout>
              <VsfFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                <VSFormItem
                  label="保健指导详述"
                  name={['bto_param', 'healthGuidanceDetail']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
              </VsfFormLayout>
              <VsfFormLayoutDefine
                labelCol={labelColWidth[6]}
                columnSpan={[1, 2]}
              >
                <VSFormItem
                  label="孕妇学校"
                  name={['bto_param', 'gravidaSchool']}
                  valueType="radio"
                  dataSource={radioDataSource4}
                ></VSFormItem>
              </VsfFormLayoutDefine>
            </CollapseBlock>

            <CollapseBlock
              title="转诊信息"
              description=""
              error=""
              id="anchor-transfer"
            >
              <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                <VSFormItem
                  label="转诊"
                  name={['bto_param', 'referral']}
                  valueType="radio"
                  dataSource={radioDataSource1}
                  fieldProps={{
                    className: 'ant-radio-checkbox-border',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="转诊原因"
                  name={['bto_param', 'referralReason']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="转诊机构"
                  name={['bto_param', 'referralOrg']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="转诊科室"
                  name={['bto_param', 'referralDepartment']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
              </VsfFormLayout>
            </CollapseBlock>

            <CollapseBlock
              title="随访信息"
              description=""
              error=""
              id="anchor-visit"
            >
              <VsfFormLayout columnCount={3} labelCol={labelColWidth[6]}>
                <VSFormItem
                  label="下次随访日期"
                  name={['bto_param', 'nextFollowupTime']}
                  valueType="date"
                  fieldProps={{
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="随访医生"
                  name={['bto_param', 'followupDoctor']}
                  valueType="select"
                  fieldProps={{
                    showSearch: true,
                    placeholder: '请选择',
                  }}
                  dataSource={hisDoctorInfo?.doctor}
                ></VSFormItem>
                <VSFormItem
                  label="随访单位"
                  name={['bto_param', 'followupUnit']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '请输入',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="录入日期"
                  name={['bto_param', 'enterTime']}
                  valueType="date"
                  fieldProps={{
                    placeholder: '请选择',
                  }}
                ></VSFormItem>
                <VSFormItem
                  label="录入人"
                  name={['bto_param', 'enterPerson']}
                  valueType="select"
                  fieldProps={{
                    showSearch: true,
                    placeholder: '请选择',
                  }}
                  dataSource={hisDoctorInfo?.doctor}
                ></VSFormItem>
                <VSFormItem
                  label="录入机构"
                  name={['bto_param', 'enterOrg']}
                  valueType="select"
                  fieldProps={{
                    placeholder: '请选择',
                    disabled: true,
                  }}
                  dataSource={hisDoctorInfo?.organization}
                ></VSFormItem>
              </VsfFormLayout>
            </CollapseBlock>
          </VSForm>
        </div>
        {/* <div className="e-medical-record-detail-footer">
          <Button type="primary" onClick={handleSubmit}>
            保存
          </Button>
        </div> */}
      </div>
    </Modal>
  );
};

export default definePage(Index);

import { createServices } from "@vs/service";

export default createServices({
  updateCityScreening: {
    method: "post",
    url: "/api/record/updateCityScreening",
    parameterFomatter: (data?: {
      btoParam: HealthcarePrenatalScreeningServiceBtoCreateInCityScreeningApplyBto,
      extApiParam: any,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  getCityScreeningDetail: {
    method: "post",
    url: "/api/record/getCityScreeningDetail",
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: HealthcarePrenatalScreeningManagerDtoPrenatalScreeningApplyInCityDto
    ) => data,
  },
  getCityScreening: {
    method: "post",
    url: "/api/record/getCityScreening",
    parameterFomatter: (data?: {}) => data,
    responseFormatter: (_, __, data?: {}) => data,
  },
});

import React, { useState, useRef } from 'react';
import vsf, { definePage, renderRoutes } from '@vs/vsf-boot';
import { Layout, Menu, Breadcrumb } from '@vs/vsf-kit';
import RecordModal from '@/pages/record/modal';
import AntenatalPrint from '../antenatalPrint';
import { useReactToPrint } from 'react-to-print';
import { globalPageType, ROOT_ROUTER } from '@/config';
import './index.less';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const breadcrumbMap = {
  archives: '孕产档案',
  info: '建档信息',
  examination: '产科检查',
  medical: '产科病历',
  pregnancy: '妊娠指标',
  antenatal: '产检信息',
  // first: '首次产检信息',
  return: '产检记录',
  screening: '产前筛查',
  city: '市内产前筛查',
};

const items = [
  getItem('孕产档案', 'archives', null, [
    getItem('建档信息', 'info'),
    getItem('产科检查', 'examination'),
    // getItem('产科病历', 'medical'),
    // getItem('妊娠图', 'pregnancy'),
  ]),
  getItem('产检信息', 'antenatal', null, [
    // getItem('首次产检信息', 'first'),
    getItem('产检记录', 'return'),
  ]),
  // getItem('产前筛查', 'screening', null, [
  //   getItem('市内产前筛查', 'city'),
  // ]),
];

const rootSubmenuKeys = ['archives', 'antenatal', 'screening'];

const Index = props => {
  const { gravida, common } = vsf?.stores;

  const { route } = props;
  const selectKeysFromPathname = window.location.pathname
    .split('/')
    .filter(v => v && v !== 'record');
  const [openKeys, setOpenKeys] = useState([selectKeysFromPathname[0]]);
  const [selectedKeys, setSelectedKeys] = useState(selectKeysFromPathname);

  const printRef = useRef(null);

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    const newPath = keyPath.reverse();
    setSelectedKeys(newPath);
    vsf.navigatorTo(`/record/${newPath.join('/')}${window?.location?.search}`);
  };
  const handlePrint = useReactToPrint({
    content: () => {
      return printRef.current;
    },
    onBeforeGetContent: async () => {
      // await common.handlePrint();
      return Promise.resolve();
    },
  });

  const renderPrintButton = name => {
    return (
      name === breadcrumbMap['return'] &&
      common?.globalPageType === globalPageType.DOCTOR && (
        <div
          className="ant-btn ant-btn-default sc-gKPRtg XIeMP"
          onClick={handlePrint}
        >
          打印
        </div>
      )
    );
  };
  return (
    <Layout className="e-medical-record-main">
      <div className="container">
        <div className="left">
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            onSelect={onSelect}
            selectedKeys={selectedKeys}
            items={items}
          />
        </div>
        <div className="right">
          <div className="header">
            <Breadcrumb>
              <Breadcrumb.Item>孕产保健</Breadcrumb.Item>
              {(selectedKeys || []).map(item => (
                <Breadcrumb.Item>{breadcrumbMap[item]}</Breadcrumb.Item>
              ))}
            </Breadcrumb>
            <div className="title">
              {breadcrumbMap[selectedKeys[selectedKeys.length - 1]]}
              <div className="extra" id="e-medical-record-header-extra"></div>
              {renderPrintButton(
                breadcrumbMap[selectedKeys[selectedKeys.length - 1]],
              )}
              {/* <button className="printButton">打印</button> */}
            </div>
          </div>
          <div className="right-content">{renderRoutes(route.routes)}</div>
          <RecordModal key={gravida?.createRecordVisible} />
        </div>
      </div>
      <div ref={printRef} className="e-medical-print-container">
        <AntenatalPrint></AntenatalPrint>
      </div>
    </Layout>
  );
};
export default definePage(Index);

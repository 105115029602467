import React, { useEffect, useState } from 'react';
import { Collapse, Button } from '@vs/vsf-kit';
import vsf, { definePage } from '@vs/vsf-boot';
import './index.less';

const { Panel } = Collapse;

export interface PageBlockType {
  title: string;
  description?: string;
  error?: string;
  children?: React.ReactNode;
  inline?: boolean;
  collapseAuto?: boolean;
  id?: string;
  style?: any;
  rightContent: any;
  defaultExpand: boolean;
}

const Index = (props: PageBlockType) => {
  const classPrefix = 'e-medical-collapse-block';
  const {
    children,
    title,
    description,
    error,
    inline,
    id,
    style,
    rightContent,
    collapseAuto = true,
    defaultExpand = true,
  } = props;
  const [activeKey, setActiveKey] = useState(
    !collapseAuto
      ? (vsf?.stores?.medical?.collapseKeys || []).filter(v => v === title)
      : defaultExpand ? [title] : [],
  );

  useEffect(() => {
    if (!collapseAuto) {
      setActiveKey(
        (vsf?.stores?.medical?.collapseKeys || []).filter(v => v === title),
      );
    }
  }, [vsf?.stores?.medical?.collapseKeys]);

  const onChange = (key: string | string[]) => {
    if (!collapseAuto) {
      vsf?.stores?.medical?.changeCollapse(
        title,
        key.length !== 0 ? 'active' : 'collaspe',
      );
    } else {
      setActiveKey(activeKey?.length === 0 ? [key] : []);
    }

  };
    const titleRender = () => { 
      return (
        <div className={`${classPrefix}-headertext`}>
          <div>
            <span className="title">{title}</span>
            {description && <span className="desc">（{description}）</span>}
            {error && <span className="error">{error}</span>}
          </div>
          {rightContent && <React.Fragment>{rightContent}</React.Fragment>}
        </div>
      );
    };

  return (
    <div
      className={`${inline ? `${classPrefix}-inline` : `${classPrefix}`}`}
      id={id}
      style={style}
    >
      <Collapse
        activeKey={activeKey}
        onChange={onChange}
        expandIconPosition="end"
      >
        <Panel header={titleRender()} key={title} forceRender={true}>
          {children}
        </Panel>
      </Collapse>
    </div>
  );
};

export default definePage(Index);

import React, { useEffect, useState } from 'react';
import { RadioGroup, Input, InputNumber } from '@vs/vsf-kit';
import './index.less';

export interface RadioStrType {
  value: any;
  onChange: (value: any) => void;
  disabled?: boolean;
}

const Index = (props: RadioStrType) => {
  const { value, onChange, disabled } = props;
  const [mature, setMature] = useState('');
  const [premature, setPremature] = useState('');
  const [abortion, setAbortion] = useState('');
  const [survival, setSurvival] = useState('');

  useEffect(() => {
    setMature(value?.mature ?? '');
    setPremature(value?.premature ?? '');
    setAbortion(value?.abortion ?? '');
    setSurvival(value?.survival ?? '');
  }, [value]);

  const handleValue1Change = v => {
    setMature(v);
    onChange({
      mature: v,
      premature,
      abortion,
      survival,
    });
    
  };
  const handleValue2Change = v => {
    setPremature(v);
    onChange({
      mature,
      premature: v,
      abortion,
      survival,
    });
  };
  const handleValue3Change = v => {
    setAbortion(v);
    onChange({
      mature,
      premature,
      abortion: v,
      survival,
    });
  };
  const handleValue4Change = v => {
    setSurvival(v);
    onChange({
      mature,
      premature,
      abortion,
      survival: v,
    });
  };

  return (
    <div className="e-medical-blood-pressure">
      <InputNumber
        value={mature}
        max={999}
        min={0}
        precision={0}
        onChange={handleValue1Change}
        addonAfter="足"
        placeholder="请输入"
        controls={false}
        disabled={disabled}
      />
      <InputNumber
        value={premature}
        max={999}
        min={0}
        precision={0}
        onChange={handleValue2Change}
        addonAfter="早"
        placeholder="请输入"
        controls={false}
        disabled={disabled}
      />
      <InputNumber
        value={abortion}
        max={999}
        min={0}
        precision={0}
        onChange={handleValue3Change}
        addonAfter="流"
        placeholder="请输入"
        controls={false}
        disabled={disabled}
      />
      <InputNumber
        value={survival}
        max={999}
        min={0}
        precision={0}
        onChange={handleValue4Change}
        addonAfter="存"
        placeholder="请输入"
        controls={false}
        disabled={disabled}
      />
    </div>
  );
};

export default Index;

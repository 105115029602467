import React, { useState, useRef, useEffect } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { Modal, CheckboxGroup } from '@vs/vsf-kit';
import { forbidEdit } from '@/pages/nurse/utils';
import './index.less';

const Index = props => {
  const { common, gravida } = vsf?.stores;
  const { onSubmit, disabled } = props;

  const [visible, setVisible] = useState(false);
  const [currentValues, setCurrentValues] = useState([]);

  const handleOk = () => {
    onSubmit?.(currentValues);
    // vsf.stores.gravida.epidemic = currentValues;
  };
  const handleCancel = () => {};

  const handleChange = (checkedValues: any) => {
    setCurrentValues(checkedValues);
  };

  useEffect(() => {
    if (!props.stores.gravida.epidemic) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [props.stores.gravida.epidemic]);

  const enums = vsf?.stores?.common?.enums;

  return (
    <Modal
      width={380}
      className="e-epidemic-modal"
      title="填写流行病学史"
      keyboard={true}
      destroyOnClose={true}
      okText="下一步"
      visible={visible && !forbidEdit(gravida?.gravidaRecord, common)}
      id="complaint-modal"
      onOk={handleOk}
      onCancel={handleCancel}
      cancelButtonProps={{
        hidden: true,
      }}
      closable={false}
      maskClosable={false}
    >
      <CheckboxGroup
        value={currentValues || []}
        onChange={handleChange}
        dataSource={enums['epidemiological_history']}
        disabled={disabled}
      ></CheckboxGroup>
    </Modal>
  );
};

export default definePage(Index);

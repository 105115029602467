import { createServices } from '@vs/service';

export default createServices({
  gravidaInfo: {
    method: 'post',
    url: '/api/gravidaInfo',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  doctorInfo: {
    method: 'post',
    url: '/api/doctorInfo',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  getAllergyDictionary: {
    method: 'post',
    url: '/api/getAllergyDictionary',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  getRecommandComplaint: {
    method: 'post',
    url: '/api/getRecommandComplaint',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  getRecommandDiagnosis: {
    method: 'post',
    url: '/api/getRecommandDiagnosis',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  getRecommandExamAndDrug: {
    method: 'post',
    url: '/api/getRecommandExamAndDrug',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  getAddressByLevel: {
    method: 'post',
    url: '/api/getAddressByLevel',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  getAllergyHistory: {
    method: 'post',
    url: '/api/getAllergyHistory',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  createAllergyHistory: {
    method: 'post',
    url: '/api/createAllergyHistory',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 查询历史诊断
  getDiagnosisHistory: {
    method: 'post',
    url: '/api/getDiagnosisHistory',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 模糊查询主诉
  getComplaintSearch: {
    method: 'post',
    url: '/api/getComplaintSearch',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 已选择的流行病学史（打印用）
  getCheckEpidemiological: {
    method: 'post',
    url: '/api/getCheckEpidemiological',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 全部流行病学史（打印用）
  getAllEpidemiological: {
    method: 'post',
    url: '/api/getAllEpidemiological',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 患者列表医生信息
  patientDoctorList: {
    method: 'post',
    url: '/api/patient/doctor/list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 患者列表医生信息
  patientDepartmentList: {
    method: 'post',
    url: '/api/patient/department/list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 患者列表医生信息
  getPatientDetail: {
    method: 'post',
    url: '/api/patient/detail',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 修改接诊状态
  updateRegisteredStatus: {
    method: 'post',
    url: '/api/patient/update/status',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 初复诊获取医生列表数据 (随访医生,录入人,随访单位)
  getHisDoctorList: {
    method: 'post',
    url: '/api/antenatal/doctor/list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 创建医生常用其他现病史
  createHpi: {
    method: 'post',
    url: '/api/medical/hpi/create',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 删除医生常用其他现病史
  deleteHpi: {
    method: 'post',
    url: '/api/medical/hpi/delete',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 获取当前医生常用其他现病史
  getHpiList: {
    method: 'post',
    url: '/api/medical/hpi/list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 获取病历详情（医生端）
  getMedicalDetailWithHisRecordIdDoctor: {
    method: 'post',
    url: '/api/medical/getMedicalDetailWithHisRecordIdDoctor',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 获取现病史
  getHistoryHpiList: {
    method: 'post',
    url: '/api/medical/historyHpi/list',
    parameterFomatter: (data?: { hisId: string }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 获取现病史
  getDiagnoseList: {
    method: 'post',
    url: '/api/medical/getDiagnose/List',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },

  // 获取科室名称
  getExternalName: {
    method: 'post',
    url: '/api/medical/getExternalName',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
});

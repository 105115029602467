export interface MedicalFormErrorFieldArrayType {
  title: string;
  name: string[];
  anchorId: string;
  children: any;
}

export const MedicalFormErrorFieldArray = [
  {
    title: '孕产妇基本信息',
    name: ['mensesInfoSnapshotBto', 'pregnancyInfoSnapshotBto'],
    anchorId: 'anchor-gravida',
    children: {
      menarche: '月经初潮',
      menstrualCycle: '月经周期',
      menstrualPeriod: '月经经期',
      menstrualVolume: '月经经量',
      dysmenorrhea: '痛经',
      leucorrhea: '白带',
      gestationalWeek: '孕周',
      edc: '预产期',
      weightBeforePregnancy: '孕前体重',
      parity: '产次',
      gravidity: '孕次',
      fereilizingMethod: '受孕方式',
      fetusNum: '胎数',
      deliveryLocal: '计划分娩医院',
      contraceptionHistory: '避孕史',
      earlyPregnancyInfo: '本次妊娠早孕期情况',
    },
  },
  {
    title: '概要',
    name: ['personalHistorySnapshotBto', 'familyHistorySnapshotBto'],
    anchorId: 'anchor-outline',
    children: {
      rhNegative: 'RH-阴性血',
      nllrHistory: '外地久居住史',
      epidemicFocus: '疫水疫源接触史',
      smokingHistory: '吸烟史',
      drinkingHistory: '喝酒史',
      vaccinationHistory: '预防接种史',
      harmfulSubstanceContactHistory: '有毒有害物质',
      radiationContactHistory: '接触放射线',
      geneticHistory: '遗传病史',
      tumorHistory: '肿瘤病史',
      infectiousHistory: '传染病史',
      mentalDiseaseHistory: '精神病史',
      malformation: '畸形',
      mentalRetardation: '智障',
    },
  },
  {
    title: '体格检查',
    name: ['extrapelvicMeasurementSnapshotBto', 'physicalInfo'],
    anchorId: 'anchor-physical',
    children: {
      interspinalDiameter: '髂棘间径',
      intercristalDiameter: '髂嵴间径',
      externalConjugate: '骶耻外径',
      transverseOutlet: '出口横径',
      temperature: '体温',
      weight: '体重',
      bloodPressure: '血压',
      pulse: '脉搏',
      deliveryLocal: '计划分娩医院',
      bellyacheDurationTime:'腹痛持续时间',
      bellyacheIntervalTime:'腹痛间隔时间',
    },
    extra: ['deliveryLocal'],
  },
  {
    title: '辅助检查',
    name: ['assistantInfo'],
    anchorId: 'anchor-assistant',
    children: {
      nt: 'NT检查',
      earlyDownsScreening: '早期唐筛',
      middleDownsScreening: '中期唐筛',
      nipt: 'NIPT检查',
    },
  },
];

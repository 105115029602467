import React, { useEffect, useState } from 'react';
import { RadioGroup, Input, Select, InputNumber } from '@vs/vsf-kit';
import '../index.less';

interface RadioStrType {
  value: string;
  onChange: (value: string | null) => void;
  dataSource?: any;
}

interface AbnormalValueType {
  value1?: string; // 早筛21三体
  value2?: string; // 早筛18三体
  ntdValue1?: string; // 中筛NTD风险
  ntdValue2?: string; // 中筛NTD风险2
  middleValue?: string; // 中筛风险
}

export const MiddleDownsScreening = (props: RadioStrType) => {
  const { value, onChange, dataSource } = props;
  const [radioValue, setRadioValue] = useState(undefined);
  const [otherValue, setOtherValue] = useState('');
  const [abnormalValue, setAbnormalValue] = useState({
    value1: '',
    value2: '',
    ntdValue1: '',
    ntdValue2: '',
    middleValue: '',
  });

  useEffect(() => {
    let formatValue: any = {};

    try {
      formatValue = JSON.parse(value);
      setRadioValue(formatValue?.value);
      setOtherValue(formatValue?.otherInfo || '');
      setAbnormalValue(
        formatValue?.abnormalInfo || {
          value1: '',
          value2: '',
          ntdValue1: '',
          ntdValue2: '',
          middleValue: '',
        },
      );
    } catch {}
  }, [value]);

  const handleChange = () => {
    const value = JSON.stringify({
      value: radioValue,
      otherInfo: otherValue,
      abnormalInfo: abnormalValue,
    });
    onChange && onChange(radioValue ? value : null);
  };

  const handleRadioChange = v => {
    setRadioValue(v)
  };
  const handleInputChange = e => {
    setOtherValue(e.target.value);
  };
  const handleAbnormalValueChange = (v, target) => {
    setAbnormalValue({
      ...abnormalValue,
      [target]: v,
    });
  };

  return (
    <div
      className={`e-medical-assistant ${
        radioValue === '2' ? 'e-medical-assistant-vertical' : ''
      }`}
    >
      <RadioGroup
        value={radioValue}
        onChange={handleRadioChange}
        dataSource={[
          { label: '未查', value: '0' },
          { label: '正常', value: '1' },
          { label: '异常', value: '2' },
          { label: '其他', value: '3' },
        ]}
        onBlur={handleChange}
      ></RadioGroup>
      {radioValue === '2' && (
        <div className="e-medical-assistant-outline">
          <div className="e-medical-assistant-formitem-block">
            <div className="formitem-block-middle">
              <div className="label">早筛21三体：</div>
              <div className="formitem-nowrap">
                <Input className="molecule" value={1} disabled />
                <span className="slash">/</span>
                <InputNumber
                  min={0}
                  max={999}
                  value={abnormalValue?.value1}
                  onChange={v => {
                    handleAbnormalValueChange(v, 'value1');
                  }}
                  placeholder="请输入分母"
                  onBlur={handleChange}
                />
              </div>
            </div>
            <div className="formitem-block-middle">
              <div className="label">早筛18三体：</div>
              <div className="formitem-nowrap">
                <Input className="molecule" value={1} disabled />
                <span className="slash">/</span>
                <InputNumber
                  min={0}
                  max={999}
                  value={abnormalValue?.value2}
                  onChange={v => {
                    handleAbnormalValueChange(v, 'value2');
                  }}
                  placeholder="请输入分母"
                  onBlur={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="e-medical-assistant-formitem-block">
            <div className="formitem-block-middle formitem-full">
              <div className="label">中筛NTD风险：</div>
              <div className="formitem-nowrap">
                <Select
                  value={abnormalValue?.ntdValue1 || undefined}
                  onChange={v => {
                    handleAbnormalValueChange(v, 'ntdValue1');
                  }}
                  dataSource={dataSource?.ntd1 || []}
                  placeholder="请选择"
                  onBlur={handleChange}
                />
                <Select
                  value={abnormalValue?.ntdValue2 || undefined}
                  onChange={v => {
                    handleAbnormalValueChange(v, 'ntdValue2');
                  }}
                  dataSource={dataSource?.ntd2 || []}
                  placeholder="请选择"
                  onBlur={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="e-medical-assistant-formitem-block">
            <div className="formitem-block-middle">
              <div className="label">中筛风险：</div>
              <div className="formitem-nowrap">
                <Select
                  className="special"
                  value={abnormalValue?.middleValue || undefined}
                  onChange={v => {
                    handleAbnormalValueChange(v, 'middleValue');
                  }}
                  dataSource={dataSource?.middle || []}
                  placeholder="请选择"
                  onBlur={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {(radioValue === '1' || radioValue === '3') && (
        <Input
          value={otherValue}
          onChange={v => handleInputChange(v)}
          placeholder="请输入"
          onBlur={handleChange}
        />
      )}
    </div>
  );
};

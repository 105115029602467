import { STORAGE_KEY_PREFIX } from '@/config';

const storage = {
    /* storage set
     *
     * @param {string} key 存储 key
     * @param {any} data 存储 数据
     * @param {string} keyGroup 存储组的 key，用于之后统一删除对应若干 key
     */
    set: (key: string, data: any, keyGroup?: string) => {
        const newKey = `${STORAGE_KEY_PREFIX}-${key}`;
        const newKeyGroup = `${STORAGE_KEY_PREFIX}-${keyGroup}`;
        if (key) { 
            localStorage.setItem(newKey, JSON.stringify(data));
        }
        if (keyGroup) {
            let group: any = localStorage.getItem(newKeyGroup);
            if (group) {
                if (!group.includes(newKey)) {
                    group.push(newKey);
                }
            } else {
                group = [newKey];
            }
            localStorage.setItem(newKeyGroup, JSON.stringify(group));
        }
    },
    /* storage get
     *
     * @param {string} key 存储 key
     */
    get: (key: string) => {
        const newKey = `${STORAGE_KEY_PREFIX}-${key}`;
        let result = null;
        try {
            const data = localStorage.getItem(newKey);
            if (data) {
                result = JSON.parse(data);
            }
        } catch { }
        return result;
    },
    /* storage clean
     *
     * @param {string} keyGroup 存储组的 key，统一删除对应若干 storage
     */
    clean: (keyGroup?: string) => {
        const newKeyGroup = `${STORAGE_KEY_PREFIX}-${keyGroup}`;
        if (newKeyGroup) {
            const group: any = localStorage.getItem(newKeyGroup);
            group &&
                group.forEach((item: any) => {
                    localStorage.removeItem(item);
                });
            localStorage.removeItem(newKeyGroup);
        } else {
            localStorage.clear();
        }
    },
    /* storage remove
     *
     * @param {string} key 移除对应 storage
     */
    remove: (key: string) => {
        const newKey = `${STORAGE_KEY_PREFIX}-${key}`;
        localStorage.removeItem(newKey);
    },
};

export default storage;

import React, { useEffect, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import vsf, { definePage } from '@vs/vsf-boot';
import { Checkbox, Icon, Input, TextArea, Dropdown } from '@vs/vsf-kit';
import Modal from './modal';
import {
  getSelection,
  setSelection,
} from '@/utils/selection';
import { useKeyPress, useDebounceFn, useEventListener } from 'ahooks';
import '../index.less';
import { useBoolean } from '@/utils';
import staticCommonService from '@/module/common/services';

export type recommendArrayType = recommendType[];

// 推荐列表类型
export interface recommendType {
  label?: string;
  value?: string;
  unit?: string;
  data?: any;
  confirm?: boolean;
  id?: string;
}

export interface ComplaintPropsType {
  // 类名
  className?: string;
  // 标题
  title?: string;
  // 智能推荐列表
  dataSource?: recommendType[];
  // 回显值
  value: string;
  // 回调
  onChange: (v: string) => void;
}

type searchItemType = {
  label: string;
  id: string;
}

/**
 * 主诉组件
 **/

const textareaId = 'complaint-textarea';

const Index = (props: ComplaintPropsType) => {
  const classPrefix = 'e-medical-data-entering';
  const { className, title, dataSource, value, onChange } = props;
  // const { complaintModalData, hisId } = vsf?.stores?.common;
  const ref = useRef<any>();
  // 常用主诉弹框控制 （未启用）
  const [visible, { on, off }] = useBoolean();
  // 搜索框控制
  const [searchVisible, { on: searchOn, off: searchOff }] = useBoolean();
  // 模糊搜索主诉列表
  const [searchList, setSearchList] = useState<searchItemType[]>([]);
  // 搜索关键字
  const [keyword, setKeyword] = useState<string>('');
  // 当前输入值
  const [inputValue, setInputValue] = useState<string>('');

  // 关闭搜索框
  useEventListener(
    'click',
    (e: any) => {
      if (e?.target?.id !== textareaId) { 
        searchOff();
      }
    },
    { target: document.body },
  );

  // 模糊搜索主诉（防抖）
  const { run } = useDebounceFn(
    value => {
      getComplainSearch(value);
    },
    {
      wait: 200,
    },
  );

  const handleInputChange = (e) => { 
    setInputValue(e?.target?.value);
  }

  /**
   * 点击推荐项、模糊搜索项，将项的名称插入到 textarea 里去
   * @param item 相关项
   * @param str 指定字符串
   * @returns 
   */
  const handleSelect = (item: {
    label?: string,
  }, str?: string) => {
    if (!item?.label) return;
    const target = document.getElementById('complaint-textarea');
    const value = `${item?.label}天，`;
    const newStr = str ?? inputValue
    const newValue = `${newStr}${value}`;
    // 最新的光标信息
    const nextSelection = {
      start: newStr.length + value.length,
      end: newStr.length + value.length,
    };
    // textarea 获取焦点，并设置光标位置
    setTimeout(() => {
      setSelection(target, nextSelection);
    }, 0);
    setInputValue(newValue);
    ref?.current?.focus();
    setTimeout(() => {
      setSelection(target, {
        start: nextSelection.start - 2,
        end: nextSelection.end - 2,
      });
    }, 0);
  };

  // 监听 textarea 的回车键，进行模糊搜索
  useKeyPress('enter', (e: any) => {
    e.preventDefault();
    if (e?.target?.id !== textareaId) return;
    const target = document.getElementById(textareaId);
    const prevSelection = getSelection(target);
    const len = inputValue.length;
    if (len > prevSelection.end) {
      setTimeout(() => {
        setSelection(target, {
          start: len,
          end: len,
        });
      }, 0);
    } else { 
      run(inputValue);
    }
  });

  // 主诉模糊搜索
  const getComplainSearch = async (value: string) => {
    const key: string = cloneDeep(value)?.split(/,|，/)?.pop() || '';
    setKeyword(key);
    const res = await staticCommonService.getComplaintSearch({
      complaintContent: key,
    });
    if (!res?.data || res?.data?.length === 0) {
      searchOff();
    } else { 
      setSearchList(handleRenderSearchList(res?.data || []));
      searchOn();
    }
  };

  // 搜索列表整理
  const handleRenderSearchList = (list) => { 
    return list.map(item => ({
      key: item?.id,
      label: item?.complaintContent,
    }));
  }

  // 选中搜索列表中的项
  const handleClickSearchItem = ({ key }) => {
    const target = searchList.find((item: any) => item.key == key);
    if (target) { 
      const replaceStr = inputValue.replace(keyword, '');
      setInputValue(replaceStr);
      handleSelect(target, replaceStr);
    } 
    searchOff();
  };

  // const handleOpenChange = (open) => { 
  //   console.log('open', open);
  // };


  // const handleModalOk = v => {
  //   off();
  // };

  useEffect(() => {
    if (inputValue !== undefined || inputValue !== null) { 
      onChange && onChange(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (typeof value === 'string') { 
      setInputValue(value);
    }
  }, [value]);

  // const formatModalData = complaintModalData;
  const formatRecommend = dataSource;

  return (
    <div className={`e-medical-block ${classPrefix} ${className}`}>
      <div className={`${classPrefix}-header`}>
        {title && <div className="title">{title}</div>}
        <Dropdown
          menu={{ items: searchList as any, onClick: handleClickSearchItem }}
          overlayClassName={`${classPrefix}-dropdown`}
          open={searchVisible}
          destroyPopupOnHide={true}
          autoFocus={true}
        >
          <TextArea
            id={textareaId}
            ref={ref}
            autoSize
            style={{ width: '100%' }}
            placeholder="请输入主诉"
            value={inputValue}
            maxLength={200}
            onChange={handleInputChange}
            // onClick={on}
          />
        </Dropdown>
      </div>
      <div className={`${classPrefix}-recommend`}>
        {formatRecommend &&
          formatRecommend?.map(item => (
            <div
              key={item?.value}
              className="item"
              onClick={() => {
                handleSelect(item);
              }}
            >
              {item?.label}
            </div>
          ))}
      </div>
      {/* <Modal
        hisId={hisId}
        title={title || ''}
        visible={visible}
        onCancel={off}
        onOk={handleModalOk}
        defaultData={inputValue}
        dataSource={formatModalData || []}
      /> */}
    </div>
  );
};

export default definePage(Index);

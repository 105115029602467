import React from 'react';
import {
    Modal
} from '@vs/vsf-kit';
import Medical from '@/pages/medical';
import { definePage } from '@vs/vsf-boot';

const Index = (props: {
    data: any;
    visible: boolean;
    onCancel: () => void;
}) => {
    const {
        data,
        visible,
        onCancel
    } = props;

    const handleOk = () => { 
        onCancel && onCancel();
    }
    const handleCancel = () => { 
        onCancel && onCancel();
    }
    return (
      <Modal
        width={1032}
        className="e-medical-record-modal"
        title="病历历史"
        keyboard={true}
        destroyOnClose={true}
        okText="确定"
        cancelText="返回"
        visible={visible}
        id="medical-history-modal"
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <div
          style={{
            padding: 20,
          }}
        >
          <Medical key="1" data={data} />
        </div>
      </Modal>
    );
}

export default definePage(Index);
import React from 'react';
import { colorBarMap, highriskVisibleType } from '@/config';
import vsf, { definePage } from '@vs/vsf-boot';
import './index.less';

export interface HighriskBarType {
  max?: {
    date: string;
    value: any[];
  };
  lately?: {
    date: string;
    value: any[];
  };
}

const Index = (props: HighriskBarType) => {
  const { max, lately } = props;
  const handleShowLatestModal = () => {
    if (lately?.date) {
      vsf.setStoreValue?.(
        'gravida.highriskVisible',
        highriskVisibleType.LATEST,
      );
    }
  };
  const handleShowHighestModal = () => {
    if (max?.date) {
      vsf.setStoreValue?.(
        'gravida.highriskVisible',
        highriskVisibleType.HIGHEST,
      );
    }
  };

  return (
    <div className="e-medical-highrisk-bar">
      <div onClick={handleShowHighestModal}>
        <span>最高</span>
        <div>
          <div className={`date ${!max?.date ? 'date-gray' : ''}`}>
            {!max?.date ? '暂无' : max?.date?.split(' ')?.[0]}
          </div>
          {!max?.date ? (
            <div className="bar">
              <span
                style={{
                  backgroundColor: '#F0F2F5',
                  borderRadius: '20px',
                }}
              ></span>
            </div>
          ) : (
            <div className="bar">
              {(max?.value || []).map(item => (
                <span
                  style={{
                    backgroundColor:
                      (colorBarMap[item] || item) ?? '#52C41A',
                    borderRadius: max?.value.length === 1 ? '20px' : '',
                  }}
                ></span>
              ))}
            </div>
          )}
        </div>
      </div>
      <div onClick={handleShowLatestModal}>
        <span>最近</span>
        <div>
          <div className={`date ${!lately?.date ? 'date-gray' : ''}`}>
            {!lately?.date ? '暂无' : lately?.date?.split(' ')?.[0]}
          </div>
          {!lately?.date ? (
            <div className="bar">
              <span
                style={{
                  backgroundColor: '#F0F2F5',
                  borderRadius: '20px',
                }}
              ></span>
            </div>
          ) : (
            <div className="bar" onClick={handleShowLatestModal}>
              {(lately?.value || []).map(item => (
                <span
                  style={{
                    backgroundColor: (colorBarMap[item] || item) ?? '#52C41A',
                    borderRadius: lately?.value.length === 1 ? '20px' : '',
                  }}
                ></span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default definePage(Index);

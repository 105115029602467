import React, { useEffect, useState } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { message, Modal, VSForm, VSFormItem } from '@vs/vsf-kit';
import VsfFormLayout from '@/components/form-layout';

/**
 * 高危评估后续上报弹框
 **/

const Index = props => {
  const { gravida, common } = vsf?.stores;
  const { highriskFormVisible } = gravida;
  const [visible, setVisible] = useState(false);

  const handleOk = () => {
    message.success('上报成功');
    vsf?.setStoreValue('gravida.highriskFormVisible', false);
  };
  const handleCancel = () => {
    vsf?.setStoreValue('gravida.highriskFormVisible', false);
  };

  return (
    <Modal
      width={1032}
      className="e-medical-record-modal"
      title="高危专案表单"
      keyboard={true}
      destroyOnClose={true}
      visible={highriskFormVisible}
      id="complaint-modal"
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      okText="保存"
      cancelText="取消"
    >
      <div
        style={{
          padding: 20,
        }}
      >
        <VSForm
          dataType="static"
          layout="horizontal"
          initialValues={{
            medicalInfo: {
              name: '陈秀英',
              complaint: '40W1D',
              sdad: new Date().toString(),
            },
          }}
        >
          <VsfFormLayout columnCount={3}>
            <VSFormItem
              label="姓名"
              name={['medicalInfo', 'name']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="孕周"
              name={['medicalInfo', 'complaint']}
              valueType="digit"
              fieldProps={{
                controls: false,
              }}
            ></VSFormItem>
            <VSFormItem
              label="就诊日期"
              name={['medicalInfo', 'sdad']}
              valueType="date"
            ></VSFormItem>
          </VsfFormLayout>
          <VsfFormLayout columnCount={1}>
            <VSFormItem
              label="病情及治疗措施"
              name={['medicalInfo', 'name22']}
              valueType="textarea"
            ></VSFormItem>
          </VsfFormLayout>
          <VsfFormLayout columnCount={3}>
            <VSFormItem
              label="下次就诊随访时间"
              name={['medicalInfo', 'name22']}
              valueType="date"
            ></VSFormItem>
            <VSFormItem
              label="报告机构"
              name={['medicalInfo', 'name22']}
              valueType="text"
            ></VSFormItem>
          </VsfFormLayout>
        </VSForm>
      </div>
    </Modal>
  );
};

export default definePage(Index);

import React from 'react';
import moment from 'moment';
import { disableEdcDate, disableLmpDate, showDateFormat } from '@/utils/rule';
import { FormBlockType } from '.';
import { radioDataSource } from '@/config';
import { VSFormItem, VSFormDependency } from '@vs/vsf-kit';
import RetainEarlyEnough from '@/components/retain-early-enough';
import GestationalWeek from '@/components/gestational-week';
import Dayrange from '@/components/day-range';
import VsfFormLayout, {
  FormBlock2,
  FormBlock,
  VsfFormLayoutDefine,
} from '@/components/form-layout';

export const pregnancyInfoFormItem = data => {
  const {
    basic,
    formType,
    labelCol,
    extra,
    disabled,
    pregnancyInfo,
    pregnancyInfoBto,
  } = data;
  const { enums, addressData } = basic || {};
  let formatProps = {};
  let Box = <React.Fragment></React.Fragment>;
  if (formType) {
    Box = formType === FormBlockType.SIGN ? FormBlock : FormBlock2;
  }
  if (labelCol) {
    formatProps = {
      labelCol,
    };
  }
  const renderPregnancyInfo = (pregnancyInfo, pregnancyInfoBto) => {
    return pregnancyInfo ?? pregnancyInfoBto;
  };
  return (
    <React.Fragment>
      <Box title="怀孕信息">
        <VsfFormLayout columnCount={3} {...formatProps}>
          <VSFormItem
            label="末次月经"
            name={['pregnancyInfoBto', 'lmp']}
            valueType="date"
            fieldProps={{
              disabledDate: disableLmpDate,
              format: showDateFormat,
            }}
            rules={[
              {
                required: true,
                message: '请填写末次月经',
              },
            ]}
          ></VSFormItem>

          <VSFormItem
            label="孕周"
            name={['pregnancyInfoBto', 'gestationalWeek']}
            valueType="custom"
          >
            <GestationalWeek disabled />
          </VSFormItem>

          <VSFormItem
            label="修正孕周"
            name={['pregnancyInfoBto', 'updateGestationalWeek']}
            valueType="custom"
          >
            <GestationalWeek disabled={disabled} />
          </VSFormItem>
          <VSFormItem
            label="预产期"
            name={['pregnancyInfoBto', 'edc']}
            valueType="date"
            fieldProps={{
              disabledDate: disableEdcDate,
              format: showDateFormat,
              disabled: true,
            }}
          ></VSFormItem>

          <VSFormItem
            label="修正预产期"
            name={['pregnancyInfoBto', 'updateEdc']}
            valueType="date"
            fieldProps={{
              disabledDate: disableEdcDate,
              format: showDateFormat,
            }}
          ></VSFormItem>
          <VSFormItem
            label="孕前体重"
            name={['pregnancyInfoBto', 'weightBeforePregnancy']}
            valueType="digit"
            fieldProps={{
              precision: 2,
              addonAfter: 'kg',
              placeholder: '请输入',
              controls: false,
              min: 30,
              max: 300,
            }}
            rules={[
              {
                required: true,
                message: '请填写孕前体重',
              },
            ]}
          ></VSFormItem>
          <VSFormItem
            label="孕前身高"
            name={['pregnancyInfoBto', 'heightBeforePregnancy']}
            valueType="digit"
            fieldProps={{
              min: 30,
              max: 300,
              precision: 2,
              addonAfter: 'cm',
              placeholder: '请输入',
              controls: false,
            }}
            rules={[
              {
                required: true,
                message: '请填写孕前身高',
              },
            ]}
          ></VSFormItem>
          <VSFormItem
            label="孕次"
            name={['pregnancyInfoBto', 'gravidity']}
            valueType="digit"
            fieldProps={{
              precision: 0,
              addonAfter: '次',
              placeholder: '请输入',
              controls: false,
              min: 1,
              max: 10,
            }}
          ></VSFormItem>
          <VSFormItem
            label="产次"
            name={['pregnancyInfoBto', 'parity']}
            valueType="digit"
            fieldProps={{
              precision: 0,
              placeholder: '请输入',
              controls: false,
              min: 1,
              max: 10,
            }}
          ></VSFormItem>

          <VSFormItem
            label="受孕方式"
            name={['pregnancyInfoBto', 'fereilizingMethod']}
            valueType="select"
            dataSource={enums['fereilizing_method']}
            // rules={[
            //   {
            //     required: true,
            //     message: '请选择受孕方式',
            //   },
            // ]}
            fieldProps={{
              placeholder: '请选择',
            }}
          ></VSFormItem>
          <VSFormItem
            label="胎数"
            name={['pregnancyInfoBto', 'fetusNum']}
            valueType="digit"
            fieldProps={{
              min: 1,
              precision: 0,
              placeholder: '请输入',
              controls: false,
            }}
            // rules={[
            //   {
            //     required: true,
            //     message: '请填写胎数',
            //   },
            // ]}
          ></VSFormItem>

          <VSFormItem
            label="计划分娩医院"
            name={['pregnancyInfoBto', 'deliveryLocal']}
            valueType="select"
            dataSource={enums['delivery_hospital']}
            // rules={[
            //   {
            //     required: true,
            //     message: '请选择计划分娩医院',
            //   },
            // ]}
            fieldProps={{
              placeholder: '请选择',
            }}
          ></VSFormItem>
        </VsfFormLayout>
        <VsfFormLayoutDefine columnSpan={[1, 2]}>
          <VSFormItem
            label="避孕史"
            name={['pregnancyInfoBto', 'contraceptionHistory']}
            valueType="radio"
            dataSource={radioDataSource}
            // rules={[
            //   {
            //     required: true,
            //     message: '请填写避孕史',
            //   },
            // ]}
          ></VSFormItem>
          <VSFormItem
            label="避孕史详述"
            name={['pregnancyInfoBto', 'contraceptionHistoryDetail']}
            valueType="text"
            fieldProps={{
              placeholder: '请输入',
            }}
          ></VSFormItem>
        </VsfFormLayoutDefine>
        <VsfFormLayoutDefine columnSpan={[1, 2]}>
          <VSFormItem
            label="本次妊娠早孕期情况"
            name={['pregnancyInfoBto', 'earlyPregnancyInfo']}
            valueType="radio"
            dataSource={radioDataSource}
            // rules={[
            //   {
            //     required: true,
            //     message: '请填写本次妊娠早孕期情况',
            //   },
            // ]}
          ></VSFormItem>
          <VSFormItem
            label="情况详述"
            name={['pregnancyInfoBto', 'earlyPregnancyInfoDetail']}
            valueType="text"
            fieldProps={{
              placeholder: '请输入',
            }}
          ></VSFormItem>
        </VsfFormLayoutDefine>
        <VsfFormLayout columnCount={1}>
          <VSFormItem
            label="足早流存"
            name={['pregnancyInfoBto', 'mpas']}
            valueType="custom"
          >
            <RetainEarlyEnough
              value={{
                mature:
                  renderPregnancyInfo(pregnancyInfo, pregnancyInfoBto)
                    ?.mature || 0,
                premature:
                  renderPregnancyInfo(pregnancyInfo, pregnancyInfoBto)
                    ?.premature || 0,
                abortion:
                  renderPregnancyInfo(pregnancyInfo, pregnancyInfoBto)
                    ?.abortion || 0,
                survival:
                  renderPregnancyInfo(pregnancyInfo, pregnancyInfoBto)
                    ?.survival || 0,
              }}
            />
          </VSFormItem>
        </VsfFormLayout>
      </Box>
      <Box title="月经史">
        <VsfFormLayout columnCount={3} {...formatProps}>
          <VSFormItem
            label="月经初潮"
            name={['recordMensesInfoBto', 'menarche']}
            valueType="digit"
            fieldProps={{
              min: 1,
              precision: 0,
              addonAfter: '岁',
              placeholder: '请输入',
              controls: false,
            }}
            // rules={[
            //   {
            //     required: true,
            //     message: '请填写月经初潮',
            //   },
            // ]}
          ></VSFormItem>
          <VSFormItem
            label="月经周期"
            name={['recordMensesInfoBto', 'menstrualCycle']}
            valueType="custom"
            // rules={[
            //   {
            //     required: true,
            //     message: '请填写月经周期',
            //   },
            // ]}
          >
            <Dayrange dataKey={['menstrualCycleMin', 'menstrualCycleMax']} />
          </VSFormItem>
          <VSFormItem
            label="月经经期"
            name={['recordMensesInfoBto', 'menstrualPeriod']}
            valueType="custom"
            // rules={[
            //   {
            //     required: true,
            //     message: '请填写月经经期',
            //   },
            // ]}
          >
            <Dayrange dataKey={['menstrualPeriodMin', 'menstrualPeriodMax']} />
          </VSFormItem>
          <VSFormItem
            label="月经经量"
            name={['recordMensesInfoBto', 'menstrualVolume']}
            valueType="select"
            dataSource={enums['menstrual_volume']}
            // rules={[
            //   {
            //     required: true,
            //     message: '请选择月经经量',
            //   },
            // ]}
            fieldProps={{
              placeholder: '请选择',
            }}
          ></VSFormItem>
          <VSFormItem
            label="痛经"
            name={['recordMensesInfoBto', 'dysmenorrhea']}
            valueType="select"
            dataSource={enums['dysmenorrhea']}
            // rules={[
            //   {
            //     required: true,
            //     message: '请选择痛经情况',
            //   },
            // ]}
            fieldProps={{
              placeholder: '请选择',
            }}
          ></VSFormItem>

          <VSFormItem
            label="白带"
            name={['recordMensesInfoBto', 'leucorrhea']}
            valueType="select"
            dataSource={enums['leucorrhea']}
            // rules={[
            //   {
            //     required: true,
            //     message: '请选择白带情况',
            //   },
            // ]}
            fieldProps={{
              placeholder: '请选择',
            }}
          ></VSFormItem>
        </VsfFormLayout>
      </Box>
    </React.Fragment>
  );
};

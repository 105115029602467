import React, { useEffect, useState } from 'react';
import { RadioGroup, Input, InputNumber } from '@vs/vsf-kit';
import { getGestationalWeek } from '@/utils';
import moment from 'moment';
import './index.less';

export interface RadioStrType {
  value: string | number | null;
  dependencyValue?: string;
  onChange?: (value: number | null) => void;
  onBlur?: (value: number | null) => void;
  disabled?: boolean;
  layout?: 'normal' | 'simple';
}

const Index = (props: RadioStrType) => {
  const {
    value,
    onChange,
    onBlur,
    disabled = false,
    layout = 'normal',
  } = props
  const [weekValue, setWeekValue] = useState<number | undefined>();
  const [dayValue, setDayValue] = useState<number | undefined>();

  useEffect(() => {
    if (value) {
      console.log('value', value);
      let formatValue: any = {};
      formatValue = Math.floor(Number(value));
      setWeekValue(Math.floor(formatValue / 7));
      setDayValue(formatValue % 7);
    } else {
      setWeekValue(undefined);
      setDayValue(undefined);
    }
  }, [value]);

  const handleWeekChange = v => {
    // onChange?.(v * 7 + (dayValue ?? 0));
    setWeekValue(v);
  };
  const handleDayChange = v => {
    // onChange?.((weekValue ?? 0) * 7 + v);
    setDayValue(v);
  };

  const handleSingleSave = () => {
    const target = (weekValue ?? 0) * 7 + (dayValue ?? 0);
    const res = target ? target : null;
    onChange?.(res);
    onBlur && onBlur(res);
  };

  return (
    <div className="e-medical-gestational-week">
      <InputNumber
        value={weekValue}
        max={50}
        min={0}
        onChange={handleWeekChange}
        addonAfter={layout === 'normal' ? '周' : ''}
        disabled={disabled}
        onBlur={handleSingleSave}
        placeholder="请输入"
        controls={false}
      />
      <InputNumber
        value={dayValue}
        max={6}
        min={0}
        onChange={handleDayChange}
        addonAfter={layout === 'normal' ? '天' : ''}
        disabled={disabled}
        onBlur={handleSingleSave}
        placeholder="请输入"
        controls={false}
      />
    </div>
  );
};

export default Index;

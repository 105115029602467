import React, { useEffect, useState } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import Medical from '@/pages/medical/index';
import { Spin } from '@vs/vsf-kit';

const Index = () => {
  const { common, medical } = vsf?.stores;
  const { showMedical, history, hisRecordId, initBasic } = common;
  const [data, setData] = useState();

  const initMedicalData = async id => {
    const res = await medical.getMedicalDetail(id);
    setData(res);
    vsf?.setStoreValue('common.showMedical', true);
  };

  useEffect(() => {
    initBasic();
  }, []);

  useEffect(() => {
    vsf?.setStoreValue('common.history', '0');
    if (hisRecordId) {
      initMedicalData(hisRecordId);
    }
  }, [history, hisRecordId]);

  if (!showMedical) {
    return (
      <div
        className="e-medical-spin"
        style={{
          height: '100vh',
        }}
      >
        <Spin tip="数据加载中" />
      </div>
    );
  }

  return <Medical data={data} />;
};

export default definePage(Index);

import React, { useState } from 'react';
import { FormBlockType } from '.';
import { VSFormItem, Checkbox } from '@vs/vsf-kit';
import VsfFormLayout, { FormBlock2, FormBlock } from '@/components/form-layout';
import Radiostr from '@/components/radiostr';

export const recordPersonalHistoryFormItem = (data) => {
  const { basic, formType, labelCol, extra } = data;
  const { enums, addressData } = basic || {};
  let formatProps = {};
  let Box = <React.Fragment></React.Fragment>;
  if (formType) {
    Box = formType === FormBlockType.SIGN ? FormBlock : FormBlock2;
  }
  if (labelCol) {
    formatProps = {
      labelCol,
    };
  }

  return (
    <React.Fragment>
      <Box title="个人史" extra={extra?.personal || null}>
        <VsfFormLayout {...formatProps}>
          <VSFormItem
            label="RH-阴性血"
            name={['recordPersonalHistoryBto', 'rhNegative']}
            valueType="custom"
            // rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr dataKey={['rhNegative', 'rhNegativeDetail']} />
          </VSFormItem>
          <VSFormItem
            label="外地久居住史"
            name={['recordPersonalHistoryBto', 'nllrHistory']}
            valueType="custom"
            // rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr dataKey={['nllrHistory', 'nllrHistoryDetail']} />
          </VSFormItem>

          <VSFormItem
            label="疫水疫源接触史"
            name={['recordPersonalHistoryBto', 'epidemicFocus']}
            valueType="custom"
            // rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr dataKey={['epidemicFocus', 'epidemicFocusDetail']} />
          </VSFormItem>
          <VSFormItem
            label="吸烟史"
            name={['recordPersonalHistoryBto', 'smokingHistory']}
            valueType="custom"
            // rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr dataKey={['smokingHistory', 'smokingHistoryDetail']} />
          </VSFormItem>

          <VSFormItem
            label="喝酒史"
            name={['recordPersonalHistoryBto', 'drinkingHistory']}
            valueType="custom"
            // rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr dataKey={['drinkingHistory', 'drinkingHistoryDetail']} />
          </VSFormItem>
          <VSFormItem
            label="经常情绪低落史"
            name={['recordPersonalHistoryBto', 'oftenDepressed']}
            valueType="custom"
            // rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr dataKey={['oftenDepressed', 'oftenDepressedDetail']} />
          </VSFormItem>

          <VSFormItem
            label="经常做事没兴趣"
            name={['recordPersonalHistoryBto', 'oftenUninterested']}
            valueType="custom"
            // rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr
              dataKey={['oftenUninterested', 'oftenUninterestedDetail']}
            />
          </VSFormItem>
          <VSFormItem
            label="预防接种史"
            name={['recordPersonalHistoryBto', 'vaccinationHistory']}
            valueType="custom"
            // rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr
              dataKey={['vaccinationHistory', 'vaccinationHistoryDetail']}
            />
          </VSFormItem>
          <VSFormItem
            label="有毒有害物质"
            name={[
              'recordPersonalHistoryBto',
              'harmfulSubstanceContactHistory',
            ]}
            valueType="custom"
            // rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr
              dataKey={[
                'harmfulSubstanceContactHistory',
                'harmfulSubstanceContactHistoryDetail',
              ]}
            />
          </VSFormItem>
          <VSFormItem
            label="接触放射线"
            name={['recordPersonalHistoryBto', 'radiationContactHistory']}
            valueType="custom"
            // rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr
              dataKey={[
                'radiationContactHistory',
                'radiationContactHistoryDetail',
              ]}
            />
          </VSFormItem>
          <VSFormItem
            label="其他"
            name={['recordPersonalHistoryBto', 'otherHistoryDetail']}
            valueType="text"
            fieldProps={{
              placeholder:'请输入'
            }}
          >
          </VSFormItem>
        </VsfFormLayout>
      </Box>

      <Box title="既往史" extra={extra?.past || null}>
        <VsfFormLayout {...formatProps}>
          <VSFormItem
            label="有无心脑血管疾病史"
            name={['recordPersonalHistoryBto', 'ccvdHistory']}
            valueType="custom"
            // rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr dataKey={['ccvdHistory', 'ccvdHistoryDetail']} />
          </VSFormItem>
          <VSFormItem
            label="有无内分泌疾病史"
            name={['recordPersonalHistoryBto', 'endocrineDiseaseHistory']}
            valueType="custom"
            // rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr
              dataKey={[
                'endocrineDiseaseHistory',
                'endocrineDiseaseHistoryDetail',
              ]}
            />
          </VSFormItem>

          <VSFormItem
            label="有无肝脏病史"
            name={['recordPersonalHistoryBto', 'hepatopathyHistory']}
            valueType="custom"
            // rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr
              dataKey={['hepatopathyHistory', 'hepatopathyHistoryDetail']}
            />
          </VSFormItem>
          <VSFormItem
            label="有无肺部病史"
            name={['recordPersonalHistoryBto', 'lungDiseaseHistory']}
            valueType="custom"
            //  rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr
              dataKey={['lungDiseaseHistory', 'lungDiseaseHistoryDetail']}
            />
          </VSFormItem>

          <VSFormItem
            label="有无肾脏病史"
            name={['recordPersonalHistoryBto', 'nephropathy']}
            valueType="custom"
            //  rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr dataKey={['nephropathy', 'nephropathyDetail']} />
          </VSFormItem>
          <VSFormItem
            label="有无其他重大疾病史"
            name={['recordPersonalHistoryBto', 'majorDiseaseHistory']}
            valueType="custom"
            //  rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr
              dataKey={['majorDiseaseHistory', 'majorDiseaseHistoryDetail']}
            />
          </VSFormItem>

          <VSFormItem
            label="有无传染性疾病史"
            name={['recordPersonalHistoryBto', 'infectiousDiseaseHistory']}
            valueType="custom"
            //  rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr
              dataKey={[
                'infectiousDiseaseHistory',
                'infectiousDiseaseHistoryDetail',
              ]}
            />
          </VSFormItem>
          <VSFormItem
            label="有无手术史"
            name={['recordPersonalHistoryBto', 'operationHistory']}
            valueType="custom"
            //  rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr
              dataKey={['operationHistory', 'operationHistoryDetail']}
            />
          </VSFormItem>

          <VSFormItem
            label="有无输血史"
            name={['recordPersonalHistoryBto', 'transfusionHistory']}
            valueType="custom"
            //  rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr
              dataKey={['transfusionHistory', 'transfusionHistoryDetail']}
            />
          </VSFormItem>
          <VSFormItem
            label="有无妇科手术史"
            name={['recordPersonalHistoryBto', 'gynaecologyOperationHistory']}
            valueType="custom"
            //  rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr
              dataKey={[
                'gynaecologyOperationHistory',
                'gynaecologyOperationHistoryDetail',
              ]}
            />
          </VSFormItem>
          <VSFormItem
            label="有无食物过敏史"
            name={['recordPersonalHistoryBto', 'foodAllergyHistory']}
            valueType="custom"
            //  rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr
              dataKey={['foodAllergyHistory', 'foodAllergyHistoryDetail']}
            />
          </VSFormItem>
          <VSFormItem
            label="有无药物过敏史"
            name={['recordPersonalHistoryBto', 'drugAllergyHistory']}
            valueType="custom"
            //  rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr
              dataKey={['drugAllergyHistory', 'drugAllergyHistoryDetail']}
            />
          </VSFormItem>

          <VSFormItem
            label="有无早孕期服药史"
            name={[
              'recordPersonalHistoryBto',
              'drugHistoryDuringEarlyPregnancy',
            ]}
            valueType="custom"
            //  rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr
              dataKey={[
                'drugHistoryDuringEarlyPregnancy',
                'drugHistoryDuringEarlyPregnancyDetail',
              ]}
            />
          </VSFormItem>
          <VSFormItem
            label="有无外伤史"
            name={['recordPersonalHistoryBto', 'traumaticHistory']}
            valueType="custom"
            //  rules={[
            //   {
            //     required: true,
            //     message: '该项必填',
            //   },
            // ]}
          >
            <Radiostr
              dataKey={['traumaticHistory', 'traumaticHistoryDetail']}
            />
          </VSFormItem>
          <VSFormItem
            label="其他"
            name={['recordPersonalHistoryBto', 'otherPastHistoryDetail']}
            valueType="text"
            fieldProps={{
              placeholder:'请输入'
            }}
          >
          </VSFormItem>
        </VsfFormLayout>
      </Box>
    </React.Fragment>
  );
};

import React, { useState } from 'react';
import moment from 'moment';
import {
  IdNumRegExp,
  phoneRegExp,
  disabledFutureDate,
  showDateFormat,
} from '@/utils/rule';
import { FormBlockType } from '.';
import { Cascader } from 'antd';
import { radioDataSource } from '@/config';
import { VSFormItem, VSFormDependency } from '@vs/vsf-kit';
import VsfFormLayout, {
  FormBlock2,
  FormBlock,
  VsfFormLayoutDefine,
} from '@/components/form-layout';

export const spouseInfoFormItem = (data) => {
  const { basic, formType, labelCol, extra, disabled , spouseInfoBto} = data;
  const { enums, addressData } = basic || {};
  let formatProps = {};
  let Box = <React.Fragment></React.Fragment>;
  if (formType) {
    Box = formType === FormBlockType.SIGN ? FormBlock : FormBlock2;
  }
  if (labelCol) {
    formatProps = {
      labelCol,
    };
  }
  return (
    <React.Fragment>
      <Box title="配偶信息">
        <VsfFormLayout columnCount={1} {...formatProps}>
          <VsfFormLayout columnCount={3}>
            <VSFormItem
              label="丈夫姓名"
              name={['spouseInfoBto', 'name']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
            <VSFormItem
              label="丈夫国籍"
              name={['spouseInfoBto', 'country']}
              valueType="select"
              dataSource={enums['country']}
              fieldProps={{
                showSearch: true,
                placeholder: '请选择',
              }}
            ></VSFormItem>
            <VSFormItem
              label="丈夫民族"
              name={['spouseInfoBto', 'nation']}
              valueType="select"
              dataSource={enums['nation']}
              fieldProps={{
                showSearch: true,
                placeholder: '请选择',
              }}
            ></VSFormItem>
            <VSFormItem
              label="证件类型"
              name={['spouseInfoBto', 'idType']}
              valueType="select"
              dataSource={enums['id_type']}
              fieldProps={{
                placeholder: '请选择',
              }}
            ></VSFormItem>
            <VSFormItem
              label="证件号码"
              name={['spouseInfoBto', 'idNum']}
              valueType="text"
              // rules={[
              //   // {
              //   //   pattern: IdNumRegExp,
              //   //   message: '证号号码格式错误',
              //   // },
              //   {
              //     required: true,
              //     message: '请填写证号号码',
              //   },
              // ]}
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
            <VSFormItem
              label="丈夫出生年月"
              name={['spouseInfoBto', 'birthday']}
              valueType="date"
              fieldProps={{
                disabledDate: disabledFutureDate,
                format: showDateFormat,
                placeholder: '请选择',
              }}
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写出生年月',
              //   },
              // ]}
            ></VSFormItem>
            <VSFormItem
              label="职业"
              name={['spouseInfoBto', 'occupation']}
              valueType="select"
              dataSource={enums['occupation']}
              fieldProps={{
                placeholder: '请选择',
              }}
            ></VSFormItem>
            <VSFormItem
              label="联系电话"
              name={['spouseInfoBto', 'cellphone']}
              valueType="text"
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写联系电话',
              //   },
              //   {
              //     pattern: phoneRegExp,
              //     message: '电话格式错误',
              //   },
              // ]}
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
            <VSFormItem
              label="丈夫年龄(岁)"
              name={['spouseInfoBto', 'age']}
              valueType="digit"
              fieldProps={{
                min: 1,
                precision: 0,
                addonAfter: '岁',
                placeholder: '请输入',
                controls: false,
              }}
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写年龄',
              //   },
              // ]}
            ></VSFormItem>

            <VSFormItem
              label="工作单位"
              name={['spouseInfoBto', 'workplace']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>

            <VSFormItem
              label="健康状况"
              name={['spouseInfoBto', 'healthyStatus']}
              valueType="select"
              dataSource={enums['healthy_status']}
              fieldProps={{
                placeholder: '请选择',
              }}
            ></VSFormItem>
          </VsfFormLayout>

          <VsfFormLayout columnCount={2}>
            <VSFormItem
              label="户籍地址"
              name={['spouseInfoBto', 'registered']}
              valueType="custom"
              fieldProps={{
                placeholder: '请选择',
              }}
            >
              <Cascader placeholder="请选择" options={addressData} />
            </VSFormItem>
            <VSFormItem
              label=""
              name={['spouseInfoBto', 'registeredDetailAddress']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
          </VsfFormLayout>
          <VsfFormLayoutDefine columnSpan={[1, 2]}>
            <VSFormItem
              label="遗传病史"
              name={['spouseInfoBto', 'geneticHistory']}
              valueType="radio"
              dataSource={radioDataSource}
            ></VSFormItem>
            <VSFormItem
              label="遗传病史详述"
              name={['spouseInfoBto', 'geneticHistoryDetail']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
          </VsfFormLayoutDefine>
          <VsfFormLayoutDefine columnSpan={[1, 2]}>
            <VSFormItem
              label="精神病史"
              name={['spouseInfoBto', 'mentalDiseaseHistory']}
              valueType="radio"
              dataSource={radioDataSource}
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写精神病史',
              //   },
              // ]}
            ></VSFormItem>
            <VSFormItem
              label="精神病史详述"
              name={['spouseInfoBto', 'mentalDiseaseHistoryDetail']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
          </VsfFormLayoutDefine>
          <VsfFormLayout columnCount={3}>
            <VSFormItem
              label="智障"
              name={['spouseInfoBto', 'mentalRetardation']}
              valueType="radio"
              dataSource={radioDataSource}
              fieldProps={{
                onChange:(e)=>{
                  console.log(e,'e')
                }
              }}
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写智障信息',
              //   },
              // ]}
            ></VSFormItem>
            <VSFormItem
              label="智障程度"
              name={['spouseInfoBto', 'mentalRetardationLevel']}
              valueType="radio"
              fieldProps={{
                disabled:  disabled?.mentalRetardationLevel || spouseInfoBto?.mentalRetardation === '无',
              }}
              dataSource={[
                {
                  label: '轻度',
                  value: '轻度',
                },
                {
                  label: '中度',
                  value: '中度',
                },
                {
                  label: '重度',
                  value: '重度',
                },
              ]}
            ></VSFormItem>
            <VSFormItem
              label="智障详述"
              name={['spouseInfoBto', 'mentalRetardationDetail']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
          </VsfFormLayout>
          <VsfFormLayoutDefine columnSpan={[1, 2]}>
            <VSFormItem
              label="畸形"
              name={['spouseInfoBto', 'malformation']}
              valueType="radio"
              dataSource={radioDataSource}
              // rules={[
              //   {
              //     required: true,
              //     message: '请填写畸形信息',
              //   },
              // ]}
            ></VSFormItem>
            <VSFormItem
              label="畸形详述"
              name={['spouseInfoBto', 'malformationDetail']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
          </VsfFormLayoutDefine>
          <VsfFormLayoutDefine columnSpan={[1, 2]}>
            <VSFormItem
              label="其他"
              name={['spouseInfoBto', 'other']}
              valueType="radio"
              dataSource={radioDataSource}
            ></VSFormItem>
            <VSFormItem
              label="其他详述"
              name={['spouseInfoBto', 'otherDetail']}
              valueType="text"
              fieldProps={{
                placeholder: '请输入',
              }}
            ></VSFormItem>
          </VsfFormLayoutDefine>
        </VsfFormLayout>
      </Box>
    </React.Fragment>
  );
};

import React from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { InfoCircleOutlined, CloseOutlined } from '@vs/vsf-icons';
import { cloneDeep } from 'lodash';
import {
  MedicalFormErrorFieldArray,
  MedicalFormErrorFieldArrayType,
} from './config';
import { Anchor } from '@vs/vsf-kit';

import './index.less';

const { Link } = Anchor;

export interface FormErrorType {
  title?: string | React.ReactNode;
  content?: string | React.ReactNode;
  onClose?: () => {};
}

export interface FormErrorDetailType { 
  name: string[];
  children: string[];
}

const Index = (props: FormErrorType) => {
  const { title = '提示', content, onClose } = props;
  const handleClose = () => {
    // onClose?.();
      vsf.setStoreValue?.('medical.formError', null);
      vsf.setStoreValue?.('nurse.formError', null);
  };

  const handleLinkClick = (v) => {
    vsf?.stores?.medical?.changeCollapse(v.title, 'active');
    setTimeout(() => {
      window.location.href = `#${v.anchorId}`;
    }, 200);
  }
  
  const renderError = (list) => { 
    console.log(list);
    const arr: FormErrorDetailType[] = [];
    list.forEach(item => {
      const other = cloneDeep(item?.name);
      const flag = other?.[0];
      const children = other.splice(1, other.length - 1);
      const dataIndex = MedicalFormErrorFieldArray.find(item => item?.name?.includes(flag) || (item?.extra || [])?.includes(children?.[0]))?.name;
      const target = arr.find(v => v.name === dataIndex);
      if (!target && dataIndex) {
        arr.push({
          name: dataIndex,
          children: children,
        });
      } else {
        const newChildren = cloneDeep(target?.children || []);
        newChildren.push(children?.[0]);
        if (target) { 
          target.children = newChildren;
        } 
      }
    });
    return (
      <div>
        {arr.map(item => {
          const target: MedicalFormErrorFieldArrayType =
            MedicalFormErrorFieldArray.find(v => v.name === item.name) ||
            ({} as MedicalFormErrorFieldArrayType);
          return (
            <div className="e-medical-form-error-block">
              <span
                style={{
                  marginRight: 12,
                  whiteSpace: 'nowrap',
                }}
              >
                {target.title}中
              </span>
              <span>
                (
                {item?.children?.map((t, index) => (
                  <span>
                    {index !== 0 ? '、' : ''}
                    {target?.children[t]}
                  </span>
                ))}
                )
              </span>
              <span
                style={{
                  marginLeft: 12,
                  whiteSpace: 'nowrap',
                }}
              >
                未填写
              </span>
              <span
                className="ant-anchor-error-link-title"
                onClick={() => {
                  handleLinkClick(target);
                }}
              >
                去填写
                {/* <Link href={`#${target.anchorId}`} title="去填写" /> */}
              </span>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="form-error-tips">
      <div className="header">
        <div className="left">
          <InfoCircleOutlined />
          <span>{title}</span>
        </div>
        <div className="right" onClick={handleClose}>
          <CloseOutlined />
        </div>
      </div>
      <div className="error-content">{renderError(content)}</div>
    </div>
  );
};

export default definePage(Index);

import React, { useEffect, useState } from 'react';
import { RadioGroup, Input, InputNumber } from '@vs/vsf-kit';
import '../index.less';

export interface RadioStrType {
  value: string;
  dataKey: string[];
  dataSource: any[];
  onChange: (value: any) => void;
}

const Index = (props: RadioStrType) => {
  const { value, dataKey ,onChange, dataSource } = props;
  const [init, setInit] = useState(false);
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');

  useEffect(() => {
    if (!init && value) {
      setValue1(value[dataKey?.[0]] || '');
      setValue2(value[dataKey?.[1]] || '');
    }
  }, [value]);

  useEffect(() => {
    onChange({
      [dataKey?.[0]]: value1,
      [dataKey?.[1]]: value2,
    });
  }, [value1, value2]);

  const handleValue1Change = v => {
    setInit(true);
    setValue1(v);
  };
  const handleValue2Change = v => {
    setInit(true);
    setValue2(v);
  };

  return (
    <div className="e-record-screening">
      <RadioGroup
        value={value1}
        onChange={handleValue1Change}
        dataSource={dataSource}
      ></RadioGroup>
      <Input value={value2} onChange={handleValue2Change} placeholder="" />
    </div>
  );
};

export default Index;

import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import vsf, { definePage } from '@vs/vsf-boot';
import { Checkbox, Icon, Input, TextArea, Select } from '@vs/vsf-kit';
import Modal from './modal';

import '../index.less';
import { useBoolean } from '@/utils';

export type recommendArrayType = recommendType[];

export interface recommendType {
  label?: string;
  value?: string;
  unit?: string;
  data?: any;
  confirm?: boolean;
  id?: string;
}

export interface DiagnosisPropsType {
  className?: string;
  // 标题
  title?: string;
  // 智能推荐
  dataSource?: recommendType[];
  // 诊断回调
  onChange?: (v) => void;
  // 诊断值
  value: any;
  // 项数
  max?: number;
}

/**
 * 诊断组件
 **/

const Index = (props: DiagnosisPropsType) => {
  const classPrefix = 'e-medical-data-entering';
  const { className, title, dataSource, onChange, value, max } = props;
  const { diagnosisModalData, diagnosisSelectData, hisId } = vsf?.stores?.common;

  //常用诊断及历史诊断弹框控制
  const [visible, { on, off }] = useBoolean();

  const handleClick = (e) => {
    const { target } = e;
    if (target?.nodeName === 'DIV') {
      on();
    }
    if (target?.nodeName === 'path' || target?.nodeName === 'svg') {
      setValue([]);
    }
  }

  const [tagList, setTagList] = useState<string[]>([]);

  const setValue = (v) => {
    if (!max || v.length <= max) {
      setTagList(v);
      onChange && onChange(v);
    }
  };

  const handleModalOk = (v) => { 
    setValue(v);
    off();
  }

  const handleClickItem = (item) => { 
    if (tagList.includes(item.label)) {
      handleDeselect({
        value: item.label
      });
    } else { 
      handleSelect(item);
    }
  }

  const handleSelect = (item) => {
    if (tagList.includes(item?.label)) return;
    const arr = cloneDeep(tagList);
    if (!max || arr.length < max) {
      arr.push(item?.label);
    } else {
      arr.shift();
      arr.push(item?.label);
    }
    setValue(arr);
  }

  const handleDeselect = data => {
    const arr = cloneDeep(tagList);
    const index = arr.findIndex(item => item === data.value);
    arr.splice(index, 1);
    setValue(arr);
  };

  useEffect(() => {
    if (Array.isArray(value)) {
      setTagList(value);
    }
  }, [value]);


  const formatModalData = diagnosisModalData;
  const formatRecommend = dataSource;

  return (
    <div className={`e-medical-block ${classPrefix} ${className}`}>
      <div className={`${classPrefix}-header`}>
        {title && <div className="title">{title}</div>}
        <Select
          mode="multiple"
          // disabled
          open={false}
          style={{ width: '100%' }}
          onClick={handleClick}
          placeholder="请选择诊断"
          value={tagList}
          onDeselect={handleDeselect}
          allowClear
        />
      </div>
      <div className={`${classPrefix}-recommend`}>
        {formatRecommend &&
          formatRecommend?.map(item => (
            <div
              key={item?.value}
              className={`item ${
                item?.label && tagList.includes(item?.label)
                  ? 'e-medical-data-entering-active-item'
                  : ''
              }`}
              onClick={() => {
                handleClickItem(item);
              }}
            >
              {item?.label}
            </div>
          ))}
      </div>
      <Modal
        key={visible}
        hisId={hisId}
        title={title || ''}
        visible={visible}
        onCancel={off}
        onOk={handleModalOk}
        defaultData={tagList}
        max={max}
        dataSource={formatModalData}
        selectDataSource={diagnosisSelectData}
      />
    </div>
  );
};

export default definePage(Index);

import React, { useEffect, useState } from 'react';
import { definePage } from '@vs/vsf-boot';
import {
  Button,
  VSTable,
  VSTableToolbar,
  VSTableColumn,
  VSTablePagination,
  VSTableAction,
  VSEditableTableAddition,
  message,
} from '@vs/vsf-kit';
import screeningService from '@/services/screening';
import ApplyModal from './apply';
import ResultModal from './result';
import './index.less';

const Index = props => {
  const [dataSource, setDataSource] = useState([]);
  const [visibleApply, setVisibleApply] = useState(false);
  const [visibleResult, setVisibleResult] = useState(false);
  const [currentData, setCurrentData] = useState({});

  const onApplyCancel = () => {
    setVisibleApply(false);
  };
  const onApplyOk = () => {
    // getData();
    setVisibleApply(false);
  };
  const onResultCancel = () => {
    setVisibleResult(false);
  };
  const onResultOk = () => {
    setVisibleResult(false);
  };
  const getDataDetail = (data) => {
    screeningService
      .getCityScreeningDetail({
        id: data?.id
      })
      .then(res => {
        if (res.code === 200) {
          setCurrentData(res.data);
          setVisibleApply(true);
        }
      });
  };

  // useEffect(() => {
  //   getData();
  // }, []);

  const pagination = {
    showQuickJumper: true,
    showSizeChanger: true,
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSizeOptions: [10, 20, 30, 50, 100],
  };
  return (
    <div className="e-medical-record-detail-container">
      <div className="detail-content">
        <VSTable
          className="city-screening-table"
          dataType="request"
          dataLoad={{
            request: screeningService.getCityScreening,
            qtoParams: {
              gravidaRecordIdIs: props?.stores?.common.gravidaRecordId,
            },
            extParams: {},
          }}
          scroll={{ x: 1300 }}
        >
          <VSTableToolbar title="申请列表">
            <VSEditableTableAddition
              onAdd={() => {
                return new Promise(resolve => {
                  setCurrentData({});
                  setVisibleApply(true);
                  resolve(true);
                });
              }}
            >
              筛查申请
            </VSEditableTableAddition>
          </VSTableToolbar>
          <VSTableColumn
            title="申请单号"
            valueType="text"
            dataIndex="applyId"
          ></VSTableColumn>
          <VSTableColumn
            title="状态"
            valueType="text"
            dataIndex="status"
          ></VSTableColumn>
          <VSTableColumn
            title="申请日期"
            valueType="date"
            dataIndex="applyTime"
          ></VSTableColumn>
          <VSTableColumn
            title="筛查类型"
            valueType="text"
            dataIndex="screeningType"
          ></VSTableColumn>
          <VSTableColumn
            title="筛查项目"
            valueType="text"
            dataIndex="screeningProgram"
          ></VSTableColumn>
          <VSTableColumn
            title="采样孕周"
            valueType="text"
            dataIndex="applyId222"
          ></VSTableColumn>
          <VSTableColumn
            title="采样日期"
            valueType="date"
            dataIndex="samplingTime"
          ></VSTableColumn>
          <VSTableColumn
            title="采样单位"
            valueType="text"
            dataIndex="samplingUnit"
          ></VSTableColumn>
          {/* <VSTableColumn
            title="筛查日期"
            valueType="date"
            dataIndex={['prenatalScreeningResultForListDto', 'screeningTime']}
          ></VSTableColumn> */}
          <VSTableColumn title="操作" valueType="option" dataIndex="operation">
            <VSTableAction
              onClick={(_, data, __, action) => {
                getDataDetail(data);
              }}
            >
              编辑申请单
            </VSTableAction>
            {/* <VSTableAction
              onClick={(_, data, __, action) => {
                setVisibleResult(true);
              }}
            >
              结果登记
            </VSTableAction> */}
          </VSTableColumn>

          <VSTablePagination {...pagination} />
        </VSTable>
        <ApplyModal
          key={currentData?.id || ''}
          visible={visibleApply}
          onCancel={onApplyCancel}
          onOk={onApplyOk}
          data={currentData}
        />
        <ResultModal
          visible={visibleResult}
          onCancel={onResultCancel}
          onOk={onResultOk}
        />
      </div>
    </div>
  );
};

export default definePage(Index);

import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { message } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Modal,
  VSEditableTable,
  VSEditableTableAddition,
  VSTableColumn,
} from '@vs/vsf-kit';
import { disabledFutureDate } from '@/utils/rule';

import gravidaServices from '@/services/gravida';
import { globalPageType} from '@/config';

import './index.less';

/**
 * 过敏信息组件
 **/

const Index = props => {
  const { isHistory = false, onChange } = props;
  const { gravida, common } = vsf?.stores;
  const { doctorInfo, enums, allergyDictionary } = common;
  const { gravidaAllergyHistory } = gravida;
  const mockId = useRef(1000000);
  const [dataSource, setDataSource] = useState(gravida.gravidaAllergyHistory);

  useEffect(() => {
    setDataSource(gravidaAllergyHistory);
  }, [gravidaAllergyHistory]);
  const editTableProps: any = {
    dataType: 'static',
    value: dataSource,
    onChange: v => {
      onChange && onChange(v);
      setDataSource(v);
    },
    onCanEdit: v => !v.hisId && !isHistory,
    saveAction: {
      onAction: data => {
        return new Promise<boolean>(resolve => {
          setTimeout(() => {
            resolve(true);
          }, 500);
        });
      },
      onError: e => {
        console.log(e.message);
      },
    },
    deleteAction: {
      onAction: data => {
        return new Promise<boolean>(resolve => {
          const newData = [...(dataSource || [])];
          const index = newData.findIndex(item => data.id === item.id);
          newData.splice(index, 1);
          onChange && onChange(newData);
          setDataSource(newData);
          resolve(true);
        });
      },
      onError: e => {
        console.log(e.message);
      },
    },
  };

  const allergyDictionaryFormat = (allergyDictionary || []).map(item => ({
    label: item.allergenName,
    value: item.allergenId,
  }));

  return (
    <VSEditableTable
      {...editTableProps}
      editType="multiple"
      className="e-medical-allergy-table"
    >
      <VSTableColumn
        title="过敏源"
        valueType="select"
        dataIndex="allergen"
        columnKey="allergen"
        key="allergen"
        dataSource={allergyDictionaryFormat}
        fieldProps={{
          showSearch: true,
          placeholder: '请选择',
        }}
      ></VSTableColumn>
      <VSTableColumn
        title="过敏症状"
        valueType="select"
        dataIndex="allergySymptom"
        columnKey="allergySymptom"
        key="allergySymptom"
        dataSource={enums['allergy_symptom']}
        fieldProps={{
          placeholder: '请选择',
        }}
      ></VSTableColumn>
      <VSTableColumn
        title="严重程度"
        valueType="select"
        dataIndex="severity"
        columnKey="severity"
        key="severity"
        dataSource={enums['allergy_severity']}
        fieldProps={{
          placeholder: '请选择',
        }}
      ></VSTableColumn>
      <VSTableColumn
        title="发生日期"
        valueType="date"
        dataIndex="occurrenceTime"
        columnKey="occurrenceTime"
        key="occurrenceTime"
        width={150}
        fieldProps={{
          placeholder: '请选择',
          disabledDate: disabledFutureDate,
          renderPreview: (v: any) =>
            v?.value ? moment(v.value).format('YYYY-MM-DD') : '',
        }}
      ></VSTableColumn>
      <VSTableColumn
        title="来源"
        valueType="text"
        dataIndex="source"
        columnKey="source"
        key="source"
        fieldProps={{
          placeholder: '请输入',
         maxLength:4
        }}
      ></VSTableColumn>
      <VSTableColumn
        title="登记人"
        valueType="text"
        dataIndex="registrant"
        editable={false}
        columnKey="registrant"
        key="registrant"
        fieldProps={{
          placeholder: '请输入',
        }}
      ></VSTableColumn>
      <VSTableColumn
        title="登记科室"
        valueType="text"
        dataIndex="registrantDepartment"
        editable={false}
        columnKey="registrantDepartment"
        key="registrantDepartment"
        fieldProps={{
          placeholder: '请输入',
        }}
      ></VSTableColumn>
      <VSTableColumn
        title="登记日期"
        valueType="date"
        dataIndex="registrantTime"
        editable={false}
        columnKey="registrantTime"
        key="registrantTime"
        fieldProps={{
          placeholder: '请选择',
          renderPreview: (v: any) =>
            v?.value ? moment(v.value).format('YYYY-MM-DD') : '',
        }}
      ></VSTableColumn>
      {!isHistory && globalPageType.NURSE !== common?.globalPageType && (
        <VSEditableTableAddition
          onAdd={() => {
            return new Promise((resolve, reject) => {
              const target = [
                ...dataSource,
                {
                  id: mockId?.current,
                  registrant: doctorInfo?.name,
                  registeredDepartment: doctorInfo?.department,
                  registeredTime: moment(new Date()),
                },
              ];
              setDataSource(target);
              onChange && onChange(target);
              mockId.current += 1;
              resolve(true);
            });
          }}
        >
          新增
        </VSEditableTableAddition>
      )}
    </VSEditableTable>
  );
};

export default definePage(Index);

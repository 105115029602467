import React, { useEffect, useRef, useState } from 'react';
import { CheckboxGroup, Input } from '@vs/vsf-kit';
import { Checkbox } from 'antd';
import './index.less';
import { cloneDeep } from 'lodash';

export interface HighriskCheckType {
  items: any;
  onChange: (v: any) => void;
  disabled?: boolean;
  keyword:any;
}

export interface HighriskItemType {
  label: string;
  checked?: string[];
  items: any[];
  onChange: (v: any) => void;
  disabled?: boolean;
  type?: string;
  keyword:any;
}

const LevelData = [
  {
    level: 0,
    color: '#FFF3BC',
    label: '黄色',
  },
  {
    level: 1,
    color: '#FFD7AF',
    label: '橙色',
  },
  {
    level: 2,
    color: '#FFBAB6',
    label: '红色',
  },
  {
    level: 3,
    color: '#C9BFFF',
    label: '紫色',
  },
];

const Item = (props: HighriskItemType) => {
  const { items, label, checked, onChange, disabled, type , keyword } = props;
  const [currentValues, setCurrentValues] = useState<any>([]);
  const [checkedstatus, setCheckedStatus] = useState(false);
  const [checkedValue, setCheckedValue] = useState();
  const [firstCheched, setFirstCheched] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedChecked, setSelectedChecked] = useState<any>([]);

  const inputRef = useRef<any>('');
  const checkedRef = useRef<any>(null);

  const handleChange = (checkedValues: any) => {
    setCurrentValues([...checkedValues]);

    if (checkedstatus) {
      onChange([
        ...checkedValues,
        checkedValue || inputRef.current,
      ]);
    } else {
      onChange(checkedValues);
    }
  };

  const CheckboxChange = e => {
    const arr = cloneDeep(currentValues);
    setCheckedStatus(e.target.checked);
    if (e.target.checked) {
      arr.push(e.target.value);
      setCurrentValues([...arr]);
      onChange([...arr]);
      setCheckedValue(e.target.value);
    } else {
      arr.pop();
      setCurrentValues([...arr]);
      onChange([...arr]);
    }
  };

  const renderValue = str => {
    console.log(str,'str-----')
    if (str && str?.indexOf('$') !== -1) {
      console.log(str.split('$')[1],'str.split()[1]')
      return str.split('$')[1];
    } else {
      return str;
    }
  };

  useEffect(() => {
    setCurrentValues(checked || []);
    checked?.forEach(item => {
      if (!items?.some(v => v.item === item)) {
        inputRef.current = renderValue(item);
        setCheckedStatus(true);
      }
    });
  }, [checked,inputRef.current]);

  useEffect(() => {
    const arr = cloneDeep(checked);
    setFirstCheched(
      arr
        ?.map(item => {
          if (!items?.some(v => v.item === item)) {
            return true;
          }
        })
        .filter(v => v)?.length === 0,
    );
    setSelectedChecked(
      arr
        ?.map(item => {
          if (!items?.some(v => v.item === item)) {
            return item;
          }
        })
        .filter(v => v),
    );
  }, [checked]);
  const target = LevelData.find(item => item.label === label);
  const formatItems = items.filter(item => item?.item !== '其他');
  console.log(checked,'----------')
  const renderSelect = () => {
    console.log(selectedChecked,'selectedChecked')
    return (selectedChecked || []).map(v => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <Checkbox
            checked={disabled}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
            disabled={disabled}
          >
            <div>其他</div>
          </Checkbox>
          <Input
            style={{ width: '70%' }}
            disabled={disabled}
            value={renderValue(v)}
          />
        </div>
      );
    });
  };
  return (
    <div className="e-medical-highrisk-item">
      <div
        className="title"
        style={{
          backgroundColor: target?.color,
        }}
      >
        {target?.label}
      </div>
      <div className="checkitems">
        {/* <CheckboxItem
          dataSource={items}
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        ></CheckboxItem> */}
        <CheckboxGroup
          value={checked || []}
          onChange={handleChange}
          dataSource={formatItems}
          disabled={disabled}
        ></CheckboxGroup>
        {!disabled  && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            <Checkbox
              checked={checkedstatus}
              value={ inputRef?.current || ''}
              style={{ display: 'flex', justifyContent: 'flex-start' }}
              onChange={CheckboxChange}
              disabled={disabled}
            >
              <div>其他</div>
            </Checkbox>
            <Input
              style={{ width: '70%' }}
              onChange={value => {
                setInputValue(value.target.value);
                inputRef.current = value.target.value;
              }}
              disabled={disabled || !checkedstatus}
              value={inputRef.current}
              onBlur={() => {
                const arr = cloneDeep(currentValues);
                inputRef.current = inputValue;
                if (checkedstatus) {
                  arr.pop();
                  arr.push(inputRef.current);
                  setCurrentValues([...arr]);
                  onChange([...arr]);
                  setCheckedValue(inputRef.current);
                }
              }}
            />
          </div>
        )}
        {disabled && renderSelect()}
      </div>
    </div>
  );
};

const CheckboxItem = props => {
  const { dataSource, checked, disabled } = props;

  const [checkedArr, setCheckedArr] = useState(checked);
  const [inputValue, setInputValue] = useState('');
  const handleOnChange = e => {
    if (e?.target?.value) {
      if (checkedArr.find(item => item === e?.target?.value)) {
        setCheckedArr(checkedArr.filter(v => v !== e?.target?.value));
        props.onChange &&
          props.onChange(checkedArr.filter(v => v !== e?.target?.value));
      } else {
        setCheckedArr([...checkedArr, e?.target?.value]);
        props.onChange && props.onChange([...checkedArr, e?.target?.value]);
      }
    }
  };
  const onBlur = () => {
    const arr = cloneDeep(checkedArr);
    if (inputValue) {
      if (!checkedArr.some(v => v === inputValue)) {
        if (!dataSource.some((v, i) => v === inputValue)) {
          checkedArr?.forEach((item, i) => {
            if (!dataSource.some((v, i) => v.item === item)) {
              arr.splice(i, 1, inputValue);
              setCheckedArr([...arr]);
              props.onChange && props.onChange([...arr]);
            } else {
              setCheckedArr([...checkedArr, inputValue]);
              props.onChange && props.onChange([...checkedArr, inputValue]);
            }
          });
        }
      }
    }
  };

  const renderInputValue = checked => {
    const arr: string[] = [];
    checked.forEach(element => {
      if (!dataSource.some(v => v.item === element)) {
        arr.unshift(element);
      }
    });
    return arr;
  };

  return (
    <div>
      {dataSource?.map(v => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <Checkbox
              value={v.item}
              checked={checkedArr.find(item => item === v.item)}
              onChange={handleOnChange}
              disabled={disabled}
            >
              {v.item}
            </Checkbox>
            {v?.type === 'input' && (
              <Input
                disabled={!checkedArr.find(item => item === v.item) || disabled}
                style={{ height: '50%', width: '70%' }}
                onChange={e => {
                  setInputValue(e.target.value);
                }}
                onBlur={onBlur}
                value={
                  disabled
                    ? renderInputValue(checked).length > 0 &&
                      renderInputValue(checked)[0]
                    : inputValue
                }
              ></Input>
            )}
          </div>
        );
      })}
    </div>
  );
};

const Index = (props: HighriskCheckType) => {
  const { items, onChange, disabled , keyword } = props;
  // let arr=[];
  const [arr, setarr] = useState(cloneDeep(items));

  const handleChange = (checkedValues: any, label: number) => {
    console.log(checkedValues);
    const target = items?.find(item => item.item === label);
    // const checkedArr = cloneDeep(target?.checked || [])?.filter(
    //   item => !(item?.children || [])?.find(citem => citem.value === item),
    // );
    onChange({
      ...(cloneDeep(target) || {}),
      checked: checkedValues,
      // checked: checkedArr.concat(checkedValues),
    });
  };
  // useEffect(()=>{
  //   // arr = cloneDeep(items)
  // },[])
  const renderTypeValue = (str, item, obj) => {
    let type = str;
    if (!type) {
      // obj.map(v1=>{
      //   item?.checkedObj?.map(v3=>{
      //     if(v3?.checked && v3?.checked?.some(v2=>v2 === v1)) {
      //       type=v3?.type
      //       return v3?.type
      //     }
      //   })
      // })
      return type;
    } else {
      return type;
    }
  };

  const renderChecked = item => {
    const { checked: obj, type = '', children: items } = item;
    const label = item.item || item.value;
    const citem = item;
    console.log((
      obj?.map((item: string) => {
        if (items && !items.some(v => v.item === item)) {
          if (item && !item.includes('$')) {
            return `${label}$${item}$${renderTypeValue(type, citem, obj)}`;
          } else {
            return item;
          }
        } else {
          return item;
        }
      })
    ));
    return (
      obj &&
      obj?.map((item: string) => {
        if (items && !items.some(v => v.item === item)) {
          if (item && !item.includes('$')) {
            return `${label}$${item}$${renderTypeValue(type, citem, obj)}`;
          } else {
            return item;
          }
        } else {
          return item;
        }
      })
    );
  };

  return (
    <div className="e-medical-highrisk-check">
      {items
        .sort((a, b) => {
          const aTarget =
            LevelData.find(item => item.label === (a.item || a.label))?.level ||
            0;
          const bTarget =
            LevelData.find(item => item.label === (b.item || b.label))?.level ||
            0;
          return aTarget - bTarget;
        })
        ?.map(item => {
          return (
            <Item
              key={item.item}
              label={item.item || item.label}
              items={item?.children || []}
              checked={item.checked || []}
              // checked={renderChecked(item)}
              onChange={v => handleChange(v, item.item)}
              disabled={disabled}
              type={item?.type || ''}
              keyword = {keyword}
            />
          );
        })}
    </div>
  );
};

export default Index;

import React, { useState, useRef, useEffect, useCallback } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { Modal, VSFormItem, VSForm } from '@vs/vsf-kit';
import gravidaServices from '@/services/gravida';
import CollapseBlock from '@/components/collapse-block';
import GestationalWeek from '@/components/gestational-week';
import BloodPressure from '@/components/blood-pressure';
import RadioInput from './components/radio-input';
import Trisomy from './components/trisomy';
import Dayrange from '@/components/day-range';
import VsfFormLayout, {
  VsfFormLayoutDefine,
  FormBlock,
  FormBlock2,
} from '@/components/form-layout';
import moment from 'moment';
import { useCallBackRef } from '@/utils';
import {
  IdNumRegExp,
  phoneRegExp,
  disabledDate,
  formatDate,
  showDateFormat,
} from '@/utils/rule';
import screeningService from '@/services/screening';
import { labelColWidth } from '@/config';
import './index.less';
// import { PlusOutlined } from '@vs/vsf-icons';

const Index = props => {
  const { visible, onCancel, onOk, data } = props;
  const [dataSource, setDataSource] = useState({});
  const [formData, setFormData] = useState({});

  const [isRef, formRef] = useCallBackRef();

  const validateForm = async () => {
    const target = formRef.current;
    if (target) {
      try {
        const values = await target?.validateFields();
        console.log('校验表单并返回格式化后的所有数据：', values);
        return values;
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleOk = async () => {
    const formValues = await validateForm();
    if (!formValues) return;
    const target = formValues?.bto_param;
    const formatData = {
      btoParam: {
        ...(target || {}),
        status: 'NORMAL',
        gravidaRecordId: vsf?.stores?.common?.gravidaRecordId,
        applyTime: formatDate(target?.applyTime),
        samplingTime: formatDate(target?.samplingTime),
        birthday: formatDate(target?.birthday),
        lmp: formatDate(target?.lmp),
        ultrasoundCheckTime: formatDate(target?.ultrasoundCheckTime),
        ivfImplantationTime: formatDate(target?.ivfImplantationTime),
        ivfOvulationTime: formatDate(target?.ivfOvulationTime),
        enterTime: formatDate(target?.enterTime),
        screeningProgram: (target?.screeningProgram || [])?.join(),
      },
    };
    screeningService.updateCityScreening(formatData).then(res => {
      if (res?.code === 200 && res.data) {
        onOk && onOk();
      }
    });
  };
  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleSetValues = data => {
    formRef?.current?.setFieldsValue({
      bto_param: data,
    });
  };

  useEffect(() => {
    if (isRef) {
      handleSetValues(data);
    }
  }, [data, isRef]);

  const enums = props?.stores?.common?.enums;

  return (
    <Modal
      width={1336}
      className="e-medical-record-city-screening-modal"
      title="产前筛查申请"
      keyboard={true}
      destroyOnClose={true}
      okText="保存"
      cancelText="取消"
      visible={visible}
      id="city-screening-modal"
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
    >
      <VSForm
        dataType="static"
        layout="horizontal"
        initialValues={data}
        labelCol={labelColWidth[5]}
        formRef={formRef}
      >
        <CollapseBlock title="申请信息" description="" error="">
          <VsfFormLayout columnCount={3}>
            <VSFormItem
              label="申请单号"
              name={['bto_param', 'applyId']}
              valueType="text"
            ></VSFormItem>
            {/* <VSFormItem
              label="筛查申请状态"
              name={['bto_param', 'status']}
              valueType="custom"
            ></VSFormItem> */}
            <VSFormItem
              label="申请日期"
              name={['bto_param', 'applyTime']}
              valueType="date"
              fieldProps={{
                format: showDateFormat,
              }}
            ></VSFormItem>
            <VSFormItem
              label="申请医生"
              name={['bto_param', 'applyDoctor']}
              valueType="text"
            ></VSFormItem>
          </VsfFormLayout>
          <VsfFormLayoutDefine columnSpan={[1, 2]}>
            <VSFormItem
              label="门诊号"
              name={['bto_param', 'hisRecordId']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="筛查类型"
              name={['bto_param', 'screeningType']}
              valueType="radio"
              dataSource={enums['screening_type']}
            ></VSFormItem>
          </VsfFormLayoutDefine>
          <VsfFormLayout columnCount={1}>
            <VSFormItem
              label="筛查项目"
              name={['bto_param', 'screeningProgram']}
              valueType="checkbox"
              dataSource={enums['screening_items']}
            ></VSFormItem>
          </VsfFormLayout>
        </CollapseBlock>
        <CollapseBlock title="标本信息" description="" error="">
          <VsfFormLayout columnCount={3}>
            <VSFormItem
              label="标本编号"
              name={['bto_param', 'specimenId']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="采样日期"
              name={['bto_param', 'samplingTime']}
              valueType="date"
              fieldProps={{
                format: showDateFormat,
              }}
            ></VSFormItem>
            <VSFormItem
              label="采样人"
              name={['bto_param', 'samplers']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="采样单位"
              name={['bto_param', 'samplingUnit']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="筛查医院"
              name={['bto_param', 'screeningHospital']}
              valueType="text"
            ></VSFormItem>
          </VsfFormLayout>
        </CollapseBlock>
        <CollapseBlock title="孕妇产检信息" description="" error="">
          <VsfFormLayout columnCount={3}>
            <VSFormItem
              label="姓名"
              name={['bto_param', 'name']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="证件类型"
              name={['bto_param', 'idType']}
              valueType="select"
            ></VSFormItem>
            <VSFormItem
              label="证件号码"
              name={['bto_param', 'idNum']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="出生日期"
              name={['bto_param', 'birthday']}
              valueType="date"
              fieldProps={{
                format: showDateFormat,
              }}
            ></VSFormItem>
            <VSFormItem
              label="年龄"
              name={['bto_param', 'age']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="保健册号"
              name={['bto_param', 'healthBookId']}
              valueType="text"
            ></VSFormItem>
          </VsfFormLayout>
          <VsfFormLayout columnCount={2}>
            <VSFormItem
              label="户籍地址"
              name={['bto_param', 'addressCascader']}
              valueType="cascader"
              dataSource={vsf?.stores?.common?.addressData}
            ></VSFormItem>
            {/* <VSFormItem
              label="省"
              name={['bto_param', 'province']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="市"
              name={['bto_param', 'city']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="区"
              name={['bto_param', 'district']}
              valueType="text"
            ></VSFormItem> */}
            <VSFormItem
              label="详细地址"
              name={['bto_param', 'addressDetail']}
              valueType="text"
            ></VSFormItem>
          </VsfFormLayout>
          <VsfFormLayout columnCount={3}>
            <VSFormItem
              label="联系电话"
              name={['bto_param', 'contactNum']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="身高"
              name={['bto_param', 'height']}
              valueType="digit"
              fieldProps={{
                addonAfter: 'cm',
                controls: false,
              }}
            ></VSFormItem>
            <VSFormItem
              label="孕次"
              name={['bto_param', 'gravidity']}
              valueType="digit"
              fieldProps={{
                precision: 0,
                addonAfter: '次',
                placeholder: '请输入',
                controls: false,
                min: 1,
                max: 10,
              }}
            ></VSFormItem>
            <VSFormItem
              label="产次"
              name={['bto_param', 'parity']}
              valueType="digit"
              fieldProps={{
                precision: 0,
                addonAfter: '次',
                placeholder: '请输入',
                controls: false,
                min: 1,
                max: 10,
              }}
            ></VSFormItem>
            <VSFormItem
              label="末次月经"
              name={['bto_param', 'lmp']}
              valueType="date"
              fieldProps={{
                format: showDateFormat,
              }}
            ></VSFormItem>
            <VSFormItem
              label="月经周期"
              name={['bto_param', 'menstrualCycle']}
              valueType="custom"
            >
              <Dayrange dataKey={['menstrualCycleMin', 'menstrualCycleMax']} />
            </VSFormItem>
            <VSFormItem
              label="月经规律"
              name={['bto_param', 'regular']}
              valueType="select"
            ></VSFormItem>
            <VSFormItem
              label="末次月经推算孕周"
              name={['bto_param', 'gestationalWeekLmp']}
              valueType="digit"
              fieldProps={{
                controls: false,
              }}
            >
              <GestationalWeek />
            </VSFormItem>
            <VSFormItem
              label="是否吸烟"
              name={['bto_param', 'smoking']}
              valueType="radio"
              dataSource={[
                {
                  label: '是',
                  value: '1',
                },
                {
                  label: '否',
                  value: '0',
                },
              ]}
            ></VSFormItem>
            <VSFormItem
              label="体重"
              name={['bto_param', 'weight']}
              valueType="digit"
              fieldProps={{
                addonAfter: 'kg',
                controls: false,
                min: 30,
                max: 300,
              }}
            ></VSFormItem>
            <VSFormItem
              label="糖尿病"
              name={['bto_param', 'diabetes']}
              valueType="radio"
              dataSource={[
                {
                  label: '是',
                  value: '1',
                },
                {
                  label: '否',
                  value: '0',
                },
              ]}
            ></VSFormItem>
            <VSFormItem
              label="胰岛素依耐型"
              name={['bto_param', 'insulinTolerance']}
              valueType="radio"
              dataSource={[
                {
                  label: '是',
                  value: '1',
                },
                {
                  label: '否',
                  value: '0',
                },
              ]}
            ></VSFormItem>
            <VSFormItem
              label="B超检查日期"
              name={['bto_param', 'ultrasoundCheckTime']}
              valueType="date"
              fieldProps={{
                format: showDateFormat,
              }}
            ></VSFormItem>
            <VSFormItem
              label="B超检查孕周"
              name={['bto_param', 'ultrasoundCheckGestationalWeek']}
              valueType="custom"
            >
              <GestationalWeek />
            </VSFormItem>
            <VSFormItem
              label="同/异卵"
              name={['bto_param', 'dizygotic']}
              valueType="select"
            ></VSFormItem>
            <VSFormItem
              label="胎数"
              name={['bto_param', 'fetusNum']}
              valueType="digit"
              fieldProps={{
                controls: false,
              }}
            ></VSFormItem>
            <VSFormItem
              label="头臂长"
              name={['bto_param', 'crl']}
              valueType="digit"
              fieldProps={{
                addonAfter: 'cm',
                controls: false,
              }}
            ></VSFormItem>
            <VSFormItem
              label="双顶径"
              name={['bto_param', 'bpd']}
              valueType="digit"
              fieldProps={{
                addonAfter: 'cm',
                controls: false,
              }}
            ></VSFormItem>
            <VSFormItem
              label="头臂长2"
              name={['bto_param', 'crlTwo']}
              valueType="digit"
              fieldProps={{
                addonAfter: 'cm',
                controls: false,
              }}
            ></VSFormItem>
            <VSFormItem
              label="双顶径2"
              name={['bto_param', 'bpdTwo']}
              valueType="digit"
              fieldProps={{
                addonAfter: 'cm',
                controls: false,
              }}
            ></VSFormItem>
            <VSFormItem
              label="头臂长3"
              name={['bto_param', 'crlThree']}
              valueType="digit"
              fieldProps={{
                addonAfter: 'cm',
                controls: false,
              }}
            ></VSFormItem>
            <VSFormItem
              label="双顶径3"
              name={['bto_param', 'bpdThree']}
              valueType="digit"
              fieldProps={{
                addonAfter: 'cm',
                controls: false,
              }}
            ></VSFormItem>
            <VSFormItem
              label="辅助生殖"
              name={['bto_param', 'assistedReproduction']}
              valueType="select"
            ></VSFormItem>
            <VSFormItem
              label="试管婴儿植入日期"
              name={['bto_param', 'ivfImplantationTime']}
              valueType="date"
              fieldProps={{
                format: showDateFormat,
              }}
            ></VSFormItem>
            <VSFormItem
              label="试管婴儿排卵日期"
              name={['bto_param', 'ivfOvulationTime']}
              valueType="date"
              fieldProps={{
                format: showDateFormat,
              }}
            ></VSFormItem>
          </VsfFormLayout>
          <VsfFormLayout columnCount={1}>
            <VSFormItem
              label="异常妊娠史"
              name={['bto_param', 'abnormalPregnancyHistory']}
              valueType="text"
            ></VSFormItem>
          </VsfFormLayout>
          <VsfFormLayout columnCount={1}>
            <VSFormItem
              label="其他病史"
              name={['bto_param', 'otherMedicalHistory']}
              valueType="text"
            ></VSFormItem>
          </VsfFormLayout>
        </CollapseBlock>
        <CollapseBlock title="既往史" description="" error="">
          <VsfFormLayout columnCount={4}>
            <VSFormItem
              label="异体输血"
              name={['bto_param', 'allogeneicTransfusion']}
              valueType="radio"
              dataSource={[
                {
                  label: '是',
                  value: '1',
                },
                {
                  label: '否',
                  value: '0',
                },
              ]}
            ></VSFormItem>
            <VSFormItem
              label="移植"
              name={['bto_param', 'transplantation']}
              valueType="radio"
              dataSource={[
                {
                  label: '是',
                  value: '1',
                },
                {
                  label: '否',
                  value: '0',
                },
              ]}
            ></VSFormItem>
            <VSFormItem
              label="干细胞治疗"
              name={['bto_param', 'stemCellTherapy']}
              valueType="radio"
              dataSource={[
                {
                  label: '是',
                  value: '1',
                },
                {
                  label: '否',
                  value: '0',
                },
              ]}
            ></VSFormItem>
            <VSFormItem
              label="免疫治疗"
              name={['bto_param', 'immunotherapy']}
              valueType="radio"
              dataSource={[
                {
                  label: '是',
                  value: '1',
                },
                {
                  label: '否',
                  value: '0',
                },
              ]}
            ></VSFormItem>
          </VsfFormLayout>
        </CollapseBlock>
        <CollapseBlock title="既往辅助检查" description="" error="">
          <VsfFormLayout columnCount={1}>
            <VSFormItem
              label="B超"
              name={['bto_param', 'ultrasoundValue']}
              valueType="custom"
            >
              <RadioInput
                dataKey={['ultrasound', 'ultrasoundAbnormal']}
                dataSource={[
                  {
                    label: '单胎',
                    value: '单胎',
                  },
                  {
                    label: '多胎',
                    value: '多胎',
                  },
                  {
                    label: '异常',
                    value: '异常',
                  },
                ]}
              />
            </VSFormItem>
            <VSFormItem
              label="既往筛查类型"
              name={['bto_param', 'previousScreeningType']}
              valueType="radio"
              dataSource={[
                {
                  label: '未做',
                  value: '未做',
                },
                {
                  label: '早孕期筛查',
                  value: '早孕期筛查',
                },
                {
                  label: '中孕期筛查',
                  value: '中孕期筛查',
                },
                {
                  label: '早中孕期联合筛查',
                  value: '早中孕期联合筛查',
                },
              ]}
            ></VSFormItem>
            <VSFormItem
              label="夫妻双方染色体"
              name={['bto_param', 'coupleChromosomesValue']}
              valueType="custom"
            >
              <RadioInput
                dataKey={['coupleChromosomes', 'chromosomesAbnormal']}
                dataSource={[
                  {
                    label: '未做',
                    value: '未做',
                  },
                  {
                    label: '正常',
                    value: '正常',
                  },
                  {
                    label: '异常',
                    value: '异常',
                  },
                ]}
              />
            </VSFormItem>
            <VSFormItem
              label="母亲血清学筛查风险率"
              name={['bto_param', 'trisomy']}
              valueType="custom"
            >
              <Trisomy />
            </VSFormItem>
          </VsfFormLayout>
        </CollapseBlock>
        <CollapseBlock title="填表相关" description="" error="">
          <VsfFormLayout columnCount={3}>
            <VSFormItem
              label="录入日期"
              name={['bto_param', 'enterTime']}
              valueType="date"
              fieldProps={{
                format: showDateFormat,
              }}
            ></VSFormItem>
            {/* <VSFormItem
          label="孕产档案ID"
          name={['bto_param', 'gravidaRecordId']}
          valueType="digit"
        ></VSFormItem> */}
            <VSFormItem
              label="录入人"
              name={['bto_param', 'enterPerson']}
              valueType="text"
            ></VSFormItem>
            <VSFormItem
              label="录入机构"
              name={['bto_param', 'enterOrg']}
              valueType="text"
            ></VSFormItem>
            {/* <VSFormItem
          label="是否市内"
          name={['bto_param', 'local']}
          valueType="switch"
        ></VSFormItem> */}
          </VsfFormLayout>
        </CollapseBlock>
        {/* <VSFormItem
          label="筛查项目"
          name={['bto_param', 'screeningProgram']}
          valueType="text"
        ></VSFormItem> */}
      </VSForm>
    </Modal>
  );
};

export default definePage(Index);

import storage from '@/utils/storage';
import { defineStore } from '@vs/vsf-boot';
import commonServices from '@/module/login/services';
import { docCookies } from '@/utils/cookie';
import common from './common';

const nurse = defineStore({
    user: storage.get('e-medical-nurse-user'),
    // 表单错误
    formError: null,
    login: async (data) => {
        const res = await commonServices.nurseLogin(data);
        if (res.code === 200 && res.data) {
            nurse.user = res?.data;
            common.doctorId = res?.data?.id;
            storage.set('e-medical-nurse-user', res.data);
        }
        return res?.data;
    },
    logout: async () => {
        nurse.user = null;
        storage.remove('e-medical-nurse-user');
        common.doctorId = '';
        common.doctorInfo = null;
        docCookies.removeItem('U_TOKEN');
    }
});

export default nurse;

import { createServices } from "@vs/service";

export default createServices({
  createMedicalRecord: {
    method: "post",
    url: "/api/medical/createRecord",
    parameterFomatter: (data?: {
      gravidaInfo: GravidaInfoServiceBtoCreateGravidaInfoAtWebBto,
      recordInfo: GravidaRecordServiceBtoUpdateGravidaRecordWithMedicalRecordBto,
      assistantInfo: MedicalRecordServiceBtoCreateAssistantInspectionsBto,
      physicalInfo: MedicalRecordServiceBtoCreatePhysicalInspectionsBto,
      medicalInfo: MedicalRecordServiceBtoCreateMedicalRecordAtWebBto,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  getMedicalList: {
    method: "post",
    url: "/api/medical/getMedicalList",
    parameterFomatter: (data?: { gravidaRecordId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordManagerDtoMedicalRecordDetailDto[]
    ) => data,
  },
  getMedicalDetailWithHisRecordId: {
    method: "post",
    url: "/api/medical/getMedicalDetailWithHisRecordId",
    parameterFomatter: (data?: { hisRecordId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordManagerDtoMedicalRecordDetailDto
    ) => data,
  },
  getMedicalDetailWithMedicalRecordId: {
    method: "post",
    url: "/api/medical/getMedicalDetailWithMedicalRecordId",
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordManagerDtoMedicalRecordDetailDto
    ) => data,
  },
  editMedicalRecord: {
    method: "post",
    url: "/api/medical/editMedicalRecord",
    parameterFomatter: (data?: {
      assistantInfo: MedicalRecordServiceBtoCreateAssistantInspectionsBto,
      physicalInfo: MedicalRecordServiceBtoCreatePhysicalInspectionsBto,
      medicalInfo: MedicalRecordServiceBtoCreateMedicalRecordAtWebBto,
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  getMedicalPrint: {
    method: "post",
    url: "/api/medical/getMedicalPrint",
    parameterFomatter: (data?: { hisRecordId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordManagerDtoMedicalRecordForPrintDto
    ) => data,
  },
});

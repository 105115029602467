import { VSValueType } from '@vs/vsf-kit';
import type { NamePath } from 'antd/lib/form/interface';
import gaoweiysdm from './gaoweiysdm.json'
/**
 *
 * @param text
 * @returns 同步状态转译
 */
export function formatStatus(text: string): string {
  const _value = {
    SYNC_SUCCESS: '同步成功',
    SYNC_FAILURE: '同步失败',
    NOT_SYNC: '未同步',
  };
  return _value[text];
}

/**
 *
 * @param text
 * @returns 数据变更类型转译
 */
export function hisSyncType(text: string): string {
  const _value = {
    API_CREATE: '接口创建',
    API_UPDATE: '接口更新',
    PAGE_UPDATE: '页面更新',
  };
  return _value[text];
}

/**
 * Placeholder
 */

export const formatPlaceholder = {
  data: '请选择',
  text: '请输入',
  select: '请选择',
};

/**
 * 初始的分页数据
 */
export const initPagation = { page: 1, pageSize: 10, total: 0 };

/**
 * form表单的字段类表
 */

export interface ReportFromArrayProps {
  label: string;
  keyName: string;
  type: VSValueType;
  require?: boolean;
  dataSource?: { label: any; value: any }[];
  fieldProps?: any;
  transform?: (value: any, namePath: string, allValues: any) => string | Record<string, any>;
  convertValue?: (value: any, field: NamePath) => string | Record<string, any>;
}

export function filterNullUndefined(obj) {
  const entries = Object.entries(obj);

  const filteredEntries = entries.filter(
    ([_, value]) => value !== null && value !== undefined,
  );

  const filteredObject = Object.fromEntries(filteredEntries);

  return filteredObject;
}

export const reportFromArrayFirst: ReportFromArrayProps[] = [
  {
    label: '随访日期',
    keyName: 'suifangrq',
    require: true,
    type: 'date',
  },
  {
    label: '孕周',
    keyName: 'suifangyz',
    require: true,
    type: 'text',
  },
  {
    label: '孕天',
    keyName: 'suifangyt',
    require: true,
    type: 'text',
  },
];

export const reportFromArraySecond: ReportFromArrayProps[] = [
  {
    label: '是否进行本次体检',
    keyName: 'sfjxbctj',
    require: true,
    type: 'select',
    dataSource: [
      { value: '1', label: '是' },
      { value: '2', label: '否' },
    ],
  },
  {
    label: '未进行体检原因',
    keyName: 'wjxtjyy',
    type: 'text',
  },
];

export const reportFromArrayThird: ReportFromArrayProps[] = [
  {
    label: '主诉',
    keyName: 'zhusu',
    type: 'text',
  },
  {
    label: '收缩压',
    keyName: 'shousuoy',
    type: 'text',
  },
  {
    label: '舒张压',
    keyName: 'shuzhangy',
    type: 'text',
  },
  {
    label: '体重',
    keyName: 'tizhong',
    type: 'text',
    require: true,
  },
  {
    label: '身高',
    keyName: 'shengao',
    type: 'text',
  },
  {
    label: '体重指数',
    keyName: 'tizhizhishu',
    type: 'text',
  },
  {
    label: '宫高',
    keyName: 'gonggao',
    type: 'text',
  },
  {
    label: '腹围',
    keyName: 'fuwei',
    type: 'text',
  },
  {
    label: '胎心率',
    keyName: 'taixinl',
    type: 'text',
  },
  {
    label: '胎位',
    keyName: 'taiwei',
    type: 'select',
    require: true,
    dataSource: [
      { value: '0', label: '无' },
      { value: '1', label: '左枕前（LOA）' },
      { value: '2', label: '右枕前（ROA）' },
      { value: '3', label: '左枕后（LOP）' },
      { value: '4', label: '右枕后（ROP）' },
      { value: '5', label: '左枕横（LOT）' },
      { value: '6', label: '右枕横（ROT）' },
      { value: '7', label: '左颏前（LMA）' },
      { value: '8', label: '右颏前（RMA）' },
      { value: '9', label: '左颏后（LMP）' },
      { value: '10', label: '右颏后（RMP）' },
      { value: '11', label: '左颏横（LMT）' },
      { value: '12', label: '右颏横（RMT）' },
      { value: '13', label: '左骶前（LSA）' },
      { value: '14', label: '右骶前（RSA）' },
      { value: '15', label: '左骶后（LSP）' },
      { value: '16', label: '右骶后（RSP）' },
      { value: '17', label: '左骶横（LST）' },
      { value: '18', label: '右骶横（RST）' },
      { value: '19', label: '左肩前（LScA）' },
      { value: '20', label: '右肩前（RscA）' },
      { value: '21', label: '左肩后（LScP）' },
      { value: '22', label: '右肩后（RScP）' },
      { value: '99', label: '不详' },
    ],
  },
  {
    label: '多胎胎心率',
    keyName: 'duotaitxl',
    type: 'text',
  },
  {
    label: '	多胎胎位',
    keyName: 'duotaitw',
    type: 'text',
  },
  {
    label: '先露固定',
    keyName: 'xianlugd',
    type: 'select',
    dataSource: [
      { value: '1', label: '未查' },
      { value: '2', label: '否' },
      { value: '3', label: '半入盆' },
      { value: '4', label: '入盆' },
    ],
  },
  {
    label: '水肿',
    keyName: 'shuizhong',
    type: 'select',
    dataSource: [
      { value: '1', label: '阴性' },
      { value: '2', label: '±' },
      { value: '3', label: '+' },
      { value: '4', label: '++' },
      { value: '5', label: '+++' },
      { value: '6', label: '++++' },
      { value: '9', label: '未查' },
    ],
  },
  {
    label: '铁蛋白',
    keyName: 'tiedanbai',
    type: 'text',
  },
  {
    label: '糖化血红蛋白',
    keyName: 'tanghuaxhdb',
    type: 'text',
  },
  {
    label: 'NST',
    keyName: 'nst',
    type: 'select',
    dataSource: [
      { value: '1', label: '反应型' },
      { value: '2', label: '可疑型' },
      { value: '3', label: '无反应型' },
      { value: '4', label: '不详' },
      { value: '5', label: '双胎' },
      { value: '9', label: '无' },
    ],
  },
  {
    label: 'NST其它',
    keyName: 'nstqt',
    type: 'text',
  },
  {
    label: '血红蛋白值',
    keyName: 'xuehongdb',
    type: 'text',
  },
  {
    label: '白细胞计数值',
    keyName: 'bxbsz',
    type: 'text',
  },
  {
    label: '血小板计数值',
    keyName: 'hxbsz',
    type: 'text',
  },
  {
    label: '其他',
    keyName: 'qita',
    type: 'text',
  },
  {
    label: '尿蛋白',
    keyName: 'ndb',
    type: 'select',
    dataSource: [
      { value: '1', label: '阴性' },
      { value: '2', label: '±' },
      { value: '3', label: '+' },
      { value: '4', label: '++' },
      { value: '5', label: '+++' },
      { value: '6', label: '++++' },
      { value: '9', label: '未查' },
    ],
  },
  {
    label: '尿蛋白详细',
    keyName: 'ndbxx',
    type: 'text',
  },
  {
    label: '尿糖',
    keyName: 'niaotang',
    type: 'select',
    dataSource: [
      { value: '1', label: '阴性' },
      { value: '2', label: '±' },
      { value: '3', label: '+' },
      { value: '4', label: '++' },
      { value: '5', label: '+++' },
      { value: '6', label: '++++' },
      { value: '9', label: '未查' },
    ],
  },
  {
    label: '尿糖详细',
    keyName: 'niaotangxx',
    type: 'text',
  },
  {
    label: '尿酮体',
    keyName: 'niaotongti',
    type: 'select',
    dataSource: [
      { value: '1', label: '阴性' },
      { value: '2', label: '±' },
      { value: '3', label: '+' },
      { value: '4', label: '++' },
      { value: '5', label: '+++' },
      { value: '6', label: '++++' },
      { value: '9', label: '未查' },
    ],
  },
  {
    label: '尿酮体详细',
    keyName: 'niaotongtixx',
    type: 'text',
  },
  {
    label: '尿潜血',
    keyName: 'niaoqianxue',
    type: 'select',
    dataSource: [
      { value: '1', label: '阴性' },
      { value: '2', label: '±' },
      { value: '3', label: '+' },
      { value: '4', label: '++' },
      { value: '5', label: '+++' },
      { value: '6', label: '++++' },
      { value: '9', label: '未查' },
    ],
  },
  {
    label: '尿潜血详细',
    keyName: 'niaoqianxuexx',
    type: 'text',
  },
  {
    label: '尿白细胞',
    keyName: 'niaobaixibao',
    type: 'select',
    dataSource: [
      { value: '1', label: '阴性' },
      { value: '2', label: '±' },
      { value: '3', label: '+' },
      { value: '4', label: '++' },
      { value: '5', label: '+++' },
      { value: '6', label: '++++' },
      { value: '9', label: '未查' },
    ],
  },
  {
    label: '尿白细胞详细',
    keyName: 'niaobaixibaoxx',
    type: 'text',
  },
  {
    label: '其他',
    keyName: 'qita2',
    type: 'text',
  },
  {
    label: 'ABO',
    keyName: 'abo',
    type: 'select',
    dataSource: [
      { value: '0', label: 'O型' },
      { value: '1', label: 'A型' },
      { value: '2', label: 'B型' },
      { value: '3', label: 'AB型' },
      { value: '4', label: '不详' },
      { value: '9', label: '未查' },
    ],
  },
  {
    label: 'Rh*',
    keyName: 'rh',
    type: 'text',
  },
  {
    label: '血糖类型',
    keyName: 'xuetanglx',
    type: 'select',
    dataSource: [
      { value: '1', label: '空腹血糖' },
      { value: '2', label: 'GCT血糖' },
      { value: '3', label: 'OGTT血糖' },
    ],
  },
  {
    label: '空腹血糖',
    keyName: 'xuetang',
    type: 'text',
  },
  {
    label: 'GCT',
    keyName: 'gct',
    type: 'text',
  },
  {
    label: 'OGTT1',
    keyName: 'ogtt1',
    type: 'text',
  },
  {
    label: 'OGTT2',
    keyName: 'ogtt2',
    type: 'text',
  },
  {
    label: 'OGTT3',
    keyName: 'ogtt3',
    type: 'text',
  },
  {
    label: '餐后2小时血糖',
    keyName: 'ch2xsxt',
    type: 'text',
  },
  {
    label: '血清谷丙转氨酶',
    keyName: 'xqgbzam',
    type: 'text',
  },
  {
    label: '血清谷草转氨酶',
    keyName: 'xqgczam',
    type: 'text',
  },
  {
    label: '白蛋白',
    keyName: 'baidanbai',
    type: 'text',
  },
  {
    label: '总胆红素',
    keyName: 'zongdanhs',
    type: 'text',
  },
  {
    label: '结合胆红素',
    keyName: 'jiehedhs',
    type: 'text',
  },
  {
    label: '总胆汁酸',
    keyName: 'zongdanzs',
    type: 'text',
  },
  {
    label: '血清肌酐',
    keyName: 'xueqingjg',
    type: 'text',
  },
  {
    label: '血尿素氮',
    keyName: 'xueniaosd',
    type: 'text',
  },
  {
    label: '凝血酶',
    keyName: 'ningxuemei',
    type: 'text',
  },
  {
    label: '纤维蛋白',
    keyName: 'xianweidb',
    type: 'text',
  },
  {
    label: '阴道分泌物',
    keyName: 'yindaofmw',
    type: 'select',
    dataSource: [
      { value: '1', label: '未见异常' },
      { value: '2', label: '滴虫' },
      { value: '3', label: '假丝酵母菌' },
      { value: '4', label: '其他' },
      { value: '9', label: '不详' },
    ],
  },
  {
    label: '阴道分泌物详细',
    keyName: 'yindaofmwxx',
    type: 'text',
  },
  {
    label: '阴道清洁度',
    keyName: 'yindaoqjd',
    type: 'select',
    dataSource: [
      { value: '1', label: 'I度' },
      { value: '2', label: 'II度' },
      { value: '3', label: 'III度' },
      { value: '4', label: 'IV度' },
      { value: '9', label: '未查' },
    ],
  },
  {
    label: '沙眼衣原体',
    keyName: 'shayanyiyt',
    type: 'select',
    dataSource: [
      { value: '0', label: '未查' },
      { value: '1', label: '阴性' },
      { value: '2', label: '阳性' },
      { value: '3', label: '已查暂无结果' },
    ],
  },
  {
    label: '淋球菌检测',
    keyName: 'linqiujunjc',
    type: 'select',
    dataSource: [
      { value: '0', label: '阴性' },
      { value: '1', label: '阳性' },
      { value: '2', label: '未查' },
    ],
  },
  {
    label: '乙肝表面抗原',
    keyName: 'yiganbmky',
    type: 'select',
    dataSource: [
      { value: '1', label: '阴性' },
      { value: '2', label: '阳性' },
      { value: '9', label: '不详' },
    ],
  },
  {
    label: '乙肝表面抗体',
    keyName: 'yiganbmkt',
    type: 'select',
    dataSource: [
      { value: '1', label: '阴性' },
      { value: '2', label: '阳性' },
      { value: '9', label: '不详' },
    ],
  },
  {
    label: '乙肝e抗原',
    keyName: 'yiganeky',
    type: 'select',
    dataSource: [
      { value: '1', label: '阴性' },
      { value: '2', label: '阳性' },
      { value: '9', label: '不详' },
    ],
  },

  {
    label: '乙肝e抗体',
    keyName: 'yiganekt',
    type: 'select',
    dataSource: [
      { value: '1', label: '阴性' },
      { value: '2', label: '阳性' },
      { value: '9', label: '不详' },
    ],
  },
  {
    label: '乙肝核心抗体',
    keyName: 'yiganhxkt',
    type: 'select',
    dataSource: [
      { value: '1', label: '阴性' },
      { value: '2', label: '阳性' },
      { value: '9', label: '不详' },
    ],
  },

  {
    label: '梅毒血清学试验',
    keyName: 'meiduxqxsy',
    type: 'select',
    dataSource: [
      { value: '1', label: '阴性' },
      { value: '2', label: '阳性' },
      { value: '9', label: '不详' },
    ],
  },

  {
    label: '梅毒血清学试验详细',
    keyName: 'meiduxqxsyxx',
    type: 'text',
  },
  {
    label: 'HIV抗体检测',
    keyName: 'hivktjc',
    type: 'select',
    dataSource: [
      { value: '1', label: '阴性' },
      { value: '2', label: '阳性' },
      { value: '9', label: '不详' },
    ],
  },

  {
    label: 'B超检查结果',
    keyName: 'bchaoxs',
    type: 'text',
  },
  {
    label: '心电图',
    keyName: 'xdt',
    type: 'text',
  },
  {
    label: '	TSH',
    keyName: 'tsh',
    type: 'text',
  },
  {
    label: '	FT3',
    keyName: 'ft3',
    type: 'text',
  },
  {
    label: '	FT4',
    keyName: 'ft4',
    type: 'text',
  },
  {
    label: 'TT3',
    keyName: 'tt3',
    type: 'text',
  },
  {
    label: 'TT4',
    keyName: 'tt4',
    type: 'text',
  },
  {
    label: 'ATG',
    keyName: 'atg',
    type: 'text',
  },
  {
    label: 'ATPO',
    keyName: 'atpo',
    type: 'text',
  },
  {
    label: '宫颈脱落细胞检查',
    keyName: 'gongjingtuoluoxbjc',
    type: 'select',
    dataSource: [
      { value: '1', label: '正常' },
      { value: '2', label: '异常' },
      { value: '3', label: '未查' },
    ],
  },
  {
    label: '其他检查详述',
    keyName: 'qitafzjc',
    type: 'text',
  },
  {
    label: '免费超声检查',
    keyName: 'mianfeichaoshengjc',
    type: 'select',
    require: true,
    dataSource: [
      { value: '1', label: '有' },
      { value: '2', label: '无' },
    ],
  },

  {
    label: '是否高危',
    keyName: 'gaoweibz',
    type: 'select',
    require: true,
    dataSource: [
      { value: '1', label: '是' },
      { value: '2', label: '否' },
      { value: '3', label: '不详' },
    ],
  },
  {
    label: '高危因素',
    keyName: 'gaoweiysdm',
    type: 'select',
    dataSource: gaoweiysdm,
    fieldProps: {
      mode: 'multiple'
    },
    convertValue: (value)=> {
      return !Array.isArray(value) ? value?.split(',') : value;
    }
  },
  {
    label: '高危因素中文',
    keyName: 'gaoweiysmc',
    type: 'text',
  },
  {
    label: '其他高危因素',
    keyName: 'gaoweiqt',
    type: 'text',
  },
  {
    label: '高危评级',
    require: true,
    keyName: 'gaoweijbdm',
    type: 'select',
    dataSource: [
      { value: '1', label: '绿色' },
      { value: '2', label: '黄色' },
      { value: '3', label: '橙色' },
      { value: '4', label: '红色' },
      { value: '5', label: '紫色' },
      { value: '2/5', label: '黄色+紫色' },
      { value: '3/5', label: '橙色+紫色' },
      { value: '4/5', label: '红色+紫色' },
    ],
  },
  {
    label: '高危评级中文',
    keyName: 'gaoweijbmc',
    type: 'text',
  },
  {
    label: '传染病',
    keyName: 'chuanranbing',
    type: 'select',
    require: true,
    dataSource: [
      { value: '1', label: '无' },
      { value: '5', label: '紫色' },
    ],
  },
  {
    label: '分类',
    keyName: 'fenlei',
    type: 'select',
    dataSource: [
      { value: '1', label: '未见异常' },
      { value: '2', label: '异常' },
    ],
  },
  {
    label: '分类详诉',
    keyName: 'fenleixs',
    type: 'text',
  },
  {
    label: '指导',
    keyName: 'zhidao',
    type: 'select',
    dataSource: [
      { value: '1', label: '个人(口腔)卫生' },
      { value: '2', label: '膳食营养' },
      { value: '3', label: '心理' },
      { value: '4', label: '运动' },
      { value: '5', label: '自我监测' },
      { value: '6', label: '分娩准备' },
      { value: '7', label: '母乳喂养' },
      { value: '8', label: '其他' },
    ],
    fieldProps: {
      mode: 'multiple'
    },
    convertValue: (value)=> {
      return !Array.isArray(value) ? value?.split(',') : value;
    }
  },
  {
    label: '处理意见',
    keyName: 'chuliyijian',
    type: 'text',
  },
  {
    label: '转诊',
    keyName: 'zhuanzhenbz',
    type: 'select',
    dataSource: [
      { value: '1', label: '有' },
      { value: '2', label: '无' },
    ],
  },
  {
    label: '转诊原因',
    keyName: 'zhuanzhenyy',
    type: 'text',
  },
  {
    label: '转诊科室',
    keyName: 'zhuanrukeshi',
    type: 'text',
  },
  {
    label: '参加孕妇学校',
    keyName: 'canjiayfxx',
    type: 'text',
  },
  {
    label: '病(孕)情记录',
    keyName: 'bingqingjl',
    type: 'text',
  },
  {
    label: '下次随访日期',
    keyName: 'xiacisfrq',
    type: 'date',
    require: true,
  },
  {
    label: '录入日期',
    keyName: 'lururiqi',
    type: 'date',
    require: true,
  },
  {
    label: '免费超声报告',
    keyName: 'mianfeichaoshengbg',
    type: 'select',
    dataSource: [
      { value: '1', label: '未见明显异常' },
      { value: '2', label: '其他' },
    ],
  },
  {
    label: '免费超声报告详情',
    keyName: 'mianfeichaoshengbgxx',
    type: 'text',
  },
  {
    label: '是否转诊',
    keyName: 'shifouzhuanzhen',
    type: 'select',
    dataSource: [
      { value: '1', label: '是' },
      { value: '2', label: '否' },
    ],
  },
  {
    label: '转至',
    keyName: 'zhuanzhi',
    type: 'text',
  },
  {
    label: '复查报告',
    keyName: 'fuchabg',
    type: 'select',
    dataSource: [
      { value: '1', label: '未见明显异常' },
      { value: '2', label: '其他' },
    ],
  },
  {
    label: '复查报告详情',
    keyName: 'fuchabgxx',
    type: 'text',
  },
  {
    label: '孕妇意愿',
    keyName: 'yiyuan',
    type: 'select',
    dataSource: [
      { value: '1', label: '终止妊娠' },
      { value: '2', label: '继续妊娠' },
    ],
  },
];

import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { VSFormItem, VSForm } from '@vs/vsf-kit';
import VsfFormLayout, { VsfFormLayoutDefine } from '@/components/form-layout';
import {
  ReportFromArrayProps,
  formatPlaceholder,
  reportFromArrayFirst,
  reportFromArraySecond,
  reportFromArrayThird,
} from '../common';

const FormItem = (item: ReportFromArrayProps) => {
  return (
    <VSFormItem
      label={item.label}
      name={[item.keyName]}
      valueType={item.type}
      transform={item.transform}
      convertValue={item.convertValue}
      rules={
        item.require
          ? [
              {
                required: true,
                message: `'请填写${item.label}'`,
              },
            ]
          : undefined
      }
      fieldProps={{ placeholder: formatPlaceholder[item.type], ...item.fieldProps }}
      dataSource={item.dataSource}
    />
  );
};

const ReportForm = (
  { formItemData }: { formItemData: Record<string, any> },
  ref: any,
) => {
  const formWarpRef = useRef(null);
  useImperativeHandle(ref, () => ({
    formWarpRef: formWarpRef.current,
  }));
  return (
    <>
      <VSForm
        dataType="static"
        layout="horizontal"
        formRef={formWarpRef}
        labelCol={{ style: { width: 150 } }}
        initialValues={formItemData}
      >
        <VsfFormLayout columnCount={3}>
          {reportFromArrayFirst.map(item => FormItem(item))}
        </VsfFormLayout>
        <VsfFormLayoutDefine columnSpan={[1, 2]}>
          {reportFromArraySecond.map(item => FormItem(item))}
        </VsfFormLayoutDefine>
        <VsfFormLayout columnCount={3}>
          {reportFromArrayThird.map(item => FormItem(item))}
        </VsfFormLayout>
      </VSForm>
    </>
  );
};

export default forwardRef(ReportForm);

import { cloneDeep, pickBy } from "lodash";

const pickByNull = (data) => {
    return data !== null && data !== undefined;
}

export const pickObject = (obj) => {
    return pickBy(obj, pickByNull);
}

/**
 * 格式化 form 出参
 * @param object 外部对象
 * @returns 
 */
export const unwrapObject = (object, areaKeys) => {
    if (typeof object !== 'object') return object;
    let obj = {};
    if (object) { 
        const keys = Object.keys(object);
        if (Array.isArray(keys)) {
            keys.forEach(key => {
                if (typeof object[key] === 'object' && object[key]) {
                    if (!Array.isArray(object[key])) {
                        obj = {
                            ...obj,
                            ...object[key]
                        }
                    } else {
                        if (areaKeys?.includes(key)) {
                            const len = object[key]?.length;
                            if (len > 0) {
                                const target = (object[key]?.[len - 1] || '').split('-');
                                obj = {
                                    ...obj,
                                    // 为什么有 "null" 数据.
                                    [`${key}Province`]: target?.[0] === 'null' ? null : target?.[0],
                                    [`${key}City`]: target?.[1] === 'null' ? null : target?.[1],
                                    [`${key}District`]: target?.[2] === 'null' ? null : target?.[2],
                                }  
                            }
                        }
                    }

                } else {
                    obj[key] = object[key];
                }
            });
        } else {
            obj = object;
        }
    }
    return obj;
}


/**
 * 合并字段 以满足 form 自定义组件的入参要求
 * @param object 外部对象
 * @returns 
 */
export const wrapObject = (object, type = 'all') => {
    if (typeof object !== 'object') return object;
    let obj = cloneDeep(object || {});
    if (object) { 
        const keys = Object.keys(object);
        if (Array.isArray(keys)) {
            keys.forEach(key => {
                if (type === 'all' || type === 'range' || type?.includes('range')) {
                    obj = {
                        ...obj,
                        ...wrapRangeObject(object, key)
                    }
                }
                if (type === 'all' || type === 'address' || type?.includes('address')) {
                    obj = {
                        ...obj,
                        ...wrapAddressObject(object, key)
                    }
                }
                if (type === 'all' || type === 'detail') {
                    obj = {
                        ...obj,
                        ...wrapDetailObject(object, key)
                    }
                }
            });
        }
    }
    return pickBy(obj, pickByNull);
}

export const wrapAddressObject = (object, key) => { 
    const obj = {};
    const cascaderKey = key.replace(/Province|City|District/s, '');
    if (object[`${cascaderKey}Province`] || object[`${cascaderKey}City`] || object[`${cascaderKey}District`]) {
        if (object[`${cascaderKey}Province`] && object[`${cascaderKey}City`] && object[`${cascaderKey}District`]) {
            obj[cascaderKey] = [`${object[`${cascaderKey}Province`]}`, `${object[`${cascaderKey}Province`]}-${object[`${cascaderKey}City`]}`, `${object[`${cascaderKey}Province`]}-${object[`${cascaderKey}City`]}-${object[`${cascaderKey}District`]}`]
        } else if (object[`${cascaderKey}Province`] && object[`${cascaderKey}City`]) {
            obj[cascaderKey] = [`${object[`${cascaderKey}Province`]}`, `${object[`${cascaderKey}Province`]}-${object[`${cascaderKey}City`]}`]
        } else if (object[`${cascaderKey}Province`]) {
            obj[cascaderKey] = [`${object[`${cascaderKey}Province`]}`];
        }
    }
    return obj;
}

export const wrapRangeObject = (object, key) => { 
    const obj = {};
    const rangeKey = key.replace(/Max|Min/s, '');
    if (object.hasOwnProperty([`${rangeKey}Min`]) || object.hasOwnProperty([`${rangeKey}Max`])) {
        obj[rangeKey] = {
            [`${rangeKey}Max`]: object[`${rangeKey}Max`],
            [`${rangeKey}Min`]: object[`${rangeKey}Min`],
        }
    }
    return obj;
}

export const wrapDetailObject = (object, key) => { 
    const obj = {};
    if (object.hasOwnProperty([`${key}Detail`])) {
        if (typeof object[key] === 'boolean') {
            obj[key] = {
                [key]: object[key],
                [`${key}Detail`]: object[`${key}Detail`]
            }
        } else { 
            obj[key] = object[key];
        }
    }
    return obj;
}

export default {}
import { cloneDeep } from "lodash";
import { formatDate } from '@/utils/rule';

export const getExaminationFormat = (data) => { 
    const target = cloneDeep(data);
    const { qto = {}, ext } = data || {};
    const { createdAtRangeIn, highriskLevelIn } = qto;
    if (target) {
        const hasInfectiousIs = (highriskLevelIn || [])?.includes('PURPLE') ? true : null;
        target.qto.hasInfectiousIs = hasInfectiousIs;
        if (hasInfectiousIs && highriskLevelIn?.length === 1) {
            Reflect.deleteProperty(target.qto, 'highriskLevelIn');
        }
        if (createdAtRangeIn) {
            target.qto.createdAtRangeIn = {
                begin: formatDate(createdAtRangeIn?.[0], 'YYYY-MM-DD 00:00:00'),
                end: formatDate(createdAtRangeIn?.[1], 'YYYY-MM-DD 23:59:59'),
                beginInclude: true,
                endInclude: true
            }
        }
    }
    return target;
}

export default {};